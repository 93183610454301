export const _BaseApi = {
  getHeaders: function (nocache, noloader) {
    let options = {
      headers: {},
    };

    if (nocache) {
      options.headers["Cache-Control"] = "no-cache";
    }

    if (noloader) {
      options.headers["h-background"] = noloader;
    }

    return options;
  },
};
