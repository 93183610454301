import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const EstimatesApi = {
  apiName: "EstimatesApi",
  baseUrl: "/webapp/businesses",

  acceptAsync: async function (businessId, estimateId, linkId, payload) {
    const fnName = "acceptAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      linkId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/publiclinks/${linkId}/accept`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  revise: async function (businessId, estimateId) {
    const fnName = "revise";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/revise`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  createPublicLinkAsync: async function (businessId, estimateId) {
    const fnName = "createPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/publiclinks`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    let options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/estimates`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  getPublicLinkAsync: async function (businessId, estimateId, linkId) {
    const fnName = "getPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      linkId
    );

    const response = await api.get(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}/publiclinks/${linkId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  update: async function (businessId, estimateId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      payload
    );

    const response = await api.put(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);

    return response && response.data;
  },

  delete: async function (businessId, estimateId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    let response = await api.delete(
      `${this.baseUrl}/${businessId}/estimates/${estimateId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);
  },
};
