import { OpenBankingApi } from "../../OpenBankingApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY_ACCOUNTS = "openbanking-accounts";
const KEY_TRANSACTIONS = "openbanking-transactions";

export const OpenBankingApi_Cache = {
  apiName: "OpenBankingApi_Cache",

  connectAsync: async function (businessId, returnUrl) {
    const fnName = "connectAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      returnUrl
    );

    let response = await OpenBankingApi.connectAsync(businessId, returnUrl);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },

  disableAsync: async function (businessId, accountId) {
    const fnName = "disableAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      accountId
    );

    const { $expiresOn: eoAccounts } = atomCache(businessId, KEY_ACCOUNTS, []);
    const { $expiresOn: eoTransactions } = atomCache(
      businessId,
      KEY_TRANSACTIONS
    );
    eoAccounts.set(new Date());
    eoTransactions.set(new Date());

    let response = await OpenBankingApi.disableAsync(businessId, accountId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },

  getAccounts: async function (businessId, nocache, noloader) {
    const fnName = "getAccountsAsync";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY_ACCOUNTS, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await OpenBankingApi.getAccounts(
        businessId,
        nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  getTransactionsAsync: async function (
    businessId,
    accountId,
    nocache,
    noloader
  ) {
    const fnName = "getTransactionsAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      accountId
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY_TRANSACTIONS, []);

    let cacheData = $data && $data.get();
    let cacheDataByAccountId =
      cacheData &&
      cacheData.filter((x) => x && x.account && x.account.uuid === accountId);

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || !cacheDataByAccountId || !cacheDataByAccountId.length) {
      cacheDataByAccountId = await OpenBankingApi.getTransactionsAsync(
        businessId,
        accountId,
        nocache,
        noloader
      );

      cacheData = cacheData.filter(
        (item) => item && item.account && item.account.uuid !== accountId
      );
      cacheData = [...cacheData, ...cacheDataByAccountId];

      $data.set([...cacheData]); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `,
      cacheDataByAccountId
    );

    return cacheDataByAccountId || [];
  },

  reConnectAsync: async function (businessId, accountId) {
    const fnName = "reConnectAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      accountId
    );

    const { $expiresOn: eoAccounts } = atomCache(businessId, KEY_ACCOUNTS, []);
    const { $expiresOn: eoTransactions } = atomCache(
      businessId,
      KEY_TRANSACTIONS
    );
    eoAccounts.set(new Date());
    eoTransactions.set(new Date());

    let response = await OpenBankingApi.reConnectAsync(businessId, accountId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },
};
