import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { OpenBankingApi_Cache } from "../api/decorators/cache/OpenBankingApi_Cache";

import { Movements } from "../components/Movements";
import { BankAccountResume } from "../components/BankAccountResume";
import { BankAccountDetail } from "../components/BankAccountDetail";

export default function BankAccount() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const navigate = useNavigate();
  const { id } = useParams();

  const [obAccount, setOBAccount] = useState({});
  const [obTransactions, setOBTransactions] = useState([]);

  const fetchReconnect = async (accountId) => {
    let response = await OpenBankingApi_Cache.reConnectAsync(
      businessId,
      accountId
    );
    if (!response) {
      console.log("Response is empty");
      return;
    }
    console.log("Reconnect link:", response);
    window.open(response, "_blank").focus();
  };

  const fetchDisable = async (accountId) => {
    await OpenBankingApi_Cache.disableAsync(businessId, accountId);
    navigate("/bankAccounts?reset=true");
  };

  useEffect(() => {
    const fetchObAccounts = async () => {
      let obAccounts = await OpenBankingApi_Cache.getAccounts(businessId);
      let currAccount = obAccounts.find((x) => x.uuid === id);
      setOBAccount(currAccount);
    };
    fetchObAccounts();
  }, [businessId, id]);

  useEffect(() => {
    const fetchObTransactions = async () => {
      if (!obAccount?.uuid) return;

      let results = await OpenBankingApi_Cache.getTransactionsAsync(
        businessId,
        obAccount.uuid
      );

      results = results.map((r, index) => {
        r.account = obAccount;
        return r;
      });

      setOBTransactions(results);
    };
    fetchObTransactions();
  }, [businessId, obAccount]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Conti</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/bankAccounts">Conti</Link>
                    </li>
                    <li className="breadcrumb-item active">Tutti i Conti</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {!!obAccount && (
            <>
              <div className="row">
                <div className="col-xl-4">
                  <BankAccountDetail
                    account={obAccount}
                    transactions={obTransactions}
                    fnAccountReconnection={fetchReconnect}
                    fnAccountDisable={fetchDisable}
                  />
                </div>
                <div className="col-xl-8">
                  <BankAccountResume
                    accounts={[obAccount]}
                    transactions={obTransactions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {id && (
                    <Movements
                      useSearch={true}
                      top={undefined}
                      obAccountId={id}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
