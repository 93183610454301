import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export const HoloTooltip = ({ id, description, className, onClick }) => {
  const doNothing = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <span className={className}>
        <Link
          onClick={onClick || doNothing}
          data-tooltip-id={id}
          data-tooltip-content={description}
        >
          <i class="bx bx-info-circle"></i>
        </Link>
        <Tooltip id={id} />
      </span>
    </>
  );
};
