import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { OpenBankingApi_Cache } from "../api/decorators/cache/OpenBankingApi_Cache";
import { StripeApi_Cache } from "../api/decorators/cache/StripeApi_Cache";

import { BankAccountsAggregate } from "../components/BankAccountsAggregate";
import { Movements } from "../components/Movements";
import { BankAccountResume } from "../components/BankAccountResume";
import { OpenBankingCTA } from "../components/OpenBankingCTA";
import {
  CardSimpleCTA,
  button as CardSimpleCTAButton,
} from "../components/CardSimpleCTA";
import { Col, Container, Row } from "reactstrap";

export default function BankAccounts() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [obAccounts, setOBAccounts] = useState([]);
  const [obTransactions, setOBTransactions] = useState([]);

  useEffect(() => {
    const fetchObAccounts = async () => {
      setOBAccounts(await OpenBankingApi_Cache.getAccounts(businessId));
    };
    fetchObAccounts();
  }, []);

  const handleLoadOBTransactions = async () => {
    if (!obAccounts?.length) return;

    let accountPromises = obAccounts.map((a) => {
      return OpenBankingApi_Cache.getTransactionsAsync(businessId, a.uuid);
    });

    let promises = await Promise.all(accountPromises);

    let results = promises.map((p) => {
      return p;
    });

    results = results.flat(1);

    results = results.map((r, index) => {
      r.account = obAccounts.find((a) => a.uuid === r.account.uuid);
      return r;
    });

    setOBTransactions(results);
  };

  useEffect(() => {
    handleLoadOBTransactions();
  }, [obAccounts]);

  const handleConnectClick = async (type) => {
    switch (type) {
      case 1:
        handleConnectOpenBanking();
        break;
      case 2:
        handleConnectStripe();
        break;
      default:
        console.log(`Unmanaged type`, type);
        break;
    }
  };

  const handleConnectOpenBanking = async () => {
    let returnUrl = `${window.location.origin}?reset=true`;

    let connectUrl = await OpenBankingApi_Cache.connectAsync(
      businessId,
      returnUrl
    );
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  const handleConnectStripe = async () => {
    let connectUrl = await StripeApi_Cache.connectAsync(businessId);
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Conti</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/bankAccounts">Conti</Link>
                  </li>
                  <li className="breadcrumb-item active">Tutti i Conti</li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>

        {!obAccounts?.length && !business.stripeConnectId ? (
          <>
            <OpenBankingCTA showCta={false} />
            <CardSimpleCTA
              number={"!"}
              headerLink={`#`}
              title={`Scegli come vuoi esser pagato`}
              subtitle={`Stripe: il sistema più semplice di incasso. OpenBanking: il nuovo sistema per incassare con bonifico tramite un semplice link di pagamento`}
              buttons={[
                CardSimpleCTAButton(
                  `Attiva Stripe`,
                  handleConnectStripe,
                  `bx-link`
                ),
                CardSimpleCTAButton(
                  `Collega OpenBanking`,
                  handleConnectOpenBanking,
                  `bx-link`
                ),
              ]}
            />
          </>
        ) : (
          <>
            <Row>
              <Col xxl={4}>
                <BankAccountsAggregate
                  accounts={obAccounts}
                  transactions={obTransactions}
                  onConnectClick={handleConnectClick}
                />
              </Col>
              <Col xxl={8}>
                <BankAccountResume
                  accounts={obAccounts}
                  transactions={obTransactions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Movements useSearch={true} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}
