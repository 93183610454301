import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";

import { SubscriptionDetailsCard } from "../components/SubscriptionDetailsCard";
import { CardTable, CardTableUtil } from "../components/CardTable";
import { PageHeader } from "../components/PageHeader";

export default function SubscriptionDetails() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [subscription, setSubscription] = useState(null);
  const [contact, setContact] = useState(null);
  const [orderModel, setOrderModel] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const handleOnLoad = async () => {
    let subscription = (await SubscriptionsApi_Cache.get(businessId)).find(
      (x) => x.rowKey === id
    );
    console.log("Subscription: ", subscription);
    setSubscription(subscription);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleOnLoad();
  }, [id]);

  const fetchContact = async () => {
    if (!subscription) return;

    let contact = (await ContactsApi_Cache.get(businessId)).find(
      (x) => x.rowKey === subscription.contactId
    );

    setContact(contact);
  };

  const fetchOrder = async () => {
    if (!subscription) return;

    let orderModel = (await OrdersApi_Cache.get(businessId)).find(
      (x) => x.order.rowKey === subscription.orderId
    );

    setOrderModel(orderModel);
  };

  const fetchTransactions = async () => {
    if (!subscription) return;

    let transactions = (await TransactionsApi_Cache.get(businessId)).filter(
      (x) => x.subscriptionId === subscription.rowKey
    );

    setTransactions(transactions);
  };

  const [transactionsCols, setTransactionsCols] = useState([
    {
      key: "expirationDate",
      value: "Data prelievo",
    },
    {
      key: "amount",
      value: "Importo",
    },
    {
      key: "status",
      value: "Stato",
    },
  ]);
  const [transactionsRows, setTransactionsRows] = useState([]);
  const loadCardTableTransactions = () => {
    if (!transactions?.length) return;

    let tblRows = transactions
      .sort((a, b) => {
        const dateA = new Date(a.expirationDate);
        const dateB = new Date(b.expirationDate);
        return dateA - dateB;
      })
      .map((x) => {
        return {
          expirationDate: CardTableUtil.getCellLink(
            `${_BaseUtil.formatDate(x.expirationDate, "it-IT")}`,
            "text-primary",
            `/payment/${x.rowKey}/details`
          ),
          amount: CardTableUtil.getCellText(
            `${_BaseUtil.formatCurrency(x.currency, x.amountGross, "it-IT")}`
          ),
          status: CardTableUtil.getCellBadge(
            `${TransactionsUtil.getStatusDescription(x)}`,
            null,
            `${TransactionsUtil.getStatusBadge(x.status)}`
          ),
        };
      });

    setTransactionsRows(tblRows);
  };

  useEffect(() => {
    fetchContact();
    fetchOrder();
    fetchTransactions();
  }, [subscription]);

  useEffect(() => {
    loadCardTableTransactions();
  }, [transactions]);

  return (
    !!subscription && (
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <PageHeader
                title={`Dettaglio sottoscrizione: ${subscription.serviceName}`}
                pageName={`Dettaglio sottoscrizione`}
                backPageName={`Sottoscrizioni`}
                backPageRoute={`/subscriptions`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4">
              <SubscriptionDetailsCard
                subscription={subscription}
                contact={contact}
                order={orderModel.order}
              />
            </div>
            {!!transactionsRows.length && (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`subscriptiondetails-card-table-transactions`}
                  title={`Piano rateale`}
                  columnNames={transactionsCols}
                  values={transactionsRows}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}
