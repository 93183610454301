import React from "react";
import { Link } from "react-router-dom";

export const PageHeader = ({
  title,
  pageName,
  backPageName,
  backPageRoute,
}) => {
  return (
    <>
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0 font-size-18">{title}</h4>
        <div className="page-title-right">
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to={backPageRoute}>{backPageName}</Link>
            </li>
            <li className="breadcrumb-item active">{pageName}</li>
          </ol>
        </div>
      </div>
    </>
  );
};
