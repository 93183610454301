import { BusinessesApi } from "../../BusinessesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "businesses";

export const BusinessesApi_Cache = {
  apiName: "BusinessesApi_Cache",

  get: async function (nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache("", KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await BusinessesApi.get(nocache, noloader);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },

  create: async function (payload) {
    const fnName = "create";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache("", KEY, []);

    const newData = await BusinessesApi.create(payload);

    if (newData) $data.set([...$data.get(), newData]); //adding object to the existing array

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  update: async function (businessId, payload) {
    const fnName = "update";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache("", KEY, []);

    payload = await BusinessesApi.update(businessId, payload);

    if (payload) {
      const cachedValues = $data.get();
      const other = cachedValues.filter((item) => item.rowKey !== businessId);
      $data.set([...other, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },

  uploadContract: async function (businessId, filename, file) {
    const fnName = "update";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, filename, file);

    const { $data } = atomCache("", KEY, []);

    const result = await BusinessesApi.uploadContract(
      businessId,
      filename,
      file
    );

    if (result) {
      const currentBusinesses = $data.get();
      const others = currentBusinesses.filter(
        (item) => item.rowKey !== businessId
      );
      $data.set([...others, result]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, result);

    return result;
  },

  // getRecap: async function (businessId, nocache, noloader) {
  //   const fnName = "getRecap";

  //   console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

  //   let cacheData = $businessRecaps && $businessRecaps.get();

  //   let expirationDate = $expiresOn && $expiresOn.get();
  //   let currentDate = DateTime.utc();
  //   let isExpired =
  //     !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

  //   if (isExpired || nocache) {
  //     const result = await BusinessesApi.getRecap(
  //       businessId,
  //       nocache,
  //       noloader
  //     );

  //     if (result) {
  //       let cacheData = $businessRecaps.get();
  //       cacheData = cacheData.filter((item) => item.partitionKey);
  //       const others = cacheData.filter(
  //         (item) => item.partitionKey !== businessId
  //       );
  //       $businessRecaps.set([...others, result]);
  //     }

  //     const cacheDuration = currentDate.plus({
  //       seconds: process.env.REACT_APP_CACHE_DURATION,
  //     });
  //     $expiresOn.set(cacheDuration);
  //   }

  //   console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

  //   return cacheData ? cacheData.filter((x) => x) : [];
  // },
};
