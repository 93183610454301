import React from "react";
import { Link } from "react-router-dom";

export const DocumentDetailsCardItem = ({
  title,
  icon,
  route,
  routeText,
  className,
}) => {
  return (
    <>
      {!!title && (
        <h5 className="font-size-14">
          <i className={`text-primary me-1 ${icon}`}></i> {title}
        </h5>
      )}
      <p className="text-muted mb-0">
        {route ? (
          <Link to={route} className={className}>
            {routeText}
          </Link>
        ) : (
          <span className={className}>{routeText}</span>
        )}
      </p>
    </>
  );
};
