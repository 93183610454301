// AtecoCodeSelect.js
import React, { useEffect, useState } from "react";
import Select from "react-select";

import atecoCodes from "../../assets/atecoCodes.json";
import { _BaseUtil } from "../../utils/_BaseUtil";

const AtecoCodeSelect = ({
  id,
  name,
  values,
  selectedValue,
  onBlur,
  onChange,
  className,
}) => {
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    let result = atecoCodes.find((item) => item.value === selectedValue);
    setCurrentValue(result);
  }, [selectedValue]);

  return (
    <Select
      id={id}
      name={name}
      options={(values || [atecoCodes.map((x) => x.value)]).map((code) =>
        _BaseUtil.formatAtecoDescription(code)
      )}
      onBlur={onBlur}
      onChange={onChange}
      value={currentValue}
      className={className}
    />
  );
};

export default AtecoCodeSelect;
