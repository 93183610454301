import React, { useContext } from "react";
import Select from "react-select";

import { UserContext } from "../contexts/AppContexts";

import { UsersApi_Cache } from "../api/decorators/cache/UsersApi_Cache";

// Avatars aggiornati con i nomi
const avatars = [
  {
    path: "/assets/images/avatars/wired-lineal-269-avatar-female.gif",
    name: "Donna",
  },
  {
    path: "/assets/images/avatars/wired-lineal-408-worker-helmet.gif",
    name: "Lavoratore con elmetto",
  },
  {
    path: "/assets/images/avatars/wired-lineal-527-boy-young-men.gif",
    name: "Ragazzo giovane",
  },
  {
    path: "/assets/images/avatars/wired-lineal-528-girl-young-women.gif",
    name: "Ragazza giovane",
  },
  {
    path: "/assets/images/avatars/wired-lineal-671-male-doctor-care.gif",
    name: "Dottore",
  },
  {
    path: "/assets/images/avatars/wired-lineal-672-farmer.gif",
    name: "Contadino",
  },
  {
    path: "/assets/images/avatars/wired-lineal-673-female-doctor-care.gif",
    name: "Dottoressa",
  },
  {
    path: "/assets/images/avatars/wired-lineal-674-painter.gif",
    name: "Pittore",
  },
  {
    path: "/assets/images/avatars/wired-lineal-675-male-cook.gif",
    name: "Cuoco",
  },
  {
    path: "/assets/images/avatars/wired-lineal-676-female-cook.gif",
    name: "Cuoca",
  },
  {
    path: "/assets/images/avatars/wired-lineal-678-fireman.gif",
    name: "Pompiere",
  },
  {
    path: "/assets/images/avatars/wired-lineal-679-dentist.gif",
    name: "Dentista",
  },
  {
    path: "/assets/images/avatars/wired-lineal-680-it-developer.gif",
    name: "Sviluppatore IT",
  },
  {
    path: "/assets/images/avatars/wired-lineal-681-lawyer.gif",
    name: "Avvocato",
  },
  {
    path: "/assets/images/avatars/wired-lineal-682-male-customer-service.gif",
    name: "Assistenza clienti uomo",
  },
  {
    path: "/assets/images/avatars/wired-lineal-683-female-customer-service.gif",
    name: "Assistenza clienti donna",
  },
  {
    path: "/assets/images/avatars/wired-lineal-684-person-face-mask.gif",
    name: "Persona con mascherina",
  },
  {
    path: "/assets/images/avatars/wired-lineal-685-sailor-capitan.gif",
    name: "Capitano marinaio",
  },
  {
    path: "/assets/images/avatars/wired-lineal-686-nurse.gif",
    name: "Infermiere",
  },
  {
    path: "/assets/images/avatars/wired-lineal-687-spaceman-space.gif",
    name: "Astronauta",
  },
  {
    path: "/assets/images/avatars/wired-lineal-691-stewardess-flight-attendant.gif",
    name: "Assistente di volo",
  },
  {
    path: "/assets/images/avatars/wired-lineal-692-hairdresser-barber.gif",
    name: "Barbiere",
  },
  {
    path: "/assets/images/avatars/wired-lineal-693-singer-vocalist.gif",
    name: "Cantante",
  },
  {
    path: "/assets/images/avatars/wired-lineal-694-intern-male.gif",
    name: "Stagista uomo",
  },
  {
    path: "/assets/images/avatars/wired-lineal-695-woman-style-19.gif",
    name: "Stile donna 19",
  },
  { path: "/assets/images/avatars/wired-lineal-696-nun.gif", name: "Suora" },
  { path: "/assets/images/avatars/wired-lineal-697-priest.gif", name: "Prete" },
  { path: "/assets/images/avatars/wired-lineal-698-robber.gif", name: "Ladro" },
  {
    path: "/assets/images/avatars/wired-lineal-699-policeman.gif",
    name: "Poliziotto",
  },
  {
    path: "/assets/images/avatars/wired-lineal-700-prisoner.gif",
    name: "Prigioniero",
  },
  {
    path: "/assets/images/avatars/wired-lineal-701-mustache-beard.gif",
    name: "Uomo con barba e baffi",
  },
  {
    path: "/assets/images/avatars/wired-lineal-702-beard-barber.gif",
    name: "Barba barbiere",
  },
  {
    path: "/assets/images/avatars/wired-lineal-703-man-style-1.gif",
    name: "Stile uomo 1",
  },
  {
    path: "/assets/images/avatars/wired-lineal-704-man-style-2.gif",
    name: "Stile uomo 2",
  },
  {
    path: "/assets/images/avatars/wired-lineal-705-man-style-3.gif",
    name: "Stile uomo 3",
  },
  {
    path: "/assets/images/avatars/wired-lineal-706-man-style-4.gif",
    name: "Stile uomo 4",
  },
  {
    path: "/assets/images/avatars/wired-lineal-707-man-style-5.gif",
    name: "Stile uomo 5",
  },
  {
    path: "/assets/images/avatars/wired-lineal-708-man-style-6.gif",
    name: "Stile uomo 6",
  },
  {
    path: "/assets/images/avatars/wired-lineal-709-man-style-7.gif",
    name: "Stile uomo 7",
  },
  {
    path: "/assets/images/avatars/wired-lineal-710-man-style-8.gif",
    name: "Stile uomo 8",
  },
  {
    path: "/assets/images/avatars/wired-lineal-711-woman-style-9.gif",
    name: "Stile donna 9",
  },
  {
    path: "/assets/images/avatars/wired-lineal-712-woman-style-10.gif",
    name: "Stile donna 10",
  },
  {
    path: "/assets/images/avatars/wired-lineal-713-woman-style-11.gif",
    name: "Stile donna 11",
  },
  {
    path: "/assets/images/avatars/wired-lineal-714-woman-style-12.gif",
    name: "Stile donna 12",
  },
  {
    path: "/assets/images/avatars/wired-lineal-715-woman-style-13.gif",
    name: "Stile donna 13",
  },
  {
    path: "/assets/images/avatars/wired-lineal-716-woman-style-14.gif",
    name: "Stile donna 14",
  },
  {
    path: "/assets/images/avatars/wired-lineal-717-woman-style-15.gif",
    name: "Stile donna 15",
  },
  {
    path: "/assets/images/avatars/wired-lineal-718-woman-style-16.gif",
    name: "Stile donna 16",
  },
  {
    path: "/assets/images/avatars/wired-lineal-719-woman-style-17.gif",
    name: "Stile donna 17",
  },
  {
    path: "/assets/images/avatars/wired-lineal-720-woman-style-18.gif",
    name: "Stile donna 18",
  },
  {
    path: "/assets/images/avatars/wired-lineal-1065-goth-music-character.gif",
    name: "Personaggio musica goth",
  },
  {
    path: "/assets/images/avatars/wired-lineal-1072-reggae-music.gif",
    name: "Musica reggae",
  },
  { path: "/assets/images/avatars/wired-lineal-1252-dizz.gif", name: "Dizz" },
  {
    path: "/assets/images/avatars/wired-lineal-1275-fever.gif",
    name: "Febbre",
  },
  { path: "/assets/images/avatars/wired-lineal-1867-genie.gif", name: "Genio" },
  {
    path: "/assets/images/avatars/wired-lineal-1872-small-cute-monster.gif",
    name: "Piccolo mostro carino",
  },
  {
    path: "/assets/images/avatars/wired-lineal-1991-geisha.gif",
    name: "Geisha",
  },
  {
    path: "/assets/images/avatars/wired-lineal-1992-the-pope.gif",
    name: "Il Papa",
  },
  {
    path: "/assets/images/avatars/wired-lineal-2077-hacking.gif",
    name: "Hacking",
  },
  {
    path: "/assets/images/avatars/wired-lineal-268-avatar-man.gif",
    name: "Uomo",
  },
];

export const AvatarDropdown = () => {
  const { user, setUser } = useContext(UserContext);

  const updateAvatar = async (avatarPath) => {
    try {
      const updatedUser = await UsersApi_Cache.update({
        ...user,
        avatarPath,
        eTag: undefined,
      });

      if (updatedUser) {
        setUser(updatedUser);
      }
    } catch (error) {
      console.error("Errore nell'aggiornamento dell'avatar:", error);
    }
  };

  // Opzioni per react-select
  const options = avatars
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((avatar) => ({
      value: avatar.name,
      path: avatar.path,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={avatar.path}
            alt={avatar.name}
            style={{ width: 30, marginRight: 10 }}
          />
          {avatar.name}
        </div>
      ),
    }));

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "300px", // Imposta la larghezza al 100% del contenitore genitore
    }),
    control: (provided) => ({
      ...provided,
      width: "300px", // Imposta la larghezza al 100% per il controllo
    }),
  };

  return (
    <div className="dropdown">
      <Select
        options={options}
        onChange={(selectedOption) => updateAvatar(selectedOption.path)}
        getOptionLabel={(option) => option.label}
        className="basic-single"
        classNamePrefix="select"
        styles={customStyles} // Applica gli stili personalizzati
      />
    </div>
  );
};
