import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { AlertsApi_Cache } from "../api/decorators/cache/AlertsApi_Cache";
import { AlertsUtil } from "../utils/AlertsUtil";

export default function Alerts() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const navigate = useNavigate();

  const [alerts, setAlerts] = useState([]);

  const fetchAlerts = async () => {
    try {
      const fetchedAlerts = await AlertsApi_Cache.get(businessId);
      // Sort alerts by createdOn in descending order
      const sortedAlerts = fetchedAlerts.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
      setAlerts(sortedAlerts);
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const handleDeactivate = async (alertId) => {
    // if already inactive I won't make the call
    const alert = alerts.find((x) => x.rowKey === alertId);
    if (alert.status === -1) return;

    try {
      await AlertsApi_Cache.deactivate(businessId, alertId, true);
      // Fetch alerts after deactivation
      fetchAlerts();
    } catch (error) {
      console.error("Error deactivating alert:", error);
    }
  };

  const handleNotificationClick = async (alertId) => {
    await handleDeactivate(alertId); // No await to run in the background
    const alert = alerts.find((x) => x.rowKey === alertId);
    navigateTo(alert);
  };

  const navigateTo = (alert) => {
    if (!alert) return; // Handle the case when the alert is not found
    let recordId = alert.referenceRowKey;
    switch (alert.referenceTable?.toLowerCase()) {
      case "contact":
        navigate(`/contact/${recordId}/profile`);
        return;
      case "estimate":
        navigate(`/estimate/${recordId}/details`);
        return;
      case "order":
        navigate(`/order/${recordId}/details`);
        return;
      case "invoice":
        navigate(`/invoice/${recordId}/details`);
        return;
      case "transaction":
        navigate(`/transaction/${recordId}/details`);
        return;
      default:
        return;
    }
  };

  const getTitle = (entityName) => {
    switch (entityName?.toLowerCase()) {
      case "contact":
        return "Contatto";
      case "estimate":
        return "Preventivo";
      case "order":
        return "Ordine";
      case "invoice":
        return "Fattura";
      case "transaction":
        return "Pagamento";
      default:
        return;
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="list-group">
              {alerts.map((item) => (
                <Link
                  key={item.rowKey}
                  className={`list-group-item list-group-item-action ${
                    item.status === 0 ? "list-group-item-info" : ""
                  }`}
                  onClick={() => handleNotificationClick(item.rowKey)}
                  onMouseEnter={() => handleDeactivate(item.rowKey)}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i
                          className={AlertsUtil.getIcon(item.referenceTable)}
                        ></i>
                      </span>
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-1">
                          {getTitle(item.referenceTable)}
                        </h5>
                        <small>
                          {new Date(item.createdOn).toLocaleString()}
                        </small>
                      </div>
                      <p className="mb-1">{item.description}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
