import React from "react";
import CardStatus from "../../Cards/CardStatus";

const PaymentCardStatus = ({ payment, className }) => {
  const getTokenizationSpan = (payment) => {
    return (
      !!payment.tokenizationId && (
        <span className="badge rounded-1 badge-soft-success">Tokenizzato</span>
      )
    );
  };

  const getAutomaticSpan = (payment) => {
    return (
      !!payment.tokenizationId &&
      !!payment.expirationDate && (
        <span className="badge rounded-1 badge-soft-info">Automatico</span>
      )
    );
  };

  return (
    <CardStatus className={className}>
      {getTokenizationSpan(payment)}
      {getAutomaticSpan(payment)}
    </CardStatus>
  );
};

export default PaymentCardStatus;
