import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

export const button = (text, to, icon) => {
  return {
    text,
    to,
    icon,
  };
};

export const CardSimpleCTA = ({
  number,
  headerLink,
  title,
  subtitle,
  description,
  descriptionIcon,
  buttons,
}) => {
  return (
    <>
      <div className="card">
        <div className="card-body card-std-height-xs">
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-sm me-3">
              <span
                className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24"
                style={{ width: "48px", height: "48px" }}
              >
                {number}
              </span>
            </div>
            <div>
              <h5 className="font-size-14 mb-1">
                <Link to={headerLink}>{title}</Link>
              </h5>
              <h6 className="font-size-14 mb-0">{subtitle}</h6>
            </div>
          </div>
          <div className="text-center py-2">
            <Row>
              {!!buttons &&
                buttons.map((x, i) => {
                  return (
                    <Col xs={12 / buttons.length} key={i} className="my-2">
                      <Link
                        to={typeof x.to !== "function" ? x.to : undefined}
                        onClick={typeof x.to === "function" ? x.to : undefined}
                        className="btn btn-primary btn-md mb-1"
                      >
                        <i className={`bx ${x.icon} align-middle`}></i> {x.text}
                      </Link>
                    </Col>
                  );
                })}
            </Row>
            <span className="text-truncate">{description}</span>
            <i
              className={`bx ${descriptionIcon} bx-tada text-warning font-size-22 align-middle`}
            ></i>
          </div>
        </div>
      </div>
    </>
  );
};
