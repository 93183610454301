import { persistentAtom } from "@nanostores/persistent";

// Factory function per creare una cache per un business specifico
export const atomCache = (uniqueId, key, initial) => {
  const fullKey = `${uniqueId}-${key}`;
  const dataKey = `${fullKey}-data`;
  const expiresOnKey = `${fullKey}-expiresOn`;

  // Aggiungi le chiavi a localStorage
  let existingKeys = JSON.parse(localStorage.getItem("cacheKeys") || "[]");
  if (!existingKeys.includes(dataKey)) {
    existingKeys.push(dataKey);
    existingKeys.push(expiresOnKey);
    localStorage.setItem("cacheKeys", JSON.stringify(existingKeys));
  }

  const $data = persistentAtom(dataKey, initial, {
    encode: JSON.stringify,
    decode: JSON.parse,
  });
  const $expiresOn = persistentAtom(expiresOnKey, undefined);

  return { $data, $expiresOn };
};

export const atomClearCache = () => {
  let cacheKeys = JSON.parse(localStorage.getItem("cacheKeys") || "[]");
  cacheKeys.forEach((key) => {
    localStorage.removeItem(key); // Rimuovi direttamente da localStorage
  });
  localStorage.removeItem("cacheKeys"); // Pulisci l'elenco delle chiavi dopo la pulizia
};
