import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { Col, Row } from "reactstrap";

import SelectRadioButtonGroup from "../components/Buttons/SelectRadioButtonGroup";
import PaymentCard, {
  PaymentCardHandler,
} from "../components/Cards/PaymentCard/PaymentCard";

const openCloseOptions = [
  {
    label: "Aperti",
    value: 0,
  },
  {
    label: "Chiusi",
    value: 1,
  },
];

const typeOptions = [
  {
    label: "Ordini",
    value: 0,
  },
  {
    label: "Sottoscrizioni",
    value: 1,
  },
];

export default function Transactions() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [open, setOpen] = useState(0);
  const [type, setType] = useState(0);

  const [transactions, setTransactions] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      setTransactions(await TransactionsApi_Cache.get(businessId));
    };
    const fetchContacts = async () => {
      setContacts(await ContactsApi_Cache.get(businessId));
    };
    const fetchEstimates = async () => {
      setEstimates(await EstimatesApi_Cache.get(businessId));
    };
    const fetchOrders = async () => {
      setOrders(await OrdersApi_Cache.get(businessId));
    };
    const fetchInvoices = async () => {
      setInvoices(await InvoicesApi_Cache.get(businessId));
    };
    const fetchSubscriptions = async () => {
      setSubscriptions(await SubscriptionsApi_Cache.get(businessId));
    };
    fetchTransactions();
    fetchContacts();
    fetchEstimates();
    fetchOrders();
    fetchInvoices();
    fetchSubscriptions();
  }, [businessId]);

  useEffect(() => {
    loadTable();
  }, [
    open,
    type,
    transactions,
    contacts,
    estimates,
    orders,
    invoices,
    subscriptions,
  ]);

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let results = transactions
      .filter((x) => x)
      .filter((x) => ![5, 6].includes(x.status));

    results =
      open === 0
        ? results.filter((x) => [1, 2].includes(x.status))
        : results.filter((x) => [3, 4].includes(x.status));

    results =
      type === 0
        ? results.filter((x) => !!x.orderId && !x.subscriptionId)
        : results.filter((x) => !!x.subscriptionId);

    results = results.map((x) =>
      PaymentCardHandler.mapPayment(
        x,
        contacts,
        estimates,
        orders,
        invoices,
        subscriptions
      )
    );

    setTableRecords(results);
  };

  const filterRecords = () => {
    let result = _BaseUtil.filterList(tableRecords, filter);

    const sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.expirationDate);
      const dateB = new Date(b.expirationDate);
      return dateA - dateB;
    });

    setFilteredRecords(sortedItems);
  };

  useEffect(() => {
    filterRecords();
  }, [tableRecords, filter]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Pagamenti</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/transactions">Pagamenti</a>
                    </li>
                    <li className="breadcrumb-item active">Lista Pagamenti</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    </div>
                    <div className="col-6 my-auto">
                      <div className="text-end">
                        <Link to="/service" className="btn btn-primary">
                          Nuovo Servizio
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Filtra ..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <Link
                            onClick={() => loadTable(true)}
                            className="btn btn-light float-end"
                          >
                            <i className="mdi mdi-refresh"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                      <SelectRadioButtonGroup
                        key={`open`}
                        defaultValue={open}
                        onValueChange={setOpen}
                        options={openCloseOptions}
                        className={`float-end`}
                      />
                      <SelectRadioButtonGroup
                        key={`type`}
                        defaultValue={type}
                        onValueChange={setType}
                        options={typeOptions}
                        className={`float-end`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Col xs={12}>
              <div className={`card d-none d-sm-block`}>
                <div className="card-body border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    <input
                      type="text"
                      className="form-control mx-3"
                      id="searchInput"
                      placeholder="Filtra ..."
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="flex-shrink-0 mx-1">
                      <Link
                        onClick={() => loadTable(true)}
                        className="btn btn-light mx-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                    <div className="flex-shrink-0 mx-1">
                      <SelectRadioButtonGroup
                        key={`open`}
                        defaultValue={open}
                        onValueChange={setOpen}
                        options={openCloseOptions}
                      />
                    </div>
                    <div className="flex-shrink-0 mx-1">
                      <SelectRadioButtonGroup
                        key={`type`}
                        defaultValue={type}
                        onValueChange={setType}
                        options={typeOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12}>
              <Row>
                {!!filteredRecords &&
                  filteredRecords.map((x) => (
                    <Col sm={6} lg={4} xxl={3}>
                      <div className="card-body">
                        <PaymentCard
                          key={`payment-${x.id}`}
                          payment={x}
                          events={PaymentCardHandler.mapEvents(
                            x,
                            estimates,
                            orders
                          )}
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
