import React, { useState, useEffect } from "react";

import { _BaseUtil } from "../../utils/_BaseUtil";

import { CardTable, CardTableUtil } from "../CardTable";

const RowsCardTable = ({ title, rows, buttons }) => {
  const [columns, setColumns] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo",
    },
  ]);

  const tblRows = rows.map((x) => {
    return {
      serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
      quantity: CardTableUtil.getCellText(`${x.quantity}`),
      amount: CardTableUtil.getCellText(
        `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
      ),
    };
  });

  return (
    <CardTable
      key="payments-card-table"
      title={title}
      columnNames={columns}
      values={tblRows}
      buttons={buttons}
    />
  );
};

export default RowsCardTable;
