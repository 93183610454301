import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";

const Estimates = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let results = (await EstimatesApi_Cache.get(businessId))
      .filter((x) => x.estimate)
      .filter((x) => x.estimate.status !== 5)
      .filter((x) => x.estimate.customerId);

    results = results.map((x) => mapTable(x));

    const sortedItems = [...results].sort((a, b) => {
      const dateA = new Date(a.createdOn);
      const dateB = new Date(b.createdOn);
      return dateB - dateA;
    });

    setTableRecords(sortedItems);
  };

  const mapTable = (estimateModel) => {
    const estimateStatus = estimateModel.estimate.status;
    let isOpen = [1, 2].includes(estimateStatus);

    let expirationDate = estimateModel.estimate.expirationDate;
    let expirationRemainingDays = _BaseUtil.dateDiffInDays(
      expirationDate,
      new Date()
    );
    let expirationDateText = expirationRemainingDays;
    if (expirationRemainingDays <= 0) expirationDateText = "Oggi!";
    if (expirationRemainingDays === 1) expirationDateText = "Domani";

    if (!isOpen) {
      expirationDateText = estimateStatus === 6 ? "Annullato" : "Concluso";
    }

    let result = {
      createdOn: estimateModel.estimate.createdOn,
      estimateId: estimateModel.estimate.rowKey,
      code: estimateModel.estimate.code,
      subject: estimateModel.estimate.subject,
      isOpen: isOpen,
      customerId: estimateModel.estimate.customerId,
      customerName: ContactsUtil.getFullCustomerName(
        estimateModel.estimate.customerType,
        estimateModel.estimate.customerFirstName,
        estimateModel.estimate.customerLastName,
        estimateModel.estimate.customerBusinessName
      ),
      customerType: ContactsUtil.getTypeDescription(
        estimateModel.estimate.customerType
      ),
      servicesAmount: _BaseUtil.formatCurrency(
        estimateModel.estimate.currency,
        estimateModel.estimate.totalAmountServicesGross,
        "it-IT"
      ),
      plansAmount: _BaseUtil.formatCurrency(
        estimateModel.estimate.currency,
        estimateModel.estimate.totalAmountPlansGross,
        "it-IT"
      ),
      revisionNumber: estimateModel.estimate.revisionNumber,
      status: estimateModel.estimate.status,
      statusText: EstimatesUtil.getStatusDescription(
        estimateModel.estimate.status
      ),
      expirationDate: _BaseUtil.formatDate(
        estimateModel.estimate.expirationDate,
        "it-IT"
      ),
      expirationRemainingDays: expirationRemainingDays,
      expirationDateText: expirationDateText,
      expirationPercentage: isOpen
        ? _BaseUtil
            .calculateCompletionPercentage(
              estimateModel.estimate.createdOn,
              expirationDate,
              new Date()
            )
            .toFixed(0)
        : 100,
    };

    return result;
  };

  useEffect(() => {
    loadTable();
  }, []);

  const filterRecords = () => {
    let result = _BaseUtil.filterList(tableRecords, filter);
    setFilteredRecords(result);
  };

  useEffect(() => {
    filterRecords();
  }, [tableRecords, filter]);

  const getDaysBadge = (text, remainingDays, status) => {
    let isOpen = [1, 2].includes(status);

    let css = isOpen
      ? remainingDays < 2
        ? "danger"
        : "warning"
      : status === 3
      ? "success"
      : status === 6
      ? "info"
      : "danger";

    return (
      <>
        <div className="d-flex">
          {isOpen ? (
            <>
              <span
                className={`badge badge-soft-${css} font-size-12 ${
                  remainingDays <= 1 ? "" : "d-none"
                }`}
              >
                {`- ${remainingDays} giorno`}
              </span>
              <span
                className={`badge badge-soft-${css} font-size-12 ${
                  remainingDays > 1 ? "" : "d-none"
                }`}
              >
                {`- ${remainingDays} giorni`}
              </span>
            </>
          ) : (
            <>
              <span className={`badge badge-soft-${css} font-size-12`}>
                {text}
              </span>
            </>
          )}
          <span className="ms-2 text-truncate"></span>
        </div>
      </>
    );
  };

  const getProgressBar = (status, expirationPercentage) => {
    let css = status === 6 ? "info" : "success";

    let isOpen = [1, 2].includes(status);

    if (isOpen && expirationPercentage >= 25) {
      css = expirationPercentage < 75 ? "warning" : "danger";
    }

    return (
      <>
        <div className="progress animated-progess progress-sm mt-2">
          <div
            className={`progress-bar bg-${css}`}
            role="progressbar"
            style={{ width: expirationPercentage + "%" }}
            aria-valuenow={expirationPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Preventivi</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/estimates">Preventivi</a>
                    </li>
                    <li className="breadcrumb-item active">Lista Preventivi</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    </div>
                    <div className="col-6 my-auto">
                      <div className="text-end">
                        <Link to="/estimate" className="btn btn-primary mx-1">
                          Nuovo Preventivo
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Filtra ..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <Link
                            onClick={() => loadTable(true)}
                            className="btn btn-light float-end"
                          >
                            <i className="mdi mdi-refresh"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`card d-none d-sm-block`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                  <input
                    type="text"
                    className="form-control mx-4"
                    id="searchInput"
                    placeholder="Filtra ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <Link to="/estimate" className="btn btn-primary mx-1">
                      Nuovo Preventivo
                    </Link>
                    <Link
                      onClick={() => loadTable(true)}
                      className="btn btn-light mx-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive tableReflow">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Codice</th>
                      <th scope="col">Preventivo</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Scadenza</th>
                      <th scope="col">Importi</th>
                      <th scope="col">Stato</th>
                      {/* <th scope="col">Azioni</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {filteredRecords.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                              <Link
                                to={`/estimate/${item.estimateId}/details`}
                                className="text-dark"
                              >
                                <span className="text-primary font-size-22">
                                  <img
                                    src={
                                      item.imagePath ||
                                      "/assets/images/services/wired-outline-1019-document-signature-hand.gif"
                                    }
                                    alt={item.name || "Preventivo"}
                                    className="mw-100"
                                  />
                                </span>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/estimate/${item.estimateId}/details`}
                                className="text-dark"
                              >
                                <span>{item.code}</span>
                              </Link>
                            </h5>
                            {!!item.revisionNumber && (
                              <>
                                <p className="text-muted mb-0 text-wrap">
                                  Revisione {item.revisionNumber}
                                </p>
                              </>
                            )}
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/estimate/${item.estimateId}/details`}
                                className="text-dark"
                              >
                                <span>{item.subject}</span>
                              </Link>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/contact/${item.customerId}/profile`}
                                style={{ color: "var(--bs-link-color)" }}
                              >
                                {item.customerName}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0 text-wrap">
                              {item.customerType}
                            </p>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-6">
                                <h5 className="font-size-14">
                                  {item.expirationDate}
                                </h5>
                              </div>
                              <div className="col-6">
                                {getDaysBadge(
                                  item.expirationDateText,
                                  item.expirationRemainingDays,
                                  item.status
                                )}
                              </div>
                            </div>
                            {getProgressBar(
                              item.status,
                              item.expirationPercentage
                            )}
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              Totale servizi: {item.servicesAmount}
                            </h5>
                            <h5 className="text-truncate font-size-14">
                              Totale piani: {item.plansAmount}
                            </h5>
                          </td>
                          <td>
                            <span
                              className={`fs-5 badge ${EstimatesUtil.getStatusBadge(
                                item.status
                              )}`}
                            >
                              {item.statusText}
                            </span>
                          </td>
                          {/* <td>
                            <div className="dropdown">
                              <a
                                href="#"
                                className="dropdown-toggle card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a
                                  className="dropdown-item"
                                  href="/estimate-new"
                                >
                                  Nuovo preventivo
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="/invoice-new"
                                >
                                  Nuova fattura
                                </a>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Estimates;
