import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

import { _BaseUtil } from "../utils/_BaseUtil";
import { BankAccountsUtil } from "../utils/BankAccountsUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";

import { CardDateTimeChart } from "./CardDateTimeChart";

export const BankAccountResume = ({ accounts, transactions }) => {
  const [chartDates, setChartDates] = useState([]);
  const [chartValues, setChartValues] = useState([]);

  const handleLoadChartValues = () => {
    if (!accounts?.length && !transactions?.length) return;

    let dailyBalances = TransactionsUtil.getDailyBalances(
      accounts,
      transactions
    );

    let chartDates = dailyBalances.map((x) => x.date);
    let chartValues = dailyBalances.map((x) => x.totalBalance);

    setChartDates(chartDates);
    setChartValues(chartValues);
  };

  useEffect(() => {
    handleLoadChartValues();
  }, [accounts, transactions]);

  useEffect(() => {
    handleLoadChartValues();
  }, []);

  return (
    <>
      <Row>
        <Col xs={6} md={3}>
          <div className="card mini-stats-wid">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium mb-2">Saldi</p>
                  <h4 className="mb-0">
                    {_BaseUtil.formatCurrency(
                      "EUR",
                      BankAccountsUtil.getTotalBalances(accounts).toFixed(2),
                      "it-IT"
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {/* <div className="col-6 col-md-3">
          <div className="card mini-stats-wid">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium mb-2">
                    Spese professionali
                  </p>
                  <h4 className="mb-0">4</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="card mini-stats-wid">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium mb-2">Tasse sul reddito</p>
                  <h4 className="mb-0">5</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="card mini-stats-wid">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted text-truncate fw-medium mb-2">
                    Contributi previdenziali
                  </p>
                  <h4 className="mb-0">5</h4>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Row>
      <Row>
        <Col>
          <div className="card">
            <div className="card-body card-std-height">
              <h4 className="card-title mb-3">Saldo totale</h4>
              <div>
                {!!chartDates?.length && !!chartValues?.length && (
                  <CardDateTimeChart
                    dates={chartDates}
                    values={chartValues.reverse()}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
