// StripePaymentsList.jsx
import React, { useState, useEffect, useContext } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectPayouts,
  ConnectPaymentDetails,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import { BusinessContext } from "../../contexts/AppContexts";

import { StripeApi_Cache } from "../../api/decorators/cache/StripeApi_Cache";

const stripePk = process.env.REACT_APP_STRIPE_PK;

const StripePaymentsList = () => {
  const { business } = useContext(BusinessContext);

  const [connectInstance, setConnectInstance] = useState(null);

  useEffect(() => {
    if (!business) return;
    retrieveInstance();
  }, []);

  const retrieveClientSecret = async () => {
    const response = await StripeApi_Cache.accountSessionsStart(
      business.rowKey
    );
    return response?.client_secret;
  };

  const retrieveInstance = async () => {
    const instance = loadConnectAndInitialize({
      publishableKey: stripePk,
      fetchClientSecret: retrieveClientSecret,
    });
    setConnectInstance(instance);
  };

  // Assicurati che stripeConnectInstance non sia null prima di renderizzare i componenti di Stripe
  if (!connectInstance) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    !!connectInstance && (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ConnectComponentsProvider connectInstance={connectInstance}>
              <ConnectPayments />
              <ConnectPayouts />
              <ConnectPaymentDetails
                onClose={() => {
                  console.log("closed");
                }}
                payment="pi_test123"
              />
            </ConnectComponentsProvider>
          </div>
        </div>
      </div>
    )
  );
};

export default StripePaymentsList;
