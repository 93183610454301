import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import { LinksCardItem } from "./LinksCardItem";

export const LinksCard = ({ values, onChangeValues, onAddLink, disabled }) => {
  const onChangeLinkItem = function (value, index) {
    onChangeValues(values.map((x, i) => (i === index ? value : x)));
  };

  const onCopyLinkItem = function (index) {
    onChangeValues(values.toSpliced(index + 1, 0, values[index]));
  };

  const onDeleteLinkItem = function (index) {
    onChangeValues(values.toSpliced(index, 1));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="card">
            <div className="card-body">
              <fieldset disabled={disabled}>
                <Container fluid>
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center">
                      <h4 className="card-title m-0">Link utili</h4>
                      {!disabled && (
                        <Button onClick={onAddLink} color="primary" outline>
                          Aggiungi un link
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {values.map((x, i) => {
                      return (
                        <Col key={i} xs={12} className="mt-4">
                          <LinksCardItem
                            value={x}
                            onChangeValue={(y) => onChangeLinkItem(y, i)}
                          ></LinksCardItem>
                          {!disabled && (
                            <div className="d-flex justify-content-end px-2">
                              <ButtonGroup>
                                <Button
                                  onClick={() => onDeleteLinkItem(i)}
                                  color="secondary"
                                  outline
                                  className="btn btn-soft-secondary waves-effect waves-light"
                                >
                                  <i className="bx bx-trash"></i>
                                </Button>
                                <Button
                                  onClick={() => onCopyLinkItem(i)}
                                  color="primary"
                                  outline
                                  className="btn btn-soft-primary waves-effect waves-light"
                                >
                                  <i className="bx bx-add-to-queue"></i>
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </fieldset>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
