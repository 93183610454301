import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { BusinessesApi_Cache } from "../api/decorators/cache/BusinessesApi_Cache";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import BusinessesTable from "../components/Tables/BusinessesTable";

const Businesses = ({ onImpersonate }) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState("");
  const [year, setYear] = useState(2024);

  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = async (nocache) => {
    if (nocache) atomClearCache();
    setBusinesses(await BusinessesApi_Cache.get());
  };

  const handleSelect = (id) => {
    navigate(`/business/${id}/details`);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">I tuoi Holo</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="/businesses">I tuoi Holo</a>
                    </li>
                    <li className="breadcrumb-item active">Tutti</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-none d-sm-block`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Cerca:</h5>
                  <input
                    type="text"
                    className="form-control mx-4"
                    id="searchInput"
                    placeholder="Filtra ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <Link
                      onClick={() => load(true)}
                      className="btn btn-light mx-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca:</h5>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control my-4"
                        id="searchInput"
                        placeholder="Filtra ..."
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </div>
                    <div className="flex-shrink-0">
                      <Link
                        onClick={() => load(true)}
                        className="btn btn-light mx-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <BusinessesTable
                businesses={businesses}
                filter={filter}
                year={year}
                onImpersonate={onImpersonate}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Businesses;
