export const ServicesOptions = [
  {
    label: "Cestino",
    value: "/assets/images/services/wired-outline-185-trash-bin.gif",
  },
  {
    label: "Tre Avatar Maschili",
    value: "/assets/images/services/wired-outline-271-three-male-avatars.gif",
  },
  {
    label: "Tre Avatar Femminili",
    value: "/assets/images/services/wired-outline-273-three-female-avatars.gif",
  },
  {
    label: "Portafoglio",
    value: "/assets/images/services/wired-outline-421-wallet-purse.gif",
  },
  {
    label: "Ombrello",
    value: "/assets/images/services/wired-outline-422-umbrella.gif",
  },
  {
    label: "Peso Palestra",
    value: "/assets/images/services/wired-outline-429-weight-gym-fitness.gif",
  },
  {
    label: "Globo",
    value: "/assets/images/services/wired-outline-735-world-globe.gif",
  },
  {
    label: "Biglietto",
    value: "/assets/images/services/wired-outline-759-ticket-coupon.gif",
  },
  {
    label: "Web Analytics",
    value: "/assets/images/services/wired-outline-976-web-analytics.gif",
  },
  {
    label: "Web Design",
    value: "/assets/images/services/wired-outline-1328-web-design.gif",
  },
  {
    label: "Carta Igienica",
    value: "/assets/images/services/wired-outline-1714-toilet-paper.gif",
  },
  {
    label: "Fischietto",
    value: "/assets/images/services/wired-outline-1772-whistle.gif",
  },
  {
    label: "Piantare un Albero",
    value: "/assets/images/services/wired-outline-1850-tree-planting.gif",
  },
  {
    label: "Prelievo",
    value: "/assets/images/services/wired-outline-2066-withdrawal.gif",
  },
  {
    label: "Condivisione Rete",
    value: "/assets/images/services/wired-outline-26-share-network.gif",
  },
  {
    label: "Server",
    value: "/assets/images/services/wired-outline-58-servers.gif",
  },
  {
    label: "Condividere",
    value: "/assets/images/services/wired-outline-75-share.gif",
  },
  {
    label: "Forbici",
    value: "/assets/images/services/wired-outline-114-scissors.gif",
  },
  {
    label: "Altoparlante",
    value: "/assets/images/services/wired-outline-191-speaker.gif",
  },
  {
    label: "Valutazione a Stelle",
    value: "/assets/images/services/wired-outline-237-star-rating.gif",
  },
  {
    label: "Studio e Laurea",
    value: "/assets/images/services/wired-outline-406-study-graduation.gif",
  },
  {
    label: "Salvadanaio",
    value: "/assets/images/services/wired-outline-453-savings-pig.gif",
  },
  {
    label: "Aiuto con Salvagente",
    value:
      "/assets/images/services/wired-outline-475-rescue-safety-ring-help.gif",
  },
  {
    label: "Negozio",
    value: "/assets/images/services/wired-outline-481-shop.gif",
  },
  {
    label: "Razzo Spaziale",
    value: "/assets/images/services/wired-outline-489-rocket-space.gif",
  },
  {
    label: "Scheletro",
    value: "/assets/images/services/wired-outline-638-skeleton.gif",
  },
  {
    label: "Valigia",
    value:
      "/assets/images/services/wired-outline-725-suitcase-travel-baggage-luggage.gif",
  },
  {
    label: "Circuiti Integrati",
    value:
      "/assets/images/services/wired-outline-746-technology-integrated-circuits.gif",
  },
  {
    label: "Sole e Mare",
    value: "/assets/images/services/wired-outline-805-sun-seaside.gif",
  },
  {
    label: "Navetta Spaziale",
    value:
      "/assets/images/services/wired-outline-879-space-shuttle-spaceship.gif",
  },
  {
    label: "Tachimetro",
    value: "/assets/images/services/wired-outline-935-speedometer.gif",
  },
  {
    label: "Marketing sui Social Media",
    value:
      "/assets/images/services/wired-outline-962-social-media-marketing.gif",
  },
  {
    label: "Scanner di Immagini",
    value: "/assets/images/services/wired-outline-998-scanner-image.gif",
  },
  {
    label: "Libro di Regole",
    value:
      "/assets/images/services/wired-outline-1020-rules-book-guideline.gif",
  },
  {
    label: "Servizio",
    value: "/assets/images/services/wired-outline-1029-service.gif",
  },
  {
    label: "Segnali",
    value: "/assets/images/services/wired-outline-1043-signal-streams.gif",
  },
  {
    label: "Musica Ska",
    value: "/assets/images/services/wired-outline-1076-ska-music.gif",
  },
  {
    label: "Fiore Spa",
    value: "/assets/images/services/wired-outline-1574-spa-flower.gif",
  },
  {
    label: "Forza",
    value: "/assets/images/services/wired-outline-1774-strenght.gif",
  },
  {
    label: "Stelle Cadenti",
    value: "/assets/images/services/wired-outline-1865-shooting-stars.gif",
  },
  {
    label: "Mostro Carino",
    value: "/assets/images/services/wired-outline-1872-small-cute-monster.gif",
  },
  {
    label: "Coltellino Svizzero",
    value: "/assets/images/services/wired-outline-1897-swiss-army-knife.gif",
  },
  {
    label: "Sirena",
    value: "/assets/images/services/wired-outline-2096-siren.gif",
  },
  {
    label: "Toccare Carta di Credito",
    value: "/assets/images/services/wired-outline-2365-tapping-credit-card.gif",
  },
  {
    label: "Play e Pausa",
    value: "/assets/images/services/wired-outline-29-play-pause-circle.gif",
  },
  {
    label: "Nota Musicale",
    value: "/assets/images/services/wired-outline-43-music-note.gif",
  },
  {
    label: "Foto",
    value: "/assets/images/services/wired-outline-54-photo.gif",
  },
  {
    label: "Tag Prezzo Saldi",
    value: "/assets/images/services/wired-outline-100-price-tag-sale.gif",
  },
  {
    label: "Tag Prezzo Dollaro",
    value: "/assets/images/services/wired-outline-101-price-tag-dollar.gif",
  },
  {
    label: "Tag Prezzo Euro",
    value: "/assets/images/services/wired-outline-102-price-tag-euro.gif",
  },
  {
    label: "Tag Prezzo Bitcoin",
    value: "/assets/images/services/wired-outline-103-price-tag-bitcoin.gif",
  },
  {
    label: "Tag Prezzo Ethereum",
    value: "/assets/images/services/wired-outline-104-price-tag-ethereum.gif",
  },
  {
    label: "Grafico a Torta",
    value: "/assets/images/services/wired-outline-159-pie-chart-multi.gif",
  },
  {
    label: "Microfono Registrazione",
    value: "/assets/images/services/wired-outline-188-microphone-recording.gif",
  },
  {
    label: "Giornale Notizie",
    value: "/assets/images/services/wired-outline-411-news-newspaper.gif",
  },
  {
    label: "Bolla Domanda",
    value: "/assets/images/services/wired-outline-424-question-bubble.gif",
  },
  {
    label: "Premio Nastro",
    value: "/assets/images/services/wired-outline-434-prize-padge-ribbon.gif",
  },
  {
    label: "Progetto Edilizia",
    value:
      "/assets/images/services/wired-outline-731-real-estate-building-project.gif",
  },
  {
    label: "Stampante",
    value: "/assets/images/services/wired-outline-732-printer.gif",
  },
  {
    label: "Notebook",
    value: "/assets/images/services/wired-outline-739-notebook-3.gif",
  },
  {
    label: "Multicanale",
    value: "/assets/images/services/wired-outline-963-multichannel.gif",
  },
  {
    label: "Privacy",
    value: "/assets/images/services/wired-outline-965-privacy.gif",
  },
  {
    label: "Gestione Progetti",
    value: "/assets/images/services/wired-outline-978-project-management.gif",
  },
  {
    label: "Stima Progetto",
    value: "/assets/images/services/wired-outline-979-project-estimate.gif",
  },
  {
    label: "Pubblicità",
    value:
      "/assets/images/services/wired-outline-1024-post-ads-advertisment.gif",
  },
  {
    label: "Camera Cinema",
    value: "/assets/images/services/wired-outline-1036-movie-camera.gif",
  },
  {
    label: "Concerto Luci Palco",
    value:
      "/assets/images/services/wired-outline-1059-music-stage-lights-concert.gif",
  },
  {
    label: "Server NAS",
    value: "/assets/images/services/wired-outline-1310-nas-server.gif",
  },
  {
    label: "Terminale POS",
    value: "/assets/images/services/wired-outline-1355-pos-terminal.gif",
  },
  {
    label: "Shopping Online",
    value: "/assets/images/services/wired-outline-1359-online-shopping.gif",
  },
  {
    label: "Riciclaggio",
    value: "/assets/images/services/wired-outline-1683-recycling.gif",
  },
  {
    label: "Parco Nazionale",
    value: "/assets/images/services/wired-outline-1699-national-park.gif",
  },
  {
    label: "Braccio Muscoloso",
    value: "/assets/images/services/wired-outline-1793-muscle-arm.gif",
  },
  { label: "NFT", value: "/assets/images/services/wired-outline-2457-nft.gif" },
  {
    label: "Collegare/Scollegare",
    value: "/assets/images/services/wired-outline-11-link-unlink.gif",
  },
  {
    label: "Strati",
    value: "/assets/images/services/wired-outline-12-layers.gif",
  },
  {
    label: "Pin su Mappa",
    value:
      "/assets/images/services/wired-outline-52-location-pin-on-square-map.gif",
  },
  { label: "Casa", value: "/assets/images/services/wired-outline-63-home.gif" },
  {
    label: "Mappa",
    value: "/assets/images/services/wired-outline-106-map.gif",
  },
  {
    label: "Legge e Giustizia",
    value: "/assets/images/services/wired-outline-119-law-judge.gif",
  },
  {
    label: "Pollice in Su",
    value: "/assets/images/services/wired-outline-267-like-thumb-up.gif",
  },
  {
    label: "Foglie Eco",
    value: "/assets/images/services/wired-outline-401-leaves-eco.gif",
  },
  {
    label: "Bilancia Legale",
    value: "/assets/images/services/wired-outline-402-legal-balance-legal.gif",
  },
  {
    label: "Chiave",
    value: "/assets/images/services/wired-outline-423-key.gif",
  },
  {
    label: "Amore e Cura",
    value: "/assets/images/services/wired-outline-436-love-care.gif",
  },
  {
    label: "Stretta di Mano",
    value: "/assets/images/services/wired-outline-456-handshake-deal.gif",
  },
  {
    label: "Clessidra",
    value: "/assets/images/services/wired-outline-472-hourglass.gif",
  },
  {
    label: "Notebook",
    value: "/assets/images/services/wired-outline-479-laptop-notebook.gif",
  },
  {
    label: "Tè Verde",
    value: "/assets/images/services/wired-outline-572-green-tea.gif",
  },
  {
    label: "Telefono in Mano",
    value: "/assets/images/services/wired-outline-721-hand-with-phone.gif",
  },
  {
    label: "Tastiera",
    value: "/assets/images/services/wired-outline-747-keyboard.gif",
  },
  {
    label: "Locazione",
    value: "/assets/images/services/wired-outline-930-lease.gif",
  },
  {
    label: "Investimento",
    value: "/assets/images/services/wired-outline-947-investment.gif",
  },
  {
    label: "Sala Riunioni",
    value:
      "/assets/images/services/wired-outline-969-meeting-conference-room.gif",
  },
  {
    label: "Campagna di Marketing",
    value: "/assets/images/services/wired-outline-1027-marketing-campaign.gif",
  },
  {
    label: "Palla Magica",
    value: "/assets/images/services/wired-outline-1114-magic-ball.gif",
  },
  {
    label: "IoT (Internet delle Cose)",
    value:
      "/assets/images/services/wired-outline-1121-iota-internet-of-things.gif",
  },
  {
    label: "Documento Google Sheets",
    value:
      "/assets/images/services/wired-outline-1124-google-sheet-document-icon.gif",
  },
  {
    label: "Hub di Rete",
    value: "/assets/images/services/wired-outline-1307-hub-network.gif",
  },
  {
    label: "Carta Fedeltà",
    value: "/assets/images/services/wired-outline-1350-loyalty-card.gif",
  },
  {
    label: "Vendita Terreni",
    value: "/assets/images/services/wired-outline-1720-ground-land-sale.gif",
  },
  {
    label: "Martello",
    value: "/assets/images/services/wired-outline-1733-hammer.gif",
  },
  {
    label: "Faro",
    value: "/assets/images/services/wired-outline-2062-light-house.gif",
  },
  {
    label: "Hacking",
    value: "/assets/images/services/wired-outline-2077-hacking.gif",
  },
  {
    label: "Impostazioni Ingranaggi",
    value: "/assets/images/services/wired-outline-40-gears-settings-double.gif",
  },
  {
    label: "Cuffie Telefono",
    value: "/assets/images/services/wired-outline-140-earphone-telephone.gif",
  },
  {
    label: "Invio Busta",
    value: "/assets/images/services/wired-outline-177-envelope-send.gif",
  },
  {
    label: "Corona Re",
    value: "/assets/images/services/wired-outline-407-crown-king-lord.gif",
  },
  {
    label: "Palla da Calcio",
    value: "/assets/images/services/wired-outline-431-football-soccer-ball.gif",
  },
  {
    label: "Coppa Premio",
    value: "/assets/images/services/wired-outline-433-cup-prize.gif",
  },
  {
    label: "Obiettivo",
    value: "/assets/images/services/wired-outline-458-goal-target.gif",
  },
  {
    label: "Cruscotto",
    value: "/assets/images/services/wired-outline-467-dashboard-gauge.gif",
  },
  {
    label: "Fast Food",
    value: "/assets/images/services/wired-outline-562-fastfood.gif",
  },
  {
    label: "Diamante Lusso",
    value:
      "/assets/images/services/wired-outline-724-diamond-luxury-precious.gif",
  },
  {
    label: "Imbuto Utensili",
    value:
      "/assets/images/services/wired-outline-736-funnel-tools-utensils.gif",
  },
  {
    label: "Ordine Trascinamento",
    value: "/assets/images/services/wired-outline-752-drag-order-list.gif",
  },
  {
    label: "Consegna",
    value: "/assets/images/services/wired-outline-757-delivery.gif",
  },
  {
    label: "Tavoletta Grafica",
    value: "/assets/images/services/wired-outline-783-drawing-tablet.gif",
  },
  {
    label: "Sicurezza Azionaria",
    value: "/assets/images/services/wired-outline-946-equity-security.gif",
  },
  {
    label: "Progetto Finanziario",
    value: "/assets/images/services/wired-outline-972-financial-project.gif",
  },
  {
    label: "Firma Documento",
    value:
      "/assets/images/services/wired-outline-1019-document-signature-hand.gif",
  },
  {
    label: "Drone Alternativo",
    value: "/assets/images/services/wired-outline-1040-drone-alt.gif",
  },
  {
    label: "Errore",
    value: "/assets/images/services/wired-outline-1140-error.gif",
  },
  {
    label: "Modulo Domande",
    value:
      "/assets/images/services/wired-outline-1142-form-sheet-questionnaire.gif",
  },
  {
    label: "Kit di Primo Soccorso",
    value: "/assets/images/services/wired-outline-1255-first-aid-kit.gif",
  },
  {
    label: "Buono Regalo",
    value: "/assets/images/services/wired-outline-1369-gift-card.gif",
  },
  {
    label: "Cancellare",
    value: "/assets/images/services/wired-outline-1432-erase.gif",
  },
  {
    label: "Servitore",
    value: "/assets/images/services/wired-outline-1463-footman.gif",
  },
  {
    label: "Abaco per l'Educazione Matematica",
    value:
      "/assets/images/services/wired-outline-1541-education-mathematics-abacus.gif",
  },
  {
    label: "Trapano",
    value: "/assets/images/services/wired-outline-1653-drilling-machine.gif",
  },
  {
    label: "Ventilatore",
    value: "/assets/images/services/wired-outline-1723-fan.gif",
  },
  {
    label: "Impiegato al Lavoro",
    value: "/assets/images/services/wired-outline-1846-employee-working.gif",
  },
  {
    label: "Lampada del Genio",
    value: "/assets/images/services/wired-outline-1862-genie-lamp.gif",
  },
  {
    label: "Fuochi d'Artificio",
    value: "/assets/images/services/wired-outline-2234-firework.gif",
  },
  {
    label: "Linea di Arrivo",
    value: "/assets/images/services/wired-outline-2525-finish-line.gif",
  },
  {
    label: "Nuvola",
    value: "/assets/images/services/wired-outline-1-cloud.gif",
  },
  {
    label: "Lampadina",
    value: "/assets/images/services/wired-outline-36-bulb.gif",
  },
  {
    label: "Fotocamera",
    value: "/assets/images/services/wired-outline-61-camera.gif",
  },
  {
    label: "Bussola",
    value: "/assets/images/services/wired-outline-86-compass.gif",
  },
  {
    label: "Pacco",
    value: "/assets/images/services/wired-outline-108-box-package.gif",
  },
  {
    label: "Chat",
    value: "/assets/images/services/wired-outline-202-chat.gif",
  },
  {
    label: "Caffè",
    value: "/assets/images/services/wired-outline-239-coffee.gif",
  },
  {
    label: "Cervello",
    value: "/assets/images/services/wired-outline-426-brain.gif",
  },
  {
    label: "Boombox",
    value: "/assets/images/services/wired-outline-477-boombox-radio.gif",
  },
  {
    label: "Schermo Computer",
    value: "/assets/images/services/wired-outline-478-computer-display.gif",
  },
  {
    label: "Edificio",
    value: "/assets/images/services/wired-outline-483-building.gif",
  },
  {
    label: "Ciak Cinematografico",
    value: "/assets/images/services/wired-outline-499-clipboard-film-clap.gif",
  },
  {
    label: "Carta di Credito",
    value: "/assets/images/services/wired-outline-799-credit-card.gif",
  },
  {
    label: "Gru",
    value: "/assets/images/services/wired-outline-848-crane-truck.gif",
  },
  {
    label: "Servizio Auto",
    value: "/assets/images/services/wired-outline-903-car-service.gif",
  },
  {
    label: "Coriandoli",
    value: "/assets/images/services/wired-outline-1103-confetti.gif",
  },
  {
    label: "Scambio Carte",
    value: "/assets/images/services/wired-outline-1347-card-exchange.gif",
  },
  {
    label: "Registratore di Cassa",
    value:
      "/assets/images/services/wired-outline-1374-cash-register-messenger.gif",
  },
  {
    label: "Castello",
    value: "/assets/images/services/wired-outline-1533-castle.gif",
  },
  {
    label: "Municipio",
    value: "/assets/images/services/wired-outline-1918-city-hall.gif",
  },
  {
    label: "Zaino",
    value: "/assets/images/services/wired-outline-111-bagpack.gif",
  },
  {
    label: "Libro",
    value: "/assets/images/services/wired-outline-112-book.gif",
  },
  {
    label: "Cesto",
    value: "/assets/images/services/wired-outline-139-basket.gif",
  },
  {
    label: "Grafico a Barre",
    value: "/assets/images/services/wired-outline-153-bar-chart.gif",
  },
  {
    label: "Campanello Notifiche",
    value: "/assets/images/services/wired-outline-193-bell-notification.gif",
  },
  {
    label: "Batteria",
    value: "/assets/images/services/wired-outline-470-battery.gif",
  },
  {
    label: "Tè Nero",
    value: "/assets/images/services/wired-outline-571-black-tea.gif",
  },
  {
    label: "Palette Colori Artista",
    value:
      "/assets/images/services/wired-outline-771-artist-painting-color-palette.gif",
  },
  {
    label: "Obbligazioni",
    value: "/assets/images/services/wired-outline-949-bonds.gif",
  },
  {
    label: "Appuntamento",
    value: "/assets/images/services/wired-outline-973-appointment-schedule.gif",
  },
  {
    label: "Contabilità",
    value: "/assets/images/services/wired-outline-987-accounting.gif",
  },
  {
    label: "Bestseller",
    value: "/assets/images/services/wired-outline-1368-best-seller.gif",
  },
  {
    label: "Cabinet Arcade",
    value: "/assets/images/services/wired-outline-1473-arcade-cabinet.gif",
  },
  {
    label: "Sacco da Boxe",
    value: "/assets/images/services/wired-outline-1773-big-punching-bag.gif",
  },
  {
    label: "Biomassa Alternativa",
    value: "/assets/images/services/wired-outline-1896-biomass-alt.gif",
  },
  {
    label: "Cronometro",
    value: "/assets/images/services/wired-outline-46-timer-stopwatch.gif",
  },
  {
    label: "Orologio",
    value: "/assets/images/services/wired-outline-87-watch.gif",
  },
  {
    label: "Design Vettoriale",
    value: "/assets/images/services/wired-outline-117-vector-design.gif",
  },
  {
    label: "Carrello",
    value: "/assets/images/services/wired-outline-146-trolley.gif",
  },
  {
    label: "Valigia",
    value: "/assets/images/services/wired-outline-187-suitcase.gif",
  },
  {
    label: "Cappello da Cuoco",
    value: "/assets/images/services/wired-outline-611-toque-cooking-hat.gif",
  },
  {
    label: "Virus",
    value: "/assets/images/services/wired-outline-723-virus.gif",
  },
  {
    label: "Codice Web",
    value: "/assets/images/services/wired-outline-743-web-code.gif",
  },
  {
    label: "Tromba",
    value: "/assets/images/services/wired-outline-784-trumpet.gif",
  },
  {
    label: "Vinile",
    value: "/assets/images/services/wired-outline-793-vinyl.gif",
  },
  { label: "Sole", value: "/assets/images/services/wired-outline-804-sun.gif" },
  {
    label: "Lavoro di Squadra",
    value: "/assets/images/services/wired-outline-957-team-work.gif",
  },
  {
    label: "Videoconferenza",
    value: "/assets/images/services/wired-outline-970-video-conference.gif",
  },
  {
    label: "Serratura Intelligente",
    value: "/assets/images/services/wired-outline-983-smart-lock-card.gif",
  },
  {
    label: "Post-it",
    value: "/assets/images/services/wired-outline-994-sticky-notes.gif",
  },
  {
    label: "Foglio di Calcolo",
    value:
      "/assets/images/services/wired-outline-995-table-sheet-spreadsheet.gif",
  },
  {
    label: "Mappa del Sito",
    value: "/assets/images/services/wired-outline-997-sitemap.gif",
  },
  { label: "VIP", value: "/assets/images/services/wired-outline-1003-vip.gif" },
  {
    label: "Pubblicità Web",
    value: "/assets/images/services/wired-outline-1028-web-advertising.gif",
  },
  {
    label: "Unicorno",
    value: "/assets/images/services/wired-outline-1133-unicorn.gif",
  },
  {
    label: "Tracciamento Ordine",
    value: "/assets/images/services/wired-outline-1352-track-order.gif",
  },
  {
    label: "Trifoglio a Tre Foglie",
    value: "/assets/images/services/wired-outline-1448-three-leaf-clover.gif",
  },
  {
    label: "Orsacchiotto",
    value: "/assets/images/services/wired-outline-1546-teddy-bear-toy.gif",
  },
  {
    label: "Timeout",
    value: "/assets/images/services/wired-outline-1784-time-out.gif",
  },
  {
    label: "Fuso Orario Globale",
    value: "/assets/images/services/wired-outline-2132-timezone-global.gif",
  },
  {
    label: "Sviluppo Web",
    value:
      "/assets/images/services/wired-outline-2287-web-development-brackets.gif",
  },
  {
    label: "Saluto con la Mano",
    value: "/assets/images/services/wired-outline-2319-wave-hello-hand.gif",
  },
  {
    label: "Scintille",
    value: "/assets/images/services/wired-outline-2474-sparkles-glitter.gif",
  },
  {
    label: "Iscriviti",
    value: "/assets/images/services/wired-outline-2506-subscribe-text.gif",
  },
  {
    label: "Istogramma Radiale",
    value: "/assets/images/services/wired-outline-170-radial-histogram.gif",
  },
  {
    label: "Grafico a Barre Radiale",
    value: "/assets/images/services/wired-outline-171-radial-bar-chart.gif",
  },
  {
    label: "Puzzle",
    value: "/assets/images/services/wired-outline-186-puzzle.gif",
  },
  {
    label: "Plug Elettrico",
    value: "/assets/images/services/wired-outline-452-plug-in-electricity.gif",
  },
  {
    label: "Scudo di Sicurezza",
    value: "/assets/images/services/wired-outline-457-shield-security.gif",
  },
  {
    label: "Scooter",
    value: "/assets/images/services/wired-outline-495-scooter.gif",
  },
  {
    label: "Cartello Indicatore",
    value: "/assets/images/services/wired-outline-730-signpost-direction.gif",
  },
  {
    label: "Pin Allegato",
    value: "/assets/images/services/wired-outline-754-pin-attachement.gif",
  },
  {
    label: "Recensione",
    value: "/assets/images/services/wired-outline-760-review.gif",
  },
  {
    label: "Righelli",
    value: "/assets/images/services/wired-outline-781-rulers.gif",
  },
  {
    label: "Questionario",
    value: "/assets/images/services/wired-outline-967-questionnaire.gif",
  },
  {
    label: "Flusso di Processo",
    value:
      "/assets/images/services/wired-outline-974-process-flow-game-plan.gif",
  },
  {
    label: "Regole",
    value: "/assets/images/services/wired-outline-1021-rules.gif",
  },
  {
    label: "Richiesta di Supporto",
    value:
      "/assets/images/services/wired-outline-1025-request-support-service.gif",
  },
  {
    label: "Rimuovi Annunci",
    value: "/assets/images/services/wired-outline-1026-remove-ads.gif",
  },
  {
    label: "Musica Rock",
    value: "/assets/images/services/wired-outline-1075-rock-music.gif",
  },
  {
    label: "Microscopio",
    value: "/assets/images/services/wired-outline-1229-microscope.gif",
  },
  {
    label: "Codice PHP",
    value: "/assets/images/services/wired-outline-1319-php-code-language.gif",
  },
  {
    label: "Codice Python",
    value:
      "/assets/images/services/wired-outline-1322-python-code-language.gif",
  },
  {
    label: "REST API",
    value: "/assets/images/services/wired-outline-1330-rest-api.gif",
  },
  {
    label: "Repository",
    value: "/assets/images/services/wired-outline-1331-repository.gif",
  },
  {
    label: "QR Code",
    value: "/assets/images/services/wired-outline-1335-qr-code.gif",
  },
  {
    label: "Codice Ruby",
    value: "/assets/images/services/wired-outline-1338-ruby-code-language.gif",
  },
  {
    label: "Ordine d'Acquisto",
    value: "/assets/images/services/wired-outline-1361-purchase-order.gif",
  },
  {
    label: "Scuoti Telefono",
    value: "/assets/images/services/wired-outline-1682-shake-phone.gif",
  },
  {
    label: "Telecomando",
    value: "/assets/images/services/wired-outline-1745-remote-control.gif",
  },
  {
    label: "Cielo Notturno",
    value:
      "/assets/images/services/wired-outline-1821-night-sky-moon-stars.gif",
  },
  {
    label: "Scrivania Ufficio",
    value: "/assets/images/services/wired-outline-1854-office-desk.gif",
  },
  {
    label: "Persone Peculiari",
    value: "/assets/images/services/wired-outline-2339-peculiar-people.gif",
  },
  {
    label: "Poesia",
    value: "/assets/images/services/wired-outline-2344-poetry.gif",
  },
  {
    label: "Ricavo Ricorrente",
    value: "/assets/images/services/wired-outline-2531-recurring-cash.gif",
  },
  {
    label: "Impostazioni Ingranaggio",
    value: "/assets/images/services/wired-outline-39-gear-settings-single.gif",
  },
  {
    label: "Bussola",
    value: "/assets/images/services/wired-outline-86-compass.gif",
  },
  {
    label: "Sblocca/Lock",
    value: "/assets/images/services/wired-outline-94-lock-unlock.gif",
  },
  {
    label: "Cartella",
    value: "/assets/images/services/wired-outline-120-folder.gif",
  },
  {
    label: "Cartella con Freccia in Su",
    value: "/assets/images/services/wired-outline-130-folder-arrow-up.gif",
  },
  {
    label: "Cartella con Freccia in Giù",
    value: "/assets/images/services/wired-outline-133-folder-arrow-down.gif",
  },
  {
    label: "Grafico a Candele",
    value: "/assets/images/services/wired-outline-164-graph-candles-stock.gif",
  },
  {
    label: "Modifica Documento",
    value: "/assets/images/services/wired-outline-245-edit-document.gif",
  },
  {
    label: "Ragazzo e Ragazza al Sole",
    value:
      "/assets/images/services/wired-outline-637-girl-boy-standing-sun-happy.gif",
  },
  {
    label: "Impostazioni Interfaccia",
    value: "/assets/images/services/wired-outline-749-interface-settings.gif",
  },
  {
    label: "Pastelli a Cera",
    value: "/assets/images/services/wired-outline-770-crayons-pastels.gif",
  },
  {
    label: "Contatore",
    value: "/assets/images/services/wired-outline-797-counter.gif",
  },
  {
    label: "Intuizioni dei Clienti",
    value: "/assets/images/services/wired-outline-954-customer-insights.gif",
  },
  {
    label: "Consultazione",
    value: "/assets/images/services/wired-outline-981-consultation.gif",
  },
  {
    label: "Musica Elettronica",
    value: "/assets/images/services/wired-outline-1052-electronic-music.gif",
  },
  {
    label: "Cassetta Postale",
    value: "/assets/images/services/wired-outline-1127-mailbox.gif",
  },
  {
    label: "Errore",
    value: "/assets/images/services/wired-outline-1140-error.gif",
  },
  {
    label: "Email",
    value: "/assets/images/services/wired-outline-1141-email.gif",
  },
  {
    label: "JSON",
    value: "/assets/images/services/wired-outline-1320-json.gif",
  },
  {
    label: "HTML 5",
    value:
      "/assets/images/services/wired-outline-1321-html-5-code-language.gif",
  },
  {
    label: "Java",
    value: "/assets/images/services/wired-outline-1323-java-code-language.gif",
  },
  {
    label: "Garanzia",
    value: "/assets/images/services/wired-outline-1349-guarantee.gif",
  },
  {
    label: "Face ID",
    value: "/assets/images/services/wired-outline-1376-face-id.gif",
  },
  {
    label: "Portachiavi",
    value: "/assets/images/services/wired-outline-1643-key-holder.gif",
  },
  {
    label: "Metro Misura",
    value: "/assets/images/services/wired-outline-1758-meter-measure.gif",
  },
  {
    label: "Frode",
    value: "/assets/images/services/wired-outline-2075-fraud.gif",
  },
  {
    label: "GDPR",
    value: "/assets/images/services/wired-outline-2257-gdpr.gif",
  },
  {
    label: "Fattura in Euro",
    value: "/assets/images/services/wired-outline-2446-invoice-euro.gif",
  },
  {
    label: "Fattura Generale",
    value: "/assets/images/services/wired-outline-2450-invoice-general.gif",
  },
  {
    label: "Ricevuta Fattura Generale",
    value:
      "/assets/images/services/wired-outline-2451-invoice-receipt-general.gif",
  },
  {
    label: "Calendario",
    value: "/assets/images/services/wired-outline-28-calendar.gif",
  },
  {
    label: "Orologio",
    value: "/assets/images/services/wired-outline-45-clock-time.gif",
  },
  {
    label: "Parte Comune Grafico",
    value: "/assets/images/services/wired-outline-160-common-part-chart.gif",
  },
  {
    label: "Orologio Inverso",
    value: "/assets/images/services/wired-outline-196-clock-time-reverse.gif",
  },
  {
    label: "Freccia Arrotondata",
    value: "/assets/images/services/wired-outline-212-arrow-1-rounded.gif",
  },
  {
    label: "Sveglia",
    value: "/assets/images/services/wired-outline-236-alarm-clock.gif",
  },
  {
    label: "Calcolatrice",
    value: "/assets/images/services/wired-outline-454-calculator.gif",
  },
  {
    label: "Codice",
    value: "/assets/images/services/wired-outline-742-code.gif",
  },
  {
    label: "Offerta Promozionale",
    value:
      "/assets/images/services/wired-outline-756-bargain-off-promotion-percentage.gif",
  },
  {
    label: "Attirare Clienti",
    value: "/assets/images/services/wired-outline-950-attract-customers.gif",
  },
  {
    label: "Archivio",
    value: "/assets/images/services/wired-outline-988-archive.gif",
  },
  {
    label: "Amplificatore Chitarra",
    value: "/assets/images/services/wired-outline-1032-amp-guitar.gif",
  },
  {
    label: "Applauso",
    value: "/assets/images/services/wired-outline-1092-applause.gif",
  },
  {
    label: "Linguaggio di Programmazione C",
    value: "/assets/images/services/wired-outline-1324-c-code-language.gif",
  },
  {
    label: "Simbolo API",
    value: "/assets/images/services/wired-outline-1327-api-symbol.gif",
  },
  { label: "3D", value: "/assets/images/services/wired-outline-1378-3-d.gif" },
  {
    label: "Realtà Aumentata",
    value: "/assets/images/services/wired-outline-1458-agumented-reality.gif",
  },
  {
    label: "Orologio da Scacchi",
    value: "/assets/images/services/wired-outline-1478-chess-clock.gif",
  },
  {
    label: "Mattoncini Giocattolo",
    value: "/assets/images/services/wired-outline-1524-bricks-toy.gif",
  },
  {
    label: "Qualità dell'Aria",
    value: "/assets/images/services/wired-outline-1644-air-quality.gif",
  },
  {
    label: "Cannabis",
    value: "/assets/images/services/wired-outline-1837-cannabis.gif",
  },
  {
    label: "Boomerang",
    value: "/assets/images/services/wired-outline-1974-boomerang.gif",
  },
  {
    label: "Candela",
    value: "/assets/images/services/wired-outline-2229-candle.gif",
  },
  {
    label: "Intelligenza Artificiale",
    value:
      "/assets/images/services/wired-outline-2511-artificial-intelligence-ai.gif",
  },
  {
    label: "Intelligenza Artificiale Alt",
    value:
      "/assets/images/services/wired-outline-2512-artificial-intelligence-ai-alt.gif",
  },
  {
    label: "Banconota",
    value: "/assets/images/services/wired-outline-2752-banknote.gif",
  },
  {
    label: "Lista delle Cose da Fare",
    value: "/assets/images/services/wired-outline-47-to-do-list.gif",
  },
  {
    label: "Bersaglio",
    value: "/assets/images/services/wired-outline-134-target.gif",
  },
];
