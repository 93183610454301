export const AlertsUtil = {
    getIcon(entity) {
        if (!entity) return "bx bx-bell";
        switch (entity.toLowerCase()) {
            case "contact":
                return "bx bx-user";
            case "estimate":
                return "bx bx-cart";
            case "order":
                return "bx bx-bullseye";
            case "invoice":
                return "bx bx-check-shield";
            case "transaction":
                return "bx bx-money";
            default:
                return "bx bx-bell";
        }
    },
};