import React, { useContext, useEffect, useState } from "react";
import {
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const PecForm = ({ business, onUpdateBusiness }) => {
  const formikPec = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: business,

    validationSchema: Yup.object({
      certifiedEmail: Yup.string().required("PEC obbligatoria"),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    try {
      const errors = await formikPec.validateForm();

      if (Object.keys(errors).length) return;

      // Perform your desired action for valid form
      console.log("🎉 OTP form is valid");

      business.certifiedEmail = formikPec.values.certifiedEmail;

      await onUpdateBusiness(business);
    } catch (error) {
      console.error("OTP form validation error:", error);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formikPec.handleSubmit();
        return false;
      }}
    >
      <FormGroup>
        <Label htmlFor="certifiedEmail">PEC</Label>
        <Input
          id="certifiedEmail"
          name="certifiedEmail"
          type="text"
          className="form-control"
          placeholder={`Inserisci la tua PEC`}
          onChange={formikPec.handleChange}
          onBlur={formikPec.handleBlur}
          value={formikPec.values.certifiedEmail || ""}
          invalid={
            formikPec.touched.certifiedEmail && formikPec.errors.certifiedEmail
          }
        />
        {formikPec.touched.certifiedEmail && formikPec.errors.certifiedEmail ? (
          <FormFeedback type="invalid">
            {formikPec.errors.certifiedEmail}
          </FormFeedback>
        ) : null}
      </FormGroup>
      <div className="d-flex justify-content-end">
        <Button color="warning" type="submit">
          {business.certifiedEmail ? "Aggiorna" : "Salva"}
        </Button>
      </div>
    </Form>
  );
};

export default PecForm;
