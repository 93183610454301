import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
import { ServicesCardItem } from "../components/ServicesCardItem";

export const ServicesCard = ({
  values,
  onChangeValues,
  services,
  onAddService,
  cardLabel,
  btnLabel,
  disabled,
  isPlan,
}) => {
  const onChangeServiceItem = function (value, index) {
    onChangeValues(values.map((x, i) => (i === index ? value : x)));
  };

  const onCopyServiceItem = function (index) {
    onChangeValues(values.toSpliced(index + 1, 0, values[index]));
  };

  const onDeleteServiceItem = function (index) {
    onChangeValues(values.toSpliced(index, 1));
  };

  return (
    <div className="card">
      <div className="card-body">
        <fieldset disabled={disabled}>
          <Container fluid>
            <Row>
              <Col className="d-flex justify-content-between align-items-center">
                <h4 className="card-title m-0">{cardLabel}</h4>
                {!disabled && (
                  <Button onClick={onAddService} color="primary" outline>
                    {btnLabel}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {values.map((x, i) => {
                return (
                  <>
                    <div className={`my-2 ${i !== 0 && "border-top"}`}></div>
                    <Col
                      key={`${isPlan ? "p" : "s"}-${i}`}
                      xs={12}
                      className={`p-0 my-2`}
                    >
                      <ServicesCardItem
                        value={x}
                        services={services}
                        onChangeValue={(y) => onChangeServiceItem(y, i)}
                        isPlan={!!isPlan}
                        disabled={disabled}
                      ></ServicesCardItem>
                      {!disabled && (
                        <div className="d-flex justify-content-end px-2">
                          <ButtonGroup>
                            <Button
                              onClick={() => onDeleteServiceItem(i)}
                              color="secondary"
                              outline
                              className="btn btn-soft-secondary waves-effect waves-light"
                            >
                              <i className="bx bx-trash"></i>
                            </Button>
                            <Button
                              onClick={() => onCopyServiceItem(i)}
                              color="primary"
                              outline
                              className="btn btn-soft-primary waves-effect waves-light"
                            >
                              <i className="bx bx-add-to-queue"></i>
                            </Button>
                          </ButtonGroup>
                        </div>
                      )}
                    </Col>
                  </>
                );
              })}
            </Row>
          </Container>
        </fieldset>
      </div>
    </div>
  );
};
