import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const BusinessTaxesApi = {
  apiName: "BusinessTaxesApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/taxes`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },
};
