import React from "react";
import { _BaseUtil } from "../../utils/_BaseUtil";

const Timeline = ({ key, events }) => (
  <ul className="verti-timeline list-unstyled mt-4">
    {events?.map((event, index) => (
      <li key={`${key}-${index}`} className="event-list pb-2">
        <div className="event-timeline-dot">
          <i className={event.icon}></i>
        </div>
        <div className="d-flex">
          <div className="flex-shrink-0 me-3">
            <h5>{_BaseUtil.formatDate(event.date)}</h5>
          </div>
          <div className="flex-grow-1">{event.description}</div>
        </div>
      </li>
    ))}
  </ul>
);

export default Timeline;
