import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { Col, Row } from "reactstrap";

import punycode from "punycode/";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";
import { OrdersUtil } from "../utils/OrdersUtil";
import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

export default function Contacts() {
  const tagsColorClasses = ["primary", "success", "warning", "danger"];

  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [tableRecords, setTableRecords] = useState([]);
  const [filter, setFilter] = useState("");

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let pContacts = ContactsApi_Cache.get(businessId);
    let pEstimates = EstimatesApi_Cache.get(businessId);
    let pOrders = OrdersApi_Cache.get(businessId);

    let promises = await Promise.all([pContacts, pEstimates, pOrders]);

    let contacts = promises[0] || [];
    let estimatesModel = promises[1] || [];
    let ordersModel = promises[2] || [];

    contacts = contacts.filter((s) => _BaseUtil.filterAttributes(s, filter));

    let result = contacts.map((x) => mapTable(x, estimatesModel, ordersModel));

    result = result.sort((a, b) =>
      a.businessName?.localeCompare(b.businessName)
    );

    result.forEach((t) => console.log(t));

    setTableRecords(result);
  };

  const mapTable = (contact, estimates, ordersModel) => {
    contact.ordersCount = OrdersUtil.countByCustomerId(
      ordersModel,
      contact.rowKey
    );
    contact.estimatesCount = EstimatesUtil.countByCustomerId(
      estimates,
      contact.rowKey
    );
    return contact;
  };

  useEffect(() => {
    loadTable();
  }, [filter]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Contatti</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/contacts">Contatti</Link>
                    </li>
                    <li className="breadcrumb-item active">Lista Contatti</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    </div>
                    <div className="col-6 my-auto">
                      <div className="text-end">
                        <Link to="/contact" className="btn btn-primary">
                          Nuovo Contatto
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Filtra ..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <Link
                            onClick={() => loadTable(true)}
                            className="btn btn-light float-end"
                          >
                            <i className="mdi mdi-refresh"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`card d-none d-sm-block`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                  <input
                    type="text"
                    className="form-control mx-4"
                    id="searchInput"
                    placeholder="Filtra ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <Link to="/contact" className="btn btn-primary">
                      Nuovo Contatto
                    </Link>
                    <Link
                      onClick={() => loadTable(true)}
                      className="btn btn-light mx-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive tableReflow">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Nome</th>
                      <th scope="col">Dati fatturazione</th>
                      <th scope="col">Recapiti</th>
                      <th scope="col">Stato</th>
                      <th scope="col">Vendite</th>
                      <th scope="col">Tag</th>
                      <th scope="col">Azioni</th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableRecords.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                              <Link
                                to={`/contact/${item.rowKey}/profile`}
                                className="text-dark"
                              >
                                <span className="avatar-title rounded-circle bg-info bg-soft text-primary font-size-22">
                                  <img
                                    src={
                                      item.imagePath ||
                                      "/assets/images/companies/hexagon.gif"
                                    }
                                    alt={item.name || "Azienda generica"}
                                    className="mw-100"
                                  />
                                </span>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/contact/${item.rowKey}/profile`}
                                className="text-dark"
                              >
                                {item.type === 1 ? (
                                  <span>
                                    {item.firstName} {item.lastName}
                                  </span>
                                ) : (
                                  <span>
                                    {item.businessName
                                      ? item.businessName
                                      : "Denominazione mancante"}
                                  </span>
                                )}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0 text-wrap">
                              <i
                                className={
                                  item.type === 1
                                    ? "bx bx-user me-1 text-muted"
                                    : "bx bx-buildings me-1 text-muted"
                                }
                              ></i>
                              <Link
                                to={`/contact/${item.rowKey}`}
                                style={{ color: "var(--bs-link-color)" }}
                              >
                                {ContactsUtil.getTypeDescription(item.type)}
                              </Link>
                            </p>
                          </td>
                          <td>
                            {item.type === 1 ? (
                              <>
                                <h5 className="font-size-14 ">
                                  COD. FISCALE: {item.governmentId}
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5 className="font-size-14 ">
                                  P. IVA: {item.vatNumber}
                                </h5>
                                <span className="text-truncate">
                                  Codice destinatario:{" "}
                                  {item.eInvoiceProviderCode}
                                </span>
                              </>
                            )}
                          </td>
                          <td>
                            <h5 className="font-size-14 text-truncate">
                              <a href={`mailto:${item.certifiedEmail}`}>
                                {item.certifiedEmail}
                              </a>
                            </h5>

                            <span className="text-truncate">
                              {!!item.email && (
                                <a
                                  href={`mailto:${punycode.toUnicode(
                                    item.email
                                  )}`}
                                  style={{ color: "black" }}
                                >
                                  {punycode.toUnicode(item.email)}
                                </a>
                              )}
                              <br />
                              {!!item.phone && (
                                <a
                                  href={`tel:${item.phone}`}
                                  style={{ color: "black" }}
                                >
                                  {item.phone}
                                </a>
                              )}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`fs-5 badge ${
                                ContactsUtil.getStatus(item.status) ===
                                "INACTIVE"
                                  ? "bg-warning"
                                  : "bg-success"
                              }`}
                            >
                              {ContactsUtil.getStatus(item.status) ===
                              "INACTIVE"
                                ? "Inattivo"
                                : "Attivo"}
                            </span>
                          </td>
                          <td>
                            <h5 className="font-size-14 ">
                              Ordini aperti: {item.ordersCount}
                            </h5>
                            <span className="text-truncate">
                              Preventivi inviati: {item.estimatesCount}
                            </span>
                          </td>
                          <td>
                            {_BaseUtil.tagsToList(item.tags).map((tag, i) => (
                              <React.Fragment key={i}>
                                <span
                                  className={`fs-6 badge badge-soft-${
                                    tagsColorClasses[i]
                                  } ${i !== 0 ? "mt-1" : ""}`}
                                >
                                  {tag}
                                </span>
                                <br />
                              </React.Fragment>
                            ))}
                          </td>
                          <td>
                            <div className="dropdown">
                              <Link
                                className="dropdown-toggle card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                              </Link>
                              <div className="dropdown-menu dropdown-menu-end">
                                <Link
                                  to={`/contact/${item.rowKey}`}
                                  className="dropdown-item"
                                >
                                  Modifica
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
