import { Link } from "react-router-dom";

export const ProfileButton = () => {
  return (
    <Link to={"settings"} role="button" className="dropdown-item">
      <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
      <span key="t-profile-settings">Profilo</span>
    </Link>
  );
};
