import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { BusinessContext } from "../contexts/AppContexts";

import { ConfigurationsApi_Cache } from "../api/decorators/cache/ConfigurationsApi_Cache";

export const SelectVatType = ({ currentId, onChange, disabled }) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [vatTypes, setVatTypes] = useState([]);

  const getVatTypes = async () => {
    const vatTypes = await ConfigurationsApi_Cache.getVatTypes(businessId);

    const sortedItems = [...vatTypes].sort((a, b) => {
      const intA = +a.id;
      const intB = +b.id;
      return intA - intB;
    });

    setVatTypes(sortedItems);
  };

  useEffect(() => {
    getVatTypes();
  }, []);

  const vatTypeOptionLabel = ({ percentage, nature, description }) => (
    <>
      <span className="fw-bold">{percentage}%</span>, {description}{" "}
      {nature && `, Natura: ${nature}`}
    </>
  );

  return (
    !!vatTypes && (
      <Select
        placeholder="Inserire Aliquota IVA"
        options={vatTypes}
        getOptionLabel={(option) => option.percentage + "%"} // Mantiene la visualizzazione della percentuale
        getOptionValue={(option) => option.id.toString()} // Usa l'id come chiave univoca
        formatOptionLabel={vatTypeOptionLabel}
        onChange={(option) => onChange(option.id)} // Passa l'id dell'opzione selezionata
        value={vatTypes.find((x) => x.id === currentId)} // Trova l'opzione selezionata basata sull'id
        isDisabled={disabled}
      />
    )
  );
};
