import { api } from "../configs/axiosConfigs";

export const GoogleApi = {
  apiName: "GoogleApi",
  baseUrl: "/webapp/businesses",

  googleOAuthCallback: async function (businessId, payload) {
    const fnName = "googleOAuthCallback";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const response = await api.post(
      `${this.baseUrl}/${businessId}/google/oauth/callback`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
