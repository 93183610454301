import axios from "axios";
import axiosRetry from "axios-retry";

const client = axios.create({
  headers: {
    "Api-Version": "1.0",
    "Cache-Control": "max-age=3600",
  },
  timeout: 120000, // Timeout di 2 minuti espressi in millisecondi
  baseURL: `${process.env.REACT_APP_BASE_URL}`, // 'https://devenvapi.getholofinance.com/webapp' 'https://h-apim-dev.azure-api.net/webapp'
});
axiosRetry(client, {
  retries: 3, // number of retries
  retryDelay: (retryCount, error) => {
    const response = error.response;

    let retryCalc = retryCount * 10000;

    if (response && response.headers["retry-after"]) {
      // Use the value of the Retry-After header as the delay in milliseconds
      const delay = parseInt(response.headers["retry-after"]) * 1000;
      console.log("retry-after: ", delay);
      retryCalc = delay;
    }

    console.log("Retry in: ", retryCalc);

    const randomNum = Math.random() * 1000;
    const finalInterval = Math.floor(retryCalc + randomNum);

    console.log("Retry with jitterer in: ", finalInterval);

    return finalInterval; // time interval between retries
  },
  retryCondition: (axios) => {
    console.log("⛔️ ~ axios ~ retryCondition:", axios);
    // if retry condition is not specified, by default idempotent requests are retried

    let method = axios.config?.method?.toLowerCase();
    let status = axios.response?.status;

    console.log("Axios Method: ", method);
    console.log("Axios Status: ", status);

    if (method !== "get") {
      return false;
    }

    switch (status) {
      case 408:
      case 429:
      case 503:
        return true;
      default:
        return false;
    }
  },
});
export const api = client;
