import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import { UserContext, BusinessContext } from "../contexts/AppContexts";

import { it } from "date-fns/locale"; // Import the Italian locale
import clsx from "clsx";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { ConditionsApi_Cache } from "../api/decorators/cache/ConditionsApi_Cache";
import { ContractsApi_Cache } from "../api/decorators/cache/ContractsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";
import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Button,
  Label,
  Container,
  FormFeedback,
} from "reactstrap";

import TotalAmountGross from "../components/Amounts/TotalAmountGross";
import { ConditionsCard } from "../components/ConditionsCard";
import { LinksCard } from "../components/LinksCard";
import { ServicesCard } from "../components/ServicesCard";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";
import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

const paymentPageBaseUrl = process.env.REACT_APP_PAY_BASE_URL;

export default function Estimate() {
  const { user } = useContext(UserContext);
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();
  const navigate = useNavigate();

  const [estimateModel, setEstimateModel] = useState(null);

  const [allContacts, setAllContacts] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [allConditions, setAllConditions] = useState([]);
  const [contract, setContract] = useState(null);

  const [currentServices, setCurrentServices] = useState([]);
  const [currentPlans, setCurrentPlans] = useState([]);
  const [currentConditions, setCurrentConditions] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact: null,
      subject: null,
      documentDate: null,
      expirationDate: null,
      closingDays: null,
      allowPublicPageEdit: false,
      requestContractAcceptance: true,
      notes: "",
    },
    onSubmit: () => handleSubmit(),
    validationSchema: Yup.object({
      contact: Yup.string().required("Seleziona un contatto"),
      subject: Yup.string().required("Oggetto obbligatorio"),
    }),
  });

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    handleOnLoad();
  }, [id]);

  useEffect(() => {
    if (!!estimateModel?.estimate) {
      const { estimate, rows, conditions, links } = estimateModel;

      const services = rows.filter((x) => x.serviceType === 1) || [];
      const subscriptions = rows.filter((x) => x.serviceType === 2) || [];

      form.setValues({
        contact: estimate.customerId,
        subject: estimate.subject,
        documentDate: new Date(estimate.date),
        expirationDate: new Date(estimate.expirationDate),
        closingDays: estimate.closingDays,
        allowPublicPageEdit: estimate.allowPublicPageEdit,
        requestContractAcceptance: !!estimate.requestContractAcceptance,
        notes: estimate.notes,
      });

      setCurrentServices(services);
      setCurrentPlans(subscriptions);
      setCurrentConditions(conditions);
      setCurrentLinks(links);

      return;
    }
  }, [estimateModel]);

  const handleOnLoad = async () => {
    const promises = [
      ContactsApi_Cache.get(businessId),
      EstimatesApi_Cache.get(businessId),
      ServicesApi_Cache.get(businessId),
      ConditionsApi_Cache.get(businessId),
      ContractsApi_Cache.get(businessId),
    ];

    let [contacts, estimates, services, conditions, contract] =
      await Promise.all(promises);

    //taking only active services
    services = services.filter((x) => x.status === 0);

    setAllContacts(contacts);
    setAllServices(services.filter((s) => s.type === 1));
    setAllPlans(services.filter((s) => s.type === 2));
    setAllConditions(conditions || []);
    setContract(contract);

    if (id) {
      const estimateModel = estimates.find((x) => x?.estimate?.rowKey === id);
      if (estimateModel) {
        setEstimateModel(estimateModel);
      } else {
        navigate("/not-found");
      }
      return;
    }

    setEstimateModel(await EstimatesUtil.getModelTemplate(businessId));
  };

  const isCreateMode = !estimateModel?.estimate?.rowKey;
  const isDraft = (estimateModel?.estimate?.status ?? -1) === 1;
  const currentContact = allContacts.find(
    (x) => x.rowKey === form.values.contact
  );

  const handlePaymentLink = async () => {
    if (isCreateMode) return;

    const result = await EstimatesApi_Cache.createPublicLinkAsync(
      businessId,
      estimateModel.estimate.rowKey
    );

    // the link change the status of the estimate
    atomClearCache();

    if (result?.rowKey) {
      const businessId = business.rowKey;
      const estimateId = estimateModel.estimate.rowKey;
      const linkId = result.rowKey;

      const paymentUrl = `${paymentPageBaseUrl}/${businessId}/e/${estimateId}/${linkId}`;

      if (navigator.share) {
        const shareOptions = {
          title: "Holo - Pagamento " + form.values.subject,
          text: `Questo è il link per procedere al pagamento del servizio: ${form.values.subject}. <br /><br />Clicca qui per vedere i dettagli del preventivo: ${paymentUrl}`,
          url: paymentUrl,
        };

        await navigator.share(shareOptions);

        handleOnLoad();
      } else {
        await navigator.clipboard.writeText(paymentUrl);
        toast.info("Link di pagamento copiato negli appunti");

        handleOnLoad();
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const errors = await form.validateForm();

      if (!Object.keys(errors).length && currentContact) {
        console.log("form is valid");

        const {
          contact,
          subject,
          closingDays,
          allowPublicPageEdit,
          requestContractAcceptance,
          notes,
        } = form.values;

        const documentDate =
          form.values.documentDate != null
            ? new Date(form.values.documentDate)
            : null;

        const expirationDate =
          form.values.expirationDate != null
            ? new Date(form.values.expirationDate)
            : null;

        const payload = {
          estimate: {
            eTag: undefined,
            code: estimateModel.estimate.code,
            sequenceLetter: business.sequenceLetter,
            revisionNumber: estimateModel.estimate.revisionNumber,
            revisedEstimateId: estimateModel.estimate.revisedEstimateId,
            contactId: contact,
            allowPublicPageEdit: allowPublicPageEdit,
            requestContractAcceptance: !!requestContractAcceptance,
            subject: subject,
            date: !documentDate ? null : new Date(documentDate).toJSON(),
            expirationDate: !expirationDate
              ? null
              : new Date(expirationDate).toJSON(),
            closingDays: closingDays,
            tags: "",
            currencyType: estimateModel.estimate.currencyType,
            currency: estimateModel.estimate.currency,
            status: estimateModel.estimate.status,
            notes: notes,
            estimateConditionId: null,
            //business
            businessId: businessId,
            businessFirstName: user.firstName,
            businessLastName: user.lastName,
            businessGovernmentId: business.governmentId,
            businessBusinessName: business.businessName,
            businessCountryCode: business.countryCode,
            businessAddress: business.address,
            businessStreetNumber: business.streetNumber,
            businessZipCode: business.zipCode,
            businessCity: business.city,
            businessProvince: business.province,
            businessBusinessTypeCode: business.businessTypeCode,
            businessPhone: business.phone,
            businessEmail: business.email,
            businessVatNumber: business.vatNumber,
            businessEInvoiceProviderCode: business.eInvoiceProviderCode,
            businessCertifiedEmail: business.certifiedEmail,
            //customer
            customerId: currentContact.rowKey,
            customerType: currentContact.type,
            customerFirstName: currentContact.firstName,
            customerLastName: currentContact.lastName,
            customerGovernmentId: currentContact.governmentId,
            customerBusinessName: currentContact.businessName,
            customerCountryCode: currentContact.countryCode,
            customerAddress: currentContact.address,
            customerStreetNumber: currentContact.streetNumber,
            customerZipCode: currentContact.zipCode,
            customerCity: currentContact.city,
            customerProvince: currentContact.province,
            customerPhone: currentContact.phone,
            customerEmail: currentContact.email,
            customerVatNumber: currentContact.vatNumber,
            customerEInvoiceProviderCode: currentContact.eInvoiceProviderCode,
            customerCertifiedEmail: currentContact.certifiedEmail,
          },
          rows: [...currentServices, ...currentPlans]
            .filter((x) => x.serviceId)
            .map((x) => ({
              ...x,
              eTag: undefined,
              estimateId: estimateModel.estimate.rowKey,
            })),
          conditions: (currentConditions || [])
            .filter((x) => x.conditionId)
            .map((x) => ({
              ...x,
              eTag: undefined,
              estimateId: estimateModel.estimate.rowKey,
              discountPercentage: x.discountPercentage || 0,
              isDefault: x.isDefault || false,
            })),
          links: (currentLinks || []).map((x) => ({
            ...x,
            eTag: undefined,
          })),
        };

        const result = isCreateMode
          ? await EstimatesApi_Cache.create(businessId, payload)
          : await EstimatesApi_Cache.update(
              businessId,
              estimateModel.estimate.rowKey,
              payload
            );

        if (result?.estimate?.rowKey) {
          navigate(`/estimate/${result.estimate.rowKey}`);

          return;
        }
      }
    } catch {
      toast.error("C'è stato un errore durante il salvataggio");
    }
  };

  const onDeleteClick = async function () {
    let estimateId = estimateModel?.estimate?.rowKey;
    let status = estimateModel?.estimate?.status;

    if (!estimateId) {
      toast.error("Preventivo non valido");
      return;
    }

    if (status !== 1) {
      toast.error(
        "Non è possibile eliminare un preventivo in attesa di pagamento"
      );
      return;
    }

    await EstimatesApi_Cache.delete(businessId, estimateId);

    navigate(`/estimates`);
  };

  const onAddService = function () {
    setCurrentServices([
      ...(currentServices ?? []),
      {
        serviceId: null,
        serviceName: null,
        quantity: null,
        taxPercentage: null,
        amount: null,
        serviceType: 1,
      },
    ]);
  };

  const onAddPlan = function () {
    setCurrentPlans([
      ...(currentPlans ?? []),
      {
        serviceId: null,
        serviceName: null,
        quantity: null,
        taxPercentage: null,
        amount: null,
        serviceType: 2,
      },
    ]);
  };

  const onAddCondition = function () {
    setCurrentConditions([
      ...(currentConditions ?? []),
      {
        status: 0,
        percentageWorkStart: 0,
        percentageWorkEnd: 0,
        percentageWorkInstallments: 0,
        installmentsCount: 0,
        installmentsFrequency: 0,
        paymentGateway: 0,
        conditionId: null,
        currency: "EUR",
        currencyType: 1,
        isDefault: null,
        discountPercentage: null,
        percentageSum: 0,
      },
    ]);
  };

  const onAddLink = function () {
    setCurrentLinks([
      ...(currentLinks ?? []),
      {
        description: null,
        url: null,
      },
    ]);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                {isCreateMode ? "Crea Preventivo" : "Modifica Preventivo"}
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/estimates">Preventivi</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {isCreateMode ? "Nuovo" : "Modifica"}
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form>
              <div className="card">
                <div className="card-body">
                  <Container fluid>
                    <fieldset disabled={!isDraft}>
                      <Row className="w-100">
                        {/* estimate logo */}
                        <Col lg={3}>
                          <img
                            src="/assets/images/estimate.png"
                            alt="Holo Estimate Logo"
                            className="img-fluid img-doc"
                          ></img>
                          <div className="position-absolute top-0 start-10 translate-middle fs-2 m-2">
                            <i className="bx bxs-add-to-queue fs-18 text-primary"></i>
                          </div>
                        </Col>

                        {/* contact */}
                        <Col lg={4}>
                          {/* contact */}
                          <FormGroup className="px-0">
                            <Label>Scegli un contatto</Label>
                            <InputGroup>
                              <Input
                                invalid={
                                  !!form.submitCount && !!form.errors.contact
                                }
                                type="select"
                                placeholder="Seleziona un contatto..."
                                name="contact"
                                value={form.values.contact}
                                onChange={form.handleChange}
                              >
                                {[
                                  <option value="" disabled selected>
                                    Scegli un contatto
                                  </option>,
                                  allContacts.map((x) => {
                                    return (
                                      <option key={x.rowKey} value={x.rowKey}>
                                        {!x.vatNumber
                                          ? `${x.lastName} ${x.firstName}`
                                          : x.businessName}
                                      </option>
                                    );
                                  }),
                                ]}
                              </Input>
                              {isCreateMode && (
                                <Button
                                  color="primary"
                                  onClick={() => navigate("/contact")}
                                >
                                  Nuovo
                                </Button>
                              )}
                              {!!currentContact && (
                                <Button
                                  color="light"
                                  onClick={() =>
                                    navigate(
                                      `/contact/${currentContact.rowKey}`
                                    )
                                  }
                                >
                                  Modifica
                                </Button>
                              )}
                            </InputGroup>
                          </FormGroup>
                          <FormFeedback
                            className={clsx("d-block", {
                              "d-none":
                                !form.submitCount || !form.errors.contact,
                            })}
                          >
                            Campo obbligatorio
                          </FormFeedback>

                          {/* business name change allowed */}
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              id="allowPublicPageEdit"
                              name="allowPublicPageEdit"
                              checked={form.values.allowPublicPageEdit}
                              onChange={form.handleChange}
                            ></Input>
                            <Label check for="allowPublicPageEdit">
                              Il cliente potrà cambiare la ragione sociale di
                              fatturazione
                            </Label>
                          </FormGroup>

                          {/* request contract acceptance */}
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              id="requestContractAcceptance"
                              name="requestContractAcceptance"
                              checked={form.values.requestContractAcceptance}
                              onChange={form.handleChange}
                            ></Input>
                            <Label check for="requestContractAcceptance">
                              Invia contratto
                            </Label>
                          </FormGroup>

                          {/* customer address */}
                          <ul
                            className={clsx("list-unstyled", "vstack", "mt-2", {
                              "d-none": !currentContact?.address,
                            })}
                          >
                            <li>
                              <div className="d-flex">
                                <i className="bx bx-map font-size-18 text-primary"></i>
                                <span className="mx-1 text-muted fw-semibold">
                                  Sede legale:
                                </span>
                                <span className="text-muted mx-1">
                                  {ContactsUtil.getFullAddress(currentContact)}
                                </span>
                                <span>
                                  <a
                                    target="_blank"
                                    href={`https://www.google.it/maps/search/${ContactsUtil.getFullAddress(
                                      currentContact
                                    )}`}
                                    rel="noreferrer"
                                  >
                                    <i className="bx bx-link-external"></i>
                                  </a>
                                </span>
                              </div>
                            </li>
                            <li>
                              <div
                                className={clsx("d-flex", "mt-2", {
                                  "d-none": !!currentContact?.firstName,
                                })}
                              >
                                <i className="bx bx-user-circle font-size-18 text-primary"></i>
                                <span className="mx-1 text-muted fw-semibold">
                                  Referente:
                                </span>
                                <span className="text-muted mx-1">
                                  {`${currentContact?.firstName} ${currentContact?.lastName}`}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </Col>

                        {/* subject and dates */}
                        <Col lg={5}>
                          <Row>
                            <Col xs={12}>
                              <FormGroup>
                                <Label>Oggetto</Label>
                                <Input
                                  type="text"
                                  name="subject"
                                  invalid={
                                    !!form.submitCount && form.errors.subject
                                  }
                                  placeholder="Inserisci oggetto"
                                  value={form.values.subject}
                                  onChange={form.handleChange}
                                ></Input>
                                <FormFeedback>Campo obbligatorio</FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              {/* date */}
                              <FormGroup className="d-flex flex-column">
                                <Label className="text-truncate d-inline-block">
                                  Data Documento
                                </Label>
                                <DatePicker
                                  locale={it}
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  placeholder="Data Documento"
                                  selected={form.values.documentDate}
                                  onChange={(value) =>
                                    form.setFieldValue("documentDate", value)
                                  }
                                ></DatePicker>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              {/* expiration date */}
                              <FormGroup className="d-flex flex-column">
                                <Label className="text-truncate d-inline-block">
                                  Scadenza Preventivo
                                </Label>
                                <DatePicker
                                  locale={it}
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  placeholder="Data Scadenza"
                                  selected={form.values.expirationDate}
                                  onChange={(value) =>
                                    form.setFieldValue("expirationDate", value)
                                  }
                                ></DatePicker>
                              </FormGroup>
                            </Col>
                            {/* closing days */}
                            <Col md={6}>
                              <Label className="text-truncate d-inline-block">
                                Giorni consegna
                              </Label>
                              <FormGroup>
                                <Input
                                  type="text"
                                  placeholder="N° giorni..."
                                  name="closingDays"
                                  value={form.values.closingDays}
                                  onChange={form.handleChange}
                                ></Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </fieldset>
                    <Row className="my-2 pt-4 border-top">
                      {/* document number */}
                      <Col sm={4} lg={3}>
                        <p className="fw-medium mb-0">Preventivo N&ordm;</p>
                        <h4>
                          <span>{estimateModel?.estimate?.code}</span>
                          <span className="font-size-14 text-muted ms-1">
                            Revisione {estimateModel?.estimate?.revisionNumber}
                          </span>
                        </h4>
                      </Col>

                      {/* totals */}
                      <Col sm={8} lg={4}>
                        <div className="d-flex flex-row">
                          <div className="px-2">
                            <p className="fw-medium mb-0">Totale Servizi</p>
                            <h4>
                              €{" "}
                              {_BaseUtil.formatDecimals(
                                currentServices.reduce(
                                  (px, x) => px + x.amount * x.quantity,
                                  0
                                )
                              )}
                            </h4>
                            <TotalAmountGross
                              services={currentServices || []}
                            />
                          </div>
                          <div className="px-2">
                            <p className="fw-medium mb-0">Totale Piani</p>
                            <h4>
                              €{" "}
                              {_BaseUtil.formatDecimals(
                                currentPlans.reduce(
                                  (px, x) =>
                                    px +
                                    x.amount *
                                      x.quantity *
                                      (x.billingIntervalsCount || 1),
                                  0
                                )
                              )}
                            </h4>
                            <TotalAmountGross services={currentPlans || []} />
                          </div>
                        </div>
                      </Col>

                      {/* preview */}
                      <Col lg={5}>
                        <Container fluid>
                          <Row className="mt-2">
                            <Col xs={6}>
                              <Button
                                type="button"
                                onClick={onDeleteClick}
                                className={clsx(
                                  "btn",
                                  "btn-danger",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  { "d-none": isCreateMode || !isDraft }
                                )}
                              >
                                <i className="bx bx-trash label-icon"></i>
                                Elimina
                              </Button>
                            </Col>
                            <Col xs={6}>
                              {((!!currentServices?.filter((x) => !!x.serviceId)
                                ?.length &&
                                !!currentConditions?.filter(
                                  (x) => !!x.conditionId
                                )?.length) ||
                                !!currentPlans?.filter((x) => !!x.serviceId)
                                  ?.length) && (
                                <>
                                  <Button
                                    type="submit"
                                    onClick={form.handleSubmit}
                                    className={clsx(
                                      "btn",
                                      "btn-success",
                                      "waves-effect",
                                      "btn-label",
                                      "waves-light",
                                      "m-1",
                                      "w-100",
                                      { "d-none": !isDraft }
                                    )}
                                  >
                                    <i className="bx bx-save label-icon"></i>
                                    Salva
                                  </Button>
                                </>
                              )}
                            </Col>
                            <Col xs={6}>
                              <Link
                                to={`/estimate/${id}/preview`}
                                className={clsx(
                                  "btn",
                                  "btn-light",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  { "d-none": isCreateMode }
                                )}
                              >
                                <i className="bx bx-file label-icon"></i>
                                Anteprima
                              </Link>
                            </Col>
                            <Col xs={6}>
                              <Link
                                onClick={handlePaymentLink}
                                className={clsx(
                                  "btn",
                                  "btn-primary",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  {
                                    "d-none":
                                      isCreateMode ||
                                      ![1, 2].includes(
                                        estimateModel?.estimate?.status
                                      ),
                                  }
                                )}
                              >
                                <i className="bx bx-send label-icon"></i>
                                Richiedi pagamento
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Form>
          </Col>
          <Col xxl={6}>
            <ServicesCard
              cardLabel="Servizi"
              btnLabel="Aggiungi un servizio"
              disabled={!isDraft}
              values={currentServices}
              onChangeValues={setCurrentServices}
              onAddService={onAddService}
              services={allServices}
              isPlan={false}
            ></ServicesCard>
          </Col>
          <Col xxl={6}>
            <ServicesCard
              cardLabel="Piani"
              btnLabel="Aggiungi un piano"
              values={currentPlans}
              disabled={!isDraft}
              onChangeValues={setCurrentPlans}
              onAddService={onAddPlan}
              services={allPlans}
              isPlan={true}
            ></ServicesCard>
          </Col>
          <Col xxl={6}>
            <ConditionsCard
              values={currentConditions}
              disabled={!isDraft}
              onChangeValues={setCurrentConditions}
              onAddCondition={onAddCondition}
              allConditions={allConditions}
            ></ConditionsCard>
          </Col>
          <Col xxl={6}>
            <LinksCard
              values={currentLinks}
              disabled={!isDraft}
              onChangeValues={setCurrentLinks}
              onAddLink={onAddLink}
            ></LinksCard>
          </Col>
          <Col xs={12}>
            <div className="card">
              <div className="card-body">
                <FormGroup check className="px-0">
                  <Label>Note contrattuali</Label>
                  <Input
                    name="notes"
                    type="textarea"
                    disabled={!isDraft}
                    rows="3"
                    placeholder="Se necessario, inserisci qui una nota integrativa al contratto configurato nella pagina Profilo"
                    value={form.values.notes}
                    onChange={form.handleChange}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
