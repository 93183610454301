import { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { AlertsApi_Cache } from "../api/decorators/cache/AlertsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { AlertsUtil } from "../utils/AlertsUtil";
import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

export const LayoutNotificationItem = () => {
  const navigate = useNavigate();

  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [alerts, setAlerts] = useState([]);
  const [activeAlerts, setActiveAlerts] = useState([]);
  const [tableRecords, setTableRecords] = useState([]);

  const alertsRef = useRef(alerts);
  alertsRef.current = alerts;

  const fetchAlerts = async () => {
    const newAlerts = await AlertsApi_Cache.get(businessId, false, true);

    if (!alertsRef.current.length) {
      setAlerts(newAlerts);
      return;
    }

    // First load or compare with the latest reference
    const latestCurrent = alertsRef.current.reduce((max, item) => {
      const currentDate = new Date(item.createdOn);
      return currentDate > max ? currentDate : max;
    }, new Date(0));

    const isNewMoreRecent = newAlerts.some(
      (item) => new Date(item.createdOn) > latestCurrent
    );

    if (isNewMoreRecent) {
      atomClearCache(); // clearing the cache to load the correct value once the user clicks on a notification
    }

    // Needed always to update to remove the bell once the alert has been deactivated
    setAlerts(newAlerts);
  };

  useEffect(() => {
    fetchAlerts(); // Initial fetch
    const interval = setInterval(fetchAlerts, 15000); // Setup interval using the ref
    return () => clearInterval(interval); // Cleanup on unmount
  }, []); // Note: empty dependency array to setup once

  useEffect(() => {
    if (!alerts) return;
    let active = alerts.filter((x) => x.status === 0);
    setActiveAlerts(active);
    mapTableRecords();
  }, [alerts]);

  const handleDeactivate = async (id) => {
    // if already inactive I won't make the call
    const alert = alerts.find((x) => x.rowKey === id);
    if (alert.status === -1) return;

    try {
      await AlertsApi_Cache.deactivate(businessId, id, true);
      // Fetch alerts after deactivation
      fetchAlerts();
    } catch (error) {
      console.error("Error deactivating alert:", error);
    }
  };

  const handleNotificationClick = async (alertId) => {
    await handleDeactivate(alertId); // No await to run in the background
    const alert = alerts.find((x) => x.rowKey === alertId);
    navigateTo(alert);
  };

  const navigateTo = async (alert) => {
    let recordId = alert.referenceRowKey;
    switch (alert.referenceTable?.toLowerCase()) {
      case "contact":
        navigate(`/contact/${recordId}/profile`);
        return;
      case "estimate":
        navigate(`/estimate/${recordId}/details`);
        return;
      case "order":
        navigate(`/order/${recordId}/details`);
        return;
      case "invoice":
        navigate(`/invoice/${recordId}/details`);
        return;
      case "transaction":
        navigate(`/payment/${recordId}/details`);
        return;
      default:
        return;
    }
  };

  const mapTableRecords = () => {
    // Sort the list by the 'createdOn' attribute in descending order
    let records = alerts.sort(
      (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
    );

    // Get the top 3 objects
    records = records.slice(0, 3);

    // Mapping the records
    records = records.map((x) => {
      return {
        id: x.rowKey,
        title: x.description,
        icon: AlertsUtil.getIcon(x.referenceTable),
        timeAgo: _BaseUtil.timeAgo(x.createdOn),
      };
    });
    setTableRecords(records);
  };

  return (
    <>
      <button
        type="button"
        className="btn header-item noti-icon waves-effect"
        id="page-header-notifications-dropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className={`bx bx-bell ${activeAlerts.length ? "bx-tada" : ""}`}></i>
        {!!activeAlerts.length && (
          <span className="badge bg-danger rounded-pill">
            {activeAlerts.length}
          </span>
        )}
      </button>
      <div
        className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
        aria-labelledby="page-header-notifications-dropdown"
      >
        <div className="p-3">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="m-0" key="t-notifications">
                Notifiche
              </h6>
            </div>
          </div>
        </div>

        <div data-simplebar style={{ maxHeight: "230px" }}>
          <div className="simplebar-wrapper" style={{ margin: "0px" }}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div
                className="simplebar-offset"
                style={{ right: "0px", bottom: "0px" }}
              >
                <div
                  className="simplebar-content-wrapper"
                  style={{
                    height: "auto",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                    overflow: "hidden",
                  }}
                >
                  <div className="simplebar-content" style={{ padding: "0px" }}>
                    {tableRecords.map((item, index) => {
                      return (
                        <Link
                          className="text-reset notification-item"
                          key={`notification-${index}`}
                          onClick={() => handleNotificationClick(item.id)}
                          onMouseEnter={() => handleDeactivate(item.id)}
                        >
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className={item.icon}></i>
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1" key="t-your-order">
                                {item.title}
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline"></i>{" "}
                                  <span key="t-min-ago">{item.timeAgo}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="simplebar-placeholder"
              style={{ width: "0px", height: "0px" }}
            ></div>
          </div>
          <div
            className="simplebar-track simplebar-horizontal"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                display: "none",
              }}
            ></div>
          </div>
          <div
            className="simplebar-track simplebar-vertical"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                display: "none",
              }}
            ></div>
          </div>
        </div>
        <div className="p-2 border-top d-grid">
          <Link
            to={`/alerts`}
            className="btn btn-sm btn-link font-size-14 text-center"
          >
            <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
            <span key="t-view-more">Vedi tutte</span>
          </Link>
        </div>
      </div>
    </>
  );
};
