import { CompaniesApi } from "../../CompaniesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "companies";

export const CompaniesApi_Cache = {
  apiName: "CompaniesApi_Cache",

  verify: async function (businessId, vatNumber) {
    const fnName = "verify";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();
    let company = cacheData.find((item) => item.piva === vatNumber);

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || !company) {
      company = await CompaniesApi.verify(businessId, vatNumber);

      const others = cacheData.filter((item) => item.vatNumber !== vatNumber);
      $data.set([...others, company]);

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, company);

    return company;
  },
};
