import { api } from "../configs/axiosConfigs";

export const StripeApi = {
  apiName: "StripeApi",
  baseUrl: "/webapp/businesses",

  connectAsync: async function (businessId) {
    const fnName = "connectAsync";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const response = await api.post(
      `${this.baseUrl}/${businessId}/stripe/connect`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  accountSessionsStart: async function (businessId) {
    const fnName = "accountSessionsStart";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const response = await api.post(
      `${this.baseUrl}/${businessId}/stripe/accountsessions/start`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
