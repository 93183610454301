import { FattureInCloudApi } from "../../FattureInCloudApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "fattureincloud";
const KEY_COMPANIES = "fattureincloud-companies";

export const FattureInCloudApi_Cache = {
  apiName: "FattureInCloudApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await FattureInCloudApi.get(businessId, nocache, noloader);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await FattureInCloudApi.create(businessId, payload);

    if (newData) $data.set([...$data.get(), newData]); //adding object to the existing array

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  update: async function (businessId, id, payload) {
    const fnName = "update";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache(businessId, KEY, []);

    payload = await FattureInCloudApi.update(businessId, id, payload);

    if (payload) {
      const currentFattureInCloud = $data.get();
      const others = currentFattureInCloud.filter((item) => item.rowKey !== id);
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },

  delete: async function (businessId, id) {
    const fnName = "delete";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data } = atomCache(businessId, KEY, []);

    await FattureInCloudApi.delete(businessId, id);

    const currentFattureInCloud = $data.get();
    const others = currentFattureInCloud.filter((item) => item.rowKey !== id);
    $data.set([...others]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response`);
  },

  getCompanies: async function (businessId, id, nocache) {
    const fnName = "getCompanies";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache(businessId, KEY_COMPANIES);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await FattureInCloudApi.getCompanies(businessId, id, nocache);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },
};
