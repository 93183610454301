import React from "react";
import logo from "../assets/images/logo-holo-onlyimg.svg";

export const HttpLoader = ({ visible }) => {
  if (!visible) return;
  console.log("loader active");
  return (
    <div className={`loader-container ${visible ? "small-screen-loader" : ""}`}>
      <div className="animation-container">
        <div className="rotation-container">
          <img src={logo} alt="Loading..." className="rotating-logo" />
        </div>
      </div>
    </div>
  );
};
