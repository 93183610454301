import { TransactionsApi } from "../../TransactionsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "transactions";

export const TransactionsApi_Cache = {
  apiName: "TransactionsApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await TransactionsApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  update: async function (businessId, transactionId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      transactionId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    payload = await TransactionsApi.update(businessId, transactionId, payload);

    if (payload) {
      let cacheData = $data.get();
      const others = cacheData.filter((item) => item.rowKey !== transactionId);
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },

  withdraw: async function (businessId, transactionId) {
    const fnName = "withdraw";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      transactionId
    );

    const url = await TransactionsApi.withdraw(businessId, transactionId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, url);

    return url;
  },

  delete: async function (businessId, transactionId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      transactionId
    );

    const { $data } = atomCache(businessId, KEY, []);

    const response = await TransactionsApi.delete(businessId, transactionId);

    if (response) {
      let cacheData = $data.get();
      const others = cacheData.filter((item) => item.rowKey !== transactionId);
      $data.set([...others, response]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },
};
