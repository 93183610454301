import React from "react";
import MovingText from "react-moving-text";
import "../../assets/css/SplashScreen.css";

const SplashScreen = () => {
  return (
    <div className="splash-container">
      <img
        src="/assets/images/logo-holo-white.svg"
        alt="HOLO Logo"
        className="logo"
      />
      <MovingText
        type="fadeIn"
        duration="5000ms"
        delay="0s"
        direction="normal"
        timing="ease"
        iteration="infinite"
        fillMode="none"
      >
        <h1 className="loading-text">Caricamento in corso...</h1>
      </MovingText>
    </div>
  );
};

export default SplashScreen;
