import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleApi } from "../api/GoogleApi";

export default function GoogleOAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const businessId = query.get("state");
    const redirectUri = `${process.env.REACT_APP_REDIRECT_URL}/google-oauth-callback`;

    if (code && businessId) {
      // Invia i dati al backend per l'elaborazione
      const sendAuthCodeToBackend = async () => {
        try {
          const response = await GoogleApi.googleOAuthCallback(businessId, {
            code,
            redirectUri,
          });

          console.log("Success:", response);

          // Se la risposta è positiva, fai redirect alla route settings
          if (response) {
            navigate("/settings?reset=true");
          }
        } catch (error) {
          console.error("Error during OAuth callback:", error);
        }
      };

      sendAuthCodeToBackend();
    } else {
      console.error("Codice o stato mancanti nella query string");
    }
  }, [location.search, navigate]);

  return (
    <div>
      <h5>Processing Google OAuth Callback...</h5>
    </div>
  );
}
