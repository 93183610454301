import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const MovementsApi = {
  apiName: "MovementsApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    let options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/movements`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data || [];
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/movements`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  update: async function (businessId, recordId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      recordId,
      payload
    );

    const response = await api.put(
      `${this.baseUrl}/${businessId}/movements/${recordId}`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  delete: async function (businessId, recordId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      recordId
    );

    let response = await api.delete(
      `${this.baseUrl}/${businessId}/movements/${recordId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);
  },

  predictionsExpenses: async function (businessId, nocache, noloader) {
    const fnName = "predictionsExpenses";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    let options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/movements/predictions/expenses`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data || [];
  },
};
