import React, { useState, useEffect } from "react";

import { TransactionsUtil } from "../../utils/TransactionsUtil";
import { _BaseUtil } from "../../utils/_BaseUtil";

import { CardTable, CardTableUtil } from "../CardTable";
import { InvoicesUtil } from "../../utils/InvoicesUtil";

const PaymentsCardTable = ({ title, transactions, buttons }) => {
  const [columns, setColumns] = useState([
    { key: "openLink", value: "#" },
    { key: "closingDate", value: "Data Chiusura" },
    { key: "amount", value: "Importo" },
    { key: "invoiceType", value: "Tipo Fatturazione" },
    { key: "status", value: "Stato" },
  ]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const result = transactions.map((x) => {
      return {
        openLink: CardTableUtil.getCellLink(
          `<i class='bx bx-right-arrow-alt'></i>`,
          `text-primary fw-bold fs-4`,
          `/payment/${x.rowKey}/details`,
          true
        ),
        closedOn: x.closedOn,
        closingDate:
          !!x.closedOn &&
          CardTableUtil.getCellText(_BaseUtil.formatDate(x.closedOn, "it-IT")),
        amount: CardTableUtil.getCellText(
          _BaseUtil.formatCurrency(x.currency, x.amountGross, "it-IT")
        ),
        invoiceType: CardTableUtil.getCellBadge(
          `${InvoicesUtil.getTypeDescription(x.invoiceType)}`,
          null,
          `${InvoicesUtil.getTypeBadge(x.invoiceType)}`
        ),
        status: CardTableUtil.getCellBadge(
          TransactionsUtil.getStatusDescription(x),
          null,
          TransactionsUtil.getStatusBadge(x.status)
        ),
      };
    });

    const sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.closedOn);
      const dateB = new Date(b.closedOn);
      return dateB - dateA;
    });

    setRows(sortedItems);
  }, [transactions]);

  return (
    <CardTable
      key="payments-card-table"
      title={title}
      columnNames={columns}
      values={rows}
      buttons={buttons}
    />
  );
};

export default PaymentsCardTable;
