import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { BankAccountsUtil } from "../utils/BankAccountsUtil";

export const BankAccountDetail = ({
  account,
  transactions,
  fnAccountReconnection,
  fnAccountDisable,
}) => {
  const { t } = useTranslation("general");

  const getProviderImg = () => {
    console.log("getProviderImg", account.providerName);
    switch (account.providerName?.toLowerCase()) {
      case "revolut":
        return "/assets/images/companies/revolut.svg";
      case "stripe":
        return "/assets/images/companies/stripe.png";
      default:
        return "/assets/images/services/wired-outline-102-price-tag-euro.gif";
    }
  };

  const onCopyIban = function () {
    console.log("onCopyIban", account.iban);
    navigator.clipboard.writeText(account.iban).then(() => {
      // let text = [t("copied-clipboard"), "\n", t("shortcut-paste")];
      toast.success(
        <div className="my-1">
          {t("copied-clipboard")}!
          <br />
          <br />
          {t("shortcut-paste")}
          <br />
        </div>,
        {
          autoClose: 10000,
        }
      );
    });
  };

  return (
    <>
      <div className="card overflow-hidden">
        <div className="bg-primary bg-soft">
          <div className="dropdown float-end m-2">
            <a
              href="#"
              className="dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <button
                className="dropdown-item"
                onClick={() => fnAccountDisable(account.uuid)}
              >
                Rimuovi account
              </button>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-7">
              <div className="text-primary p-3">
                <h2 className="text-primary">Conto {account.providerName}</h2>
                {/* <h5>
                  <a href="https://www.stripe.com" target="_blank">
                    Portale amministrazione
                    <i className="mdi mdi-link-variant ms-1"></i>
                  </a>
                </h5> */}
              </div>
            </div>
            <div className="col-5 align-self-end">
              <img
                src="/assets/images/profile-img-fiat.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-sm-4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={getProviderImg()}
                  alt="Provider"
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </div>
            <div className="col-sm-8">
              <div className="pt-1">
                {/* <span
                  className="badge rounded-pill badge-soft-primary font-size-12"
                  id="task-status"
                >
                  Carte di credito
                </span>
                <span
                  className="badge rounded-pill badge-soft-success font-size-12 m-2"
                  id="task-status"
                >
                  SEPA
                </span> */}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-nowrap mb-0">
                <tbody>
                  {/* <tr>
                    <th scope="row">Nome Account :</th>
                    <td>Anna Martini</td>
                  </tr> */}
                  <tr>
                    <th scope="row">IBAN :</th>
                    <td>
                      {account.iban}{" "}
                      <i
                        onClick={onCopyIban}
                        role="button"
                        className="fa-solid fa-copy ms-3 text-primary"
                      ></i>
                    </td>
                  </tr>
                  <tr
                    className={`${
                      BankAccountsUtil.isExpiringConsent(account)
                        ? "text-warning"
                        : ""
                    }`}
                  >
                    <th scope="row">Scadenza consenso:</th>
                    <td>
                      {new Date(account.consentExpiresAt).toLocaleDateString()}
                    </td>
                  </tr>
                  {BankAccountsUtil.isExpiringConsent(account) && (
                    <>
                      <tr className={`text-warning`}>
                        <th scope="row">Rinnova consenso</th>
                        <td>
                          <button
                            onClick={() => fnAccountReconnection(account.uuid)}
                            className="btn btn-warning"
                          >
                            Riconnetti account
                          </button>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-body">
          <h4 className="card-title mb-3 mt-3">Questo Mese:</h4>
          <div className="text-center">
            <div className="row">
              <div className="col-sm-6">
                <div className="mt-4 mt-sm-0">
                  <div className="font-size-24 text-primary mb-2">
                    <i className="bx bx-import"></i>
                  </div>
                  <p className="text-success mb-2">Incassi</p>
                  <h5>
                    <span className="text-success">
                      €{" "}
                      {BankAccountsUtil.getIncome(
                        transactions,
                        account.uuid,
                        true
                      ).toFixed(2)}
                    </span>
                  </h5>
                  {/* <div className="mt-3">
                    <a
                      href="javascript: void(0);"
                      className="btn btn-primary btn-sm w-md"
                    >
                      Invia Richiesta
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-sm-6">
                <div>
                  <div className="font-size-24 text-primary mb-2">
                    <i className="bx bx-export"></i>
                  </div>
                  <p className="text-danger mb-2">Uscite</p>
                  <h5>
                    <span className="text-danger">
                      €{" "}
                      {BankAccountsUtil.getIncome(
                        transactions,
                        account.uuid,
                        false
                      ).toFixed(2)}
                    </span>
                  </h5>
                  {/* <div className="mt-3">
                    <a
                      href="javascript: void(0);"
                      className="btn btn-primary btn-sm w-md"
                    >
                      Invia Denaro
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
