import React from "react";
import { Card } from "reactstrap";
import PaymentCardBody from "./PaymentCardBody";

import { _BaseUtil } from "../../../utils/_BaseUtil";
import { ContactsUtil } from "../../../utils/ContactsUtil";
import { EstimatesUtil } from "../../../utils/EstimatesUtil";
import { OrdersUtil } from "../../../utils/OrdersUtil";
import { TransactionsUtil } from "../../../utils/TransactionsUtil";

export const PaymentCardHandler = {
  mapEventEstimate: (estimate) => {
    if (!estimate) return null;

    if (estimate.closedOn) {
      return {
        date: estimate.closedOn,
        description: `Preventivo ${EstimatesUtil.getStatusDescription(
          estimate.status
        )}`,
        icon: "bx bx-right-arrow-circle",
      };
    }

    return {
      date: estimate.createdOn,
      description: "Preventivo creato",
      icon: "bx bx-right-arrow-circle",
    };
  },

  mapEventOrder: (order) => {
    if (!order) return null;

    return {
      date: order.closedOn || order.createdOn,
      description: `Ordine ${OrdersUtil.getStatusDescription(order.status)}`,
      icon: "bx bx-right-arrow-circle",
    };
  },

  mapEventPayment: (payment) => {
    if (!payment) return null;

    return {
      date: payment.closedOn || payment.createdOn,
      description: `Pagamento ${
        !!payment.createdOn
          ? "creato"
          : TransactionsUtil.getStatusDescription(payment.status)
      }`,
      icon: "bx bx-right-arrow-circle",
    };
  },

  mapEvents: (payment, estimates, orders) => {
    let estimateModel = estimates.find(
      (x) => x.estimate.rowKey === payment.estimateId
    );
    let orderModel = orders.find((x) => x.order.rowKey === payment.orderId);

    if (!estimateModel && orderModel) {
      estimateModel = estimates.find(
        (x) => x.estimate.rowKey === orderModel.order.estimateId
      );
    }

    const events = [
      PaymentCardHandler.mapEventEstimate(estimateModel?.estimate),
      PaymentCardHandler.mapEventOrder(orderModel?.order),
      PaymentCardHandler.mapEventPayment(payment),
    ].filter((x) => x);

    return [...events].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  },

  mapPayment: (
    transaction,
    contacts,
    estimates,
    orders,
    invoices,
    subscriptions
  ) => {
    let contact = contacts.find((x) => x.rowKey === transaction.contactId);
    let estimateModel = estimates.find(
      (x) => x.estimate.rowKey === transaction.estimateId
    );
    let orderModel = orders.find((x) => x.order.rowKey === transaction.orderId);
    let invoiceModel = invoices.find(
      (x) => x.invoice.rowKey === transaction.invoiceId
    );
    let subscription = subscriptions.find(
      (x) => x.rowKey === transaction.subscriptionId
    );

    let result = {
      id: transaction.rowKey,
      amount: transaction.amountGross,
      amountFormatted: _BaseUtil.formatCurrency(
        transaction.currency,
        transaction.amountGross,
        "it-IT"
      ),
      createdOn: transaction.createdOn,
      createdOnFormatted: _BaseUtil.formatDate(transaction.createdOn, "it-IT"),
      closedOn: transaction.closedOn,
      closedOnFormatted:
        transaction.closedOn &&
        _BaseUtil.formatDate(transaction.closedOn, "it-IT"),
      expirationDate: transaction.expirationDate,
      expirationDateFormatted:
        transaction.expirationDate &&
        _BaseUtil.formatDate(transaction.expirationDate, "it-IT"),
      contactId: contact?.rowKey,
      contactName: ContactsUtil.getFullName(contact),
      estimateId: estimateModel?.estimate?.rowKey,
      estimateSubject: estimateModel?.estimate?.subject,
      orderId: orderModel?.order?.rowKey,
      orderSubject: orderModel?.order?.subject,
      invoiceId: invoiceModel?.invoice?.rowKey,
      invoiceSubject: invoiceModel?.invoice?.subject,
      subscriptionId: subscription?.rowKey,
      subscriptionName: subscription?.serviceName,
      paymentProvider: transaction.paymentProvider,
      paymentProviderText: TransactionsUtil.getPaymentProviderDescription(
        transaction.paymentProvider
      ),
      status: transaction.status,
      tokenizationId: transaction.tokenizationId,
    };

    return result;
  },
};

const PaymentCard = ({ payment, events }) => {
  const handleCollect = () => {
    console.log("Collect action triggered");
    // Define collect functionality
  };

  const handleReminder = () => {
    console.log("Reminder action triggered");
    // Define reminder functionality
  };

  const handleCancel = () => {
    console.log("Cancel action triggered");
    // Define cancel functionality
  };

  return (
    !!payment && (
      <Card>
        <PaymentCardBody
          payment={payment}
          events={events}
          onCollect={handleCollect}
          onReminder={handleReminder}
          onCancel={handleCancel}
        />
      </Card>
    )
  );
};

export default PaymentCard;
