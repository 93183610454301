import { useEffect, useState } from "react";

import { _BaseUtil } from "../utils/_BaseUtil";
import { OrdersUtil } from "../utils/OrdersUtil";

import { CardTable, CardTableUtil } from "../components/CardTable";

export const CardTableOrders = ({ title, orders }) => {
  const cardTableOrdersColumns = [
    {
      key: "code",
      value: "Codice",
    },
    {
      key: "subject",
      value: "Oggetto",
    },
    {
      key: "startDate",
      value: "Data Inizio",
    },
    {
      key: "expirationDate",
      value: "Data consegna",
    },
    {
      key: "amount",
      value: "Importo",
    },
    {
      key: "status",
      value: "Stato",
    },
  ];

  const [cardTableOrdersValues, setCardTableOrdersValues] = useState([]);

  const handleOnLoad = async () => {
    if (!orders) return;

    let values = orders
      .map((x) => {
        return {
          code: CardTableUtil.getCellLink(
            x.order.code,
            `text-primary fw-bold`,
            `/order/${x.order.rowKey}/details`
          ),
          subject: CardTableUtil.getCellLink(
            x.order.subject,
            `text-primary fw-bold`,
            `/order/${x.order.rowKey}/details`
          ),
          startDate: CardTableUtil.getCellText(
            _BaseUtil.formatDate(x.order.createdOn, "it-IT")
          ),
          expirationDate: CardTableUtil.getCellText(
            _BaseUtil.formatDate(x.order.deliveryDate, "it-IT")
          ),
          amount: CardTableUtil.getCellText(
            _BaseUtil.formatCurrency(
              x.order.currency,
              x.order.totalAmountServicesNet,
              "it-IT"
            )
          ),
          status: CardTableUtil.getCellBadge(
            `${OrdersUtil.getStatusDescription(x.order.status)}`,
            null,
            `${OrdersUtil.getStatusBadge(x.order.status)}`
          ),
        };
      })
      .filter((x) => x);

    setCardTableOrdersValues(values);
  };

  useEffect(() => {
    handleOnLoad();
  }, [orders]);

  return (
    <>
      <CardTable
        key={`ct-orders`}
        title={title}
        columnNames={cardTableOrdersColumns}
        values={cardTableOrdersValues}
      />
    </>
  );
};
