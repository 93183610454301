import React, { useContext, useEffect, useState } from "react";

import { BusinessContext } from "../contexts/AppContexts";

import { TinyEditor } from "../components/TinyEditor";
import { ContractsApi_Cache } from "../api/decorators/cache/ContractsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { Link } from "react-router-dom";

export const SettingsContractEditor = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [text, setText] = useState(null);
  const [newText, setNewText] = useState(null);

  const retrieveDefaultContract = async () => {
    const result = await ContractsApi_Cache.getDefault(businessId);
    setText(result);
  };

  const retrieveContract = async () => {
    const result = await ContractsApi_Cache.get(businessId);
    setText(result);
  };

  const handleDownload = async () => {
    const result = await ContractsApi_Cache.getFor(
      businessId,
      "templates",
      "00000000-0000-0000-0000-000000000000"
    );

    if (result) {
      _BaseUtil.downloadPdf(result);
    }
  };

  const handleSave = async () => {
    const result = await ContractsApi_Cache.set(businessId, newText);
    setText(result);
    setNewText(null);
  };

  useEffect(() => {
    retrieveContract();
  }, []);

  return (
    <>
      <div className="card">
        <h5 className="card-header bg-transparent border-bottom text-uppercase">
          Configurazione contratto
        </h5>
        <div className="card-body">
          <div className="my-1">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <Link onClick={handleDownload}>
                <i className="bx bx-download"></i> scarica
              </Link>
              <div>
                <button
                  onClick={retrieveDefaultContract}
                  className="btn btn-success mx-1"
                >
                  Usa Template Holo
                </button>
                {!!newText && (
                  <button onClick={handleSave} className="btn btn-primary mx-1">
                    Salva
                  </button>
                )}
              </div>
            </div>
          </div>
          <TinyEditor
            initialValue={text}
            onChange={(value) => setNewText(value)}
          />
        </div>
      </div>
    </>
  );
};
