import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _BaseUtil } from "../utils/_BaseUtil";
import Chart from "react-apexcharts";

export const CardDateTimeChart = ({ dates, values }) => {
  const navigate = useNavigate();

  // Sample data
  const seriesData = [
    {
      name: "Saldo",
      data: values,
    },
  ];

  // Options for the area chart
  const options = {
    chart: {
      type: "area",
      height: 260,
      // toolbar: "true",
      // zoom: {
      //   autoScaleYaxis: true,
      // },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      categories: dates,
      tickAmount: 15,
    },
    yaxis: {
      categories: dates,
      tickAmount: 5,
    },
    tooltip: {
      x: {
        format: "dd-MM-yyyy",
      },
    },
    colors: ["#556ee6"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.6,
        opacityTo: 0.5,
        stops: [42, 100, 100, 100],
      },
    },
  };

  return (
    <div>
      <Chart options={options} series={seriesData} type="area" height={260} />
    </div>
  );
};
