export const ActivationStatusCTA = ({ status }) => {
  const renderLineItem = (attribute, text, done) => {
    console.log("renderLineItem", attribute, text, done);
    return (
      <li className="event-list pb-4">
        <div className="event-timeline-dot">{renderBox(attribute, done)}</div>
        <div className="d-flex">
          <div className="flex-shrink-0 me-3">
            <h5 className="font-size-14 fw-semibold">{text}</h5>
          </div>
          <div className="flex-grow-1">{renderBadge(attribute, done)}</div>
        </div>
      </li>
    );
  };

  const renderBox = (attribute, done) => {
    console.log("renderBox", attribute, done);
    return done ? (
      <i className="bx bx-check-square text-primary font-size-18"></i>
    ) : (
      <i className="bx bx-square text-primary font-size-18"></i>
    );
  };

  const renderBadge = (attribute, done) => {
    console.log("renderBadge", attribute, done);
    return done ? (
      <span className="badge badge-soft-success font-size-12">Completato</span>
    ) : (
      <span className="badge badge-soft-warning font-size-12">Da fare</span>
    );
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-start">
            <div className="me-2">
              <h5 className="card-title mb-3">Stato del tuo Holo</h5>
            </div>
          </div>
          <div
            data-simplebar="init"
            className="mt-2"
            style={{ maxHeight: "300px" }}
          >
            <div className="simplebar-wrapper" style={{ margin: "0px" }}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div
                  className="simplebar-offset"
                  style={{ right: "0px", bottom: "0px" }}
                >
                  <div
                    className="simplebar-content-wrapper"
                    style={{
                      height: "auto",
                      overflow: "hidden",
                      paddingRight: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    <div
                      className="simplebar-content"
                      style={{ padding: "0px" }}
                    >
                      <ul className="verti-timeline list-unstyled">
                        {renderLineItem(
                          "contact",
                          "Primo contatto",
                          status.contact
                        )}
                        {renderLineItem(
                          "service",
                          "Configurazione servizi",
                          status.service
                        )}
                        {renderLineItem(
                          "plan",
                          "Configurazione piani",
                          status.plan
                        )}
                        {renderLineItem(
                          "paymentMethod",
                          "Configurazione metodo di pagamento",
                          status.openBanking || status.stripe
                        )}
                        {renderLineItem(
                          "estimate",
                          "Primo preventivo",
                          status.estimate
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: "auto", height: "293px" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  display: "none",
                  width: "302px",
                }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  display: "none",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
