import React from "react";
import { Link } from "react-router-dom";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";
import { InvoicesUtil } from "../utils/InvoicesUtil";

export const PreInvoiceDetailsCard = ({
  preInvoice,
  contact,
  order,
  estimateCondition,
}) => {
  return (
    <>
      <div className="card card-std-height-l">
        <div className="card-body binvoice-bottom">
          <div className="d-flex">
            <div className="flex-shrink-0 me-4">
              <img
                src="/assets/images/documents/preInvoice.gif"
                alt="generic preInvoice"
                className="avatar-sm"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">
                {preInvoice.subject}
              </h5>
            </div>
          </div>
          {preInvoice.notes ? (
            <div className="mt-2">
              <h5 className="font-size-15">Note:</h5>
              <p className="text-muted">{preInvoice.notes}</p>
            </div>
          ) : (
            <div className="mt-5"></div>
          )}
          {estimateCondition ? (
            <div className="mt-2">
              <h5 className="font-size-15">Modalità Pagamento:</h5>
              <p className="text-muted">{estimateCondition.name}</p>
            </div>
          ) : (
            <div className="mt-5"></div>
          )}

          <div className="row task-dates mt-2">
            {!!order && (
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Ordine`}
                  icon={`bx bx-buildings`}
                  route={`/orders/${order.rowKey}/details`}
                  routeText={order.subject || "Ordine"}
                />
              </div>
            )}
            {!!contact && (
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Cliente`}
                  icon={`bx bx-buildings`}
                  route={`/contact/${contact.rowKey}/profile`}
                  routeText={ContactsUtil.getFullName(contact)}
                />
              </div>
            )}
            <div className="col-6 col-lg-4 p-2">
              <DocumentDetailsCardItem
                title={`Data`}
                icon={`bx bx-calendar`}
                route={null}
                routeText={_BaseUtil.formatDate(preInvoice.createdOn, "it-IT")}
              />
            </div>
            <div className="col-6 col-lg-4 p-2">
              <DocumentDetailsCardItem
                title={`Importo`}
                icon={`bx bx-money`}
                route={null}
                routeText={_BaseUtil.formatCurrency(
                  preInvoice.currency,
                  preInvoice.amount,
                  "it-IT"
                )}
              />
            </div>
            <div className="col-6 col-lg-4 p-2">
              <DocumentDetailsCardItem
                title={`Tipo`}
                icon={`bx bx-check-double`}
                route={null}
                routeText={InvoicesUtil.getTypeDescription(preInvoice.type)}
                className={`badge ${InvoicesUtil.getTypeBadge(
                  preInvoice.type
                )} font-size-12`}
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-6 p-2">
              <Link
                to={`/preInvoices/${preInvoice.rowKey}/preview`}
                className="btn btn-warning waves-effect btn-label waves-light m-1 w-100"
              >
                <i className="bx bx-file label-icon "></i> Visualizza
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
