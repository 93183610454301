import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { BusinessContext } from "../../contexts/AppContexts";

import { MovementsApi_Cache } from "../../api/decorators/cache/MovementsApi_Cache";
import { OpenBankingApi_Cache } from "../../api/decorators/cache/OpenBankingApi_Cache";

import { _BaseUtil } from "../../utils/_BaseUtil";

const SelectMovementsModal = ({
  show,
  onConfirm,
  onClose,
  onlyPositive,
  onlyNegative,
}) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [movements, setMovements] = useState([]);
  const [obTransactions, setObTransactions] = useState([]);

  const [obMappedMovements, setMappedMovements] = useState([]);
  const [selectedMovements, setSelectedMovements] = useState([]);

  useEffect(() => {
    if (!movements.length) return;
    if (!obTransactions.length) return;

    const mapMovement = (obTransaction) => {
      let movement = movements.find(
        (x) =>
          x.obAccountId === obTransaction.account.uuid &&
          x.obTransactionId === obTransaction.transactionId
      ) || {
        obAccountId: obTransaction.account.uuid,
        bankName: obTransaction.account.providerName,
        iban: obTransaction.account.iban,
        obTransactionId: obTransaction.transactionId,
        madeOn: obTransaction.madeOn,
        description: obTransaction.description,
        amount: obTransaction.amount,
        currency: obTransaction.currencyCode,
        currencyType: 1, // 1. Fiat
        tags: "",
      };
      return movement;
    };

    setMappedMovements(obTransactions.map((x) => mapMovement(x)));
  }, [movements, obTransactions]);

  useEffect(() => {
    const fetchMovements = async () => {
      let records = await MovementsApi_Cache.get(businessId);

      records.push(...obMappedMovements); // Usa spread operator per concatenare correttamente

      //distinct
      records = records.reduce((acc, current) => {
        const x = acc.find(
          (item) =>
            item.obAccountId === current.obAccountId &&
            item.obTransactionId === current.obTransactionId
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      if (onlyPositive) records = records.filter((x) => x.amount >= 0);
      if (onlyNegative) records = records.filter((x) => x.amount < 0);

      const sortedItems = [...records].sort((a, b) => {
        const dateA = new Date(a.madeOn);
        const dateB = new Date(b.madeOn);
        return dateB - dateA;
      });

      setMovements(sortedItems);
    };
    const fetchObTransactions = async () => {
      let obAccounts =
        (await OpenBankingApi_Cache.getAccounts(businessId, false, true)) || [];
      let promises = obAccounts.map((a) => {
        return OpenBankingApi_Cache.getTransactionsAsync(
          businessId,
          a.uuid,
          false,
          true
        );
      });
      promises = await Promise.all(promises);
      let results = promises
        .map((p) => {
          return p;
        })
        .flat(1);
      results = results.map((r, i) => {
        r.account = obAccounts.find((a) => a.uuid === r.account.uuid);
        return r;
      });
      setObTransactions(results);
    };
    fetchMovements();
    fetchObTransactions();
  }, [businessId, show, onlyPositive, onlyNegative]);

  const handleSelectionChange = (movement, isSelected) => {
    if (isSelected) {
      // Seleziona il movimento e popola l'importo da associare
      setSelectedMovements((prev) => [
        ...prev,
        { ...movement, selected: true, associatedAmount: movement.amount },
      ]);
    } else {
      // Deseleziona il movimento
      setSelectedMovements((prev) =>
        prev.filter((m) => m.obTransactionId !== movement.obTransactionId)
      );
    }
  };

  const handleAmountChange = (obTransactionId, inputAmount) => {
    // Converte il valore dell'input in un numero float per la validazione
    let newAmount = parseFloat(inputAmount);

    // Trova il movimento corrispondente per confrontare l'importo
    const movement = movements.find(
      (m) => m.obTransactionId === obTransactionId
    );
    if (!movement) return; // Se il movimento non è trovato, esce

    // Valida l'importo in base alla positività o negatività dell'importo originale del movimento
    if (movement.amount < 0) {
      newAmount = Math.max(newAmount, movement.amount);
      newAmount = Math.min(newAmount, 0); // Impedisce valori positivi per movimenti negativi
    } else {
      newAmount = Math.min(newAmount, movement.amount);
      newAmount = Math.max(newAmount, 0); // Impedisce valori negativi per movimenti positivi
    }

    // Aggiorna lo stato con il nuovo importo validato
    setSelectedMovements((prevMovements) =>
      prevMovements.map((m) =>
        m.obTransactionId === obTransactionId
          ? { ...m, associatedAmount: newAmount.toString() }
          : m
      )
    );
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-90vw"
    >
      <Modal.Header closeButton>
        <Modal.Title>Seleziona Movimenti</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col">Seleziona</th>
                <th scope="col">Conto</th>
                <th scope="col">Causale</th>
                <th scope="col">Data</th>
                <th scope="col">Importo</th>
                <th scope="col">Importo da associare</th>
              </tr>
            </thead>
            <tbody>
              {movements.map((movement, i) => (
                <tr
                  key={movement.obTransactionId || i}
                  className="align-middle"
                >
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectedMovements.some(
                        (m) => m.obTransactionId === movement.obTransactionId
                      )}
                      onChange={(e) =>
                        handleSelectionChange(movement, e.target.checked)
                      }
                    />
                  </td>
                  <td className="text-start">{movement.bankName}</td>
                  <td className="text-start">{movement.description}</td>
                  <td className="text-center">
                    {_BaseUtil.formatDate(movement.madeOn, "it-IT")}
                  </td>
                  <td className="text-lg-end">
                    {_BaseUtil.formatCurrency(
                      movement.currency,
                      movement.amount,
                      "it-IT"
                    )}
                  </td>
                  <td className="text-lg-end">
                    <input
                      type="number"
                      className="form-control"
                      value={
                        selectedMovements.find(
                          (m) => m.obTransactionId === movement.obTransactionId
                        )?.associatedAmount || ""
                      }
                      onChange={(e) =>
                        handleAmountChange(
                          movement.obTransactionId,
                          e.target.value
                        )
                      }
                      disabled={
                        !selectedMovements.some(
                          (m) => m.obTransactionId === movement.obTransactionId
                        )
                      }
                      min={movement.amount < 0 ? movement.amount : 0}
                      max={movement.amount >= 0 ? movement.amount : 0}
                      step="0.01"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annulla
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onConfirm(selectedMovements);
          }}
        >
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectMovementsModal;
