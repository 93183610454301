import { useContext } from "react";
import { UserContext, BusinessContext } from "../../contexts/AppContexts";

import { CardMainSummaryCTA } from "../CardMainSummaryCTA";
import { CardSimpleCTA, button as CardSimpleCTAButton } from "../CardSimpleCTA";
import { FattureInCloudCTA } from "../FattureInCloudCTA";
import { OpenBankingCTA } from "../OpenBankingCTA";
import { ActivationStatusCTA } from "../ActivationStatusCTA";

import { OpenBankingApi_Cache } from "../../api/decorators/cache/OpenBankingApi_Cache";
import { StripeApi_Cache } from "../../api/decorators/cache/StripeApi_Cache";

const DashboardActivationIncomplete = ({ activationStatus }) => {
  const { user } = useContext(UserContext);
  const { business } = useContext(BusinessContext);

  const connectStripe = async () => {
    console.log("connectStripe");
    let connectUrl = await StripeApi_Cache.connectAsync(business.rowKey);
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  const connectOpenBanking = async () => {
    console.log("connectOpenBanking");

    let returnUrl = `${window.location.origin}?reset=true`;

    let connectUrl = await OpenBankingApi_Cache.connectAsync(
      business.rowKey,
      returnUrl
    );
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  return (
    <>
      <div className={`row`}>
        <div className="col-md-12">
          <CardMainSummaryCTA
            avatarPath={
              user.avatarPath ||
              "/assets/images/avatars/wired-lineal-527-boy-young-men.gif"
            }
            user_full_name={`${user.firstName} ${user.lastName}`}
          ></CardMainSummaryCTA>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <CardSimpleCTA
            number={1}
            headerLink={`/contacts`}
            title={`Contatti`}
            // subtitle={`Sono i soggetti con cui lavori`}
            subtitle={`I tuoi clienti analizzati con Holo Index e con una panoramica completa di preventivi, ordini, fatture e incassi!`}
            description={`Ti basta sapere la partita IVA`}
            buttons={[
              CardSimpleCTAButton(
                `Crea contatto`,
                `/contact`,
                `bx-plus-medical`
              ),
            ]}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <CardSimpleCTA
            number={2}
            headerLink={`/services`}
            title={`Servizi`}
            // subtitle={`Sono le prestazioni che offri`}
            subtitle={`Le prestazioni che offri`}
            description={`Ti consigliamo di usare nomi brevi e chiari`}
            descriptionIcon={`bxs-bulb`}
            buttons={[
              CardSimpleCTAButton(
                `Crea servizio`,
                `/service`,
                `bx-plus-medical`
              ),
            ]}
          />
        </div>
        <div className="col-xs-12 col-sm-6">
          <CardSimpleCTA
            number={3}
            headerLink={`/plans`}
            title={`Piani`}
            // subtitle={`Sono abbonamenti o sottoscrizioni`}
            subtitle={`Abbonamenti o sottoscrizioni che incasserai in maniera ricorrente e automatica`}
            description={`Assicurati ricavi certi per il futuro`}
            descriptionIcon={`bxs-calendar-check`}
            buttons={[
              CardSimpleCTAButton(`Crea piano`, `/plan`, `bx-plus-medical`),
            ]}
          />
        </div>
        {(!business.stripeConnectId || !business.isOpenBankingConnected) && (
          <div className="col-xs-12 col-sm-6">
            <CardSimpleCTA
              number={4}
              headerLink={`#`}
              title={`Modalità di pagamento`}
              subtitle={`Scegli come vuoi esser pagato`}
              // subtitle={`Stripe: il sistema più semplice di incasso. OpenBanking: il nuovo sistema per incassare con bonifico tramite un semplice link di pagamento`}
              description={`Ti aiutiamo noi`}
              descriptionIcon={`bxs-buoy`}
              buttons={[
                CardSimpleCTAButton(`Attiva Stripe`, connectStripe, `bx-link`),
                CardSimpleCTAButton(
                  `Collega OpenBanking`,
                  connectOpenBanking,
                  `bx-link`
                ),
              ]}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <ActivationStatusCTA status={activationStatus} />
        </div>
        {!business.fattureInCloudExternalKey && (
          <div className="col-12 col-md-4">
            <FattureInCloudCTA />
          </div>
        )}
        {!business.isOpenBankingConnected && (
          <div className="col-12 col-md">
            <OpenBankingCTA />
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardActivationIncomplete;
