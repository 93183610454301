import { useContext, useState } from "react";

import { BusinessContext } from "../contexts/AppContexts";

import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { MovementsTransactionsApi_Cache } from "../api/decorators/cache/MovementsTransactionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";

import { CardTable, CardTableUtil } from "../components/CardTable";
import SelectMovementsModal from "../components/Movements/SelectMovementsModal";

export const CardTableMovements = ({
  title,
  transaction,
  movements,
  movementsTransaction,
  refresh,
}) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const mapRecords = () => {
    return movementsTransaction
      .map((x) => {
        let movement = movements.find((m) => m.rowKey === x.movementId);
        if (!movement) return null;
        return {
          bankName: CardTableUtil.getCellText(movement.bankName),
          date: CardTableUtil.getCellText(
            _BaseUtil.formatDate(movement.madeOn, "it-IT")
          ),
          associationDate: CardTableUtil.getCellText(
            _BaseUtil.formatDate(x.createdOn, "it-IT")
          ),
          amount: CardTableUtil.getCellText(
            _BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")
          ),
          actions: CardTableUtil.getCellActions(x.rowKey, [
            CardTableUtil.getAction(x.rowKey, "Rimuovi", () =>
              handleDisassociate(x.rowKey)
            ),
          ]),
        };
      })
      .filter((x) => x);
  };

  const columns = [
    {
      key: "bankName",
      value: "Banca",
    },
    {
      key: "date",
      value: "Data",
    },
    {
      key: "associationDate",
      value: "Data Associazione",
    },
    {
      key: "amount",
      value: "Importo Associato",
    },
    {
      key: "actions",
      value: "Azioni",
    },
  ];
  const records = mapRecords();

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = (nocache) => {
    setShowModal(false);
    refresh(nocache);
  };

  const handleDisassociate = async (recordId) => {
    await MovementsTransactionsApi_Cache.delete(businessId, recordId);
  };

  const handleAssociate = async (movements) => {
    if (!movements?.length) return;

    let movementsToCreate = movements.filter((x) => !x.rowKey);

    let promises = movementsToCreate.map((m) => {
      return MovementsApi_Cache.create(businessId, m);
    });

    let results = await Promise.all(promises);

    movements.forEach((m) => {
      const result = results.find(
        (r) =>
          r.obTransactionId === m.obTransactionId &&
          r.obAccountId === m.obAccountId
      );

      if (result) {
        m.rowKey = result.rowKey;
      }
    });

    movements = movements.filter((x) => x);

    let movementsTransactions = movements.map((x) => {
      return {
        transactionId: transaction.rowKey,
        movementId: x.rowKey,
        amount: x.associatedAmount,
        currency: x.currency,
        currencyType: x.currencyType,
      };
    });

    for (let i = 0; i < movementsTransactions.length; i++) {
      await MovementsTransactionsApi_Cache.create(
        businessId,
        movementsTransactions[i]
      );
    }

    handleModalClose(true);
  };

  return (
    <>
      <CardTable
        key={`ct-movements`}
        title={title}
        columnNames={columns}
        values={records}
        buttons={[
          {
            label: `Aggiungi`,
            onClick: () => setShowModal(true),
          },
        ]}
      />
      <SelectMovementsModal
        show={showModal}
        onlyPositive={transaction.amountGross >= 0}
        onlyNegative={transaction.amountGross < 0}
        onConfirm={handleAssociate}
        onClose={handleModalClose}
      />
    </>
  );
};
