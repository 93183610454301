import { BusinessesRolesApi } from "../../BusinessesRolesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "businessesroles";

export const BusinessesRolesApi_Cache = {
  apiName: "BusinessesRolesApi_Cache",

  get: async function (nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache("", KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await BusinessesRolesApi.get(nocache, noloader);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },
};
