import React, { useContext, useEffect, useState } from "react";

const RegisterSummary = ({ user, business }) => {
  useEffect(() => {}, []);

  return (
    <div className="card border border-success">
      <div className="card-header bg-transparent border-success">
        {business.vatNumber ? (
          <h5 className="my-0 text-success">
            <i className="mdi mdi-check-all me-3"></i>
            La partita IVA è stata trovata
          </h5>
        ) : (
          <h5 className="my-0 text-danger">
            <i className="mdi mdi-check-all me-3"></i>
            Partita IVA non trovata
          </h5>
        )}
      </div>
      <div className="card-body">
        <h5 className="card-title">Controlla i dati in archivio</h5>
        <p className="card-text">
          {user.firstName} {user.lastName}
          <br />
          {business.address} <br />
          Partita IVA {business.vatNumber} - PEC {business.certifiedEmail}
          <br />
          <br />
          Se sono corretti inserisci il codice di conferma che hai ricevuto
          sulla tua PEC.
        </p>
      </div>
    </div>
  );
};

export default RegisterSummary;
