import React, { useState } from "react";
import { Link } from "react-router-dom";
import { _BaseUtil } from "../utils/_BaseUtil";
import HoloTags from "../components/Tags/HoloTags";

import { ContactsUtil } from "../utils/ContactsUtil";

export const MovementRowHandler = {
  mapRecord: (
    movement,
    transactions,
    movementsAttachments,
    movementsTransactions,
    contacts
  ) => {
    movement.attachments = MovementRowHandler.mapAttachments(
      movement.rowKey,
      movementsAttachments
    );
    movement.transactions = MovementRowHandler.mapTransactions(
      movement.rowKey,
      transactions,
      movementsTransactions,
      contacts
    );
    return movement;
  },

  mapAttachments: (movementId, movementsAttachments) => {
    if (!movementId) return [];
    if (!movementsAttachments?.length) return [];

    let records = movementsAttachments.filter(
      (x) => x.movementId === movementId
    );

    return (records || []).map((x) => {
      return {
        rowKey: x.rowKey,
        filename: x.filename,
      };
    });
  },

  mapTransactions: (
    movementId,
    transactions,
    movementsTransactions,
    contacts
  ) => {
    if (!movementId) return [];
    if (!transactions?.length) return [];
    if (!movementsTransactions?.length) return [];

    let movementTransactions = movementsTransactions.filter(
      (x) => x.movementId === movementId
    );
    let transactionIds = movementTransactions.map((x) => x.transactionId);
    let filteredTransactions = transactions.filter((x) =>
      transactionIds.includes(x.rowKey)
    );

    return (filteredTransactions || []).map((x) => {
      return {
        rowKey: x.rowKey,
        name: x.name,
        contact: MovementRowHandler.mapContact(contacts, x.contactId),
      };
    });
  },

  mapContact: (contacts, id) => {
    if (!contacts?.length) return null;
    let contact = contacts.find((x) => x.rowKey === id);

    return (
      !!contact && {
        rowKey: contact.rowKey,
        name: ContactsUtil.getFullName(contact),
        type: ContactsUtil.getTypeDescription(contact?.type),
      }
    );
  },
};

const MovementRow = ({
  movement,
  suggestions,
  handlePropertyChange,
  handleCreateInvoice,
  handleFileDownload,
  handleFileUpload,
  handleFileDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAttachments = () => {
    setIsExpanded(!isExpanded);
  };

  const onFileDownload = (movementId, fileRowKey, filename) => () => {
    handleFileDownload(movementId, fileRowKey, filename);
  };

  const onFileUpload = (movementId, e) => () => {
    handleFileUpload(movementId, e);
  };

  const onFileDelete = (movementId, fileRowKey) => () => {
    handleFileDelete(movementId, fileRowKey);
  };

  return (
    <>
      <tr>
        <td>
          <Link onClick={toggleAttachments}>
            {isExpanded ? (
              <i className="bx bx-chevron-down fs-4"></i>
            ) : (
              <i className="bx bx-chevron-right fs-4"></i>
            )}
          </Link>
        </td>
        <td>
          <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
            <span className="text-primary font-size-22">
              <img
                src={
                  movement.amount >= 0
                    ? "/assets/images/movements/down.gif"
                    : "/assets/images/movements/up.gif"
                }
                alt={movement.name}
                className="mw-100"
              />
            </span>
          </div>
        </td>
        <td>
          <h5
            className={`font-size-16 mb-0 ${
              movement.amount <= 0 ? "text-danger" : "text-success"
            }`}
          >
            {_BaseUtil.formatCurrency(
              movement.currency,
              movement.amount,
              "it-IT"
            )}
          </h5>
          <p className="text-muted mb-0">
            {_BaseUtil.formatDate(movement.madeOn, "it-IT")}
          </p>
        </td>
        <td>
          <div className="text-body text-wrap" style={{ maxWidth: "200px" }}>
            {movement.description}
          </div>
        </td>
        <td>
          <h5 className="font-size-16 mb-0">
            <i className="bx bx-book-bookmark"></i> {movement.bankName}
          </h5>
          <p className="text-muted mb-0">{movement.iban}</p>
        </td>
        <td>
          <span className="badge badge-soft-info font-size-12 text-uppercase mt-2">
            {movement.category}
          </span>
        </td>
        <td className="text-center">
          <div>
            {movement.amount <= 0 && (
              <div className="form-group">
                <select
                  className="form-select"
                  id="expenseType"
                  value={movement.expenseType}
                  onChange={(e) =>
                    handlePropertyChange(
                      "expenseType",
                      e.target.value,
                      movement.obTransactionId
                    )
                  }
                >
                  <option value="" checked disabled>
                    Seleziona
                  </option>
                  <option value="0">Personale</option>
                  <option value="2">Tasse sul reddito</option>
                  <option value="3">Contributi previdenziali</option>
                  <option value="1">Altre spese professionali</option>
                  <optgroup label="Consumi">
                    <option value="5">Carburanti, Lubrificanti</option>
                    <option value="4">Energia Elettrica</option>
                    <option value="6">Servizi Telefonici</option>
                  </optgroup>
                </select>
              </div>
            )}
          </div>
        </td>
        <td>
          <HoloTags
            initialTags={_BaseUtil.tagsToList(movement.tags)}
            suggestions={suggestions}
            readOnly={false}
            onChange={(newTags) =>
              handlePropertyChange(
                "tags",
                _BaseUtil.listToTags(newTags),
                movement.obTransactionId
              )
            }
          />
        </td>
        <td>
          {!!movement.transactions?.length ? (
            <>
              <span className="badge badge-soft-success font-size-12">
                Associato
              </span>
              {movement.transactions.map((x) => {
                return (
                  <>
                    <p className="text-muted">
                      <i className="bx bx-buildings me-1 text-muted"></i>{" "}
                      <Link to={`/payment/${x.rowKey}/details`}> {x.name}</Link>
                      {" - "}
                      <Link to={`/contact/${x.contact?.rowKey}/profile`}>
                        {" "}
                        {x.contact?.name}
                      </Link>
                    </p>
                  </>
                );
              })}
            </>
          ) : (
            <span className="badge badge-soft-danger font-size-12">
              Non associato
            </span>
          )}
        </td>
        <td>
          {!movement.transactions?.length && (
            <div className="dropdown">
              <Link
                className="dropdown-toggle card-drop"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target=".movements-detailModal"
                  onClick={() => handleCreateInvoice(movement)}
                >
                  {movement.amount >= 0 ? "Crea Fattura" : "Crea Auto Fattura"}
                </Link>
              </div>
            </div>
          )}
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="100%">
            {movement.attachments.map((x) => {
              return (
                <div className="my-1">
                  {x.filename}{" "}
                  <Link
                    onClick={onFileDownload(
                      movement.rowKey,
                      x.rowKey,
                      x.filename
                    )}
                  >
                    <i className="bx bx-download"></i>
                  </Link>
                  <Link onClick={onFileDelete(movement.rowKey, x.rowKey)}>
                    <i className="bx bx-x"></i>
                  </Link>
                </div>
              );
            })}
            <div className="my-1">
              Carica Nuovo Allegato:
              <input
                type="file"
                accept=".jpg,.png,.pdf"
                onChange={(e) => onFileUpload(movement.rowKey, e)}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default MovementRow;
