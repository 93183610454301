import { useContext, useEffect, useState } from "react";

import { BusinessContext } from "../../contexts/AppContexts";

import { ConfigurationsApi_Cache } from "../../api/decorators/cache/ConfigurationsApi_Cache";

import { _BaseUtil } from "../../utils/_BaseUtil";

const TotalAmountGross = ({ services }) => {
  const { business } = useContext(BusinessContext);

  const [vatTypes, setVatTypes] = useState([]);

  const getVatTypes = async () => {
    const vatTypes = await ConfigurationsApi_Cache.getVatTypes(business.rowKey);

    const sortedItems = [...vatTypes].sort((a, b) => {
      const intA = +a.id;
      const intB = +b.id;
      return intA - intB;
    });

    setVatTypes(sortedItems);
  };

  useEffect(() => {
    getVatTypes();
  }, []);

  const getVatPercentage = (vatTypeId) => {
    return vatTypes.find((x) => x.id === vatTypeId)?.percentage || 0;
  };

  return (
    !!services &&
    !!services.find((x) => getVatPercentage(x.vatTypeId) > 0) && (
      <>
        <span className="font-size-11 text-muted fst-italic">
          Totale con IVA: €{" "}
          {_BaseUtil.formatDecimals(
            services.reduce(
              (px, x) =>
                px +
                (x.amount * x.quantity +
                  ((x.amount * x.quantity) / 100) *
                    getVatPercentage(x.vatTypeId)) *
                  (x.billingIntervalsCount || 1),
              0
            )
          )}
        </span>
      </>
    )
  );
};

export default TotalAmountGross;
