import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BusinessContext } from "../contexts/AppContexts";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { ConditionsApi_Cache } from "../api/decorators/cache/ConditionsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";
import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Button,
  Label,
  Container,
  FormFeedback,
} from "reactstrap";

import { _BaseUtil } from "../utils/_BaseUtil";
import { OrdersUtil } from "../utils/OrdersUtil";

import { ServicesCard } from "../components/ServicesCard";

import DatePicker from "react-datepicker";

import { it } from "date-fns/locale"; // Import the Italian locale
import { ConditionsCard } from "../components/ConditionsCard";
import { LinksCard } from "../components/LinksCard";
import clsx from "clsx";
import { ContactsUtil } from "../utils/ContactsUtil";

const paymentPageBaseUrl = process.env.REACT_APP_PAY_BASE_URL;

export default function Order() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();
  const navigate = useNavigate();

  const [orderModel, setOrderModel] = useState(null);

  const [allContacts, setAllContacts] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [allConditions, setAllConditions] = useState([]);

  const [currentServices, setCurrentServices] = useState([]);
  const [currentPlans, setCurrentPlans] = useState([]);
  const [currentConditions, setCurrentConditions] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact: null,
      subject: null,
      documentDate: null,
      expirationDate: null,
      closingDays: null,
      allowPublicPageEdit: false,
      notes: "",
    },
    onSubmit: () => submitAsync(),
    validationSchema: Yup.object({
      contact: Yup.string().required("Seleziona un contatto"),
      subject: Yup.string().required("Oggetto obbligatorio"),
    }),
  });

  useEffect(() => {
    onLoadAsync();
  }, []);

  useEffect(() => {
    onLoadAsync();
  }, [id]);

  useEffect(() => {
    if (orderModel && orderModel.order) {
      const { order, rows, conditions, links } = orderModel;

      const services = rows.filter((x) => x.serviceType === 1) || [];
      const subscriptions = rows.filter((x) => x.serviceType === 2) || [];

      form.setValues({
        contact: order.customerId,
        subject: order.subject,
        documentDate: new Date(order.date),
        expirationDate: new Date(order.expirationDate),
        closingDays: order.closingDays,
        allowPublicPageEdit: order.allowPublicPageEdit,
      });

      setCurrentServices(services);
      setCurrentPlans(subscriptions);
      setCurrentConditions(conditions);
      setCurrentLinks(links);
    }
  }, [orderModel]);

  const onLoadAsync = async () => {
    const promises = [
      ContactsApi_Cache.get(businessId),
      OrdersApi_Cache.get(businessId),
      ServicesApi_Cache.get(businessId),
      ConditionsApi_Cache.get(businessId),
    ];

    const [contacts, orders, services, conditions] = await Promise.all(
      promises
    );

    setAllContacts(contacts);
    setAllServices(services.filter((s) => s.type === 1));
    setAllPlans(services.filter((s) => s.type === 2));
    setAllConditions(conditions || []);

    if (id) {
      const orderModel = orders.find((x) => x?.order?.rowKey === id);

      if (orderModel) {
        setOrderModel(orderModel);
      } else {
        navigate("/not-found");

        return;
      }
    } else {
      setOrderModel(await OrdersUtil.getModelTemplate(businessId));
    }
  };

  const isCreateMode = !orderModel?.order?.rowKey;
  const isDraft = isCreateMode || (orderModel?.order?.status ?? -1) === 1;
  const currentContact = allContacts.find(
    (x) => x.rowKey === form.values.contact
  );

  async function getPaymentLinkAsync() {
    if (!isCreateMode) {
      const result = await OrdersApi_Cache.createPublicLinkAsync(
        businessId,
        orderModel.order.rowKey
      );

      if (result?.rowKey) {
        const businessId = business.rowKey;
        const orderId = orderModel.order.rowKey;
        const linkId = result.rowKey;

        const paymentUrl = `${paymentPageBaseUrl}/${businessId}/${orderId}/${linkId}`;

        if (navigator.share) {
          const shareOptions = {
            title: "Holo - Pagamento " + form.values.subject,
            text: `Questo è il link per procedere al pagamento del servizio: ${form.values.subject}. <br /><br />Clicca qui per vedere i dettagli del ordine: ${paymentUrl}`,
            url: paymentUrl,
          };

          await navigator.share(shareOptions);
        } else {
          await navigator.clipboard.writeText(paymentUrl);
          toast.info("Link di pagamento copiato negli appunti");
        }
      }
    }
  }

  const submitAsync = async () => {
    try {
      const errors = await form.validateForm();

      if (!Object.keys(errors).length && currentContact) {
        console.log("form is valid");

        const { contact, subject, closingDays, allowPublicPageEdit, notes } =
          form.values;

        const documentDate =
          form.values.documentDate != null
            ? new Date(form.values.documentDate)
            : null;

        const expirationDate =
          form.values.expirationDate != null
            ? new Date(form.values.expirationDate)
            : null;

        const payload = {
          order: {
            code: orderModel.order.code,
            revisionNumber: orderModel.order.revisionNumber,
            revisedOrderId: orderModel.order.revisedOrderId,
            contactId: contact,
            allowPublicPageEdit: allowPublicPageEdit,
            subject: subject,
            date: !documentDate ? null : new Date(documentDate).toJSON(),
            expirationDate: !expirationDate
              ? null
              : new Date(expirationDate).toJSON(),
            closingDays: closingDays,
            tags: "",
            currencyType: orderModel.order.currencyType,
            currency: orderModel.order.currency,
            status: orderModel.order.status,
            notes: notes,
            orderConditionId: null,
            contactType: currentContact.type,
            firstName: currentContact.firstName,
            lastName: currentContact.lastName,
            governmentId: currentContact.governmentId,
            businessName: currentContact.businessName,
            countryCode: currentContact.countryCode,
            vatNumber: currentContact.vatNumber,
            address: currentContact.address,
            streetNumber: currentContact.streetNumber,
            zipCode: currentContact.zipCode,
            city: currentContact.city,
            province: currentContact.province,
            businessTypeCode: currentContact.businessTypeCode,
            eInvoiceProviderCode: currentContact.eInvoiceProviderCode,
            certifiedEmail: currentContact.certifiedEmail,
          },
          rows: [...currentServices, ...currentPlans].map((x) => ({
            ...x,
            orderId: orderModel.order.rowKey,
          })),
          conditions: (currentConditions || []).map((x) => ({
            ...x,
            orderId: orderModel.order.rowKey,
            discountPercentage: x.discountPercentage ?? 0,
            isDefault: x.isDefault ?? false,
          })),
          links: currentLinks,
        };

        const result = isCreateMode
          ? await OrdersApi_Cache.create(businessId, payload)
          : await OrdersApi_Cache.update(
              businessId,
              orderModel.order.rowKey,
              payload
            );

        if (result?.order?.rowKey) {
          navigate(`/order/${result.order.rowKey}`);

          return;
        }
      }
    } catch {
      toast.error("C'è stato un errore durante il salvataggio");
    }
  };

  const onDeleteClick = async function () {
    let orderId = orderModel?.order?.rowKey;
    let status = orderModel?.order?.status;

    if (!orderId) {
      toast.error("Ordine non valido");
      return;
    }

    if (status !== 1) {
      toast.error("Non è possibile eliminare un ordine in attesa di pagamento");
      return;
    }

    await OrdersApi_Cache.delete(businessId, orderId);

    navigate(`/orders`);
  };

  const onAddService = function () {
    setCurrentServices([
      ...(currentServices ?? []),
      {
        serviceId: "",
        serviceName: "",
        quantity: 0,
        // taxPercentage: 0,
        amount: 0,
        serviceType: 1,
      },
    ]);
  };

  const onAddPlan = function () {
    setCurrentPlans([
      ...(currentPlans ?? []),
      {
        serviceId: "",
        serviceName: "",
        quantity: 0,
        // taxPercentage: 0,
        amount: 0,
        serviceType: 2,
      },
    ]);
  };

  const onAddCondition = function () {
    setCurrentConditions([
      ...(currentConditions ?? []),
      {
        status: 0,
        percentageWorkStart: 0,
        percentageWorkEnd: 0,
        percentageWorkInstallments: 0,
        installmentsCount: 0,
        installmentsFrequency: 0,
        paymentGateway: 0,
        conditionId: "",
        currency: "EUR",
        currencyType: 1,
        isDefault: null,
        discountPercentage: null,
        percentageSum: 0,
      },
    ]);
  };

  const onAddLink = function () {
    setCurrentLinks([
      ...(currentLinks ?? []),
      {
        description: null,
        url: null,
      },
    ]);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                {isCreateMode ? "Crea Ordine" : "Modifica Ordine"}
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/orders">Ordini</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {isCreateMode ? "Nuovo" : "Modifica"}
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form>
              <div className="card">
                <div className="card-body">
                  <Container fluid>
                    <fieldset disabled={!isDraft}>
                      <Row className="w-100">
                        {/* order logo */}
                        <Col md={3} xs={12}>
                          <img
                            src="/assets/images/estimate.png"
                            alt="Holo Order Logo"
                            className="img-fluid"
                          ></img>
                          <div className="position-absolute top-0 start-10 translate-middle fs-2 m-2">
                            <i className="bx bxs-add-to-queue fs-18 text-primary"></i>
                          </div>
                        </Col>

                        {/* contact */}
                        <Col md={4} xs={12}>
                          {/* contact */}
                          <FormGroup className="px-0">
                            <Label>Scegli un contatto</Label>
                            <InputGroup>
                              <Input
                                invalid={
                                  !!form.submitCount && !!form.errors.contact
                                }
                                type="select"
                                placeholder="Seleziona un contatto..."
                                name="contact"
                                value={form.values.contact}
                                onChange={form.handleChange}
                              >
                                {[
                                  <option value="" disabled selected>
                                    Scegli un contatto
                                  </option>,
                                  allContacts.map((x) => {
                                    return (
                                      <option key={x.rowKey} value={x.rowKey}>
                                        {!x.vatNumber
                                          ? `${x.lastName} ${x.firstName}`
                                          : x.businessName}
                                      </option>
                                    );
                                  }),
                                ]}
                              </Input>
                              {isCreateMode && (
                                <Button
                                  color="primary"
                                  onClick={() => navigate("/contact")}
                                >
                                  Nuovo
                                </Button>
                              )}
                            </InputGroup>
                          </FormGroup>
                          <FormFeedback
                            className={clsx("d-block", {
                              "d-none":
                                !form.submitCount || !form.errors.contact,
                            })}
                          >
                            Campo obbligatorio
                          </FormFeedback>

                          {/* customer address */}
                          <ul
                            className={clsx("list-unstyled", "vstack", "mt-2", {
                              "d-none": !currentContact?.address,
                            })}
                          >
                            <li>
                              <div className="d-flex">
                                <i className="bx bx-map font-size-18 text-primary"></i>
                                <span className="mx-1 text-muted fw-semibold">
                                  Sede legale:
                                </span>
                                <span className="text-muted mx-1">
                                  {ContactsUtil.getFullAddress(currentContact)}
                                </span>
                                <span>
                                  <a
                                    target="_blank"
                                    href={`https://www.google.it/maps/search/${ContactsUtil.getFullAddress(
                                      currentContact
                                    )}`}
                                    rel="noreferrer"
                                  >
                                    <i className="bx bx-link-external"></i>
                                  </a>
                                </span>
                              </div>
                            </li>
                            <li>
                              <div
                                className={clsx("d-flex", "mt-2", {
                                  "d-none": !!currentContact?.firstName,
                                })}
                              >
                                <i className="bx bx-user-circle font-size-18 text-primary"></i>
                                <span className="mx-1 text-muted fw-semibold">
                                  Referente:
                                </span>
                                <span className="text-muted mx-1">
                                  {`${currentContact?.firstName} ${currentContact?.lastName}`}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </Col>

                        {/* subject and dates */}
                        <Col md={5}>
                          <Container fluid>
                            <Row>
                              <Col xs={12}>
                                <FormGroup>
                                  <Label>Oggetto</Label>
                                  <Input
                                    type="text"
                                    name="subject"
                                    invalid={
                                      !!form.submitCount && form.errors.subject
                                    }
                                    placeholder="Inserisci oggetto"
                                    value={form.values.subject}
                                    onChange={form.handleChange}
                                  ></Input>
                                  <FormFeedback>
                                    Campo obbligatorio
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} lg={6}>
                                {/* date */}
                                <FormGroup>
                                  <Label className="text-truncate d-inline-block">
                                    Data Documento
                                  </Label>
                                  <DatePicker
                                    locale={it}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    placeholder="Data Documento"
                                    selected={form.values.documentDate}
                                    onChange={(value) =>
                                      form.setFieldValue("documentDate", value)
                                    }
                                  ></DatePicker>
                                </FormGroup>
                              </Col>

                              {/* closing days */}
                              <Col xs={12} lg={6}>
                                <Label className="text-truncate d-inline-block">
                                  Giorni consegna
                                </Label>
                                <FormGroup>
                                  <Input
                                    type="text"
                                    placeholder="N° giorni..."
                                    name="closingDays"
                                    value={form.values.closingDays}
                                    onChange={form.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    </fieldset>
                    <Row className="my-2 pt-4 border-top">
                      {/* document number */}
                      <Col xs={12} sm={4} md={3}>
                        <p className="fw-medium mb-0">Documento N&ordm;</p>
                        <h4>
                          <span>{orderModel?.order?.code}</span>
                          <span className="font-size-14 text-muted ms-1">
                            Revisione {orderModel?.order?.revisionNumber}
                          </span>
                        </h4>
                      </Col>

                      {/* totals */}
                      <Col xs={12} sm={8} md={4}>
                        <div className="d-flex flex-row">
                          <div className="px-2">
                            <p className="fw-medium mb-0">Totale Servizi</p>
                            <h4>
                              €{" "}
                              {_BaseUtil.formatDecimals(
                                currentServices.reduce(
                                  (px, x) => px + x.amount,
                                  0
                                )
                              )}
                            </h4>
                          </div>
                          <div className="px-2">
                            <p className="fw-medium mb-0">Totale Piani</p>
                            <h4>
                              €{" "}
                              {_BaseUtil.formatDecimals(
                                currentPlans.reduce((px, x) => px + x.amount, 0)
                              )}
                            </h4>
                          </div>
                        </div>
                      </Col>

                      {/* preview */}
                      <Col xs={12} md={5}>
                        <Container fluid>
                          <Row className="mt-2">
                            <Col xs={6}>
                              <Button
                                type="button"
                                onClick={onDeleteClick}
                                className={clsx(
                                  "btn",
                                  "btn-danger",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  { "d-none": isCreateMode || !isDraft }
                                )}
                              >
                                <i className="bx bx-trash label-icon"></i>
                                Elimina
                              </Button>
                            </Col>
                            <Col xs={6}>
                              <Button
                                type="submit"
                                onClick={form.handleSubmit}
                                className={clsx(
                                  "btn",
                                  "btn-success",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  { "d-none": !isDraft }
                                )}
                              >
                                <i className="bx bx-save label-icon"></i>Salva
                              </Button>
                            </Col>
                            <Col xs={6}>
                              <Link
                                to={`/order/${id}/preview`}
                                className={clsx(
                                  "btn",
                                  "btn-light",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  { "d-none": isCreateMode }
                                )}
                              >
                                <i className="bx bx-file label-icon"></i>
                                Anteprima
                              </Link>
                            </Col>
                            <Col xs={6}>
                              <Link
                                onClick={getPaymentLinkAsync}
                                className={clsx(
                                  "btn",
                                  "btn-primary",
                                  "waves-effect",
                                  "btn-label",
                                  "waves-light",
                                  "m-1",
                                  "w-100",
                                  {
                                    "d-none":
                                      isCreateMode ||
                                      ![1, 2].includes(
                                        orderModel?.order?.status
                                      ),
                                  }
                                )}
                              >
                                <i className="bx bx-send label-icon"></i>
                                Condividi link di pagamento
                              </Link>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Form>
          </Col>
          <Col md={6}>
            <ServicesCard
              cardLabel="Servizi"
              btnLabel="Aggiungi un servizio"
              disabled={!isDraft}
              values={currentServices}
              onChangeValues={(values) => {
                setCurrentServices(values);
                console.log("cambio");
              }}
              onAddService={onAddService}
              allServices={allServices}
            ></ServicesCard>
          </Col>
          <Col md={6}>
            <ServicesCard
              cardLabel="Piani"
              btnLabel="Aggiungi un piano"
              values={currentPlans}
              disabled={!isDraft}
              onChangeValues={setCurrentPlans}
              onAddService={onAddPlan}
              allServices={allPlans}
            ></ServicesCard>
          </Col>
          <Col md={6}>
            <ConditionsCard
              values={currentConditions}
              disabled={!isDraft}
              onChangeValues={setCurrentConditions}
              onAddCondition={onAddCondition}
              allConditions={allConditions}
            ></ConditionsCard>
          </Col>
          <Col md={6}>
            <LinksCard
              values={currentLinks}
              disabled={!isDraft}
              onChangeValues={setCurrentLinks}
              onAddLink={onAddLink}
            ></LinksCard>
          </Col>
          <Col md={12}>
            <div className="card">
              <div className="card-body">
                <FormGroup check className="px-0">
                  <Label>Note</Label>
                  <Input
                    name="notes"
                    type="textarea"
                    disabled={!isDraft}
                    rows="3"
                    placeholder="Se necessario, inserisci una nota"
                    value={form.values.notes}
                    onChange={form.handleChange}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
