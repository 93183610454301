import { ContractsApi } from "../../ContractsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "contracts";

export const ContractsApi_Cache = {
  apiName: "ContractsApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await ContractsApi.get(businessId, nocache, noloader);
      $data.set(cacheData);
      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData;
  },

  getDefault: async function (businessId, nocache) {
    const fnName = "getDefault";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const value = await ContractsApi.getDefault(businessId, nocache);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, value);

    return value;
  },

  getFor: async function (businessId, entityName, entityId) {
    const fnName = "getFor";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const value = await ContractsApi.getFor(businessId, entityName, entityId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, value);

    return value;
  },

  set: async function (businessId, value) {
    const fnName = "set";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      value
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newValue = await ContractsApi.set(businessId, value);

    if (newValue) {
      $data.set(newValue); //adding object to the existing array
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newValue);

    return newValue;
  },
};
