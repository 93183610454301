import { Link } from "react-router-dom";

import punycode from "punycode/";

export const ContactsDetailsCellHandler = {
  get: function (certifiedEmail, email, phone) {
    return {
      certifiedEmail,
      email,
      phone,
    };
  },
};

const ContactsDetailsCell = ({ cell }) => {
  return (
    <>
      <h5 className="font-size-14 text-truncate">
        <a href={`mailto:${cell.certifiedEmail}`}>{cell.certifiedEmail}</a>
      </h5>
      <span className="text-truncate">
        {!!cell.email && (
          <div>
            <Link
              href={`mailto:${punycode.toUnicode(cell.email)}`}
              style={{ color: "black" }}
            >
              {punycode.toUnicode(cell.email)}
            </Link>
          </div>
        )}
        {!!cell.phone && (
          <div>
            <Link href={`tel:${cell.phone}`} style={{ color: "black" }}>
              {cell.phone}
            </Link>
          </div>
        )}
      </span>
    </>
  );
};

export default ContactsDetailsCell;
