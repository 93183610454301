import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { OrdersUtil } from "../utils/OrdersUtil";
import { Col, Row } from "reactstrap";

export default function Orders() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let orders = await OrdersApi_Cache.get(businessId);

    orders = orders.filter((x) => x.order);
    orders = orders.filter((x) => x.order.customerId);
    orders = orders.filter((s) => _BaseUtil.filterAttributes(s, filter));

    const result = orders.map((x) => mapTable(x));

    const sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.createdOn);
      const dateB = new Date(b.createdOn);
      return dateB - dateA;
    });

    setTableRecords(sortedItems);
  };

  const mapTable = (orderModel) => {
    const deliveryDate = orderModel.order.deliveryDate;
    const orderStatus = orderModel.order.status;

    const result = {
      createdOn: orderModel.order.createdOn,
      orderId: orderModel.order.rowKey,
      // imagePath: contact.imagePath,
      code: orderModel.order.code,
      subject: orderModel.order.subject,
      customerId: orderModel.order.customerId,
      customerName: ContactsUtil.getFullCustomerName(
        orderModel.order.customerType,
        orderModel.order.customerFirstName,
        orderModel.order.customerLastName,
        orderModel.order.customerBusinessName
      ),
      customerType: ContactsUtil.getTypeDescription(
        orderModel.order.customerType
      ),
      status: orderStatus,
      statusText: OrdersUtil.getStatusDescription(orderStatus),
      statusBadge: OrdersUtil.getStatusBadge(orderStatus),
      progressBar: getProgressBar(orderModel.order),
      servicesAmount: _BaseUtil.formatCurrency(
        orderModel.order.currency,
        orderModel.order.totalAmountServicesGross,
        "it-IT"
      ),
      plansAmount: _BaseUtil.formatCurrency(
        orderModel.order.currency,
        orderModel.order.totalAmountPlansGross,
        "it-IT"
      ),
      servicesNet: _BaseUtil.formatCurrency(
        "EUR",
        orderModel.order.totalAmountServicesNet,
        "it-IT"
      ),
      plansNet: _BaseUtil.formatCurrency(
        "EUR",
        orderModel.order.totalAmountPlansNet,
        "it-IT"
      ),
      deliveryDate: _BaseUtil.formatDate(deliveryDate, "it-IT"),
      deliveryText:
        orderStatus === 3
          ? ""
          : `- ${_BaseUtil.dateDiffInDays(
              new Date(deliveryDate),
              new Date()
            )} giorni`,
    };

    return result;
  };

  useEffect(() => {
    loadTable();
  }, [businessId]);

  const filterRecords = () => {
    const result = _BaseUtil.filterList(tableRecords, filter);
    setFilteredRecords(result);
  };

  useEffect(() => {
    filterRecords();
  }, [tableRecords, filter]);

  const getProgressBar = (order) => {
    let completion =
      order.status === 3
        ? 100
        : _BaseUtil
            .calculateCompletionPercentage(
              order.createdOn,
              order.deliveryDate,
              new Date()
            )
            .toFixed(0);

    return (
      <>
        <div className="progress animated-progess progress-sm mt-2">
          <div
            className={`progress-bar bg-${
              completion < 25
                ? "success"
                : completion < 75
                ? "warning"
                : order.status === 3
                ? "success"
                : "danger"
            }`}
            role="progressbar"
            style={{ width: completion + "%" }}
            aria-valuenow={completion}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Ordini</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/orders">Ordini</Link>
                    </li>
                    <li className="breadcrumb-item active">Lista Ordini</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    </div>
                    <div className="col-6 my-auto">
                      <div className="text-end"></div>
                    </div>
                    <div className="col-12 my-4">
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Filtra ..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <Link
                            onClick={() => loadTable(true)}
                            className="btn btn-light float-end"
                          >
                            <i className="mdi mdi-refresh"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`card d-none d-sm-block`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                  <input
                    type="text"
                    className="form-control mx-4"
                    id="searchInput"
                    placeholder="Filtra ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <Link
                      onClick={() => loadTable(true)}
                      className="btn btn-light mx-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive tableReflow">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Codice</th>
                      <th scope="col">Ordine</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Scadenza</th>
                      <th scope="col">Stato</th>
                      <th scope="col">Importi</th>
                      {/* <th scope="col">Azioni</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {filteredRecords.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                              <Link
                                to={`/order/${item.orderId}/details`}
                                className="text-dark"
                              >
                                <span className="text-primary font-size-22">
                                  <img
                                    src={
                                      item.imagePath ||
                                      "/assets/images/services/wired-outline-1020-rules-book-guideline.gif"
                                    }
                                    alt={item.name || "Ordine"}
                                    className="mw-100"
                                  />
                                </span>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/order/${item.orderId}/details`}
                                className="text-dark"
                              >
                                <span>{item.code}</span>
                              </Link>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/order/${item.orderId}/details`}
                                className="text-dark"
                              >
                                <span>
                                  {item.subject ?? "Ordine senza oggetto"}
                                </span>
                              </Link>
                            </h5>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/contact/${item.customerId}/profile`}
                                style={{ color: "var(--bs-link-color)" }}
                              >
                                {item.customerName || ""}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0 text-wrap">
                              {item.customerType || ""}
                            </p>
                          </td>
                          <td>
                            <div className="row">
                              <div className="col-6">
                                <h5 className="font-size-14">
                                  {item.deliveryDate}
                                </h5>
                              </div>
                              <div className="col-6">
                                <div className="d-flex">
                                  <span className="badge badge-soft-danger font-size-12">
                                    {item.deliveryText}
                                  </span>{" "}
                                  <span className="ms-2 text-truncate"></span>
                                </div>
                              </div>
                            </div>
                            {item.progressBar}
                          </td>
                          <td>
                            <span className={`fs-5 badge ${item.statusBadge}`}>
                              {item.statusText}
                            </span>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              Servizi: {item.servicesNet}
                            </h5>
                            <h5 className="text-truncate font-size-14">
                              Piani: {item.plansNet}
                            </h5>
                          </td>
                          {/* <td>
                            <div className="dropdown">
                              <a
                                href="#"
                                className="dropdown-toggle card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-end">
                                <a className="dropdown-item" href="/order-new">
                                  Nuovo ordine
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="/invoice-new"
                                >
                                  Nuova fattura
                                </a>
                              </div>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
