import React from "react";
import { Link } from "react-router-dom";

import { _BaseUtil } from "../utils/_BaseUtil";
import { BankAccountsUtil } from "../utils/BankAccountsUtil";

export const BankAccountsAggregate = ({
  accounts,
  transactions,
  onConnectClick,
}) => {
  const accountRow = (account, index) => {
    return (
      <tr key={index}>
        <th scope="row">
          <Link to={"/bankAccounts/" + account.uuid}>
            <span
              className={`${
                BankAccountsUtil.isExpiringConsent(account)
                  ? "text-warning"
                  : ""
              }`}
            >
              {account.providerName}
            </span>
          </Link>
        </th>
        <td className="text-lg-end">
          {_BaseUtil.formatCurrency("EUR", account.balance, "it-IT")}
        </td>
        <td className="text-lg-end">
          <span className="text-success">
            +{" "}
            {_BaseUtil.formatCurrency(
              "EUR",
              Math.abs(
                BankAccountsUtil.getIncome(transactions, account.uuid, true)
              ),
              "it-IT"
            )}
          </span>
        </td>
        <td className="text-lg-end">
          <span className="text-danger">
            -{" "}
            {_BaseUtil.formatCurrency(
              "EUR",
              Math.abs(
                BankAccountsUtil.getIncome(transactions, account.uuid, false)
              ),
              "it-IT"
            )}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <div className="card card-std-height-l">
      <div className="bg-primary bg-soft">
        <div className="row">
          <div className="col-7">
            <div className="text-primary p-3">
              <h2 className="text-primary">Visione Aggregata</h2>
            </div>
          </div>
          <div className="col-5 align-self-end">
            <img
              src="/assets/images/profile-img-fiat.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="card-body pt-0 pb-0">
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th scope="col">Conto</th>
                  <th scope="col" className="text-end">
                    Saldo
                  </th>
                  <th scope="col" className="text-end">
                    Ingressi Mese
                  </th>
                  <th scope="col" className="text-end">
                    Uscite Mese
                  </th>
                </tr>
              </thead>
              <tbody>
                {accounts &&
                  accounts.map((item, index) => {
                    return accountRow(item, index);
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card-body pt-3 pb-1">
        <h4 className="card-title mb-1">Questo mese:</h4>
        <div className="text-center">
          <div className="row">
            <div className="col-6">
              <div>
                <div className="font-size-24 text-primary">
                  <i className="bx bx-import"></i>
                </div>
                <p className="mb-1 text-success">Incassi</p>
                <h5>
                  <span className="text-success">
                    {_BaseUtil.formatCurrency(
                      "EUR",
                      Math.abs(
                        BankAccountsUtil.getIncome(
                          transactions,
                          undefined,
                          true
                        )
                      ),
                      "it-IT"
                    )}
                  </span>
                </h5>
              </div>
            </div>
            <div className="col-6">
              <div>
                <div className="font-size-24 text-primary">
                  <i className="bx bx-export"></i>
                </div>
                <p className="mb-1 text-danger">Uscite</p>
                <h5>
                  <span className="text-danger">
                    {_BaseUtil.formatCurrency(
                      "EUR",
                      Math.abs(
                        BankAccountsUtil.getIncome(
                          transactions,
                          undefined,
                          false
                        )
                      ),
                      "it-IT"
                    )}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body border-top">
        <div className="d-flex flex-wrap flex-row-reverse gap-2">
          <div className="dropdown float-end">
            <button
              id="btnGroupVerticalDrop1"
              type="button"
              className="btn btn-primary btn-label waves-light"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="bx bxs-bank label-icon"></i> Conti{" "}
              <i className="mdi mdi-chevron-down"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="btnGroupVerticalDrop1"
            >
              <Link
                className="dropdown-item d-block"
                onClick={() => onConnectClick(1)}
              >
                <span key="t-settings">Aggiungi Conto Bancario</span>
              </Link>
              <Link
                className="dropdown-item d-block"
                onClick={() => onConnectClick(2)}
              >
                <span key="t-settings">Aggiungi Stripe</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
