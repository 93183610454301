import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import Chart from "react-apexcharts";

import { _BaseUtil } from "../utils/_BaseUtil";

export const CardRadialChartUtil = {
  getQuarter: function (id, label, amount, currency, culture) {
    return {
      id,
      label,
      amount,
      currency,
      culture,
    };
  },
};

export const CardRadialChart = ({ key, title, subtitle, label, values }) => {
  const [current, setCurrent] = useState(null);
  const [chartStates, setChartStates] = useState([]);

  useEffect(() => {
    if (!values?.length) return;
    setChartStates(values.map((x) => createChartState(x)));
  }, [values]);

  useEffect(() => {
    handleChange();
  }, [chartStates]);

  const handleChange = (e) => {
    if (!values) return;
    const id = e?.target?.value;
    if (!id) {
      setCurrent(values[0]);
      return;
    }
    setCurrent(values.find((x) => x.id === id));
  };

  const createChartState = (item) => {
    const total = values
      .map((x) => x.amount)
      .reduce((total, num) => Number(total) + Number(num), 0);

    const chartState = {
      id: item.id,
      series: [((item.amount / total) * 100).toFixed(2)],
      options: {
        labels: ["del totale"],
        colors: ["#556ee6"],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                fontWeight: "lighter",
                color: "lightgrey",
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                fontWeight: "lighter",
                color: "lightgrey",
                formatter: function (e) {
                  return e + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
      },
    };

    return chartState;
  };

  const getChart = (chartState) => {
    if (!chartState) return;
    return (
      <Chart
        series={chartState.series}
        options={chartState.options}
        height={330}
        type="radialBar"
        offsetY={-10}
      />
    );
  };

  const getPercentageDifference = (current) => {
    const previousIndex =
      values.findIndex((item) => item.id === current.id) - 1;

    const currentAmount = current.amount;
    const previousAmount = previousIndex < 0 ? 0 : values[previousIndex].amount;

    const percDiff = _BaseUtil.getPercentageDifference(
      currentAmount,
      previousAmount
    );

    return (
      <span
        className={`text-${_BaseUtil.getPercentageDifferenceClass(
          percDiff
        )} me-2`}
      >
        {" "}
        {percDiff}%{" "}
        <i className={`mdi mdi-arrow-${percDiff < 0 ? "down" : "up"}`}></i>{" "}
      </span>
    );
  };

  return (
    !!current &&
    !!chartStates.length && (
      <>
        <div className="card">
          <div className="card-body card-std-height-s">
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <Input
                    type="select"
                    name={`chart-radial-select-${key}`}
                    value={current.id}
                    onChange={handleChange}
                  >
                    {values.map((item, index) => {
                      return (
                        <option
                          key={`chart-radial-select-${key}-option-${index}`}
                          value={item.id}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                  <label className="input-group-text">{label}</label>
                </div>
              </div>
              <h4 className="card-title mb-4">{title}</h4>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <p className="text-muted">{subtitle}</p>
                <h3>
                  {_BaseUtil.formatCurrency(
                    "EUR",
                    current.amount || 0,
                    "it-IT"
                  )}
                </h3>
                <p className="text-muted">
                  {getPercentageDifference(current)} Rispetto al periodo
                  precedente
                </p>
              </div>
              <div className="col-sm-6">
                <div className="mt-4 mt-sm-0">
                  {getChart(chartStates.find((cs) => cs.id === current.id))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
