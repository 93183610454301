import { Progress } from "reactstrap";
import { _BaseUtil } from "../../../utils/_BaseUtil";

export const SalesCellHandler = {
  get: function (current, inProgress) {
    const total = 85000;
    const remaining = total - current - inProgress;
    return {
      current,
      inProgress,
      remaining,
      currentSize: ((current / total) * 100).toFixed(2),
      inProgressSize: ((inProgress / total) * 100).toFixed(2),
      remainingSize: ((remaining / total) * 100).toFixed(2),
    };
  },
};

const SalesCell = ({ cell }) => {
  return (
    <>
      <div className="row">
        <div className="col-4">
          <h6 className="mb-0">
            {_BaseUtil.formatCurrency("EUR", cell.current, "it-IT")}{" "}
          </h6>
        </div>
        <div className="col-4">
          <h6 className="mb-0">
            {_BaseUtil.formatCurrency("EUR", cell.inProgress, "it-IT")}{" "}
          </h6>
        </div>
        <div className="col-4">
          <h6 className="mb-0" data-bs-toggle="tooltip" data-bs-placement="top">
            {_BaseUtil.formatCurrency("EUR", cell.remaining, "it-IT")}{" "}
          </h6>
        </div>
      </div>
      <div className="row mt-2" style={{ height: "25px" }}>
        <div className="col-12">
          <Progress multi className="h-100">
            <Progress
              bar
              animated
              color="success"
              striped
              value={cell.currentSize}
            >
              {`${cell.currentSize}%`}
            </Progress>
            <Progress
              bar
              animated
              color="primary"
              striped
              value={cell.inProgressSize}
            >
              {`${cell.inProgressSize}%`}
            </Progress>
            <Progress
              bar
              animated
              color="secondary"
              striped
              value={cell.remainingSize}
            >
              {`${cell.remainingSize}%`}
            </Progress>
          </Progress>
        </div>
      </div>
    </>
  );
};

export default SalesCell;
