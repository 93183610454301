import { ServicesApi } from "../../ServicesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "services";

export const ServicesApi_Cache = {
  apiName: "ServicesApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await ServicesApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );

      $data.set(cacheData); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await ServicesApi.create(businessId, payload);

    if (newData) {
      $data.set([...$data.get(), newData]); //adding object to the existing array
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  delete: async function (businessId, serviceId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      serviceId
    );

    const { $data } = atomCache(businessId, KEY, []);

    await ServicesApi.delete(businessId, serviceId);

    let cacheData = $data.get();
    cacheData = cacheData.filter((item) => item);

    const current = cacheData.find((item) => item.rowKey === serviceId);
    current.status = -1;

    const others = cacheData.filter((item) => item.rowKey !== serviceId);
    $data.set([...others, current]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName}`);
  },

  update: async function (businessId, serviceId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      serviceId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    payload = await ServicesApi.update(businessId, serviceId, payload);

    if (payload) {
      const cacheData = $data.get();
      const others = cacheData.filter((item) => item.rowKey !== serviceId);
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },
};
