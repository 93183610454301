import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CardMainSummaryCTA = ({ avatarPath, user_full_name }) => {
  const { t } = useTranslation("dashboard");

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <img
                    src={`${avatarPath}`}
                    alt="Avatar"
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <h5 className="mb-1">Ciao {user_full_name}!</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 align-self-center">
              <div className="text-lg-center mt-4 mt-lg-0">
                <div className="d-flex">
                  <div>
                    <h4 className="card-title mb-3">
                      Trasforma il tuo business con Holo!
                    </h4>
                    <p className="text-muted">
                      Usare Holo è davvero semplice, segui gli step dei riquadri
                      indicati sotto, in pochi minuti tutto sarà pronto per
                      automatizzare i tuoi processi di incasso, dai preventivi
                      fino alla fatturazione.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 d-none d-lg-block">
              <div className="clearfix mt-4 mt-lg-0">
                <div className="float-end">
                  <Link
                    to={`/estimate`}
                    className="btn btn-primary"
                    type="button"
                  >
                    <i className="bx bxs-magic-wand align-middle mx-1"></i>
                    <p className="m-0 label-button text-capitalize-first-word">
                      {t("new_estimate")}
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
