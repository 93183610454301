import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const ContractsApi = {
  apiName: "ContractsApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/contract`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  getDefault: async function (businessId, nocache) {
    const fnName = "getDefault";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const options = _BaseApi.getHeaders(nocache);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/contract/default`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  getFor: async function (businessId, entityName, entityId) {
    const fnName = "getFor";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/contract/${entityName}/${entityId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  set: async function (businessId, value) {
    const fnName = "set";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      value
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/contract`,
      value
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },
};
