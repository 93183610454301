import React from "react";

import { ConfirmModal } from "../components/ConfirmModal";

import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { InvoicesUtil } from "../utils/InvoicesUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";
import { Col, Row } from "reactstrap";

export const PaymentDetailsCard = ({
  transaction,
  contact,
  estimate,
  order,
  subscription,
  preInvoice,
  invoice,
  handleWithdraw,
  handleCancel,
  handleUpdate,
}) => {
  return (
    transaction && (
      <>
        <div className="card card-std-height-l">
          <div className="card-body btransaction-bottom">
            <div className="d-flex mb-5">
              <div className="flex-shrink-0 me-4">
                <img
                  src="/assets/images/services/wired-outline-2752-banknote.gif"
                  alt="generic payment"
                  className="avatar-sm"
                />
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">
                  {transaction.name}
                </h5>
                <DocumentDetailsCardItem
                  routeText={TransactionsUtil.getStatusDescription(transaction)}
                  className={`badge ${TransactionsUtil.getStatusBadge(
                    transaction.status
                  )} font-size-12`}
                />
              </div>
            </div>

            <Row className="task-dates mt-5">
              {!!contact && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Cliente`}
                    icon={`bx bx-buildings`}
                    route={`/contact/${contact.rowKey}/profile`}
                    routeText={ContactsUtil.getFullName(contact)}
                  />
                </Col>
              )}
              {!!estimate && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Preventivo`}
                    icon={`bx bx-buildings`}
                    route={`/estimate/${estimate.rowKey}/details`}
                    routeText={estimate.subject || "Preventivo"}
                  />
                </Col>
              )}
              {!!order && !subscription && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Ordine`}
                    icon={`bx bx-buildings`}
                    route={`/order/${order.rowKey}/details`}
                    routeText={order.subject || "Ordine"}
                  />
                </Col>
              )}
              {!!subscription && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Sottoscrizione`}
                    icon={`bx bx-buildings`}
                    route={`/subscription/${subscription.rowKey}/details`}
                    routeText={subscription.serviceName || "Sottoscrizione"}
                  />
                </Col>
              )}
              {!!preInvoice && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Pro Forma`}
                    icon={`bx bx-buildings`}
                    route={`/preInvoice/${preInvoice.rowKey}/details`}
                    routeText={preInvoice.subject || "Pro Forma"}
                  />
                </Col>
              )}
              {!!invoice && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={InvoicesUtil.getTypeDescription(invoice.invoiceType)}
                    icon={`bx bx-buildings`}
                    route={`/invoice/${invoice.rowKey}/details`}
                    routeText={invoice.subject || "Fattura"}
                  />
                </Col>
              )}
              <Col xs={6} xxl={4} className="p-2">
                <DocumentDetailsCardItem
                  title={`Data Creazione`}
                  icon={`bx bx-calendar`}
                  route={null}
                  routeText={_BaseUtil.formatDate(
                    transaction.createdOn,
                    "it-IT"
                  )}
                />
              </Col>
              {!!transaction.closedOn && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Data Chiusura`}
                    icon={`bx bx-calendar-check`}
                    route={null}
                    routeText={_BaseUtil.formatDate(
                      transaction.closedOn,
                      "it-IT"
                    )}
                  />
                </Col>
              )}
              <Col xs={6} xxl={4} className="p-2">
                <DocumentDetailsCardItem
                  title={`Importo`}
                  icon={`bx bx-euro`}
                  routeText={_BaseUtil.formatCurrency(
                    transaction.currency,
                    transaction.amountGross,
                    "it-IT"
                  )}
                />
              </Col>
              {!!transaction.invoiceDiscountPercentage && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto Fattura %`}
                    icon={`bx bx-euro`}
                    routeText={`${transaction.invoiceDiscountPercentage}%`}
                  />
                </Col>
              )}
              {!!transaction.invoiceDiscountAmountNet && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto Fattura ${transaction.currency}`}
                    icon={`bx bx-euro`}
                    routeText={_BaseUtil.formatCurrency(
                      transaction.currency,
                      transaction.invoiceDiscountAmountNet,
                      "it-IT"
                    )}
                  />
                </Col>
              )}
              {!!transaction.paymentProvider && (
                <Col xs={6} xxl={4} className="p-2">
                  <DocumentDetailsCardItem
                    title={`Provider`}
                    icon={`bx bxs-bank`}
                    routeText={TransactionsUtil.getPaymentProviderDescription(
                      transaction.paymentProvider
                    )}
                    className={`badge ${TransactionsUtil.getPaymentProviderBadge(
                      transaction.paymentProvider
                    )} font-size-12`}
                  />
                </Col>
              )}
              <Col xs={6} xxl={4} className="p-2">
                <DocumentDetailsCardItem
                  title={`Tipo Fatturazione`}
                  icon={`bx bx-check-double`}
                  route={null}
                  routeText={InvoicesUtil.getTypeDescription(
                    transaction.invoiceType
                  )}
                  className={`badge ${InvoicesUtil.getTypeBadge(
                    transaction.invoiceType
                  )} font-size-12`}
                />
              </Col>
            </Row>
          </div>

          <div className="card-body border-top">
            <Row>
              <Col xs={6} className="my-1">
                {[1, 2, 4].includes(transaction.status) && (
                  <button
                    type="button"
                    className="btn btn-success waves-effect btn-label waves-light w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`.confirm-modal-paid`}
                  >
                    <i className="bx bx-check label-icon"></i> Segna come
                    incassato
                  </button>
                )}
              </Col>
              <Col xs={6} className="my-1">
                {[1, 2, 4].includes(transaction.status) &&
                  (!transaction.expirationDate ||
                    TransactionsUtil.isExpired(transaction)) && (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect btn-label waves-light w-100"
                      data-bs-toggle="modal"
                      data-bs-target={`.confirm-modal`}
                    >
                      <i className="bx bx-money label-icon"></i> Incassa
                    </button>
                  )}
              </Col>
              <Col xs={6} className="my-1">
                {[1].includes(transaction.status) &&
                  (!transaction.expirationDate ||
                    TransactionsUtil.isExpired(transaction)) && (
                    <button
                      type="button"
                      className="btn btn-danger waves-effect btn-label waves-light w-100"
                      data-bs-toggle="modal"
                      data-bs-target={`.confirm-modal-cancel`}
                    >
                      <i className="bx bx-x label-icon"></i> Annulla
                    </button>
                  )}
                {[3, 5].includes(transaction.status) &&
                  (!transaction.expirationDate ||
                    TransactionsUtil.isExpired(transaction)) && (
                    <button
                      type="button"
                      className="btn btn-light waves-effect btn-label waves-light w-100"
                      data-bs-toggle="modal"
                      data-bs-target={`.confirm-modal-reactivate`}
                    >
                      <i className="bx bx-book-open label-icon"></i> Riattiva
                    </button>
                  )}
              </Col>
            </Row>
          </div>
        </div>

        <ConfirmModal
          id={`confirm-modal-cancel`}
          title={`Conferma annullamento pagamento`}
          text={`Attenzione, annullando il pagamento non sarà più possibile procedere con il prelievo, sei sicuro di voler proseguire?`}
          confirmText={`Conferma annullamento pagamento`}
          cancelText={`Annulla operazione`}
          onConfirm={handleCancel}
        />

        <ConfirmModal
          id={`confirm-modal-reactivate`}
          title={`Conferma riattivazione pagamento`}
          text={`Attenzione, con la riattivazione del pagamento si potrà nuovamente procedere con l'incasso, sei sicuro di voler proseguire?`}
          confirmText={`Riattiva pagamento`}
          cancelText={`Annulla operazione`}
          onConfirm={() => {
            handleUpdate({ ...transaction, status: 1 });
          }}
        />

        <ConfirmModal
          id={`confirm-modal-paid`}
          title={`Conferma chiuso come incassato`}
          text={`Attenzione, sei sicuro di voler impostare lo stato come incassato?`}
          confirmText={`Segna come incassato`}
          cancelText={`Annulla operazione`}
          onConfirm={() => {
            handleUpdate({ ...transaction, status: 3 });
          }}
        />

        <ConfirmModal
          title={`Conferma richiesta saldo`}
          text={`Attenzione, confermando la richiesta verrà tentato l'incasso in base alla modalità di pagamento collegata, se non dovesse andare a buon fine verrà creata una pro forma e generato un link di pagamento da mandare al cliente. Sei sicuro di voler proseguire?`}
          confirmText={`Conferma`}
          cancelText={`Annulla`}
          onConfirm={handleWithdraw}
        />
      </>
    )
  );
};
