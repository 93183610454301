import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { MovementsAttachmentsApi_Cache } from "../api/decorators/cache/MovementsAttachmentsApi_Cache";
import { MovementsTransactionsApi_Cache } from "../api/decorators/cache/MovementsTransactionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";

import MovementRow, { MovementRowHandler } from "./MovementRow";

export const Movements = ({ useSearch, top, obAccountId }) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState([]); // Nuovo stato per i suggerimenti

  const [contacts, setContacts] = useState([]);
  const [movements, setMovements] = useState([]);
  const [movementsAttachments, setMovementsAttachments] = useState([]);
  const [movementsTransactions, setMovementsTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    loadTable();
  }, [businessId]);

  useEffect(() => {
    if (!obAccountId) return;
    setMovements(movements.filter((x) => x.obAccountId === obAccountId));
  }, [obAccountId, movements]);

  useEffect(() => {
    mapTable();
  }, [movements]);

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();
    const fetchContacts = async () => {
      setContacts(await ContactsApi_Cache.get(businessId));
    };
    const fetchMovements = async () => {
      setMovements(await MovementsApi_Cache.get(businessId));
    };
    const fetchMovementsAttachments = async () => {
      setMovementsAttachments(
        await MovementsAttachmentsApi_Cache.get(businessId)
      );
    };
    const fetchMovementsTransactions = async () => {
      setMovementsTransactions(
        await MovementsTransactionsApi_Cache.get(businessId)
      );
    };
    const fetchTransactions = async () => {
      setTransactions(await TransactionsApi_Cache.get(businessId));
    };

    await Promise.all([
      fetchContacts(),
      fetchMovementsAttachments(),
      fetchMovementsTransactions(),
      fetchTransactions(),
    ]);

    await fetchMovements();
  };

  const filterRecords = () => {
    let result = _BaseUtil.filterList(tableRecords, filter);

    let sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.madeOn);
      const dateB = new Date(b.madeOn);
      return dateB - dateA;
    });

    if (top) {
      sortedItems = sortedItems.slice(0, top);
    }

    setFilteredRecords(sortedItems);
  };

  useEffect(() => {
    filterRecords();
  }, [tableRecords, filter]);

  useEffect(() => {
    // Aggrega tutti i tag univoci dai movimenti
    const allTags = tableRecords.reduce((acc, item) => {
      _BaseUtil.tagsToList(item.tags || "").forEach((tag) => {
        if (acc.every((accTag) => accTag.text !== tag)) {
          acc.push({ id: tag, text: tag });
        }
      });
      return acc;
    }, []);

    setSuggestions(allTags); // Imposta i suggerimenti con i tag univoci
  }, [tableRecords]);

  const handleCreateInvoice = async (movement) => {
    const invoiceType = movement.amount >= 0 ? 1 : 4;
    navigate("/invoice", {
      state: { pInvoiceType: invoiceType, pMovement: movement },
    });
  };

  const handlePropertyChange = async (
    propertyName,
    newValue,
    obTransactionId
  ) => {
    // Find the index of the record with the corresponding obTransactionId
    const index = tableRecords.findIndex(
      (record) => record.obTransactionId === obTransactionId
    );

    if (index === -1) return;

    const records = [...tableRecords];
    const movement = records[index];
    movement[propertyName] = newValue; // Update the property with the new value

    await save(movement);

    mapTable();
  };

  const mapTable = () => {
    if (!movements.length) return;
    let result = movements.map((x) =>
      MovementRowHandler.mapRecord(
        x,
        transactions,
        movementsAttachments,
        movementsTransactions,
        contacts
      )
    );
    setTableRecords(result);
  };

  const save = async (movement) => {
    movement.eTag = undefined;
    await MovementsApi_Cache.update(businessId, movement.rowKey, movement);
  };

  const handleFileDownload = async (movementId, attachmentId, filename) => {
    const file = await MovementsAttachmentsApi_Cache.download(
      businessId,
      movementId,
      attachmentId
    );
    if (file) {
      _BaseUtil.download(filename, file);
    }
  };

  const handleFileUpload = async (movementId, e) => {
    const files = e.target.files; // Access all selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filename = file.name; // Get the file name
      await MovementsAttachmentsApi_Cache.create(
        businessId,
        movementId,
        filename,
        file
      );
    }
    await loadTable();
  };

  const handleFileDelete = async (movementId, attachmentId) => {
    await MovementsAttachmentsApi_Cache.delete(
      businessId,
      movementId,
      attachmentId
    );
    await loadTable();
  };

  return (
    <>
      {!!useSearch && (
        <>
          <div className={`card d-sm-none`}>
            <div className="card-body border-bottom">
              <div className="d-flex align-items-center">
                <div className="row">
                  <div className="col-6 my-auto">
                    <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                  </div>
                  <div className="col-6 my-auto">
                    <div className="text-end"></div>
                  </div>
                  <div className="col-12 my-4">
                    <div className="row">
                      <div className="col-10">
                        <input
                          type="text"
                          className="form-control"
                          id="searchInput"
                          placeholder="Filtra ..."
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <Link
                          onClick={() => loadTable(true)}
                          className="btn btn-light float-end"
                        >
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`card d-none d-sm-block`}>
            <div className="card-body border-bottom">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                <input
                  type="text"
                  className="form-control mx-4"
                  id="searchInput"
                  placeholder="Filtra ..."
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
                <div className="flex-shrink-0">
                  <Link
                    onClick={() => loadTable(true)}
                    className="btn btn-light mx-1"
                  >
                    <i className="mdi mdi-refresh"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="card-body">
        <div className="table-responsive tableReflow">
          <table className="table project-list-table table-nowrap align-middle table-borderless">
            <thead>
              <tr>
                <th style={{ width: "50px" }}></th>
                <th style={{ width: "50px" }}></th>
                <th style={{ width: "150px" }}>Importo</th>
                <th style={{ width: "350px" }}>Causale</th>
                <th style={{ width: "50px" }}>Conto</th>
                <th style={{ width: "50px" }}>Categoria</th>
                <th style={{ width: "150px" }}>Tipo spesa</th>
                <th style={{ width: "250px" }}>Tag</th>
                <th style={{ width: "150px" }}>Stato</th>
                <th style={{ width: "25px" }}>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {filteredRecords.map((m, i) => (
                <MovementRow
                  key={i}
                  movement={m}
                  suggestions={suggestions}
                  handlePropertyChange={handlePropertyChange}
                  handleCreateInvoice={handleCreateInvoice}
                  handleFileDownload={handleFileDownload}
                  handleFileUpload={handleFileUpload}
                  handleFileDelete={handleFileDelete}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
