import { MovementsAttachmentsApi } from "../../MovementsAttachmentsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "movementsattachments";

export const MovementsAttachmentsApi_Cache = {
  apiName: "MovementsAttachmentsApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await MovementsAttachmentsApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  download: async function (
    businessId,
    movementId,
    attachmentId,
    nocache,
    noloader
  ) {
    const fnName = "download";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      movementId,
      attachmentId,
      nocache,
      noloader
    );

    const response = await MovementsAttachmentsApi.download(
      businessId,
      movementId,
      attachmentId,
      nocache,
      noloader
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },

  create: async function (businessId, recordId, filename, file) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      recordId,
      filename
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await MovementsAttachmentsApi.create(
      businessId,
      recordId,
      filename,
      file
    );

    if (newData) {
      $data.set([...$data.get(), newData]); //adding object to the existing array
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  delete: async function (businessId, recordId, attachmentId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      recordId,
      attachmentId
    );

    const { $data } = atomCache(businessId, KEY, []);

    await MovementsAttachmentsApi.delete(businessId, recordId, attachmentId);

    let cacheData = $data.get();
    const others = cacheData.filter((item) => item.rowKey !== attachmentId);
    $data.set([...others]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName}`);
  },
};
