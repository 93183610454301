import { ConfigurationsApi } from "../../ConfigurationsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "configurations";

export const ConfigurationsApi_Cache = {
  apiName: "ConfigurationsApi_Cache",

  getVatTypes: async function (businessId) {
    const fnName = "getVatTypes";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired) {
      cacheData = await ConfigurationsApi.getVatTypes(businessId);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData && cacheData.filter((x) => x);
  },
};
