import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { UserContext } from "../contexts/AppContexts";

export default function Profile() {
  const { t } = useTranslation("profile");

  const { user } = useContext(UserContext);

  const onCopyReferralCode = function () {
    navigator.clipboard.writeText(user.referralCodePersonal).then(() => {
      toast.success(t("copied_referral"), { autoClose: 2500 });
    });
  };

  const onChangeLanguage = function () {};

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="pt-4 text-center">
            <p className="mt-5">Email: {user.email}</p>
            <p>
              Referral: {user.referralCodePersonal}
              <i
                onClick={onCopyReferralCode}
                role="button"
                className="fa-solid fa-copy ms-3"
              ></i>
            </p>
            <p>
              Language:
              <select className="ms-2" value={"it"} onChange={onChangeLanguage}>
                <option value="it">Italian</option>
                <option value="en">English</option>
              </select>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
