import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const ContactsApi = {
    apiName: "ContactsApi",
    baseUrl: "/webapp/businesses",

    get: async function(businessId, nocache, noloader) {
        const fnName = "get";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            nocache
        );

        let options = _BaseApi.getHeaders(nocache, noloader);

        const response = await api.get(
            `${this.baseUrl}/${businessId}/contacts`,
            options
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data || [];
    },

    create: async function(businessId, payload) {
        const fnName = "create";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            payload
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/contacts`,
            payload
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },

    update: async function(businessId, contactId, payload) {
        const fnName = "update";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            contactId,
            payload
        );

        payload = await api.put(
            `${this.baseUrl}/${businessId}/contacts/${contactId}`,
            payload
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `);

        return payload ? payload.data : null;
    },
};