import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "reactstrap";
import "../../assets/css/SelectRadioButtonGroup.css"; // Importare il foglio di stile

function SelectRadioButtonGroup({
  key,
  defaultValue,
  onValueChange,
  options,
  className,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    if (onValueChange) {
      onValueChange(selectedValue);
    }
  }, [selectedValue, onValueChange]);

  return (
    <ButtonGroup
      key={`btngroup-${key}`}
      className={`button-group ${className}`}
    >
      {options.map((option, index) => (
        <Button
          key={`btn-${key}-${index}`}
          className={`button ${selectedValue === option.value ? "active" : ""}`}
          onClick={() => setSelectedValue(option.value)}
          active={selectedValue === option.value}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default SelectRadioButtonGroup;
