import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/reactTags.css";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";

import { EstimateDetailsCard } from "../components/EstimateDetailsCard";
import { CardTable, CardTableUtil } from "../components/CardTable";
import { PageHeader } from "../components/PageHeader";

export default function EstimateDetails() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [model, setModel] = useState(null);
  const [condition, setCondition] = useState(null);
  const [order, setOrder] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleOnLoad = async (nocache) => {
    if (nocache) atomClearCache();
    let model = (await EstimatesApi_Cache.get(businessId)).find(
      (x) => x.estimate.rowKey === id
    );
    console.log(`model:`, model);
    setModel(model);
  };

  useEffect(() => {
    handleOnLoad();
  }, [id]);

  useEffect(() => {
    fetchOrder();
    fetchSubscriptions();
    loadCardTableServices();
    loadCardTablePlans();
    loadCardTableConditions();
    loadCardTableLinks();
  }, [model]);

  useEffect(() => {
    if (!model) return;
    const selectedConditionId = model.estimate.estimateConditionId;
    const condition = model.conditions.find(
      (x) => x.rowKey === selectedConditionId
    );
    setCondition(condition);
  }, [model]);

  useEffect(() => {
    loadCardTableSubscriptions();
  }, [model, subscriptions]);

  const fetchOrder = async () => {
    if (!model) return;

    let order = (await OrdersApi_Cache.get(businessId)).find(
      (x) => x.order.estimateId === model.estimate.rowKey
    );

    setOrder(order);
  };

  const fetchSubscriptions = async (nocache) => {
    if (!model) return;

    let subscriptions = (
      await SubscriptionsApi_Cache.get(businessId, nocache)
    ).filter((x) => x.estimateId === model.estimate.rowKey);

    setSubscriptions(subscriptions);
  };

  const [servicesCols, setServicesCol] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo netto",
    },
  ]);
  const [servicesRows, setServicesRows] = useState([]);
  const loadCardTableServices = () => {
    if (!model) return;
    let estimateRows = model.rows || [];
    let services = estimateRows
      .filter((x) => x.serviceType === 1)
      .sort((a, b) => {
        return a.lineNumber - b.lineNumber;
      });

    let tblRows = services.map((x) => {
      return {
        serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
        quantity: CardTableUtil.getCellText(`${x.quantity}`),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
        ),
      };
    });

    setServicesRows(tblRows);
  };

  const [plansCols, setPlansCols] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo netto",
    },
  ]);
  const [plansRows, setPlansRows] = useState([]);
  const loadCardTablePlans = () => {
    if (!model) return;
    let estimateRows = model.rows || [];
    let plans = estimateRows
      .filter((x) => x.serviceType === 2)
      .sort((a, b) => {
        return a.lineNumber - b.lineNumber;
      });

    let tblRows = plans.map((x) => {
      return {
        serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
        quantity: CardTableUtil.getCellText(`${x.quantity}`),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(
            x.currency,
            x.amount * x.billingIntervalsCount,
            "it-IT"
          )}`
        ),
      };
    });

    setPlansRows(tblRows);
  };

  const [conditionsCols, setConditionsCols] = useState([
    {
      key: "name",
      value: "Nome",
    },
    {
      key: "discount",
      value: "Sconto",
    },
    {
      key: "default",
      value: "Default",
    },
  ]);
  const [conditionsRows, setConditionsRows] = useState([]);
  const loadCardTableConditions = () => {
    if (!model) return;
    let estimateConditions = model.conditions || [];

    let tblRows = estimateConditions.map((x) => {
      return {
        name: CardTableUtil.getCellText(`${x.name}`),
        discount: CardTableUtil.getCellText(
          `${x.discountPercentage}% (${_BaseUtil.formatCurrency(
            x.currency,
            x.discountAmountNet,
            "it-IT"
          )})`
        ),
        default: CardTableUtil.getCellText(`${x.isDefault ? "Si" : "No"}`),
      };
    });

    setConditionsRows(tblRows);
  };

  const [linksCols, setLinksCols] = useState([
    {
      key: "description",
      value: "Descrizione",
    },
    {
      key: "url",
      value: "Url",
    },
  ]);
  const [linksRows, setLinksRows] = useState([]);
  const loadCardTableLinks = () => {
    if (!model) return;
    let estimateLinks = model.links || [];

    let tblRows = estimateLinks.map((x) => {
      return {
        description: CardTableUtil.getCellText(x.description),
        url: CardTableUtil.getCellText(x.url),
      };
    });

    setLinksRows(tblRows);
  };

  const [subscriptionsCols, setSubscriptionCols] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "amount",
      value: "Importo Totale",
    },
  ]);
  const [subscriptionsRows, setSubscriptionsRows] = useState([]);
  const loadCardTableSubscriptions = () => {
    if (!model?.estimate) return;
    if (!subscriptions.length) return;

    let rows = model.rows || [];
    let plans = rows.filter((x) => x.serviceType === 2);

    let tblRows = plans.map((x) => {
      let subscription = subscriptions.find((s) => s.serviceId === x.serviceId);

      return {
        serviceName: CardTableUtil.getCellLink(
          x.serviceName,
          `text-primary fw-bold`,
          `/subscription/${subscription.rowKey}/details`
        ),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(
            x.currency,
            x.amount * x.billingIntervalsCount,
            "it-IT"
          )}`
        ),
      };
    });

    setSubscriptionsRows(tblRows);
  };

  return (
    !!model && (
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <PageHeader
                title={`Dettaglio preventivo: ${model.estimate.code}`}
                pageName={`Dettaglio preventivo`}
                backPageName={`Preventivi`}
                backPageRoute={`/estimates`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4">
              <EstimateDetailsCard
                businessId={businessId}
                estimate={model.estimate}
                estimateCondition={condition}
                order={order?.order}
                refresh={() => handleOnLoad(true)}
              />
            </div>
            {servicesRows?.length ? (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`estimatedetails-card-table-services`}
                  title={`Servizi`}
                  columnNames={servicesCols}
                  values={servicesRows}
                />
              </div>
            ) : null}
            {plansRows?.length ? (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`estimatedetails-card-table-plans`}
                  title={`Piani`}
                  columnNames={plansCols}
                  values={plansRows}
                />
              </div>
            ) : null}
            {conditionsRows?.length ? (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`estimatedetails-card-table-conditions`}
                  title={`Condizioni`}
                  columnNames={conditionsCols}
                  values={conditionsRows}
                />
              </div>
            ) : null}
            {linksRows?.length ? (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`estimatedetails-card-table-link`}
                  title={`Link utili`}
                  columnNames={linksCols}
                  values={linksRows}
                />
              </div>
            ) : null}

            {subscriptionsRows?.length ? (
              <div className="col-md-6 col-xxl-4">
                <CardTable
                  key={`orderdetails-card-table-subscriptions`}
                  title={`Sottoscrizioni`}
                  columnNames={subscriptionsCols}
                  values={subscriptionsRows}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}
