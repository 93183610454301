import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export const CardBarChartUtil = {
  get: function (id, years, series) {
    return {
      id,
      years,
      series,
    };
  },
};

export const CardBarChart = ({ title, value }) => {
  const [chartState, setChartState] = useState(null);

  useEffect(() => {
    if (!value) return;
    setChartState(createChartState(value));
  }, [value]);

  const createChartState = (item) => {
    return {
      id: item.id,
      series: item.series,
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: item.years.map((x) => x.toString()),
        },
        colors: ["#34c38f", "#556ee6", "#74788d"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ".000 €";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  };

  return (
    !!chartState && (
      <>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4">{title}</h4>
            <div className="row">
              <div className="col-12">
                <div className="mt-4 mt-sm-0">
                  <Chart
                    type={chartState.options.chart.type}
                    series={chartState.series}
                    width={chartState.options.chart.width}
                    height={chartState.options.chart.height}
                    options={chartState.options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
