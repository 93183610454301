import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const AlertsApi = {
  apiName: "AlertsApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/alerts`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data || [];
  },

  deactivate: async function (businessId, alertId, noloader) {
    const fnName = "deactivate";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      alertId
    );

    const options = _BaseApi.getHeaders(null, noloader);

    const response = await api.delete(
      `${this.baseUrl}/${businessId}/alerts/${alertId}`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);
  },
};
