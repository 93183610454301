import StripePaymentsList from "../components/Stripe/StripePaymentsList";

export default function Stripe() {
  return (
    <div className="page-content">
      {" "}
      <StripePaymentsList />
    </div>
  );

  // return (
  //   <>
  //     <div className="page-content">
  //       <div className="container-fluid">
  //         <div className="row">
  //           <div className="col-12">
  //             <div className="page-title-box d-sm-flex align-items-center justify-content-between">
  //               <h4 className="mb-sm-0 font-size-18">Conto Stripe</h4>

  //               <div className="page-title-right">
  //                 <ol className="breadcrumb m-0">
  //                   <li className="breadcrumb-item">
  //                     <a href="#">Conti Fiat</a>
  //                   </li>
  //                   <li className="breadcrumb-item active">Stripe</li>
  //                 </ol>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="row">
  //           <div className="col-xl-4">
  //             <div className="card overflow-hidden">
  //               <div className="bg-primary bg-soft">
  //                 <div className="dropdown float-end m-2">
  //                   <a
  //                     href="#"
  //                     className="dropdown-toggle arrow-none"
  //                     data-bs-toggle="dropdown"
  //                     aria-expanded="false"
  //                   >
  //                     <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
  //                   </a>
  //                   <div className="dropdown-menu dropdown-menu-end">
  //                     <a className="dropdown-item" href="#">
  //                       Rimuovi account
  //                     </a>
  //                     <a className="dropdown-item" href="#">
  //                       Cambia account
  //                     </a>
  //                   </div>
  //                 </div>
  //                 <div className="row">
  //                   <div className="col-7">
  //                     <div className="text-primary p-3">
  //                       <h2 className="text-primary">Stripe Account</h2>
  //                       <h5>
  //                         <a href="https://www.stripe.com" target="_blank">
  //                           Portale amministrazione
  //                           <i className="mdi mdi-link-variant ms-1"></i>
  //                         </a>
  //                       </h5>
  //                     </div>
  //                   </div>
  //                   <div className="col-5 align-self-end">
  //                     <img
  //                       src="/assets/images/profile-img-fiat.png"
  //                       alt=""
  //                       className="img-fluid"
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="card-body pt-0">
  //                 <div className="row">
  //                   <div className="col-sm-4">
  //                     <div className="avatar-md profile-user-wid mb-4">
  //                       <img
  //                         src="/assets/images/companies/stripe.svg"
  //                         alt=""
  //                         className="img-thumbnail rounded-circle"
  //                       />
  //                     </div>
  //                   </div>

  //                   <div className="col-sm-8">
  //                     <div className="pt-1">
  //                       <span
  //                         className="badge rounded-pill badge-soft-primary font-size-12"
  //                         id="task-status"
  //                       >
  //                         Carte di credito
  //                       </span>
  //                       <span
  //                         className="badge rounded-pill badge-soft-success font-size-12 m-2"
  //                         id="task-status"
  //                       >
  //                         SEPA
  //                       </span>
  //                     </div>
  //                   </div>

  //                   <div className="table-responsive tableReflow">
  //                     <table className="table table-nowrap mb-0">
  //                       <tbody>
  //                         <tr>
  //                           <th scope="row">Nome Account :</th>
  //                           <td>Anna Martini</td>
  //                         </tr>
  //                         <tr>
  //                           <th scope="row">IBAN :</th>
  //                           <td>IT0531152000000015652 </td>
  //                         </tr>
  //                       </tbody>
  //                     </table>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="card-body">
  //                 <h4 className="card-title mb-3 mt-3">Questo Mese:</h4>
  //                 <div className="text-center">
  //                   <div className="row">
  //                     <div className="col-sm-6">
  //                       <div className="mt-4 mt-sm-0">
  //                         <div className="font-size-24 text-primary mb-2">
  //                           <i className="bx bx-import"></i>
  //                         </div>

  //                         <p className="text-muted mb-2">Incassi</p>
  //                         <h5>€ 2.150,00</h5>

  //                         <div className="mt-3">
  //                           <a href="#" className="btn btn-primary btn-sm w-md">
  //                             Invia Richiesta
  //                           </a>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <div className="col-sm-6">
  //                       <div>
  //                         <div className="font-size-24 text-primary mb-2">
  //                           <i className="bx bx-export"></i>
  //                         </div>

  //                         <p className="text-muted mb-2">Uscite</p>
  //                         <h5>€ 3.400,00</h5>

  //                         <div className="mt-3">
  //                           <a href="#" className="btn btn-primary btn-sm w-md">
  //                             Invia Denaro
  //                           </a>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="col-xl-8">
  //             <div className="row">
  //               <div className="col-md-4">
  //                 <div className="card mini-stats-wid">
  //                   <div className="card-body">
  //                     <div className="d-flex">
  //                       <div className="flex-grow-1">
  //                         <p className="text-muted fw-medium mb-2">Saldo</p>
  //                         <h4 className="mb-0">€ 2,876.00</h4>
  //                         <p className="text-muted mb-0">
  //                           <span className="badge badge-soft-success font-size-12">
  //                             {" "}
  //                             +25%{" "}
  //                           </span>
  //                         </p>
  //                       </div>

  //                       <div className="flex-shrink-0 align-self-center">
  //                         <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
  //                           <span className="avatar-title">
  //                             <i className="bx bx-bar-chart-square font-size-24"></i>
  //                           </span>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="col-md-4">
  //                 <div className="card mini-stats-wid">
  //                   <div className="card-body">
  //                     <div className="d-flex">
  //                       <div className="flex-grow-1">
  //                         <p className="text-muted fw-medium mb-2">
  //                           Transazioni in corso
  //                         </p>
  //                         <h4 className="mb-0">1</h4>
  //                         <p className="text-muted mb-0">€ 158.40</p>
  //                       </div>

  //                       <div className="flex-shrink-0 align-self-center">
  //                         <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
  //                           <span className="avatar-title">
  //                             <i className="bx bxs-hourglass-top font-size-24"></i>
  //                           </span>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="col-md-4">
  //                 <div className="card mini-stats-wid">
  //                   <div className="card-body">
  //                     <div className="d-flex">
  //                       <div className="flex-grow-1">
  //                         <p className="text-muted fw-medium mb-2">
  //                           Transazioni contestate
  //                         </p>
  //                         <h4 className="mb-0">2</h4>
  //                         <p className="text-muted mb-0">€ 1,1450.13</p>
  //                       </div>

  //                       <div className="flex-shrink-0 align-self-center">
  //                         <div className="avatar-sm mini-stat-icon rounded-circle bg-primary">
  //                           <span className="avatar-title">
  //                             <i className="bx bx bx-transfer-alt font-size-24"></i>
  //                           </span>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="row">
  //               <div className="col-lg-12">
  //                 <div className="card">
  //                   <div className="card-body card-std-height">
  //                     <h4 className="card-title mb-3">Saldo</h4>

  //                     <div>
  //                       <div
  //                         id="overview-chart"
  //                         className="apex-charts"
  //                         dir="ltr"
  //                       >
  //                         <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
  //                           <button
  //                             type="button"
  //                             className="btn btn-light btn-sm"
  //                             id="one_month"
  //                           >
  //                             1M
  //                           </button>
  //                           <button
  //                             type="button"
  //                             className="btn btn-light btn-sm"
  //                             id="six_months"
  //                           >
  //                             6M
  //                           </button>
  //                           <button
  //                             type="button"
  //                             className="btn btn-light btn-sm active"
  //                             id="one_year"
  //                           >
  //                             1A
  //                           </button>
  //                           <button
  //                             type="button"
  //                             className="btn btn-light btn-sm"
  //                             id="all"
  //                           >
  //                             TUTTO
  //                           </button>
  //                         </div>
  //                         <div
  //                           id="overview-chart-timeline"
  //                           data-colors='["--bs-primary"]'
  //                         ></div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div className="col-lg-12">
  //             <div className="card">
  //               <div className="card-body bPayment-bottom">
  //                 <div className="d-flex align-items-center">
  //                   <h5 className="mb-0 card-title flex-grow-1">Movimenti</h5>
  //                   <div className="flex-shrink-0">
  //                     <a href="#!" className="btn btn-light">
  //                       <i className="mdi mdi-refresh"></i>
  //                     </a>
  //                     <div className="dropdown d-inline-block">
  //                       <button
  //                         type="menu"
  //                         className="btn btn-success"
  //                         id="dropdownMenuButton1"
  //                         data-bs-toggle="dropdown"
  //                         aria-expanded="false"
  //                       >
  //                         <i className="mdi mdi-dots-vertical"></i>
  //                       </button>
  //                       <ul
  //                         className="dropdown-menu"
  //                         aria-labelledby="dropdownMenuButton1"
  //                       >
  //                         <li>
  //                           <a className="dropdown-item" href="#">
  //                             Collega a Fattura
  //                           </a>
  //                         </li>
  //                         <li>
  //                           <a className="dropdown-item" href="#">
  //                             Crea Fattura
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="card-body border-bottom">
  //                 <div className="row g-3">
  //                   <div className="col-xxl-4 col-lg-6">
  //                     <input
  //                       type="search"
  //                       className="form-control"
  //                       id="searchInput"
  //                       placeholder="Search for ..."
  //                     />
  //                   </div>
  //                   <div className="col-xxl-2 col-lg-6">
  //                     <select className="form-control select2">
  //                       <option>Conto</option>
  //                       <option value="Active">STRIPE</option>
  //                       <option value="Delay">REVOLUT</option>
  //                       <option value="Completed">NFT Events</option>
  //                     </select>
  //                   </div>
  //                   <div className="col-xxl-2 col-lg-4">
  //                     <select className="form-control select2">
  //                       <option>Stato</option>
  //                       <option value="1">In corso</option>
  //                       <option value="2">Completato</option>
  //                       <option value="3">Registrato</option>
  //                     </select>
  //                   </div>
  //                   <div className="col-xxl-2 col-lg-4">
  //                     <div id="datepicker1">
  //                       <input
  //                         type="text"
  //                         className="form-control"
  //                         placeholder="Data"
  //                         data-date-format="dd M, yyyy"
  //                         data-date-autoclose="true"
  //                         data-date-container="#datepicker1"
  //                         data-provide="datepicker"
  //                       />
  //                     </div>
  //                   </div>
  //                   <div className="col-xxl-2 col-lg-4">
  //                     <button
  //                       type="button"
  //                       className="btn btn-soft-secondary w-100"
  //                     >
  //                       <i className="mdi mdi-filter-outline align-middle"></i>{" "}
  //                       Filtro
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="card-body">
  //               <div className="table-responsive tableReflow">
  //                 <table className="table project-list-table table-nowrap align-middle table-borderless">
  //                   <thead>
  //                     <tr>
  //                       <th scope="col" style={{ width: "20px" }}>
  //                         #
  //                       </th>
  //                       <th scope="col" style={{ width: "30px" }}></th>
  //                       <th scope="col" style={{ width: "130px" }}>
  //                         Valore
  //                       </th>
  //                       <th scope="col" style={{ width: "130px" }}>
  //                         Data
  //                       </th>
  //                       <th scope="col" style={{ width: "380px" }}>
  //                         Conto
  //                       </th>
  //                       <th scope="col">Dettaglio</th>
  //                       <th scope="col" style={{ width: "100px" }}>
  //                         Stato
  //                       </th>
  //                       <th scope="col">Azioni</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>
  //                         <div className="form-check font-size-16">
  //                           <input
  //                             className="form-check-input"
  //                             type="checkbox"
  //                             id="customerlistcheck01"
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             for="customerlistcheck01"
  //                           ></label>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <div className="fs-1">
  //                           <i className="mdi mdi-currency-eur text-success"></i>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">€ 225,87</h5>
  //                         <p className="text-muted">Bonifico</p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">01 Mag 23</h5>
  //                         <p className="text-muted">
  //                           <span className="badge badge-soft-success font-size-12">
  //                             Regolare
  //                           </span>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">
  //                           <i className="bx bx-book-bookmark"></i>{" "}
  //                           DE0588921321389 - REVOLUT
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-buildings me-1 text-muted"></i>
  //                           Da <a href="company.html"> ACME ltd</a>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16 ">
  //                           Anticipo -{" "}
  //                           <a href="invoice-detail.html">Fattura #045/22</a>
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-link me-1 text-muted"></i>
  //                           <a href="transaction-detail.html" target="_blank">
  //                             {" "}
  //                             Dettagli
  //                           </a>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <span className="badge bg-success">Completed</span>
  //                         <span className="badge bg-primary m-1">Linked</span>
  //                       </td>
  //                       <td>
  //                         <div className="dropdown">
  //                           <a
  //                             href="#"
  //                             className="dropdown-toggle card-drop"
  //                             data-bs-toggle="dropdown"
  //                             aria-expanded="false"
  //                           >
  //                             <i className="mdi mdi-dots-horizontal font-size-18"></i>
  //                           </a>
  //                           <div className="dropdown-menu dropdown-menu-end">
  //                             <a
  //                               className="dropdown-item"
  //                               href="transaction-detail.htmls"
  //                             >
  //                               Dettaglio
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Crea Fatturta Anticipo
  //                             </a>
  //                             <a
  //                               className="dropdown-item"
  //                               href="#"
  //                               data-bs-toggle="modal"
  //                               data-bs-target=".transaction-detailModal"
  //                             >
  //                               Collega a fattura
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Aggiungi Nota
  //                             </a>
  //                           </div>
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>
  //                         <div className="form-check font-size-16">
  //                           <input
  //                             className="form-check-input"
  //                             type="checkbox"
  //                             id="customerlistcheck01"
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             for="customerlistcheck01"
  //                           ></label>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <div className="fs-1">
  //                           <i className="mdi mdi-currency-eur text-danger"></i>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">- € 127,43</h5>
  //                         <p className="text-muted">Bonifico</p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">10 Feb 23</h5>
  //                         <p className="text-muted">
  //                           <span className="badge badge-soft-success font-size-12">
  //                             Regolare
  //                           </span>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">
  //                           <i className="bx bx-book-bookmark"></i> STRIPE -
  //                           IT0185921328574
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-buildings me-1 text-muted"></i>A{" "}
  //                           <a href="company.html"> Dangerous Dust ltd</a>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16 ">
  //                           Rimborso -{" "}
  //                           <a href="project-detail.html">Ordine #144/22</a>
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-link me-1 text-muted"></i>
  //                           <a href="#" target="_blank">
  //                             {" "}
  //                             Dettagli
  //                           </a>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <span className="badge bg-success">Completed</span>
  //                         <span className="badge bg-primary m-1">Linked</span>
  //                       </td>
  //                       <td>
  //                         <div className="dropdown">
  //                           <a
  //                             href="#"
  //                             className="dropdown-toggle card-drop"
  //                             data-bs-toggle="dropdown"
  //                             aria-expanded="false"
  //                           >
  //                             <i className="mdi mdi-dots-horizontal font-size-18"></i>
  //                           </a>
  //                           <div className="dropdown-menu dropdown-menu-end">
  //                             <a
  //                               className="dropdown-item"
  //                               href="transaction-detail.html"
  //                             >
  //                               Dettaglio
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Create credit-note
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Link to order
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Link to invoice
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Aggiungi Nota
  //                             </a>
  //                           </div>
  //                         </div>
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>
  //                         <div className="form-check font-size-16">
  //                           <input
  //                             className="form-check-input"
  //                             type="checkbox"
  //                             id="customerlistcheck01"
  //                           />
  //                           <label
  //                             className="form-check-label"
  //                             for="customerlistcheck01"
  //                           ></label>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <div className="fs-1">
  //                           <i className="mdi mdi-currency-eur text-warning"></i>
  //                         </div>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">- € 1,50</h5>
  //                         <p className="text-muted">Commissioni</p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">10 Feb 23</h5>
  //                         <p className="text-muted">
  //                           <span className="badge badge-soft-success font-size-12">
  //                             Regolare
  //                           </span>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16">
  //                           <i className="bx bx-wallet"></i> STRIPE - Stripe
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-infinite me-1 text-muted"></i>A
  //                           Stripe
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <h5 className="font-size-16 ">
  //                           Costi Transazione -{" "}
  //                           <a href="project-detail.html">Ordine #144/22</a>
  //                         </h5>
  //                         <p className="text-muted">
  //                           <i className="bx bx-link me-1 text-muted"></i>
  //                           <a href="#" target="_blank">
  //                             {" "}
  //                             Dettagli
  //                           </a>
  //                         </p>
  //                       </td>
  //                       <td>
  //                         <span className="badge bg-success">Completed</span>
  //                       </td>
  //                       <td>
  //                         <div className="dropdown">
  //                           <a
  //                             href="#"
  //                             className="dropdown-toggle card-drop"
  //                             data-bs-toggle="dropdown"
  //                             aria-expanded="false"
  //                           >
  //                             <i className="mdi mdi-dots-horizontal font-size-18"></i>
  //                           </a>
  //                           <div className="dropdown-menu dropdown-menu-end">
  //                             <a
  //                               className="dropdown-item"
  //                               href="transaction-detail.html"
  //                             >
  //                               Dettaglio
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Create auto-invoice
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Link to invoice
  //                             </a>
  //                             <a className="dropdown-item" href="#">
  //                               Aggiungi Nota
  //                             </a>
  //                           </div>
  //                         </div>
  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}
