import React from "react";
import { Label, Row, Col, Input, Container } from "reactstrap";
import clsx from "clsx";

export const ConditionsCardItem = ({ value, onChangeValue, allConditions }) => {
  const onChange = function (value, reset) {
    if (reset) {
      const condition = allConditions.find(
        (x) => x.rowKey === value.conditionId
      );

      value.conditionId = condition.rowKey;
      value.currency = "EUR";
      value.isDefault = null;
      value.discountPercentage = null;

      value.name = condition.name;
      value.status = condition.status;
      value.percentageWorkStart = condition.percentageWorkStart;
      value.percentageWorkEnd = condition.percentageWorkEnd;
      value.percentageWorkInstallments = condition.percentageWorkInstallments;
      value.installmentsCount = condition.installmentsCount;
      value.installmentsFrequency = condition.installmentsFrequency;
      value.paymentGateway = condition.paymentGateway;
    }

    onChangeValue(value);
  };

  return (
    <Container fluid className="document-item">
      <Row className="mb-2 align-items-center">
        <Col xs={12} className="my-1">
          <Input
            type="select"
            placeholder={`Seleziona una condizione`}
            value={value.conditionId}
            onChange={(e) =>
              onChange({ ...value, conditionId: e.target.value }, true)
            }
          >
            {[
              <option value="" className="text-muted" disabled selected>
                Seleziona una condizione
              </option>,
              allConditions.map((x) => {
                return (
                  <option key={x.rowKey} value={x.rowKey}>
                    {x.name}
                  </option>
                );
              }),
            ]}
          </Input>
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col xs={4}>
          <Label className={clsx("form-label-item")}>Valuta</Label>
          <Input
            type="select"
            value={value.currency}
            disabled={!value.conditionId}
            onChange={(e) =>
              onChange({ ...value, currency: e.target.value }, false)
            }
          >
            <option value="EUR">€ - Euro</option>
          </Input>
        </Col>
        <Col xs={4}>
          <Label className={clsx("form-label-item")}>Default</Label>
          <Input
            type="select"
            value={value.isDefault}
            disabled={!value.conditionId}
            onChange={(e) =>
              onChange({ ...value, isDefault: e.target.value }, false)
            }
          >
            <option value="">-- Default --</option>
            <option value="true">Si</option>
            <option value="false">No</option>
          </Input>
        </Col>
        <Col xs={4}>
          <Label className={clsx("form-label-item")}>Sconto %</Label>
          <Input
            type="number"
            placeholder="Sconto %"
            disabled={!value.conditionId}
            value={value.discountPercentage}
            onChange={(e) =>
              onChange({ ...value, discountPercentage: e.target.value }, false)
            }
          ></Input>
        </Col>
      </Row>
    </Container>
  );
};
