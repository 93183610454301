import atecoCodes from "../assets/atecoCodes.json";

export const _BaseUtil = {
    regex_PIVA: /^[0-9]{11}$/,

    copyToClipboard: function(value, callback) {
        console.log("copyToClipboard", value);
        navigator.clipboard.writeText(value).then(callback);
    },

    getImgPath: function(path) {
        if (!path) return "/assets/images/avatar-empty.png";
        return "https://hsapublicdev.blob.core.windows.net/hpublicassets/" + path;
    },

    countBy: function(array, prop, value) {
        return array.filter((obj) => obj[prop] === value).length;
    },

    timeAgo: function(createdOn) {
        const now = new Date();
        const createdDate = new Date(createdOn);

        const timeDifference = now - createdDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return `${seconds} second${seconds === 1 ? "o" : "i"} fa`;
        } else if (minutes < 60) {
            return `${minutes} minut${minutes === 1 ? "o" : "i"} fa`;
        } else if (hours < 24) {
            return `${hours} or${hours === 1 ? "a" : "e"} fa`;
        } else if (days < 30) {
            return `${days} giorn${days === 1 ? "o" : "i"} fa`;
        } else if (months < 12) {
            return `${months} mes${months === 1 ? "e" : "i"} fa`;
        } else {
            return `${years} ann${years === 1 ? "o" : "i"} fa`;
        }
    },

    formatDateToMonthYear: function(inputDate) {
        const date = new Date(inputDate);
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear();

        // Pad the month with a leading zero if it is a single digit
        month = month < 10 ? "0" + month : month;

        return `${month}/${year}`;
    },

    formatDecimals: function(num) {
        // Round the number to two decimal places
        const roundedNum = Math.round(num * 100) / 100;

        // Use the Italian locale for formatting
        let numFormatted = roundedNum.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return numFormatted;
    },

    formatDate: function(dateString, culture) {
        if (!dateString) return "Non definita";

        // Step 1: Convert the string to a JavaScript Date object
        const date = new Date(dateString);

        // Step 2: Format the date based on the culture
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };

        return date.toLocaleDateString(culture, options);
    },

    getLastMonthDate: function(dateString, culture) {
        const date = new Date(dateString);

        // Imposta il mese al prossimo mese
        date.setMonth(date.getMonth() + 1);
        // Imposta il giorno su 0 per tornare all'ultimo giorno del mese corrente
        date.setDate(0);

        // Riutilizza formatDate per restituire la data formattata
        return this.formatDate(date, culture);
    },

    formatCurrency: function(currency, amount, culture) {
        if (!amount && amount !== 0) return "Non disponibile";

        try {
            const formattedAmount = new Intl.NumberFormat(culture, {
                    style: "currency",
                    currency: currency,
                    currencyDisplay: "code",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
                .format(amount)
                .replace(currency, "")
                .trim();
            return `${currency} ${formattedAmount}`;
        } catch (error) {
            console.error("Currency formatting error:", error);
            return `${currency} ${amount}`;
        }
    },

    validatePIva: function(pIva) {
        return this.regex_PIVA.test(pIva);
    },

    getFullAddress: function(address, streetNumber, zipCode, city, country) {
        if (!address) return "Indirizzo sconosciuto";
        let fullAddres = `${address} ${streetNumber || ""}, ${city || ""} ${
      zipCode || ""
    } ${country || "Italia"}`;

        console.log("getFullAddress result: ", fullAddres);
        return fullAddres;
    },

    getCurrency: function(currencyCode) {
        switch (currencyCode) {
            case "EUR":
                return "€";
            default:
                return "";
        }
    },

    getNextAvailableCode: function(array, attribute) {
        // Extract the numeric portion of each code and store it in an array
        const codeNumbers = array.map((obj) => {
            const numericPart = obj[attribute].match(/\d+/);
            return numericPart ? parseInt(numericPart[0]) : 0;
        });

        // Find the maximum numeric value
        const maxCodeNumber = Math.max(...codeNumbers);

        // Generate the next code by incrementing the maximum numeric value
        const nextCodeNumber = maxCodeNumber + 1;

        return nextCodeNumber;
    },

    tagsToList: function(tagsField) {
        return tagsField && tagsField.split ? tagsField.split("|") : [];
    },

    listToTags: function(tagsList) {
        return tagsList && tagsList.join ? tagsList.join("|") : "";
    },

    filterAttributes: function(x, filtro) {
        // Concatena tutti gli attributi del servizio in una stringa e trasforma tutto in minuscolo per una ricerca case-insensitive
        const attrs = Object.values(x).join("").toLowerCase();
        return attrs.includes(filtro.toLowerCase());
    },

    filterList: function(list, searchText) {
        return list.filter((item) => this.filterObject(item, searchText));
    },

    filterObject: function(obj, searchText) {
        if (typeof obj !== "object") {
            // If the current item is not an object, check if it matches the searchText
            return obj.toString().toLowerCase().includes(searchText.toLowerCase());
        }

        if (Array.isArray(obj)) {
            // If the current item is an array, iterate through its elements
            for (const item of obj) {
                if (this.filterObject(item, searchText)) {
                    return true;
                }
            }
        } else {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (typeof value === "object") {
                        // If the property value is an object or an array, recursively call filterObject
                        if (this.filterObject(value, searchText)) {
                            return true;
                        }
                    } else if (
                        value &&
                        value.toString().toLowerCase().includes(searchText.toLowerCase())
                    ) {
                        // If the property value is not an object or an array, check if it matches the searchText
                        return true;
                    }
                }
            }
        }

        return false;
    },

    dateDiffInDays: function(date1, date2) {
        // Parse the input dates if they are not already Date objects
        date1 = new Date(date1);
        date2 = new Date(date2);

        // Calculate the time difference in milliseconds
        const timeDifference = date2 - date1;

        // Convert the time difference to days
        const daysDifference = Math.abs(timeDifference / (1000 * 60 * 60 * 24));

        return (daysDifference + 1).toFixed(0);
    },

    calculateCompletionPercentage: function(startDate, endDate, currentDate) {
        // Parse the input dates if they are not already Date objects
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        currentDate = new Date(currentDate);

        // Calculate the total time range in milliseconds
        const totalRange = endDate - startDate;

        // Calculate the time completed so far in milliseconds
        const timeCompleted = currentDate - startDate;

        // Calculate the completion percentage
        const completionPercentage = (timeCompleted / totalRange) * 100;

        // Ensure the percentage is within the 0-100 range
        return Math.min(100, Math.max(0, completionPercentage));
    },

    getPercentageDifference: function(currentAmount, previousAmount) {
        const percentageDifference = (
            ((currentAmount - previousAmount) / (previousAmount || 1)) *
            100
        ).toFixed(0);
        return percentageDifference > 100 ? 100 : percentageDifference;
    },

    getPercentageDifferenceClass: function(percentage) {
        return percentage < 0 ? "danger" : percentage < 10 ? "warning" : "success";
    },

    getLastMonths: function(count) {
        const months = [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
        ];

        // Get the current date
        const currentDate = new Date();

        // Create an array to store the last three months
        const lastThreeMonths = [];

        // Loop through the last three months and add them to the array
        for (let i = count - 1; i >= 0; i--) {
            // Calculate the month and year for each iteration
            const month = currentDate.getMonth() - i;
            const year = currentDate.getFullYear();

            // Adjust for months crossing into the previous year
            const adjustedMonth = month < 0 ? 12 + month : month;

            // Create an object with the desired format
            const monthObject = {
                value: adjustedMonth + 1, // Adding 1 to convert from zero-based index to 1-based index
                text: `${months[adjustedMonth]} ${year}`,
            };

            // Add the object to the array
            lastThreeMonths.push(monthObject);
        }

        return lastThreeMonths.reverse();
    },

    download: function(filename, strBase64) {
        // Tentativo di inferire il tipo MIME dal'estensione del file
        const mimeTypes = {
            pdf: "application/pdf",
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            png: "image/png",
            gif: "image/gif",
            // Aggiungi altri tipi MIME come necessario
        };

        // Estrae l'estensione del file dal nome del file
        const fileExtension = filename.split(".").pop().toLowerCase();
        // Cerca il tipo MIME basato sull'estensione del file, altrimenti usa un tipo generico
        const mimeType = mimeTypes[fileExtension] || "application/octet-stream";

        // Crea la stringa dei dati corretta includendo il tipo MIME
        const base64Data = `data:${mimeType};base64,${strBase64}`;

        // Usa fetch per convertire i dati Base64 in un Blob
        fetch(base64Data)
            .then((res) => res.blob())
            .then((blob) => {
                // Crea un URL dal Blob
                const url = window.URL.createObjectURL(blob);
                // Crea un elemento <a> temporaneo per il download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                // Simula un click sul link per avviare il download
                link.click();
                // Rimuove il link dal documento e rilascia l'URL dell'oggetto Blob
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    },

    downloadPdf: function(strBase64) {
        const base64Data = `data:application/pdf;base64,${strBase64}`;

        // Usa fetch per convertire i dati Base64 in un Blob
        fetch(base64Data)
            .then((res) => res.blob())
            .then((blob) => {
                // Crea un URL dal Blob
                const url = window.URL.createObjectURL(blob);
                // Crea un elemento <a> temporaneo per il download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "contratto.pdf");
                document.body.appendChild(link);
                // Simula un click sul link per avviare il download
                link.click();
                // Rimuove il link dal documento e rilascia l'URL dell'oggetto Blob
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    },

    getAteco: function(code) {
        if (!code) return null;
        return atecoCodes.find((x) => x.value === code) || {};
    },

    formatAtecoDescription: function(code) {
        if (!code) return null;
        const item = this.getAteco(code);
        return (!!item && {
            value: item.value,
            label: `${item.value} - ${item.label}`,
        });
    },

    isValidUuid: function(value) {
        const regex = /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/;
        return regex.test(value);
    },
};