import React from "react";
import { Row, Col, Container, Input } from "reactstrap";

export const LinksCardItem = ({ value, onChangeValue }) => {
  return (
    <Container fluid>
      <Row className="mb-2 align-items-center">
        <Col xs={12} sm={6} className="my-1">
          <Input
            type="text"
            placeholder="Descrizione"
            value={value.description}
            onChange={(e) =>
              onChangeValue({ ...value, description: e.target.value })
            }
          ></Input>
        </Col>
        <Col xs={12} sm={6} className="my-1">
          <Input
            type="text"
            placeholder="https://www.google.com"
            value={value.url}
            onChange={(e) => onChangeValue({ ...value, url: e.target.value })}
          ></Input>
        </Col>
      </Row>
    </Container>
  );
};
