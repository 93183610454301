import { Link } from "react-router-dom";

export const BusinessCellHandler = {
  get: function (business) {
    return {
      link: `/business/${business.rowKey}/profile`,
      businessName: business.businessName,
      vatNumber: business.vatNumber,
      taxRegime: business.taxRegime,
      atecoCodes: business.atecoCodes,
      businessTypeDescription: business.businessTypeDescription,
    };
  },
};

const BusinessCell = ({ cell }) => {
  return (
    <>
      <h5 className="text-truncate font-size-14">
        <Link to={cell.link} className="text-dark">
          {cell.businessName}
        </Link>
      </h5>

      {!!cell.businessTypeDescription && (
        <div className="text-truncate">{cell.businessTypeDescription}</div>
      )}

      <div className="text-truncate">Regime Fiscale: {cell.taxRegime}</div>
      <div className="text-truncate">Codici Ateco: {cell.atecoCodes}</div>
    </>
  );
};

export default BusinessCell;
