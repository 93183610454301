import React, { useEffect } from "react";

import { Row, Col } from "reactstrap";

import RegisterSummary from "./RegisterSummary";
import PecForm from "./PecForm";
import OtpValidation from "./OtpValidation";

const RegisterStep2 = ({
  user,
  business,
  back,
  onUpdateBusiness,
  validatePecAsync,
  resendOtpAsync,
}) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <RegisterSummary user={user} business={business}></RegisterSummary>
        </div>
      </div>
      <Row>
        <Col sm={6}>
          <PecForm
            business={business}
            onUpdateBusiness={onUpdateBusiness}
          ></PecForm>
        </Col>
        <Col sm={6}>
          <OtpValidation
            business={business}
            back={back}
            validatePecAsync={validatePecAsync}
            resendOtpAsync={resendOtpAsync}
          ></OtpValidation>
        </Col>
      </Row>
    </>
  );
};

export default RegisterStep2;
