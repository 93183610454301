export const DocumentsCellHandler = {
  get: function (estimates, orders, invoices, subscriptions, payments) {
    let data = {
      estimates: {},
      orders: {},
      invoices: {},
      subscriptions: {},
      payments: {},
    };
    data.estimates.count = estimates.length;
    data.orders.count = orders.length;
    data.invoices.paidCount = invoices.filter(
      (x) => x.invoice.eInvoiceCreated
    ).length;
    data.invoices.notPaidCount = invoices.filter(
      (x) => !x.invoice.eInvoiceCreated
    ).length;
    data.subscriptions.count = subscriptions.length;
    data.payments.count = payments.length;
    return data;
  },
};

const DocumentsCell = ({ cell }) => {
  return (
    <>
      <h5 className="font-size-14 ">
        Nr. Fatture Pagate: {cell.invoices.paidCount}
      </h5>
      <div className="text-truncate">
        Nr. Fatture non Pagate: {cell.invoices.notPaidCount}
      </div>
      <div className="text-truncate">Nr. Ordini: {cell.orders.count}</div>
      {/* <div className="text-truncate">
        Nr. Preventivi: {cell.estimates.count}
      </div>
      <div className="text-truncate">
        Nr. Sottoscrizioni: {cell.subscriptions.count}
      </div>
      <div className="text-truncate">Nr. Incassi: {cell.payments.count}</div> */}
    </>
  );
};

export default DocumentsCell;
