import { api } from "../configs/axiosConfigs";

export const ConfigurationsApi = {
  apiName: "ConfigurationsApi",
  baseUrl: "/webapp/businesses",

  getVatTypes: async function (businessId) {
    const fnName = "getVatTypes";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/configurations/vatTypes`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
