import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import "../assets/css/reactTags.css";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";

import { BusinessContext } from "../contexts/AppContexts";

import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ServicesUtil } from "../utils/ServicesUtil";

import { ServicesOptions } from "../options/ImageOptions";

import { ConfirmModal } from "../components/ConfirmModal";
import { SelectVatType } from "../components/SelectVatType";
import AtecoCodeSelect from "../components/Select/AtecoCodesSelect";

const suggestions = [
  {
    id: "Consulenza",
    text: "Consulenza",
  },
  {
    id: "Meeting",
    text: "Meeting",
  },
  {
    id: "Sviluppo",
    text: "Sviluppo",
  },
  {
    id: "Manutenzione",
    text: "Manutenzione",
  },
  {
    id: "Supporto",
    text: "Supporto",
  },
];

const KeyCodes = {
  comma: 188,
  enter: 13,
  pipe: 124,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.pipe];

export default function Service() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);

  // Stato per la gestione dei codici ATECO
  const [atecoCodes, setAtecoCodes] = useState(
    business.atecoCodes?.split("|") || []
  );

  const handleTagDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleTagAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleTagDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const mapTag = (tag) => {
    return {
      id: tag,
      text: tag,
    };
  };

  const handleOnLoad = async () => {
    let service = await ServicesUtil.getTemplate(1, businessId);

    if (id) {
      service = (await ServicesApi_Cache.get(businessId)).find(
        (x) => x.rowKey === id
      );
    }

    let currTags = service.tags || [];
    if (currTags.length) {
      let tagsList = _BaseUtil.tagsToList(currTags).map((x) => mapTag(x));
      setTags(tagsList);
    }

    formik.setValues(service);
  };

  useEffect(() => {
    handleOnLoad();
  }, [id]);

  let formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
      name: Yup.string().required("Il campo Nome obbligatorio"),
      description: Yup.string().required("Il campo Descrizione è obbligatorio"),
      defaultAmount: Yup.number()
        .positive()
        .required("Il campo Importo è obbligatorio"),
      atecoCode: Yup.string().required("Il campo Codice ATECO è obbligatorio"),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    try {
      const errors = await formik.validateForm();

      if (Object.keys(errors).length) {
        // Perform your desired action for invalid form
        console.log("Form is not invalid", errors);
        return;
      }

      let obj = formik.values;
      obj.eTag = undefined;
      obj.createdOn = undefined;

      // Perform your desired action for valid form
      console.log("Form is valid", obj);

      obj.tags = _BaseUtil.listToTags(tags.map((x) => x.text));

      if (!obj.rowKey) {
        obj = await ServicesApi_Cache.create(businessId, obj);
        if (obj) formik.setValues(obj);
        navigate("/service/" + obj.rowKey);
        return;
      }

      obj = await ServicesApi_Cache.update(businessId, obj.rowKey, obj);

      if (obj) formik.setValues(obj);
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  // Custom component for rendering options with images
  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={value}
        alt={label}
        style={{ width: "30px", height: "30px", marginRight: "10px" }}
      />
      <span>{label}</span>
    </div>
  );

  const onDeactivate = async () => {
    await ServicesApi_Cache.delete(businessId, id);
    navigate("/services");
  };

  return (
    <>
      <div className="page-content vh-100 overflow-auto">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {formik.values.rowKey ? "Aggiorna" : "Crea nuovo"} servizio
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/services">Servizi</Link>
                    </li>
                    <li className="breadcrumb-item active">Nuovo</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div id="service-info">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="my-1">
                        <Col sm={3}>
                          <img
                            src="/assets/images/page-service.png"
                            alt=""
                            className="img-fluid"
                            loading="lazy"
                          />
                          <div className="position-absolute top-0 start-10 translate-middle fs-2 m-2">
                            <i className="bx bxs-add-to-queue fs-18 text-primary"></i>
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-1 d-none">
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="code">Codice identificativo</Label>
                            <Input
                              type="text"
                              name="code"
                              className="form-control"
                              id="code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.code || ""}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-3 my-1">
                        <Col sm="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="name">Nome</Label>
                            <Input
                              type="text"
                              name="name"
                              className="form-control"
                              id="name"
                              placeholder="Nome del servizio"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name || ""}
                              invalid={
                                formik.touched.name && formik.errors.name
                              }
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <FormFeedback type="invalid">
                                {formik.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="description">Descrizione</Label>
                            <Input
                              type="textarea"
                              name="description"
                              className="form-control"
                              id="description"
                              placeholder="Descrizione del servizio"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description || ""}
                              invalid={
                                formik.touched.description &&
                                formik.errors.description
                              }
                            />
                            {formik.touched.description &&
                            formik.errors.description ? (
                              <FormFeedback type="invalid">
                                {formik.errors.description}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="unitCode">Unità di misura</Label>
                            <select
                              className="form-control form-select"
                              name="unitCode"
                              id="unitCode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.unitCode || ""}
                              invalid={
                                formik.touched.unitCode &&
                                formik.errors.unitCode
                              }
                            >
                              <option value="">--Seleziona--</option>
                              <option value="N">Numero</option>
                              <option value="PZ">Pezzi</option>
                              <option value="HR">Ore</option>
                              <option value="GG">Giorni</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs="6" sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="defaultCurrency">Valuta</Label>
                            <select
                              className="form-control form-select"
                              name="defaultCurrency"
                              id="defaultCurrency"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.defaultCurrency || ""}
                              invalid={
                                formik.touched.defaultCurrency &&
                                formik.errors.defaultCurrency
                              }
                            >
                              <option defaultValue="EUR">EUR</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col xs="6" sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="defaultAmount">
                              Importo unitario
                            </Label>
                            <Input
                              type="number"
                              name="defaultAmount"
                              className="form-control"
                              id="defaultAmount"
                              placeholder="Esempio: 500"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.defaultAmount || ""}
                              invalid={
                                formik.touched.defaultAmount &&
                                formik.errors.defaultAmount
                              }
                            />
                            {formik.touched.defaultAmount &&
                            formik.errors.defaultAmount ? (
                              <FormFeedback type="invalid">
                                {formik.errors.defaultAmount}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="vatType">Aliquota IVA</Label>
                            <SelectVatType
                              currentId={formik.values.vatTypeId}
                              onChange={(selectedOptionId) => {
                                // Handle the selected image
                                formik.setFieldValue(
                                  "vatTypeId",
                                  selectedOptionId
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="imageSelect">
                              Scegli un'immagine
                            </Label>
                            <Select
                              placeholder="Scegli l'immagine del tuo servizio"
                              options={ServicesOptions.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )}
                              formatOptionLabel={formatOptionLabel}
                              onChange={(selectedOption) => {
                                // Handle the selected image
                                formik.setFieldValue(
                                  "imagePath",
                                  selectedOption.value
                                );
                              }}
                              value={ServicesOptions.find(
                                (option) =>
                                  option.value === formik.values.imagePath
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="atecoCode">Codice ATECO</Label>
                            <AtecoCodeSelect
                              id="atecoCode"
                              name="atecoCode"
                              values={atecoCodes}
                              selectedValue={formik.values.atecoCode || ""}
                              onBlur={formik.handleBlur}
                              onChange={(option) =>
                                formik.setFieldValue("atecoCode", option.value)
                              }
                              invalid={
                                formik.touched.atecoCode &&
                                formik.errors.atecoCode
                              }
                            />
                            {formik.touched.atecoCode &&
                              !!formik.errors.atecoCode && (
                                <FormFeedback type="invalid">
                                  {formik.errors.atecoCode}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="tags">Tags</Label>
                            <div>
                              <ReactTags
                                placeholder="Usare Invio o , per inserire un nuovo TAG"
                                classNames={{
                                  tags: "ReactTags__tags",
                                  tagInput: "",
                                  tagInputField: "form-control",
                                  selected: "mt-1",
                                  tag: "fs-6 badge badge-soft-primary me-1 mt-1",
                                  remove:
                                    "ReactTags__selected ReactTags__remove",
                                  suggestions: "ReactTags__suggestions",
                                  activeSuggestion:
                                    "ReactTags__activeSuggestion",
                                  editTagInput: "ReactTags__editTagInput",
                                  editTagInputField: "ReactTags__editTagInput",
                                  clearAll: "ReactTags__clearAll",
                                }}
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleTagDelete}
                                handleAddition={handleTagAddition}
                                handleDrag={handleTagDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="top"
                                autocomplete
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-end">
                        <Col xs={12} className="d-flex justify-content-end">
                          <div className="text-end mt-3">
                            {!!formik.values?.rowKey && (
                              <>
                                <Link
                                  to="/services"
                                  className={`btn btn-success waves-effect btn-label waves-light m-2`}
                                >
                                  <i className="bx bx-arrow-back label-icon"></i>{" "}
                                  Torna ai servizi
                                </Link>
                              </>
                            )}
                            <button
                              type="submit"
                              className={`btn btn-primary w-md waves-effect btn-label waves-light m-2`}
                            >
                              <i className="bx bx-user label-icon"></i>{" "}
                              {formik.values.rowKey ? "Aggiorna" : "Crea"}
                            </button>
                          </div>
                        </Col>
                        {!!formik.values?.rowKey && (
                          <Col
                            xs={12}
                            className="d-flex justify-content-end mt-5"
                          >
                            <>
                              <button
                                type="button"
                                className="btn btn-danger waves-effect btn-label waves-light m-2"
                                data-bs-toggle="modal"
                                data-bs-target={`.confirm-modal`}
                              >
                                <i className="bx bx-file label-icon "></i>{" "}
                                Disattiva
                              </button>
                            </>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        title={`Conferma disattivazione!`}
        text={`Attenzione, sei sicuro di voler procedere?`}
        confirmText={`Disattiva`}
        confirmClass={`btn-danger`}
        cancelText={`Annulla`}
        onConfirm={onDeactivate}
      />
    </>
  );
}
