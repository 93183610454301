import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const ConditionsApi = {
  apiName: "ConditionsApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/conditions`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const response = await api.post(
      `${this.baseUrl}/${businessId}/conditions`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  createDefaults: async function (businessId) {
    const fnName = "createDefaults";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const response = await api.post(
      `${this.baseUrl}/${businessId}/conditions/defaults`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },
};
