import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const MovementsAttachmentsApi = {
  apiName: "MovementsAttachmentsApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    let options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/movements/attachments`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data || [];
  },

  download: async function (
    businessId,
    movementId,
    attachmentId,
    nocache,
    noloader
  ) {
    const fnName = "download";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      movementId,
      attachmentId,
      nocache,
      noloader
    );

    let options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/movements/${movementId}/attachments/${attachmentId}`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  create: async function (businessId, recordId, filename, file) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      filename
    );

    const config = {
      headers: {
        "Content-Type": file.type,
        "x-filename": filename,
      },
    };

    const response = await api.post(
      `${this.baseUrl}/${businessId}/movements/${recordId}/attachments`,
      file,
      config
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  delete: async function (businessId, recordId, attachmentId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      recordId
    );

    let response = await api.delete(
      `${this.baseUrl}/${businessId}/movements/${recordId}/attachments/${attachmentId}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);
  },
};
