import { AlertsApi } from "../../AlertsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "alerts";
export const AlertsApi_Cache = {
  apiName: "AlertsApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await AlertsApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      //Pay attention to change this value.
      //The setInterval that call this function is not waiting if the server returns 429 Too Many Requests error even if axios is configured correctly.
      const secondsDelay = currentDate.plus({ seconds: 30 });
      $expiresOn.set(secondsDelay);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },

  deactivate: async function (businessId, alertId, noloader) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      alertId
    );

    const { $data } = atomCache(businessId, KEY, []);

    await AlertsApi.deactivate(businessId, alertId, noloader);

    const cacheData = $data.get();

    const currentValue = cacheData.find((item) => item.rowKey === alertId);
    const others = cacheData.filter((item) => item.rowKey !== alertId);

    currentValue.status = -1; //deactivate alert

    $data.set([...others, currentValue]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response`);
  },
};
