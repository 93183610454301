import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const TransactionsApi = {
    apiName: "TransactionsApi",
    baseUrl: "/webapp/businesses",

    get: async function(businessId, nocache, noloader) {
        const fnName = "get";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            nocache
        );

        let options = _BaseApi.getHeaders(nocache, noloader);

        const response = await api.get(
            `${this.baseUrl}/${businessId}/transactions`,
            options
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data || [];
    },

    withdraw: async function(businessId, transactionId) {
        const fnName = "withdraw";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            transactionId
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/transactions/${transactionId}/withdraw`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },

    update: async function(businessId, transactionId, payload) {
        const fnName = "update";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            transactionId,
            payload
        );

        const response = await api.put(
            `${this.baseUrl}/${businessId}/transactions/${transactionId}`, {
                ...payload,
                eTag: undefined,
            }
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);

        return response && response.data;
    },

    delete: async function(businessId, transactionId) {
        const fnName = "delete";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            transactionId
        );

        const response = await api.delete(
            `${this.baseUrl}/${businessId}/transactions/${transactionId}`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },
};