import { _BaseUtil } from "../../../utils/_BaseUtil";
import { FaCheck, FaExclamationTriangle, FaTimes } from "react-icons/fa";

export const PredictionsCellHandler = {
  get: function (expenses, revenues, redditivityIndex) {
    let sumExpenses = expenses
      .map((x) => Math.abs(x.amount))
      .reduce((total, num) => Number(total) + Number(num), 0);

    return {
      expenses: sumExpenses,
      revenues,
      redditivityIndex,
    };
  },
};

const PredictionsCell = ({ cell }) => {
  const getRevenueIcon = (revenues) => {
    if (revenues < 75000) {
      return <FaCheck style={{ color: "green" }} />;
    }
    if (revenues >= 75000 && revenues <= 85000) {
      return <FaExclamationTriangle style={{ color: "yellow" }} />;
    }
    return <FaTimes style={{ color: "red" }} />;
  };

  const getExpenseIcon = (expenses, revenues, redditivityIndex) => {
    const maxAllowedExpenses = (1 - redditivityIndex) * revenues;

    if (expenses <= maxAllowedExpenses) {
      return <FaCheck style={{ color: "green" }} />;
    }
    if (expenses <= maxAllowedExpenses * 1.2) {
      return <FaExclamationTriangle style={{ color: "yellow" }} />;
    }
    return <FaTimes style={{ color: "red" }} />;
  };

  return (
    <>
      <h5 className="font-size-14 ">
        Fatturato: {_BaseUtil.formatCurrency("EUR", cell.revenues, "it-IT")}{" "}
        {getRevenueIcon(cell.revenues)}
      </h5>
      <h5 className="font-size-14 ">
        Spese professionali:{" "}
        {_BaseUtil.formatCurrency("EUR", cell.expenses, "it-IT")}{" "}
        {getExpenseIcon(cell.expenses, cell.revenues, cell.redditivityIndex)}
      </h5>
    </>
  );
};

export default PredictionsCell;
