import { EstimatesApi } from "../../EstimatesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "estimates";

export const EstimatesApi_Cache = {
  apiName: "EstimatesApi_Cache",

  acceptAsync: async function (businessId, estimateId, linkId, payload) {
    const fnName = "acceptAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      linkId,
      payload
    );

    const result = await EstimatesApi.acceptAsync(
      businessId,
      estimateId,
      linkId,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, result);

    return result;
  },

  revise: async function (businessId, estimateId) {
    const fnName = "revise";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const { $data } = atomCache(businessId, KEY, []);

    const result = await EstimatesApi.revise(businessId, estimateId);

    if (result) {
      let cacheData = $data.get();
      cacheData = cacheData.filter((item) => item.estimate);
      const others = cacheData.filter(
        (item) => item.estimate.rowKey !== estimateId
      );
      $data.set([...others, result]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, result);

    return result;
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await EstimatesApi.create(businessId, payload);
    $data.set([...$data.get(), newData]); //adding object to the existing array

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  createPublicLinkAsync: async function (businessId, estimateId) {
    const fnName = "createPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const newData = await EstimatesApi.createPublicLinkAsync(
      businessId,
      estimateId
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await EstimatesApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  update: async function (businessId, estimateId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    payload = await EstimatesApi.update(businessId, estimateId, payload);

    if (payload) {
      let cacheData = $data.get();
      cacheData = cacheData.filter((item) => item.estimate);
      const others = cacheData.filter(
        (item) => item.estimate.rowKey !== estimateId
      );
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },

  delete: async function (businessId, estimateId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      estimateId
    );

    const { $data } = atomCache(businessId, KEY, []);

    await EstimatesApi.delete(businessId, estimateId);

    let cacheData = $data.get();
    cacheData = cacheData.filter((item) => item.estimate);
    const others = cacheData.filter(
      (item) => item.estimate.rowKey !== estimateId
    );
    $data.set([...others]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName}`);
  },
};
