import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { PreInvoicesApi_Cache } from "../api/decorators/cache/PreInvoicesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { OrdersUtil } from "../utils/OrdersUtil";
import { InvoicesUtil } from "../utils/InvoicesUtil";
import { Col, Row } from "reactstrap";

export default function PreInvoices() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  const loadTableRecords = async (nocache) => {
    if (nocache) atomClearCache();

    let pContacts = ContactsApi_Cache.get(businessId);
    let pPreInvoices = PreInvoicesApi_Cache.get(businessId);
    let pOrders = OrdersApi_Cache.get(businessId);

    let promises = await Promise.all([pContacts, pPreInvoices, pOrders]);

    let contacts = promises[0] || [];
    let preInvoicesModels = promises[1] || [];
    let ordersModels = promises[2] || [];

    //clean orders list
    preInvoicesModels = preInvoicesModels.filter((x) => x?.preInvoice);

    let result = preInvoicesModels.map((x) =>
      mapTableRecords(x, contacts, ordersModels)
    );

    setTableRecords(result);
  };

  const mapTableRecords = (preInvoiceModel, contacts, ordersModels) => {
    console.log("mapping preInvoice: ", preInvoiceModel);

    let contact = contacts.find(
      (x) => x.rowKey === preInvoiceModel.preInvoice.customerId
    );

    let result = {
      preInvoiceId: preInvoiceModel.preInvoice.rowKey,
      preInvoiceCode: preInvoiceModel.preInvoice.code,
      contactId: preInvoiceModel.preInvoice.customerId,
      contactName: ContactsUtil.getFullName(contact),
      contactType: ContactsUtil.getTypeDescription(contact?.type),
      createdOn: preInvoiceModel.preInvoice.createdOn,
      type: preInvoiceModel.preInvoice.type,
      currency: preInvoiceModel.preInvoice.currency,
      amount: preInvoiceModel.preInvoice.amount,
    };

    return result;
  };

  useEffect(() => {
    loadTableRecords();
  }, []);

  const filterRecords = () => {
    let result = _BaseUtil.filterList(tableRecords, filter);

    const sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });

    setFilteredRecords(sortedItems);
  };

  useEffect(() => {
    filterRecords();
  }, [tableRecords, filter]);

  return (
    filteredRecords && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">ProForma</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/preInvoices">ProForma</a>
                      </li>
                      <li className="breadcrumb-item active">Lista ProForma</li>
                    </ol>
                  </div>
                </div>
              </Col>

              <div className={`card d-none d-sm-block`}>
                <div className="card-body border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">ProForma</h5>
                    <input
                      type="text"
                      className="form-control mx-4"
                      id="searchInput"
                      placeholder="Filtra ..."
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="flex-shrink-0">
                      {/* <Link to="/preInvoice" className="btn btn-primary">
                        Nuovo ProForma
                      </Link> */}
                      <Link
                        onClick={() => loadTableRecords(true)}
                        className="btn btn-light mx-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`card d-sm-none`}>
                <div className="card-body border-bottom">
                  <div className="d-flex align-items-center">
                    <div className="row">
                      <div className="col-6 my-auto">
                        <h5 className="mb-0 card-title flex-grow-1">
                          ProForma
                        </h5>
                      </div>
                      {/* <div className="col-6 my-auto">
                        <div className="text-end">
                          <Link to="/preInvoice" className="btn btn-primary">
                            Nuovo ProForma
                          </Link>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control my-4"
                          id="searchInput"
                          placeholder="Filtra ..."
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive tableReflow">
                  <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">#</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Data</th>
                        <th scope="col">Importo</th>
                        <th scope="col">Tipologia</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredRecords.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                                <Link
                                  to={`/preInvoices/${item.preInvoiceId}/details`}
                                  className="text-dark"
                                >
                                  <span className="text-primary font-size-22">
                                    <img
                                      src={
                                        item.imagePath ||
                                        "/assets/images/services/wired-outline-245-edit-document.gif"
                                      }
                                      alt={item.name || "Pro Forma"}
                                      className="mw-100"
                                    />
                                  </span>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/preInvoices/${item.preInvoiceId}/details`}
                                  className="text-dark"
                                >
                                  <span>
                                    {item.preInvoiceCode ??
                                      "ProForma senza codice"}
                                  </span>
                                </Link>
                              </h5>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/contact/${item.contactId}`}
                                  style={{ color: "var(--bs-link-color)" }}
                                >
                                  {item.contactName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0 text-wrap">
                                {item.contactType}
                              </p>
                            </td>
                            <td>
                              <div className="row">
                                <div className="col-6">
                                  <h5 className="font-size-14">
                                    {!!item.createdOn &&
                                      _BaseUtil.formatDate(
                                        item.createdOn,
                                        "it-IT"
                                      )}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td className="text-lg-end">
                              <h5 className="text-truncate font-size-14">
                                {_BaseUtil.formatCurrency(
                                  item.currency,
                                  item.amount,
                                  "it-IT"
                                )}
                              </h5>
                            </td>
                            <td style={{ width: "250px" }}>
                              <span
                                className={`fs-5 badge ${InvoicesUtil.getTypeBadge(
                                  item.type
                                )}`}
                              >
                                {InvoicesUtil.getTypeDescription(item.type)}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </>
    )
  );
}
