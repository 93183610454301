import React from "react";
import { CardBody } from "reactstrap";
import PaymentCardActions from "./PaymentCardActions";
import PaymentCardInfo from "./PaymentCardInfo";
import PaymentCardStatus from "./PaymentCardStatus";
import Timeline from "../../Cards/Timeline";
import PaymentCardHeader from "./PaymentCardHeader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PaymentCardBody = ({
  payment,
  events,
  onCollect,
  onReminder,
  onCancel,
  className,
}) => {
  const navigate = useNavigate();

  const [renderTimeline, setRenderTimeline] = useState(false);

  const handleClickEdit = () => {
    navigate(`/payment/${payment.id}/details`);
  };

  const handleClickPlus = () => {
    setRenderTimeline(!renderTimeline);
  };

  return (
    <CardBody className={className}>
      <PaymentCardHeader
        payment={payment}
        onClickEdit={handleClickEdit}
        onClickPlus={handleClickPlus}
        className={`mb-2`}
      />
      <PaymentCardInfo payment={payment} />
      <PaymentCardStatus payment={payment} />
      {/* <PaymentCardActions
        payment={payment}
        onCollect={onCollect}
        onReminder={onReminder}
        onCancel={onCancel}
      /> */}
      {!!renderTimeline && (
        <Timeline key={`tm-${payment.id}`} events={events} />
      )}
    </CardBody>
  );
};

export default PaymentCardBody;
