import { useEffect, useState } from "react";
import { Container, Row, Col, Input, Label } from "reactstrap";
import { EstimatesUtil } from "../utils/EstimatesUtil";
import { _BaseUtil } from "../utils/_BaseUtil";

import { SelectVatType } from "./SelectVatType";
import clsx from "clsx";

export const ServicesCardItem = ({
  value,
  onChangeValue,
  services,
  isPlan,
  disabled,
}) => {
  const [startDates, setStartDates] = useState([]);

  const getNextElevenMonths = (firstDate) => {
    const date = new Date(firstDate);
    let results = [];

    for (let i = 1; i <= 11; i++) {
      // Increment the month
      date.setMonth(date.getMonth() + 1);

      // Set the day to the first of the month
      date.setDate(1);

      // Add the new date to the results array
      results.push(new Date(date)); // Cloning the date object to avoid reference issues
    }

    return results;
  };

  const calculateStartDates = () => {
    let firstDate = EstimatesUtil.getNextMonthFirstDayDate();
    let nextElevenDates = getNextElevenMonths(firstDate);
    let result = [firstDate, ...nextElevenDates];
    setStartDates(result);
  };

  useEffect(() => {
    calculateStartDates();
  }, []);

  const onChange = function (value, reset) {
    const service = services.find((x) => x.rowKey === value.serviceId);

    value.quantity = value.quantity ? parseFloat(value.quantity) : null;
    value.amount = value.amount ? parseFloat(value.amount) : null;
    value.currencyType = 1;
    value.currency = "EUR";

    if (isPlan) {
      value.startDate = value.startDate ? new Date(value.startDate) : null;
    }

    if (reset) {
      value = {
        ...value,
        amount: service.defaultAmount,
        quantity: service.defaultQuantity || 1,
        vatTypeId: service.vatTypeId,
        serviceName: service.name,
        serviceDescription: service.description,
        serviceType: service.type,
      };

      if (isPlan) {
        value.startDate = EstimatesUtil.getNextMonthFirstDayDate();
        value.billingIntervalsCount = service.billingIntervalsCount;
        value.billingInterval = service.billingInterval;
        value.billingIntervalType = service.billingIntervalType;
        value.automaticRenew = service.automaticRenew;
        value.cancellationDays = service.cancellationDays;
      }
    }

    onChangeValue(value);
  };

  return (
    <Container fluid className="document-item">
      <Row className="mb-2 align-items-center">
        <Col md={4} className="my-1">
          <Input
            type="select"
            placeholder={isPlan ? "Piano" : "Servizio"}
            value={value.serviceId}
            onChange={(e) =>
              onChange({ ...value, serviceId: e.target.value }, true)
            }
          >
            {[
              <option value="" className="text-muted" disabled selected>
                Scegli un {isPlan ? "piano" : "servizio"}
              </option>,
              services.map((x) => {
                return (
                  <option key={x.rowKey} value={x.rowKey}>
                    {x.name}
                  </option>
                );
              }),
            ]}
          </Input>
        </Col>
        <Col md={8} className="my-1">
          <Input
            type="textarea"
            placeholder="Descrizione"
            disabled={!value.serviceId}
            value={value.serviceDescription ?? ""}
            onChange={(e) =>
              onChange({ ...value, serviceDescription: e.target.value }, false)
            }
          ></Input>
        </Col>
        <Col xs={6} xl={3} className="my-1">
          <Label
            className={clsx("d-block", "form-label-item", {
              "d-none": !value.serviceId,
            })}
          >
            Quantità
          </Label>
          <Input
            type="number"
            placeholder="Quantità"
            disabled={!value.serviceId}
            min={0}
            value={value.quantity ?? ""}
            onChange={(e) =>
              onChange({ ...value, quantity: e.target.value }, false)
            }
          ></Input>
        </Col>
        <Col xs={6} xl={3} className="my-1">
          <Label
            className={clsx("d-block", "form-label-item", {
              "d-none": !value.serviceId,
            })}
          >
            Prezzo unitario
          </Label>
          <Input
            type="number"
            placeholder="Prezzo unitario"
            disabled={!value.serviceId}
            value={value.amount}
            onChange={(e) =>
              onChange({ ...value, amount: e.target.value }, false)
            }
          ></Input>
        </Col>
        <Col xs={6} xl={3} className="my-1">
          <Label
            className={clsx("d-block", "form-label-item", {
              "d-none": !value.serviceId,
            })}
          >
            Valuta
          </Label>
          <Input
            type="select"
            placeholder="Valuta"
            disabled={true}
            value={`${value.currency}`}
            onChange={(e) =>
              onChange({ ...value, currency: e.target.value }, false)
            }
          >
            <option value="EUR">Euro</option>
          </Input>
        </Col>
        <Col xs={6} xl={3} className="my-1">
          <Label
            className={clsx("d-block", "form-label-item", {
              "d-none": !value.serviceId,
            })}
          >
            Aliquota IVA
          </Label>
          <SelectVatType
            currentId={value.vatTypeId}
            onChange={(selectedOptionId) =>
              onChange({ ...value, vatTypeId: selectedOptionId }, false)
            }
            disabled={disabled}
          />
        </Col>
      </Row>
      {isPlan && (
        <Row className="mb-2 align-items-center">
          <Col sm={6} xl={3} className="my-1">
            <Label
              className={clsx("d-block", "form-label-item", {
                "d-none": !value.serviceId,
              })}
            >
              Data inizio del piano
            </Label>
            <Input
              type="select"
              placeholder="Inizio"
              disabled={!value.serviceId}
              value={value.startDate}
              onChange={(e) =>
                onChange({ ...value, startDate: e.target.value }, false)
              }
            >
              {[
                <option value="" className="text-muted" disabled selected>
                  Inizio piano
                </option>,
                startDates.map((x) => {
                  return (
                    <option key={x} value={x}>
                      {_BaseUtil.formatDateToMonthYear(x)}
                    </option>
                  );
                }),
              ]}
            </Input>
          </Col>
          <Col sm={6} xl={3} className="my-1">
            <Label
              className={clsx("d-block", "form-label-item", {
                "d-none": !value.serviceId,
              })}
            >
              Numero rate totali dovute
            </Label>
            <Input
              type="number"
              placeholder="Numero rate"
              min={0}
              disabled={!value.serviceId}
              value={value.billingIntervalsCount ?? ""}
              onChange={(e) =>
                onChange(
                  { ...value, billingIntervalsCount: e.target.value },
                  false
                )
              }
            ></Input>
          </Col>
          <Col sm={6} xl={3} className="my-1">
            <Label
              className={clsx("d-block", "form-label-item", {
                "d-none": !value.serviceId,
              })}
            >
              Giorni disdetta
            </Label>
            <Input
              type="number"
              placeholder="Giorni disdetta"
              min={0}
              disabled={!value.serviceId}
              value={value.cancellationDays}
              onChange={(e) =>
                onChange({ ...value, cancellationDays: e.target.value }, false)
              }
            ></Input>
          </Col>
          <Col sm={6} xl={3} className="my-1">
            <Label>Rinnovo automatico </Label>{" "}
            <Input
              type="checkbox"
              placeholder="Rinnovo automatico"
              disabled={!value.serviceId}
              checked={!!value.automaticRenew}
              onChange={(e) =>
                onChange(
                  { ...value, automaticRenew: !value.automaticRenew },
                  false
                )
              }
            ></Input>
          </Col>
        </Row>
      )}
    </Container>
  );
};
