import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import "../assets/css/reactTags.css";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { MovementsTransactionsApi_Cache } from "../api/decorators/cache/MovementsTransactionsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { PaymentDetailsCard } from "../components/PaymentDetailsCard";
import { CardTableMovements } from "../components/CardTableMovements";
import { PageHeader } from "../components/PageHeader";

export default function PaymentDetails() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [transaction, setTransaction] = useState(null);
  const [contact, setContact] = useState(null);
  const [estimateModel, setEstimateModel] = useState(null);
  const [orderModel, setOrderModel] = useState(null);
  const [invoiceModel, setInvoiceModel] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [movementsTransaction, setMovementsTransaction] = useState([]);
  const [movements, setMovements] = useState([]);

  useEffect(() => {
    handleOnLoad();
  }, [businessId, id]);

  useEffect(() => {
    if (!transaction) return;
    const transactionId = transaction.rowKey;
    const contactId = transaction.contactId;
    const estimateId = transaction.estimateId;
    const orderId = transaction.orderId;
    const invoiceId = transaction.invoiceId;
    const subscriptionId = transaction.subscriptionId;

    const fetchContact = async () => {
      setContact(
        (await ContactsApi_Cache.get(businessId)).find(
          (x) => x.rowKey === contactId
        )
      );
    };

    const fetchEstimate = async () => {
      setEstimateModel(
        (await EstimatesApi_Cache.get(businessId)).find(
          (x) => x.estimate.rowKey === estimateId
        )
      );
    };

    const fetchOrder = async () => {
      setOrderModel(
        (await OrdersApi_Cache.get(businessId)).find(
          (x) => x.order.rowKey === orderId
        )
      );
    };

    const fetchInvoice = async () => {
      setInvoiceModel(
        (await InvoicesApi_Cache.get(businessId)).find(
          (x) => x.invoice.rowKey === invoiceId
        )
      );
    };

    const fetchSubscription = async () => {
      setSubscription(
        (await SubscriptionsApi_Cache.get(businessId)).find(
          (x) => x.rowKey === subscriptionId
        )
      );
    };

    const fetchMovementsTransaction = async () => {
      setMovementsTransaction(
        (await MovementsTransactionsApi_Cache.get(businessId)).filter(
          (x) => x.transactionId === transactionId
        )
      );
    };

    fetchContact();
    fetchEstimate();
    fetchOrder();
    fetchInvoice();
    fetchSubscription();
    fetchMovementsTransaction();
  }, [businessId, transaction]);

  useEffect(() => {
    if (!movementsTransaction.length) return;
    const movementsIds = movementsTransaction.map((mt) => mt.movementId);

    const fetchMovements = async () => {
      setMovements(
        (await MovementsApi_Cache.get(businessId)).filter((x) =>
          movementsIds.includes(x.rowKey)
        )
      );
    };

    fetchMovements();
  }, [businessId, movementsTransaction]);

  const handleOnLoad = async (nocache) => {
    if (nocache) atomClearCache();
    const fetchTransaction = async () => {
      const transaction = (await TransactionsApi_Cache.get(businessId)).find(
        (x) => x.rowKey === id
      );
      console.log("Transaction: ", transaction);
      setTransaction(transaction);
    };
    await fetchTransaction();
  };

  const handleWithdraw = async () => {
    let url = await TransactionsApi_Cache.withdraw(
      businessId,
      transaction.rowKey
    );

    if (!url) {
      handleOnLoad(true);
      return;
    }

    if (navigator.share) {
      const shareOptions = {
        title: "Holo - Completamento pagamento",
        text: `Questo è il link per procedere al pagamento. <br /><br />Clicca qui per vedere i dettagli: ${url}`,
        url: url,
      };

      await navigator.share(shareOptions);
    } else {
      await navigator.clipboard.writeText(url);
      toast.info("Link da condividere copiato negli appunti");
    }
  };

  const handleUpdate = async (transaction) => {
    await TransactionsApi_Cache.update(businessId, id, transaction);
  };

  const handleCancel = async () => {
    await TransactionsApi_Cache.delete(businessId, id);
  };

  return (
    !!transaction && (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <PageHeader
                title={`Dettaglio: ${transaction.name}`}
                pageName={`Dettaglio`}
                backPageName={`Pagamenti`}
                backPageRoute={`/payments`}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={4}>
              <PaymentDetailsCard
                transaction={transaction}
                contact={contact}
                estimate={estimateModel?.estimate}
                order={orderModel?.order}
                subscription={subscription}
                preInvoice={null}
                invoice={invoiceModel?.invoice}
                handleWithdraw={handleWithdraw}
                handleCancel={handleCancel}
                handleUpdate={handleUpdate}
              />
            </Col>
            <Col xxl={8}>
              <CardTableMovements
                key={`card-table-movements`}
                title={`Movimenti associati`}
                transaction={transaction}
                movements={movements}
                movementsTransaction={movementsTransaction}
                refresh={handleOnLoad}
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}
