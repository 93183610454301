import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import DashboardActivationComplete from "../components/Dashboard/DashboardActivationComplete";
import DashboardActivationIncomplete from "../components/Dashboard/DashboardActivationIncomplete";
import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";

const Dashboard = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [contacts, setContacts] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const results = await Promise.all([
        ContactsApi_Cache.get(businessId),
        EstimatesApi_Cache.get(businessId),
        ServicesApi_Cache.get(businessId),
      ]);
      setContacts(results[0]);
      setEstimates(results[1]);
      setServices(results[2]);
    };
    fetch();
  }, [businessId]);

  const [activationStatus, setActivationStatus] = useState(null);

  useEffect(() => {
    const loadActivationStatus = () => {
      let contactsConfigured = !!contacts.length;
      let servicesConfigured = !!services.filter((x) => x.type === 1).length;
      let plansConfigured = !!services.filter((x) => x.type === 2).length;
      let estimateConfigured = !!estimates.length;
      let stripeConfigured = !!business.stripeConnectId;
      let openBankingConfigured = !!business.isOpenBankingConnected;

      setActivationStatus({
        contact: contactsConfigured,
        service: servicesConfigured,
        plan: plansConfigured,
        estimate: estimateConfigured,
        stripe: stripeConfigured,
        openBanking: openBankingConfigured,
        isComplete:
          !!contactsConfigured &&
          !!servicesConfigured &&
          !!plansConfigured &&
          !!estimateConfigured &&
          (!!stripeConfigured || !!openBankingConfigured),
      });
    };
    loadActivationStatus();
  }, [business, contacts, estimates, services]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Holo | Get Paid</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link>Portale</Link>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {business.isActivationComplete === false ? (
            <>
              {!!activationStatus && (
                <DashboardActivationIncomplete
                  activationStatus={activationStatus}
                />
              )}
            </>
          ) : (
            <DashboardActivationComplete />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
