import React from "react";

const PaymentCardHeader = ({
  payment,
  onClickEdit,
  onClickPlus,
  className,
}) => {
  const expirationDate =
    payment.closedOnFormatted || payment.expirationDateFormatted;

  return (
    <div class={`d-flex justify-content-between ${className}`}>
      <button
        type="button"
        onClick={onClickEdit}
        className="btn btn-light btn-sm align-self-start"
      >
        <i className="bx bx-pencil text-muted fs-4"></i>
      </button>
      <p className="fw-semibold fs-4 mb-0 text-truncate">
        {!!expirationDate && (
          <span className="text-muted mx-1 align-self-start" id="datepickers">
            {expirationDate} |
          </span>
        )}
        {payment.amountFormatted}
      </p>
      <button
        type="button"
        className="btn btn-light btn-sm align-self-start"
        onClick={onClickPlus}
      >
        <i className="bx bx-plus fs-5"></i>
      </button>
    </div>
  );
};

export default PaymentCardHeader;
