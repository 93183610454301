import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";

import { EstimatesApi_Cache } from "../../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../../api/decorators/cache/InvoicesApi_Cache";
import { PreInvoicesApi_Cache } from "../../api/decorators/cache/PreInvoicesApi_Cache";

const paymentPageBaseUrl = process.env.REACT_APP_PAY_BASE_URL;

export const PaymentButton = ({
  businessId,
  documentId,
  documentType,
  sharable,
  onClick,
  className,
}) => {
  const handleClick = async () => {
    let result = null;
    let relativePath = `${paymentPageBaseUrl}/${businessId}/`;

    switch (documentType) {
      case "estimate":
        relativePath += "e";
        result = await EstimatesApi_Cache.createPublicLinkAsync(
          businessId,
          documentId
        );
        break;
      case "preInvoice":
        relativePath += "p";
        result = await PreInvoicesApi_Cache.createPublicLinkAsync(
          businessId,
          documentId
        );
        break;
      case "invoice":
        relativePath += "i";
        result = await InvoicesApi_Cache.createPublicLinkAsync(
          businessId,
          documentId
        );
        break;
      default:
        throw "Invalid documentType";
    }

    if (result?.rowKey) {
      const paymentUrl = `${relativePath}/${documentId}/${result.rowKey}`;

      if (sharable) {
        if (navigator.share) {
          const shareOptions = {
            title: "Holo - Link di pagamento",
            text: `Questo è il link per procedere al pagamento. <br /><br />Clicca qui per vedere i dettagli: ${paymentUrl}`,
            url: paymentUrl,
          };
          await navigator.share(shareOptions);
        } else {
          await navigator.clipboard.writeText(paymentUrl);
          toast.info("Link di pagamento copiato negli appunti");
        }
      }

      onClick(paymentUrl);
    }
  };

  return (
    <>
      <Link
        onClick={handleClick}
        className={clsx(
          "btn",
          "btn-primary",
          "waves-effect",
          "btn-label",
          "waves-light",
          "w-100",
          className
        )}
      >
        <i className="bx bx-send label-icon"></i>
        Richiedi Pagamento
      </Link>
    </>
  );
};
