const authority = process.env.REACT_APP_AUTHORITY;
const domain = process.env.REACT_APP_DOMAIN;

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_POLICY_SIGNUP_SIGNIN",
        forgotPassword: "B2C_1_POLICY_RESET_PASSWORD",
        // editProfile: "B2C_1_POLICY_EDIT_USER_PROFILE"
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${domain}/${authority}.onmicrosoft.com/B2C_1_POLICY_SIGNUP_SIGNIN`,
        },
        forgotPassword: {
            authority: `https://${domain}/${authority}.onmicrosoft.com/B2C_1_POLICY_RESET_PASSWORD`,
        },
        // editProfile: {
        //     authority: `https://${authority}.b2clogin.com/${authority}.onmicrosoft.com/B2C_1_POLICY_EDIT_USER_PROFILE`
        // }
    },
    authorityDomain: `${domain}`
}