import { _BaseUtil } from "./_BaseUtil";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";

export const InvoicesUtil = {
  getModelTemplate: async function (businessId, type) {
    let today = new Date();

    const invoiceType = type || 1;

    let invoice = {
      code: await this.getNextAvailableCodeAsync(
        businessId,
        today.getFullYear()
      ),
      revisionNumber: 1,
      contactId: null,
      allowPublicPageEdit: true,
      subject: "",
      description: "",
      closingDays: 60,
      tags: "",
      currencyType: 1, //1. Fiat, 2. Crypto
      currency: "EUR",
      customerNotes: "",
      invoiceType,
      status: 0,
      revisedinvoiceId: "", // To fill only for revise
    };

    invoice.date = today;
    invoice.expirationDate = today.addDays(5);
    invoice.closingDate = today.addDays(invoice.closingDays);

    let model = {
      invoice: invoice,
      rows: [],
      conditions: [],
      links: [],
    };

    return model;
  },

  getNextAvailableCodeAsync: async function (businessId, year) {
    const invoices = await InvoicesApi_Cache.get(businessId);

    if (!invoices || !invoices.length) {
      // If there are no existing codes, start with 1/2023
      return `1/${year}`;
    }

    let existingCodes = invoices.map((x) => x.invoice.code).filter((x) => !!x);

    existingCodes = existingCodes
      .filter((code) => code.endsWith(`/${year}`)) // Filter by current year
      .map((code) => code.split("/")[0]); // Extract the number

    const maxNumber = Math.max(...existingCodes);

    // Increment the maximum number
    const nextNumber = maxNumber + 1;
    return `${nextNumber}/${year}`;
  },

  getTypes: function () {
    return [
      { label: "Fattura", value: 1 },
      { label: "Auto Fattura", value: 4 },
      { label: "Nota di Credito", value: 3 },
    ];
  },

  getTypeBadge: function (type) {
    switch (type) {
      case 0:
        return "bg-primary";
      case 1:
        return "bg-success";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-info";
      case 4:
        return "bg-light";
      default:
        break;
    }
    return "";
  },

  getTypeDescription: function (type) {
    switch (type) {
      case 0:
        return "No Fattura";
      case 1:
        return "Fattura";
      case 2:
        return "Fattura di anticipo";
      case 3:
        return "Nota di credito";
      case 4:
        return "Autofattura";
      default:
        break;
    }
    return "";
  },

  getStatusDescription: function (status) {
    switch (status) {
      case 0:
        return "Bozza";
      case 1:
        return "Attesa pagamento";
      case 2:
        return "Pagata";
      case 3:
        return "Annullata";
      default:
        break;
    }
    return "";
  },

  getStatusBadge: function (status) {
    switch (status) {
      case 0:
        return "bg-primary";
      case 1:
        return "bg-warning";
      case 2:
        return "bg-success";
      case 3:
        return "bg-info";
      default:
        break;
    }
    return "";
  },

  getEInvoiceBadge: function (eInvoiceCreated) {
    return !!eInvoiceCreated ? "bg-success" : "bg-warning";
  },

  getEInvoiceDescription: function (eInvoiceCreated) {
    return !!eInvoiceCreated ? "Inviata allo SDI" : "Da inviare";
  },

  getBilledByCustomerId: function (invoices, customerId, currency, culture) {
    invoices = invoices.filter((x) => x.customerId === customerId);
    invoices = invoices.filter((x) => !!x.eInvoiceCreated);
    // Use the reduce function to calculate the sum
    const amount = invoices.reduce((accumulator, currentService) => {
      return accumulator + currentService.totalAmountGross;
    }, 0);
    return _BaseUtil.formatCurrency(currency, amount.toFixed(2), culture);
  },
};
