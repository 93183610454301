import { useParams } from "react-router-dom";
import { useEffect, useContext, useState } from "react";

import { BusinessContext } from "../contexts/AppContexts";

import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";

import DocumentPreview from "../components/Documents/DocumentPreview";

export default function EstimatePreview() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [model, setModel] = useState(null);

  useEffect(() => {
    const promises = [EstimatesApi_Cache.get(businessId)];
    Promise.all(promises).then((results) => {
      setModel((results[0] || []).find((x) => x?.estimate?.rowKey === id));
    });
  }, []);

  return (
    !!model && (
      <div className="page-content">
        <div className="container-fluid">
          <DocumentPreview
            type={`estimate`}
            document={model.estimate}
            rows={model.rows}
          ></DocumentPreview>
        </div>
      </div>
    )
  );
}
