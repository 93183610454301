import { useEffect, useState } from "react";
import { _BaseUtil } from "../utils/_BaseUtil";
import Chart from "react-apexcharts";
import { Input } from "reactstrap";

export const CardDonutChartUtil = {
  getPie: function (id, label, currency, culture, pieces) {
    return {
      id,
      label,
      total: pieces.reduce((sum, obj) => sum + Number(obj.amount || 0), 0),
      currency,
      culture,
      pieces: pieces,
    };
  },
  getPiece: function (label, amount) {
    return {
      label,
      amount,
    };
  },
};

export const CardDonutChart = ({
  key,
  title,
  subtitle,
  label,
  colorsHex,
  pies,
}) => {
  const [current, setCurrent] = useState(null);
  const [chartStates, setChartStates] = useState([]);

  useEffect(() => {
    if (!pies?.length) return;
    createChartStates();
  }, [pies]);

  useEffect(() => {
    handleChange();
  }, [chartStates]);

  const handleChange = (e) => {
    if (!pies) return;
    const id = e?.target?.value;
    if (!id) {
      setCurrent(pies[0]);
      return;
    }
    const pie = pies.find((x) => x.id === id);
    setCurrent(pie);
  };

  const createChartStates = () => {
    const states = pies.map((x) => createChartState(x));
    setChartStates(states);
  };

  const getPercentages = (group) => {
    const percentages = group.pieces.map((x) => {
      return {
        label: x.label,
        percentage: Number(((x.amount / group.total) * 100).toFixed(2)),
      };
    });
    return percentages;
  };

  const createChartState = (pie) => {
    const percentages = getPercentages(pie);
    const chartState = {
      id: pie.id,
      series: percentages.map((x) => x.percentage),
      chart: {
        height: 260,
        type: "donut",
      },
      options: {
        labels: percentages.map((x) => x.label),
        colors: colorsHex,
        legend: {
          show: !1,
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (val, opts) {
            return Number(val).toFixed(2) + "%";
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Poppins, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: true,
          theme: true,
          style: {
            fontSize: "14px",
            fontFamily: "Poppins, Arial, sans-serif",
            fontWeight: "bold",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
      },
    };
    return chartState;
  };

  const getChart = (chartState) => {
    if (!chartState) return;
    return (
      <Chart
        key={chartState.id}
        type={chartState.chart.type}
        series={chartState.series}
        width={chartState.chart.width}
        height={chartState.chart.height}
        options={chartState.options}
      />
    );
  };

  return (
    !!current &&
    !!chartStates.length && (
      <>
        <div className="card">
          <div className="card-body">
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <Input
                    type="select"
                    name={`chart-donut-select-${key}`}
                    value={current.id}
                    onChange={handleChange}
                  >
                    {pies.map((item, index) => {
                      return (
                        <option
                          key={`chart-donut-select-${key}-option-${index}`}
                          value={item.id}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </Input>
                  <label className="input-group-text">{label}</label>
                </div>
              </div>
              <h4 className="card-title mb-4">{title}</h4>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <p className="text-muted">{subtitle}</p>
                <h3>
                  {_BaseUtil.formatCurrency("EUR", current.total || 0, "it-IT")}
                </h3>
              </div>
              <div className="col-sm-6">
                <div className="mt-4 mt-sm-0">
                  {getChart(chartStates.find((cs) => cs.id === current.id))}
                </div>
              </div>
              <div className="text-center text-muted">
                <div className="row">
                  {current.pieces?.map((item, i) => {
                    return (
                      <div className={`col-4`} key={i}>
                        <div className="mt-4">
                          <p className="mb-2 text-truncate">
                            <i
                              className="mdi mdi-circle me-1"
                              style={{ color: `${colorsHex[i]}` }}
                            ></i>{" "}
                            {item.label}
                          </p>
                          <h5>
                            {_BaseUtil.formatCurrency(
                              current.currency,
                              item.amount,
                              current.culture
                            )}
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};
