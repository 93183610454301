import it from "../locale/it.json";
import en from "../locale/en.json";

export function getI18nextConfig(lng) {

    return {
        interpolation: {
            escapeValue: false
        },
        lng: lng,
        resources: {
            it: it,
            en: en
        }
    }
}