import { BusinessTaxesApi } from "../../BusinessTaxesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "businessTaxes";

export const BusinessTaxesApi_Cache = {
  apiName: "BusinessTaxesApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await BusinessTaxesApi.get(businessId, nocache, noloader);
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },
};
