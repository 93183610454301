import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QuickActionsButton = () => {
  const { t } = useTranslation("dashboard");

  return (
    <div className="dropdown float-end">
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <p className="m-0 label">
          <i className="bx bxs-magic-wand align-middle mx-1" />
          {t("quick_action")}
          <i class="bx bx-chevron-down align-middle mx-1 fs-4" />
        </p>
      </button>
      <div className="dropdown-menu dropdown-menu-end">
        <Link
          to={`/estimate`}
          className="dropdown-item text-capitalize-first-word"
        >
          {t("new_estimate")}
        </Link>
        <Link
          to={`/invoice`}
          className="dropdown-item text-capitalize-first-word"
        >
          {t("new_invoice")}
        </Link>
      </div>
    </div>
  );
};

export default QuickActionsButton;
