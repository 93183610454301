import React, { useContext, useEffect, useState } from "react";

import {
  Row,
  Col,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const RegisterStep1 = ({
  user,
  business,
  updateUserAsync,
  createBusinessAsync,
}) => {
  useEffect(() => {}, []);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      countryCode: business?.countryCode || "IT",
      vatNumber: business?.vatNumber,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Nome obbligatorio"),
      lastName: Yup.string().required("Cognome"),
      countryCode: Yup.string().required("Country Code is required"),
      vatNumber: Yup.number()
        .positive("VAT Number is invalid")
        .required("VAT Number is required"),
    }),
    onSubmit: (values) => {
      console.log(`values`, values);
      checkFormValidation();
    },
  });

  const checkFormValidation = async () => {
    try {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        // Perform your desired action for valid form
        console.log("Form is valid");

        const { firstName, lastName, countryCode, vatNumber } = formik.values;

        await updateUserAsync({ firstName, lastName });
        await createBusinessAsync({ countryCode, vatNumber });
      } else {
        console.log("Form is invalid");
        // Perform your desired action for invalid form
      }
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="kycfirstname-input">Nome</Label>
            <Input
              type="text"
              name="firstName"
              className="form-control"
              id="kycfirstname-input"
              placeholder="Inserisci il tuo nome"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName || ""}
              invalid={formik.touched.firstName && formik.errors.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <FormFeedback type="invalid">
                {formik.errors.firstName}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="kyclastname-input">Cognome</Label>
            <Input
              type="text"
              name="lastName"
              className="form-control"
              id="kyclastname-input"
              placeholder="Inserisci il tuo cognome"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName || ""}
              invalid={formik.touched.lastName && formik.errors.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <FormFeedback type="invalid">
                {formik.errors.lastName}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="kycvatnum-input">Partita Iva</Label>
            <Input
              name="vatNumber"
              className="form-control"
              id="kycvatnum-input"
              placeholder="Inserisci la tua partita iva"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vatNumber || ""}
              invalid={formik.touched.vatNumber && formik.errors.vatNumber}
            />{" "}
            {formik.touched.vatNumber && formik.errors.vatNumber ? (
              <FormFeedback type="invalid">
                {formik.errors.vatNumber}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <p>
            Inserendo la tua partita iva, <b>Holo recupererà gli altri dati</b>{" "}
            relativi alla tua attività.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="d-flex justify-content-end">
          <Button color="primary" type="submit" className="my-2">
            Avanti
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterStep1;
