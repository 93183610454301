import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { SubscriptionsUtil } from "../utils/SubscriptionsUtil";
import { Col, Row } from "reactstrap";

export default function Subscriptions() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [tableRecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filter, setFilter] = useState("");

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let promises = await Promise.all([
      ContactsApi_Cache.get(businessId),
      OrdersApi_Cache.get(businessId),
      ServicesApi_Cache.get(businessId),
      SubscriptionsApi_Cache.get(businessId),
    ]);

    let contacts = promises[0] || [];
    let ordersModels = promises[1] || [];
    let services = promises[2] || [];
    let subscriptions = promises[3] || [];

    let plans = services.filter((x) => x.type === 2);

    subscriptions = subscriptions.filter((x) => x);

    let result = subscriptions.map((x) =>
      mapTable(x, contacts, plans, ordersModels)
    );

    setTableRecords(result.filter((x) => x));
  };

  const mapTable = (subscription, contacts, plans, ordersModels) => {
    let contactId = subscription.contactId;
    let contact = contacts.find((x) => x.rowKey === contactId);

    let planId = subscription.serviceId;
    let plan = plans.find((x) => x.rowKey === planId);

    let orderId = subscription.orderId;
    let orderModel = ordersModels.find((x) => x.order.rowKey === orderId);

    if (!orderModel) return null;

    let result = {
      subscriptionId: subscription.rowKey,
      name: subscription.serviceName,
      startDate: subscription.startDate,
      endDate: subscription.endDate,
      currency: subscription.currency,
      totalAmount: subscription.totalAmountGross,
      status: subscription.status,
      planId: planId,
      planName: plan.name,
      contactId: contactId,
      contactName: ContactsUtil.getFullName(contact),
      contactType: ContactsUtil.getTypeDescription(contact?.type),
      orderId: subscription.orderId,
      orderCode: orderModel.order.code,
      orderSubject: orderModel.order.subject,
    };

    return result;
  };

  useEffect(() => {
    loadTable();
  }, [businessId]);

  useEffect(() => {
    let result = _BaseUtil.filterList(tableRecords, filter);

    const sortedItems = [...result].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });

    setFilteredRecords(sortedItems);
  }, [tableRecords, filter]);

  return (
    filteredRecords && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col md={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">Sottoscrizioni</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/subscriptions">Sottoscrizioni</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Lista Sottoscrizioni
                      </li>
                    </ol>
                  </div>
                </div>
              </Col>

              <div className={`card d-sm-none`}>
                <div className="card-body border-bottom">
                  <div className="d-flex align-items-center">
                    <div className="row">
                      <div className="col-6 my-auto">
                        <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                      </div>
                      <div className="col-6 my-auto">
                        <div className="text-end"></div>
                      </div>
                      <div className="col-12 my-4">
                        <div className="row">
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-control"
                              id="searchInput"
                              placeholder="Filtra ..."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </div>
                          <div className="col-2">
                            <Link
                              onClick={() => loadTable(true)}
                              className="btn btn-light float-end"
                            >
                              <i className="mdi mdi-refresh"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`card d-none d-sm-block`}>
                <div className="card-body border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    <input
                      type="text"
                      className="form-control mx-4"
                      id="searchInput"
                      placeholder="Filtra ..."
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="flex-shrink-0">
                      <Link
                        onClick={() => loadTable(true)}
                        className="btn btn-light mx-1"
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive tableReflow">
                  <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Ordine</th>
                        <th scope="col">Data Scadenza</th>
                        <th scope="col">Importo</th>
                        <th scope="col">Stato</th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredRecords.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                                <Link
                                  to={`/subscription/${item.subscriptionId}/details`}
                                  className="text-dark"
                                >
                                  <span className="text-primary font-size-22">
                                    <img
                                      src={
                                        item.imagePath ||
                                        "/assets/images/services/wired-outline-28-calendar.gif"
                                      }
                                      alt={item.name || "Sottoscrizione"}
                                      className="mw-100"
                                    />
                                  </span>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/subscription/${item.subscriptionId}/details`}
                                  className="text-dark"
                                >
                                  <span>
                                    {item.name ?? "Sottoscrizione senza nome"}
                                  </span>
                                </Link>
                              </h5>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/contact/${item.contactId}/profile`}
                                  style={{ color: "var(--bs-link-color)" }}
                                >
                                  {item.contactName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0 text-wrap">
                                {item.contactType}
                              </p>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/order/${item.orderId}/details`}
                                  style={{ color: "var(--bs-link-color)" }}
                                >
                                  {item.orderSubject}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0 text-wrap">
                                <Link
                                  to={`/plans/${item.planId}`}
                                  style={{ color: "var(--bs-link-color)" }}
                                >
                                  {item.planName}
                                </Link>
                              </p>
                            </td>
                            <td>
                              <div className="row">
                                <div className="col-12">
                                  <h5 className="font-size-14">
                                    Termine:{" "}
                                    {_BaseUtil.formatDate(
                                      item.endDate,
                                      "it-IT"
                                    )}
                                  </h5>
                                  <p className="text-muted mb-0 text-wrap">
                                    Inizio:{" "}
                                    {_BaseUtil.formatDate(
                                      item.startDate,
                                      "it-IT"
                                    )}
                                  </p>
                                </div>
                                {/* <div className="col-6">
                                  <div className="d-flex">
                                    <span className="badge badge-soft-danger font-size-12">
                                      {`- ${_BaseUtil.dateDiffInDays(
                                        new Date(item.endDate),
                                        new Date()
                                      )} giorni`}
                                    </span>{" "}
                                    <span className="ms-2 text-truncate"></span>
                                  </div>
                                </div> */}
                              </div>
                            </td>
                            <td>
                              <h5 className="text-truncate font-size-14">
                                Totale:{" "}
                                {_BaseUtil.formatCurrency(
                                  item.currency,
                                  item.totalAmount,
                                  "it-IT"
                                )}
                              </h5>
                            </td>
                            <td>
                              <span
                                className={`fs-5 badge ${SubscriptionsUtil.getStatusBadge(
                                  item.status
                                )}`}
                              >
                                {SubscriptionsUtil.getStatusDescription(
                                  item.status
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </>
    )
  );
}
