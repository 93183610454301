import React, { useContext, useEffect, useState } from "react";

import {
  Row,
  Col,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const OtpValidation = ({
  business,
  back,
  validatePecAsync,
  resendOtpAsync,
}) => {
  useEffect(() => {}, []);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: 0,
    },

    validationSchema: Yup.object({
      otp: Yup.number().positive().required("OTP obbligatorio"),
    }),
    onSubmit: (values) => {
      submitAsync();
    },
  });

  const submitAsync = async () => {
    try {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length) return;

      // Perform your desired action for valid form
      console.log("🎉 OTP form is valid");

      await validatePecAsync(formik.values.otp);
    } catch (error) {
      console.error("OTP form validation error:", error);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col className={`${business.vatNumber ? "" : "d-none"}`}>
          <FormGroup className="">
            <Label htmlFor="otp">OTP PEC</Label>
            <Input
              id="otp"
              name="otp"
              type="text"
              className="form-control"
              placeholder={`Inserisci l'OTP ricevuto sulla pec ${
                business.certifiedEmail || ""
              }`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.otp || ""}
              invalid={formik.touched.otp && formik.errors.otp}
              disabled={!business.certifiedEmail}
            />
            {formik.touched.otp && formik.errors.otp ? (
              <FormFeedback type="invalid">{formik.errors.otp}</FormFeedback>
            ) : null}

            <div className="mb-3">
              Non hai ricevuto nessun codice?
              <Button type="button" color="link" onClick={resendOtpAsync}>
                Reinvia
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="d-flex justify-content-end">
          <Button className="previous m-2" color="primary" onClick={back}>
            Indietro
          </Button>
          <Button color="primary" type="submit" className="my-2">
            Conferma
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OtpValidation;
