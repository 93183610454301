import React, { useContext } from "react";

import { UserContext } from "../contexts/AppContexts";

import { AvatarDropdown } from "./AvatarDropdown";

export const AccountSettings = ({ onChangeContract }) => {
  const { user } = useContext(UserContext);

  return (
    <>
      <div className="card">
        <h5 className="card-header bg-transparent border-bottom text-uppercase">
          Impostazioni Account
        </h5>
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-12">
                {user.avatarPath ? (
                  <img
                    src={user.avatarPath}
                    alt={user.avatarPath}
                    className="img-thumbnail rounded-circle"
                  />
                ) : (
                  <p>Nessun avatar selezionato</p>
                )}
                <div className="col-12">
                  <AvatarDropdown />
                </div>
              </div>
              {/* <div className="col-12 my-3">
                <SettingsUploadContract onChange={onChangeContract} />
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
