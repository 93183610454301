import { OrdersApi } from "../../OrdersApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "orders";

export const OrdersApi_Cache = {
  apiName: "OrdersApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await OrdersApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await OrdersApi.create(businessId, payload);
    $data.set([...$data.get(), newData]); //adding object to the existing array

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  complete: async function (businessId, orderId) {
    const fnName = "complete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      orderId
    );

    const url = await OrdersApi.complete(businessId, orderId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, url);

    return url;
  },
};
