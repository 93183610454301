import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const UsersApi = {
  apiName: "UsersApi",
  baseUrl: "/webapp/users",

  me: async function (noloader) {
    const fnName = "me";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(false, noloader);

    const response = await api.get(`${this.baseUrl}/${fnName}`, options);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  update: async function (payload) {
    const fnName = "update";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const response = await api.put(`${this.baseUrl}`, payload);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  uploadAvatar: async function (payload) {
    const fnName = "uploadAvatar";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const config = {
      headers: {
        "Content-Type": payload.type,
      },
    };

    const response = await api.post(
      `${this.baseUrl}/files/upload/avatar`,
      payload,
      config
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
