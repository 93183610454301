import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { InvoiceDetailsCard } from "../components/InvoiceDetailsCard";
import { PageHeader } from "../components/PageHeader";
import { InvoicesUtil } from "../utils/InvoicesUtil";
import PaymentsCardTable from "../components/CardTables/PaymentsCardTable";
import RowsCardTable from "../components/CardTables/RowsCardTable";
import SelectMovementsModal from "../components/Movements/SelectMovementsModal";
import { Col, Row } from "reactstrap";

const InvoiceDetails = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [model, setModel] = useState(null);
  const [orderModel, setOrderModel] = useState(null);
  const [estimateModel, setEstimateModel] = useState(null);
  const [condition, setCondition] = useState(null);
  const [contact, setContact] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [rowsServices, setRowsServices] = useState([]);
  const [rowsPlans, setRowsPlans] = useState([]);

  useEffect(() => {
    if (!id) return;

    const fetchInvoice = async () => {
      const model = (await InvoicesApi_Cache.get(businessId)).find(
        (x) => x.invoice.rowKey === id
      );
      console.log("Model: ", model);
      setModel(model);
    };

    fetchInvoice();
  }, [businessId, id]);

  useEffect(() => {
    if (!model) return;
    const invoiceId = model.invoice.rowKey;
    const contactId = model.invoice.contactId;
    const orderId = model.invoice.orderId;

    const fetchContact = async () => {
      setContact(
        (await ContactsApi_Cache.get(businessId)).find(
          (x) => x.rowKey === contactId
        )
      );
    };

    const fetchOrder = async () => {
      setOrderModel(
        (await OrdersApi_Cache.get(businessId)).find(
          (x) => x.order.rowKey === orderId
        )
      );
    };

    const fetchTransactions = async () => {
      setTransactions(
        (await TransactionsApi_Cache.get(businessId)).filter(
          (x) => x.invoiceId === invoiceId
        )
      );
    };

    setRowsServices(
      model.rows
        .filter((x) => x.serviceType === 1)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );
    setRowsPlans(
      model.rows
        .filter((x) => x.serviceType === 2)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );

    fetchContact();
    fetchOrder();
    fetchTransactions();
  }, [businessId, model]);

  useEffect(() => {
    if (!orderModel) return;
    const estimateId = orderModel.order.estimateId;

    const fetchEstimate = async () => {
      setEstimateModel(
        (await EstimatesApi_Cache.get(businessId)).find(
          (x) => x.estimate.rowKey === estimateId
        )
      );
    };

    fetchEstimate();
  }, [businessId, orderModel]);

  useEffect(() => {
    if (!orderModel) return;
    if (!estimateModel) return;

    setCondition(
      estimateModel.conditions.find(
        (x) => x.rowKey === orderModel.order.estimateConditionId
      )
    );
  }, [orderModel, estimateModel]);

  const [showAddMovementModal, setShowAddMovementModal] = useState(false);

  const handleAddFromMovements = async (movements) => {
    if (!movements?.length) return;

    let movementsToCreate = movements.filter((x) => !x.rowKey);

    let promises = movementsToCreate.map((m) => {
      return MovementsApi_Cache.create(businessId, m);
    });

    let results = await Promise.all(promises);

    movements.forEach((m) => {
      const result = results.find(
        (r) =>
          r.obTransactionId === m.obTransactionId &&
          r.obAccountId === m.obAccountId
      );

      if (result) {
        m.rowKey = result.rowKey;
      }
    });

    movements = movements.filter((x) => x);

    let movementsTransactions = movements.map((x) => {
      return {
        movementId: x.rowKey,
        amount: x.associatedAmount,
        currency: x.currency,
        currencyType: x.currencyType,
      };
    });

    await InvoicesApi_Cache.associateMovements(
      businessId,
      model.invoice.rowKey,
      movementsTransactions
    );

    setShowAddMovementModal(false);
    handleRefresh(true);
  };

  const handleRefresh = (nocache) => {
    if (nocache) atomClearCache();
  };

  return (
    !!model && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col>
                <PageHeader
                  title={`Dettaglio ${InvoicesUtil.getTypeDescription(
                    model.invoice.invoiceType
                  )}: ${model.invoice.code}`}
                  pageName={`Dettaglio`}
                  backPageName={`Fatture`}
                  backPageRoute={`/invoices`}
                />
              </Col>
            </Row>
            <Row>
              <Col xxl={4}>
                <InvoiceDetailsCard
                  invoice={model.invoice}
                  contact={contact}
                  condition={condition}
                  onRefresh={handleRefresh}
                />
              </Col>
              {!!rowsServices.length && (
                <Col xxl={8}>
                  <RowsCardTable title="Righe" rows={rowsServices} />
                </Col>
              )}
              {!!rowsPlans.length && (
                <Col xxl={8}>
                  <RowsCardTable title="Righe" rows={rowsPlans} />
                </Col>
              )}
              <Col xxl={6}>
                <PaymentsCardTable
                  title="Pagamenti"
                  transactions={transactions}
                  buttons={[
                    {
                      label: `Crea da movimento bancario`,
                      onClick: () => setShowAddMovementModal(true),
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>
        <SelectMovementsModal
          show={showAddMovementModal}
          onlyPositive={model.invoice.totalAmountGross >= 0}
          onlyNegative={model.invoice.totalAmountGross < 0}
          onConfirm={handleAddFromMovements}
          onClose={() => {
            setShowAddMovementModal(false);
          }}
        />
      </>
    )
  );
};

export default InvoiceDetails;
