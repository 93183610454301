import { Link, useNavigate } from "react-router-dom";

import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";

import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";
import { PaymentButton } from "./Buttons/PaymentButton";

import { ConfirmModal } from "../components/ConfirmModal";
import { ContractsApi_Cache } from "../api/decorators/cache/ContractsApi_Cache";
import { Col, Row } from "reactstrap";

export const EstimateDetailsCard = ({
  businessId,
  estimate,
  estimateCondition,
  order,
  refresh,
}) => {
  const navigate = useNavigate();

  const onDelete = async () => {
    await EstimatesApi_Cache.delete(businessId, estimate.rowKey);
    refresh();
  };

  const handleDownloadContract = async () => {
    const result = await ContractsApi_Cache.getFor(
      businessId,
      "estimate",
      estimate.rowKey
    );

    if (result) {
      _BaseUtil.downloadPdf(result);
    }
  };

  const onRevise = async () => {
    const result = await EstimatesApi_Cache.revise(businessId, estimate.rowKey);

    if (result) {
      navigate(`/estimate/${result.estimate.rowKey}`);
    }
  };

  const onPaymentLinkShared = () => {
    refresh();
  };

  return (
    !!estimate && (
      <>
        <div className="card card-std-height-l">
          <div className="card-body border-bottom">
            <div className="d-flex">
              <div className="flex-shrink-0 me-4">
                <img
                  src="/assets/images/services/wired-outline-1019-document-signature-hand.gif"
                  alt="generic estimate"
                  className="avatar-sm"
                />
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">
                  {estimate.subject}
                </h5>
                <DocumentDetailsCardItem
                  routeText={EstimatesUtil.getStatusDescription(
                    estimate.status
                  )}
                  className={`badge ${EstimatesUtil.getStatusBadge(
                    estimate.status
                  )} font-size-12`}
                />
              </div>
              {estimate.status === 1 && (
                <div className="flex-shrink-0 dropdown ms-2">
                  <Link
                    to={`/estimate/${estimate.rowKey}`}
                    className="btn btn-light btn-sm"
                  >
                    <i className="bx bxs-pencil align-middle me-1"></i> Modifica
                  </Link>
                </div>
              )}
            </div>
            {estimate.notes ? (
              <div className="mt-2">
                <h5 className="font-size-15">Note:</h5>
                <p className="text-muted">{estimate.notes}</p>
              </div>
            ) : (
              <div className="mt-5"></div>
            )}
            <div className="row task-dates">
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Cliente`}
                  icon={`bx bx-buildings`}
                  route={`/contact/${estimate.customerId}/profile`}
                  routeText={
                    estimate.customerType === 1
                      ? `${estimate.customerFirstName} ${estimate.customerLastName}`
                      : `${estimate.customerBusinessName}`
                  }
                />
              </div>
              {!!order && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Ordine`}
                    icon={`bx bx-buildings`}
                    route={`/order/${order.rowKey}/details`}
                    routeText={order.subject || "Ordine"}
                  />
                </div>
              )}
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Data Creazione`}
                  icon={`bx bx-calendar`}
                  route={null}
                  routeText={_BaseUtil.formatDate(estimate.createdOn, "it-IT")}
                />
              </div>
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Data Scadenza`}
                  icon={`bx bx-calendar-check`}
                  route={null}
                  routeText={_BaseUtil.formatDate(
                    estimate.expirationDate,
                    "it-IT"
                  )}
                />
              </div>
              {!!estimate.closedOn && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={EstimatesUtil.getClosingLabel(estimate.status)}
                    icon={`bx bx-calendar-check`}
                    route={null}
                    routeText={_BaseUtil.formatDate(estimate.closedOn, "it-IT")}
                  />
                </div>
              )}
              {!!estimateCondition && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Condizione selezionata`}
                    icon={`bx bx-receipt`}
                    route={null}
                    routeText={estimateCondition.name}
                  />
                </div>
              )}
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Importo totale`}
                  icon={`bx bx-money`}
                  route={null}
                  routeText={_BaseUtil.formatCurrency(
                    estimate.currency,
                    estimate.totalAmountGross,
                    "it-IT"
                  )}
                />
              </div>
              {!!estimate.totalVatAmount && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Importo iva`}
                    icon={`bx bx-money`}
                    route={null}
                    routeText={_BaseUtil.formatCurrency(
                      estimate.currency,
                      estimate.totalVatAmount,
                      "it-IT"
                    )}
                  />
                </div>
              )}
              {!!estimate.discountPercentage && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto %`}
                    icon={`bx bxs-discount`}
                    route={null}
                    routeText={`${estimate.discountPercentage}%`}
                  />
                </div>
              )}
              {!!estimate.discountAmountGross && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto ${estimate.currency}`}
                    icon={`bx bxs-discount`}
                    route={null}
                    routeText={_BaseUtil.formatCurrency(
                      estimate.currency,
                      estimate.discountAmountGross,
                      "it-IT"
                    )}
                  />
                </div>
              )}
              {!!estimate.paymentProvider && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Provider`}
                    icon={`bx bxs-bank`}
                    routeText={TransactionsUtil.getPaymentProviderDescription(
                      estimate.paymentProvider
                    )}
                    className={`badge ${TransactionsUtil.getPaymentProviderBadge(
                      estimate.paymentProvider
                    )} font-size-12`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="card-body">
            <Row>
              <Col xs={6} className="my-1">
                <Link
                  to={`/estimate/${estimate.rowKey}/preview`}
                  className="btn btn-warning waves-effect btn-label waves-light w-100"
                >
                  <i className="bx bx-file label-icon "></i> Anteprima
                </Link>
              </Col>
              {!![1, 2].includes(estimate.status) && (
                <Col xs={6} className="my-1">
                  <PaymentButton
                    businessId={businessId}
                    documentId={estimate.rowKey}
                    documentType={`estimate`}
                    sharable={true}
                    onClick={onPaymentLinkShared}
                  />
                </Col>
              )}
              {!![1, 2].includes(estimate.status) && (
                <Col xs={6} className="my-1">
                  <button
                    type="button"
                    className="btn btn-danger waves-effect btn-label waves-light w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`.confirm-modal`}
                  >
                    <i className="bx bx-file label-icon "></i>{" "}
                    {estimate.status === 1 ? "Elimina" : "Annulla"}
                  </button>
                </Col>
              )}
              {!!estimate.requestContractAcceptance && (
                <Col xs={6} className="my-1">
                  <Link
                    onClick={handleDownloadContract}
                    className="btn btn-light waves-effect btn-label waves-light w-100"
                  >
                    <i className="bx bx-download label-icon "></i>
                    Contratto
                  </Link>
                </Col>
              )}
              {!![2].includes(estimate.status) && (
                <Col xs={6} className="my-1">
                  <Link
                    onClick={onRevise}
                    className="btn btn-info waves-effect btn-label waves-light w-100"
                  >
                    <i className="bx bx-file label-icon "></i> Revisiona
                  </Link>
                </Col>
              )}
            </Row>
          </div>
        </div>

        <ConfirmModal
          title={`Attenzione`}
          text={`Confermi di voler proseguire?`}
          confirmText={`Conferma`}
          cancelText={`Annulla`}
          onConfirm={onDelete}
        />
      </>
    )
  );
};
