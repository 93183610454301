import { api } from "../configs/axiosConfigs";

export const PecApi = {
    apiName: "PecApi",
    baseUrl: "/webapp/businesses",

    validate: async function(businessId, payload) {
        const fnName = "validate";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            payload
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/pec/otp/validate`,
            payload
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },

    send: async function(businessId) {
        const fnName = "send";

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

        const response = await api.post(
            `${this.baseUrl}/${businessId}/pec/otp/send`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },
};