import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../assets/css/reactTags.css";

import { BusinessContext } from "../contexts/AppContexts";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { CompaniesApi_Cache } from "../api/decorators/cache/CompaniesApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { ContactsUtil } from "../utils/ContactsUtil";
import { InvoicesUtil } from "../utils/InvoicesUtil";
import { OrdersUtil } from "../utils/OrdersUtil";

import { CardSimpleSummary } from "../components/CardSimpleSummary";
import { ContactProfileDetails } from "../components/ContactProfileDetails";
import { CardTableEstimates } from "../components/CardTableEstimates";
import { CardTableOrders } from "../components/CardTableOrders";
import { CardTableSubscriptions } from "../components/CardTableSubscriptions";

const ContactProfile = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [contact, setContact] = useState(null);
  const [company, setCompany] = useState(null);
  const [estimatesModel, setEstimatesModel] = useState([]);
  const [ordersModel, setOrdersModel] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoicesModel, setInvoicesModel] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchContact = async () => {
      setContact(await ContactsApi_Cache.find(businessId, id));
    };
    fetchContact();
  }, [businessId, id]);

  useEffect(() => {
    if (!contact) return;

    const contactId = contact.rowKey;

    const fetchCompany = async () => {
      if (contact.type === 1) {
        return;
      }
      if (!contact.vatNumber) {
        return;
      }
      let company = await CompaniesApi_Cache.verify(
        businessId,
        contact.vatNumber
      );
      setCompany(company);
    };

    const fetchEstimates = async () => {
      setEstimatesModel(
        (await EstimatesApi_Cache.get(businessId)).filter(
          (x) => x.estimate.customerId === contactId
        )
      );
    };

    const fetchOrders = async () => {
      setOrdersModel(
        (await OrdersApi_Cache.get(businessId)).filter(
          (x) => x.order.customerId === contactId
        )
      );
    };

    const fetchSubscriptions = async () => {
      setSubscriptions(
        (await SubscriptionsApi_Cache.get(businessId)).filter(
          (x) => x.contactId === contactId
        )
      );
    };

    const fetchInvoices = async () => {
      setInvoicesModel(
        (await InvoicesApi_Cache.get(businessId)).filter(
          (x) => x.invoice.customerId === contactId
        )
      );
    };

    const fetchTransactions = async () => {
      setTransactions(
        (await TransactionsApi_Cache.get(businessId)).filter(
          (x) => x.contactId === contactId
        )
      );
    };

    fetchCompany();
    fetchEstimates();
    fetchOrders();
    fetchSubscriptions();
    fetchInvoices();
    fetchTransactions();
  }, [businessId, contact]);

  return (
    !!contact && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Profilo {ContactsUtil.getFullName(contact)}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/contacts">Contatti</Link>
                      </li>
                      <li className="breadcrumb-item active">Profilo</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4">
                <ContactProfileDetails
                  contact={contact}
                  company={company}
                ></ContactProfileDetails>
              </div>

              <div className="col-xxl-8">
                <div className="row">
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Ordini Aperti`}
                      value={OrdersUtil.getAmountByCustomerId(
                        ordersModel.map((x) => x.order),
                        contact.rowKey,
                        1,
                        "EUR",
                        "it-IT"
                      )}
                      icon={`bx bx-hourglass`}
                    />
                  </div>
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Ordini Completati`}
                      value={OrdersUtil.getAmountByCustomerId(
                        ordersModel.map((x) => x.order),
                        contact.rowKey,
                        3,
                        "EUR",
                        "it-IT"
                      )}
                      icon={`bx bx-check-circle`}
                    />
                  </div>
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Totale Fatturato`}
                      value={InvoicesUtil.getBilledByCustomerId(
                        invoicesModel.map((x) => x.invoice),
                        contact.rowKey,
                        "EUR",
                        "it-IT"
                      )}
                      icon={`bx bx-package`}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xxl-6">
                    <CardTableOrders
                      key={`card-table-contactprofile-orders`}
                      title={`Ordini`}
                      orders={ordersModel}
                    />
                  </div>
                  <div className="col-xxl-6">
                    <CardTableEstimates
                      key={`card-table-contactprofile-estimates`}
                      estimates={estimatesModel}
                    />
                  </div>
                  <div className="col">
                    <CardTableSubscriptions
                      key={`card-table-contactprofile-subscriptions`}
                      title={`Piani`}
                      subscriptions={subscriptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ContactProfile;
