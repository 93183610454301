import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { _BaseUtil } from "../../utils/_BaseUtil";
import { EstimatesUtil } from "../../utils/EstimatesUtil";

export default function DocumentPreview({ type, document, rows }) {
  const [typName, setTypeName] = useState(null);
  const [services, setServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    switch (type) {
      case "estimate":
        setTypeName("Preventivo");
        break;
      case "preInvoice":
        setTypeName("Pro Forma");
        break;
      case "invoice":
        setTypeName("Fattura");
        break;
      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    setServices(
      (rows ?? [])
        .filter((x) => x.serviceType === 1)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );
    setSubscriptions(
      (rows ?? [])
        .filter((x) => x.serviceType === 2)
        .sort((a, b) => {
          return a.lineNumber - b.lineNumber;
        })
    );
  }, [rows]);

  const contentToPrintRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => contentToPrintRef.current,
  });

  return (
    !!document && (
      <>
        <div ref={contentToPrintRef}>
          <div className="card p-3">
            {" "}
            {/* Use padding here and on card-body to have a correct padding on print */}
            <div className="card-body p-0">
              <div className="invoice-title">
                <h4 className="float-end font-size-16">
                  {typName}: #{document.code}
                </h4>
                <div className="mb-4">
                  <img
                    src="/assets/images/logo-dark.png"
                    alt="logo"
                    height="20"
                  ></img>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-sm-6">
                  <address>
                    <strong>Soggetto fatturazione:</strong>
                    <br />
                    {!document.customerVatNumber
                      ? `${document.customerFirstName} ${document.customerLastName}`
                      : document.customerBusinessName}
                    <br />
                    {!document.customerVatNumber
                      ? `Codice Fiscale: ${document.customerGovernmentId}`
                      : `Partita IVA: ${document.customerVatNumber}`}
                    <br />
                    {document.customerAddress
                      ? `Indirizzo: ${document.customerAddress} ${document.customerStreetNumber}, ${document.customerZipCode} ${document.customerCity}`
                      : null}
                    {`${
                      document.customerProvince
                        ? " (" + document.customerProvince + ")"
                        : ""
                    }`}
                  </address>
                </div>
                <div className="col-sm-6 text-sm-end">
                  <div>
                    <address>
                      {document.businessBusinessName}
                      <br />
                      {document.businessVatNumber}
                      <br />
                      {document.businessAddress
                        ? `${document.businessAddress} ${document.businessStreetNumber}, ${document.businessZipCode} ${document.businessCity}`
                        : null}
                      {`${
                        document.businessProvince
                          ? " (" + document.businessProvince + ")"
                          : ""
                      }`}
                    </address>
                  </div>
                  <address className="mt-2 mt-sm-0">
                    <div className="my-1">
                      <strong>Oggetto:</strong>
                      <br />
                      {document.subject}
                    </div>

                    <div className="my-1">
                      <strong>Data:</strong>
                      <br />
                      {new Date(document.date).toLocaleDateString()}
                    </div>

                    {!!document.expirationDate && (
                      <div className="my-1">
                        <strong>Data Scadenza:</strong>
                        <br />
                        {new Date(document.expirationDate).toLocaleDateString()}
                      </div>
                    )}

                    {!!document.closingDays && (
                      <div className="my-1">
                        <strong>Giorni consegna:</strong>
                        <br />
                        {document.closingDays}
                      </div>
                    )}
                  </address>
                </div>
              </div>
              {!!services.length && (
                <>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Servizi una tantum</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th>Servizio</th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Quantita
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Prezzo netto
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            IVA
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Prezzo lordo
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {services.map((x, i) => {
                          return (
                            <tr>
                              <td>
                                {`${x.lineNumber || i + 1}`.padStart(2, "0")}
                              </td>
                              <td className="text-wrap">
                                {x.serviceName}
                                <br />
                                <small>{x.serviceDescription}</small>
                              </td>
                              <td className="text-lg-end">{x.quantity}</td>
                              <td className="text-lg-end">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalAmountNet,
                                  "it-IT"
                                )}
                              </td>
                              <td className="text-lg-end">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalVatAmount,
                                  "it-IT"
                                )}
                              </td>
                              <td className="text-end fw-bold">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalAmountGross,
                                  "it-IT"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-end fw-bold">
                            TOTALI&nbsp;&nbsp;&nbsp;
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalAmountNet ||
                                document.totalAmountServicesNet,
                              "it-IT"
                            )}
                          </td>
                          <td className="text-end fw-bold">
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalVatAmount ||
                                document.totalVatAmountServices,
                              "it-IT"
                            )}
                          </td>
                          <td className="text-end fw-bold">
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalAmountGross ||
                                document.totalAmountServicesGross,
                              "it-IT"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {!!subscriptions.length && (
                <>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Piani ricorrenti</h3>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px;" }}>No.</th>
                          <th>Piano</th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Quantita
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Prezzo netto
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            IVA
                          </th>
                          <th className="text-end" style={{ width: "200px" }}>
                            Prezzo lordo
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptions.map((x, i) => {
                          return (
                            <tr>
                              <td>
                                {`${x.lineNumber || i + 1}`.padStart(2, "0")}
                              </td>
                              <td>
                                {x.serviceName}
                                <br />
                                <small>{x.serviceDescription}</small>
                                <br />
                                <strong>Durata del piano:</strong>{" "}
                                {x.billingIntervalsCount} mesi
                                <br />
                                <strong>Fatturazione:</strong> mensile
                                anticipata il primo del mese di competenza
                                <br />
                                <strong>Inizio del piano:</strong>{" "}
                                {EstimatesUtil.getNextMonthFirstDayDate().toLocaleDateString()}
                              </td>
                              <td className="text-lg-end">{x.quantity}</td>
                              <td className="text-lg-end">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalAmountNet,
                                  "it-IT"
                                )}
                              </td>
                              <td className="text-lg-end">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalVatAmount,
                                  "it-IT"
                                )}
                              </td>
                              <td className="text-end fw-bold">
                                {_BaseUtil.formatCurrency(
                                  x.currency,
                                  x.totalAmountGross,
                                  "it-IT"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-end fw-bold">
                            TOTALI&nbsp;&nbsp;&nbsp;
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalAmountPlansNet,
                              "it-IT"
                            )}
                          </td>
                          <td className="text-end fw-bold">
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalVatAmountPlans,
                              "it-IT"
                            )}
                          </td>
                          <td className="text-end fw-bold">
                            {_BaseUtil.formatCurrency(
                              document.currency,
                              document.totalAmountPlansGross,
                              "it-IT"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row-reverse">
          <Link
            className={`btn btn-warning waves-effect btn-label waves-light m-2`}
            onClick={handlePrint}
          >
            <i className="fa fa-print label-icon "></i> Stampa
          </Link>
          <Link
            className={`btn btn-info waves-effect btn-label waves-light m-2`}
            onClick={() => window.history.back()}
          >
            <i className="bx bx-arrow-back label-icon"></i> Indietro
          </Link>
        </div>
      </>
    )
  );
}
