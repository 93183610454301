export const BankAccountsUtil = {
    isExpiringConsent: (account) => {
        if (!account) return;
        if (!account.consentExpiresAt) return;

        const expirationDate = new Date(account.consentExpiresAt);
        const today = new Date();

        const diffInMs = expirationDate.getTime() - today.getTime();
        const remainingDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        return remainingDays <= 20;
    },

    getMonthlyTransactions: function(transactions, accountUuid) {
        const today = new Date(); // Get the current date
        const startDay = today.setDate(1); // Set the day of the month to 1

        let monthlyTransactions = transactions.filter(
            (t) => new Date(t.madeOn) >= startDay
        );

        return accountUuid ?
            monthlyTransactions.filter((t) => t.account.uuid === accountUuid) :
            monthlyTransactions;
    },

    getIncome: function(transactions, accountUuid, positive) {
        let monthlyTransactions = this.getMonthlyTransactions(
            transactions,
            accountUuid
        );

        monthlyTransactions = monthlyTransactions.filter((t) =>
            !!positive ? t.amount > 0 : t.amount < 0
        );

        return monthlyTransactions
            .map((x) => x.amount)
            .reduce((total, num) => Number(total) + Number(num), 0);
    },

    getTotalBalances: function(accounts) {
        return accounts
            .map((x) => x.balance)
            .reduce((total, num) => Number(total) + Number(num), 0);
    },
};