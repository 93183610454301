import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { _BaseUtil } from "../../utils/_BaseUtil";
import { TransactionsUtil } from "../../utils/TransactionsUtil";

import { CardBarChart, CardBarChartUtil } from "../Charts/CardBarChart";

export const BusinessProfileDetails = ({ business, transactions }) => {
  const [years, setYears] = useState([2024]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!transactions?.length) return;

    let series = [
      {
        name: "Fatturato",
        data: [],
      },
      {
        name: "Da Fatturare",
        data: [],
      },
      {
        name: "Residuo",
        data: [],
      },
    ];

    for (let index in years) {
      let year = years[index];

      let yearTransactions = transactions
        .filter((x) => !!x.expirationDate)
        .filter((x) => new Date(x.expirationDate).getFullYear() === year);

      let soldAmount = TransactionsUtil.sumAmount(
        yearTransactions.filter((x) => x.status === 3)
      );
      let toSellAmount = TransactionsUtil.sumAmount(
        yearTransactions.filter((x) => [1, 2].includes(x.status))
      );
      let remainingAmount = 85000 - soldAmount - toSellAmount;

      series[0].data.push(soldAmount);
      series[1].data.push(toSellAmount);
      series[2].data.push(remainingAmount);
    }

    setChartData(CardBarChartUtil.get(`businesschart`, years, series));
  }, [transactions, years]);

  return (
    <>
      <div className="card overflow-hidden">
        <div className="bg-primary bg-soft">
          <div className="dropdown float-end m-2">
            <Link
              className="dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              {/* <Link
                to={`/business/${business.rowKey}`}
                className="dropdown-item"
              >
                Modifica
              </Link> */}
            </div>
          </div>
          <div className="row">
            <div className="col-7">
              <div className="text-primary p-3 mb-4">
                <h2 className="text-primary">{business.businessName}</h2>
                <h6>{business.businessEmail}</h6>
              </div>
            </div>
            <div className="col-5 align-self-end mt-3">
              <img
                src="/assets/images/profile-img-company.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="row">
            <div className="col-sm-4">
              <div className="avatar-md profile-user-wid mb-2">
                <img
                  src={"/assets/images/companies/hexagon.gif"}
                  alt="logo azienda"
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-nowrap mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Partita IVA:</th>
                    <td>{business.vatNumber}</td>
                  </tr>
                  <tr>
                    <th scope="row">Pec:</th>
                    <td>{business.certifiedEmail}</td>
                  </tr>
                  <tr>
                    <th scope="row">Indirizzo:</th>
                    <td>
                      <a
                        href={`https://www.google.it/maps/search/${_BaseUtil.getFullAddress(
                          business.address,
                          business.streetNumber,
                          business.zipCode,
                          business.city,
                          business.country
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {_BaseUtil.getFullAddress(
                            business.address,
                            business.streetNumber,
                            business.zipCode,
                            business.city,
                            business.country
                          )}
                        </span>
                        <i className="bx bx-link-external ms-2"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {!!chartData && (
          <div className="card-body">
            <CardBarChart title={`Soglie Forfettario`} value={chartData} />
          </div>
        )}
      </div>
    </>
  );
};
