import { useParams } from "react-router-dom";
import { useEffect, useContext, useState } from "react";

import { BusinessContext } from "../contexts/AppContexts";

import { PreInvoicesApi_Cache } from "../api/decorators/cache/PreInvoicesApi_Cache";

import DocumentPreview from "../components/Documents/DocumentPreview";

export default function PreInvoicePreview() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [model, setModel] = useState(null);

  useEffect(() => {
    const promises = [PreInvoicesApi_Cache.get(businessId)];
    Promise.all(promises).then((results) => {
      setModel((results[0] || []).find((x) => x?.preInvoice?.rowKey === id));
    });
  }, []);

  return (
    !!model && (
      <div className="page-content">
        <div className="container-fluid">
          <DocumentPreview
            type={`preInvoice`}
            document={model.preInvoice}
            rows={model.rows}
          ></DocumentPreview>
        </div>
      </div>
    )
  );
}
