export const WelcomeBox = ({ title, message, list }) => {
  return (
    <div className="card bg-primary bg-soft card-std-height-xs">
      <div className="row">
        <div className="col-8">
          <div className="text-primary p-3">
            <h5 className="text-primary text-capitalize-first-word">{title}</h5>
            <p>{message}</p>
            <div>
              <ul className="ps-3 mb-2">
                {list?.map((x, i) => {
                  return (
                    <li className="py-1" key={i}>
                      {x}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-4 align-self-end">
          <img
            src="/assets/images/profile-img.png"
            alt="immagine profilo"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};
