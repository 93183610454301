import { InvoicesApi } from "../../InvoicesApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "invoices";

export const InvoicesApi_Cache = {
  apiName: "InvoicesApi_Cache",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await InvoicesApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData ? cacheData.filter((x) => x) : [];
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await InvoicesApi.create(businessId, payload);
    $data.set([...$data.get(), newData]); //adding object to the existing array

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  update: async function (businessId, invoiceId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    payload = await InvoicesApi.update(businessId, invoiceId, payload);

    if (payload) {
      const cacheData = $data.get();
      const others = cacheData.filter(
        (item) => item.invoice.rowKey !== invoiceId
      );
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },

  delete: async function (businessId, invoiceId) {
    const fnName = "delete";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId
    );

    const { $data } = atomCache(businessId, KEY, []);

    await InvoicesApi.delete(businessId, invoiceId);

    let cacheData = $data.get();
    cacheData = cacheData.filter((item) => item.invoice);
    const others = cacheData.filter(
      (item) => item.invoice.rowKey !== invoiceId
    );
    $data.set([...others]);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName}`);
  },

  createEInvoice: async function (businessId, invoiceId) {
    const fnName = "createEInvoice";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId
    );

    await InvoicesApi.createEInvoice(businessId, invoiceId);

    this.clearCache();

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response`);
  },

  createTransaction: async function (businessId, invoiceId) {
    const fnName = "createTransaction";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId
    );

    const newData = await InvoicesApi.createTransaction(businessId, invoiceId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  associateMovements: async function (
    businessId,
    invoiceId,
    movementsTransactions
  ) {
    const fnName = "associateMovements";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId,
      movementsTransactions
    );

    await InvoicesApi.associateMovements(
      businessId,
      invoiceId,
      movementsTransactions
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response`);
  },

  createPublicLinkAsync: async function (businessId, invoiceId) {
    const fnName = "createPublicLinkAsync";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      invoiceId
    );

    const newData = await InvoicesApi.createPublicLinkAsync(
      businessId,
      invoiceId
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  predictionsRevenues: async function (businessId, nocache, noloader) {
    const fnName = "predictionsRevenues";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      nocache
    );

    let response = await InvoicesApi.predictionsRevenues(
      businessId,
      nocache,
      noloader
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },
};
