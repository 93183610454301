import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const OpenBankingApi = {
    apiName: "OpenBankingApi",
    baseUrl: "/webapp/businesses",

    connectAsync: async function(businessId, returnUrl) {
        const fnName = "connectAsync";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            returnUrl
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/openbanking/connect?returnUrl=${returnUrl}`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },

    disableAsync: async function(businessId, accountId) {
        const fnName = "disableAsync";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            accountId
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/openbanking/accounts/${accountId}/disable`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },

    getAccounts: async function(businessId, nocache, noloader) {
        const fnName = "getAccountsAsync";

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

        const options = _BaseApi.getHeaders(nocache, noloader);

        const response = await api.get(
            `${this.baseUrl}/${businessId}/openbanking/accounts`,
            options
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return (response ? response.data || [] : []).filter((x) => x.enabled);
    },

    getTransactionsAsync: async function(
        businessId,
        accountId,
        nocache,
        noloader
    ) {
        const fnName = "getTransactionsAsync";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            accountId
        );

        const options = _BaseApi.getHeaders(nocache, noloader);

        const response = await api.get(
            `${this.baseUrl}/${businessId}/openbanking/accounts/${accountId}/transactions`,
            options
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response ? response.data : [];
    },

    reConnectAsync: async function(businessId, accountId) {
        const fnName = "reConnectAsync";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            accountId
        );

        const response = await api.post(
            `${this.baseUrl}/${businessId}/openbanking/accounts/${accountId}/reconnect`
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },
};