import { _BaseUtil } from "./_BaseUtil";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";

export const ServicesUtil = {
  getCodePrefix: function (type) {
    switch (type) {
      case 1:
        return "S";
      case 2:
        return "R";
      default:
        return "";
    }
  },

  getTemplate: async function (type, businessId) {
    let obj = {
      code: await this.getNextAvailableCodeAsync(
        businessId,
        this.getCodePrefix(type)
      ),
      name: "",
      description: "",
      defaultAmount: "",
      defaultCurrency: "EUR",
      type: type, //1. Single, 2. Recurrent
      vatType: 0,
      status: 0,
    };

    //recurrent
    if (type === 2) {
      obj.billingIntervalsCount = 12;
      obj.billingInterval = 1;
      obj.billingIntervalType = 2;
      obj.automaticRenew = true;
      obj.cancellationDays = 30;
    }

    return obj;
  },

  getUnitDescription: function (unitCode) {
    if (!unitCode) return "";
    switch (unitCode.toUpperCase()) {
      case "N":
        return "Numero";
      case "PZ":
        return "Pezzi";
      case "HR":
        return "Ore";
      case "GG":
        return "Giorni";
      default:
        break;
    }
    return "";
  },

  getBillingIntervalDescription: function (billingInterval) {
    if (!billingInterval) return "";
    switch (billingInterval.toString()) {
      case "1":
        return "Mensile";
      case "2":
        return "Bimestrale";
      case "3":
        return "Trimestrale";
      case "4":
        return "Quadrimestrale";
      case "6":
        return "Semestrale";
      case "12":
        return "Annuale";
      default:
        break;
    }
    return "";
  },

  getNextAvailableCodeAsync: async function (businessId, prefix) {
    const services = await ServicesApi_Cache.get(businessId);

    // Generate the next code by incrementing the maximum numeric value
    const nextCodeNumber = _BaseUtil.getNextAvailableCode(services, "code");

    // Format the next code back to the 'SXXX' format
    const nextCode = prefix + `${String(nextCodeNumber).padStart(3, "0")}`;

    return nextCode;
  },
};
