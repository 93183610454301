// SelectCountry.js
import React, { useState, useEffect } from "react";
import Select from "react-select";

import countries from "../../assets/countries.json";

const SelectCountry = ({ id, name, className, value, onChange, onBlur }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    let selectedCountry = countries.find((country) => country.sigla === value);
    setSelectedValue(
      !!selectedCountry && {
        label: selectedCountry.nome,
        value: selectedCountry.sigla,
      }
    );
  }, [value]);

  const handleChange = (option) => {
    setSelectedValue(option);
    onChange(option?.value);
  };

  return (
    <Select
      id={id}
      name={name}
      className={className}
      placeholder="-- Seleziona --"
      options={countries.map((country) => ({
        label: country.nome,
        value: country.sigla,
      }))}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      value={selectedValue}
      onChange={handleChange}
      onBlur={onBlur}
      isClearable={true}
      isSearchable={true}
    />
  );
};

export default SelectCountry;
