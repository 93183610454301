import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { BusinessContext } from "../contexts/AppContexts";

import { _BaseUtil } from "../utils/_BaseUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";

import { BusinessTaxesApi_Cache } from "../api/decorators/cache/BusinessTaxesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { FinancialAssistantAggregate } from "../components/FinancialAssistantAggregate";
import { CardSimpleSummary } from "../components/CardSimpleSummary";

import atecoCodes from "../assets/atecoCodes.json";

export default function FinancialAssistant() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);

  const [TaxesCard, setTaxesCard] = useState({
    title: "Stima mensile tasse",
    icon: "bx bx-calculator",
    value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    badgeClass: "badge-soft-success",
    badgeValue: "0%",
    badgeDescription: " dell'incassato",
  });

  const [RedditivityIndexCard, setRedditivityIndexCard] = useState({
    title: "Spese e indice reditività",
    icon: "bx bx-trending-up",
    value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    badgeClass: "badge-soft-success",
    badgeValue: "0%",
    badgeDescription: " dell'incassato",
  });

  const [RSExpensesCard, setRSExpensesCard] = useState({
    title: "Stima pese quadro RS (Machine Learning)",
    icon: "bx bx-trending-up",
    value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    // badgeClass: "badge-soft-success",
    // badgeValue: "0%",
    // badgeDescription: "Stima basata su Machine learning",
  });

  const [RevenuesCard, setRevenuesCard] = useState({
    title: "Stima fatturato (Machine Learning)",
    icon: "bx bx-trending-up",
    value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    // badgeClass: "badge-soft-success",
    // badgeValue: "0%",
    // badgeDescription: "Stima basata su Machine learning",
  });

  useEffect(() => {
    fetchTaxes();
    fetchExpenses();
    fetchPredictionsExpenses();
    fetchPredictionsRevenues();
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (!totalEarnings) return;
    if (!totalExpenses) return;

    const percentage = ((totalExpenses / totalEarnings) * 100).toFixed(0);

    let bAtecoCodes = business.atecoCodes?.toString().split("|");
    bAtecoCodes = atecoCodes.filter((x) => bAtecoCodes.includes(x.value));

    // Take the maximum ateco code redditivity index
    let sortedItems = [...bAtecoCodes].sort((a, b) => {
      const aVal = a.taxIndex;
      const bVal = b.taxIndex;
      return bVal - aVal;
    });

    let bAtecoCodeResult = sortedItems[0];

    let expenseRedditivityIndex = 100 - bAtecoCodeResult.taxIndex * 100;

    let diff = percentage - expenseRedditivityIndex;
    let badgeClassValue =
      Math.abs(diff) < 15 ? "warning" : diff < 0 ? "success" : "danger";

    setRedditivityIndexCard({
      ...RedditivityIndexCard,
      value: _BaseUtil.formatCurrency("EUR", totalExpenses, "it-IT"),
      badgeValue: `${percentage}%`,
      badgeClass: `badge-soft-${badgeClassValue}`,
    });
  }, [totalEarnings, totalExpenses]);

  useEffect(() => {
    if (!totalEarnings) return;
    if (!totalTaxes) return;

    const percentage = ((totalTaxes / totalEarnings) * 100).toFixed(0);

    let badgeClassValue =
      Math.abs(percentage) < 15
        ? "warning"
        : percentage < 0
        ? "success"
        : "danger";

    setTaxesCard({
      ...TaxesCard,
      value: _BaseUtil.formatCurrency("EUR", totalTaxes, "it-IT"),
      badgeValue: `${percentage}%`,
      badgeClass: `badge-soft-${badgeClassValue}`,
    });
  }, [totalEarnings, totalTaxes]);

  const fetchTaxes = async () => {
    let result = await BusinessTaxesApi_Cache.get(businessId);

    const today = new Date();
    const year = today.getFullYear();
    const period = today.getMonth() + 1;
    const interval = 1; // 1. Month

    let currentMonthTaxes = result.find(
      (x) => x.year === year && x.period === period && x.interval === interval
    );

    setTotalTaxes(currentMonthTaxes?.taxAmount);
  };

  const fetchExpenses = async () => {
    let result = await MovementsApi_Cache.get(businessId);

    result = result.filter((x) => [4, 5, 6].includes(x.expenseType));

    let sum = result
      .map((x) => Math.abs(x.amount))
      .reduce((total, num) => Number(total) + Number(num), 0);

    setTotalExpenses(sum);
  };

  const fetchPredictionsExpenses = async () => {
    let result = await MovementsApi_Cache.predictionsExpenses(businessId);

    let sum = result
      .map((x) => Math.abs(x.amount))
      .reduce((total, num) => Number(total) + Number(num), 0);

    setRSExpensesCard({
      ...RSExpensesCard,
      value: _BaseUtil.formatCurrency("EUR", sum, "it-IT"),
    });
  };

  const fetchPredictionsRevenues = async () => {
    let result = await InvoicesApi_Cache.predictionsRevenues(businessId);

    setRevenuesCard({
      ...RevenuesCard,
      value: _BaseUtil.formatCurrency("EUR", result, "it-IT"),
    });
  };

  const fetchTransactions = async () => {
    let result = await TransactionsApi_Cache.get(businessId);

    const today = new Date();

    result = result.filter(
      (x) =>
        x.closedOn && new Date(x.closedOn).getFullYear() === today.getFullYear()
    );

    setTotalEarnings(TransactionsUtil.sumAmount(result));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Gestione Finanziaria</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/financialAssistant">Finanza</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Programmazione finanziaria
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xxl={4}>
            <FinancialAssistantAggregate />
          </Col>
          <Col xxl={8}>
            <Row>
              <Col sm={4}>
                <CardSimpleSummary
                  title={TaxesCard.title}
                  icon={TaxesCard.icon}
                  value={TaxesCard.value}
                  badgeClass={TaxesCard.badgeClass}
                  badgeValue={TaxesCard.badgeValue}
                  badgeDescription={TaxesCard.badgeDescription}
                />
              </Col>
              {/* <Col sm={4}>
                <CardSimpleSummary
                  title={RedditivityIndexCard.title}
                  icon={RedditivityIndexCard.icon}
                  value={RedditivityIndexCard.value}
                  badgeClass={RedditivityIndexCard.badgeClass}
                  badgeValue={RedditivityIndexCard.badgeValue}
                  badgeDescription={RedditivityIndexCard.badgeDescription}
                />
              </Col> */}
              <Col sm={4}>
                <CardSimpleSummary
                  title={RSExpensesCard.title}
                  icon={RSExpensesCard.icon}
                  value={RSExpensesCard.value}
                  badgeClass={RSExpensesCard.badgeClass}
                  badgeValue={RSExpensesCard.badgeValue}
                  badgeDescription={RSExpensesCard.badgeDescription}
                />
              </Col>
              <Col sm={4}>
                <CardSimpleSummary
                  title={RevenuesCard.title}
                  icon={RevenuesCard.icon}
                  value={RevenuesCard.value}
                  badgeClass={RevenuesCard.badgeClass}
                  badgeValue={RevenuesCard.badgeValue}
                  badgeDescription={RevenuesCard.badgeDescription}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-9">
                        <h5 class="text-primary">
                          Semplifica la tua gestione finaziaria con HOLO!
                        </h5>
                        <p>
                          Ecco alcuni semplici consigli per automatizzare la tua
                          serenità finanziaria:
                        </p>
                        <div class="text-muted">
                          <p class="mb-1">
                            <i class="mdi mdi-circle-medium align-middle text-primary me-1"></i>{" "}
                            Separa il tuo conto di lavoro da quello che usi per
                            le spese personali
                          </p>
                          <p class="mb-1">
                            <i class="mdi mdi-circle-medium align-middle text-primary me-1"></i>{" "}
                            Crea un flusso strutturato fra i due conti, in modo
                            da accantonare i fondi che ti serviranno in futuro
                          </p>
                          <p class="mb-0">
                            <i class="mdi mdi-circle-medium align-middle text-primary me-1"></i>{" "}
                            Pianifica gli investimenti in modo strutturato,
                            stima il denaro di cui hai bisogno e ricorri a un
                            partner per gestire le tue necessità
                          </p>
                        </div>
                      </div>
                      <div class="col-3">
                        <div>
                          <img
                            src="/assets/images/profile-img-openbanking.png"
                            alt=""
                            class="img-fluid d-block"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <div class="card">
                  <div class="card-body card-std-height-xs">
                    <div class="d-flex align-items-center mb-3">
                      <div class="flex-shrink-0">
                        <div class="avatar-sm me-3">
                          <span class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                            1
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <div>
                          <h5 class="font-size-14 mb-1">
                            <a href="#">Dove accantoni?</a>
                          </h5>
                          <h6 class="font-size-14 mb-0">Scegli il conto</h6>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-2">
                      <p>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-primary btn-md mb-1 disabled"
                        >
                          <i class="bx bx-plus-medical align-middle"></i>{" "}
                          Imposta conto
                        </a>
                      </p>
                      <span>Automatizza i trasferimenti con HOLO</span>
                      <i class="bx bxs-bolt bx-tada text-warning font-size-22 align-middle"></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div class="card">
                  <div class="card-body card-std-height-xs">
                    <div class="d-flex align-items-center mb-3">
                      <div class="flex-shrink-0">
                        <div class="avatar-sm me-3">
                          <span class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                            2
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <div>
                          <h5 class="font-size-14 mb-1">
                            <a href="#">Spese ricorrenti e imprevisti?</a>
                          </h5>
                          <h6 class="font-size-14 mb-0">
                            Accantona un importo extra
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-2">
                      <p>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-primary btn-md mb-1 disabled"
                        >
                          <i class="bx bx-plus-medical align-middle"></i>{" "}
                          Imposta extra mensile
                        </a>
                      </p>
                      <span>Holo già considera la tua reddività media</span>
                      <i class="bx bxs-bulb bx-tada text-warning font-size-22 align-middle"></i>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div class="card">
                  <div class="card-body card-std-height-xs">
                    <div class="d-flex align-items-center mb-3">
                      <div class="flex-shrink-0">
                        <div class="avatar-sm me-3">
                          <span class="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                            3
                          </span>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <div>
                          <h5 class="font-size-14 mb-1">
                            <a href="#">Devi investire?</a>
                          </h5>
                          <h6 class="font-size-14 mb-0">
                            Programma un finanziamento
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="text-center mt-2">
                      <p>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-primary btn-md mb-1 disabled"
                        >
                          <i class="bx bx-plus-medical align-middle"></i>{" "}
                          Richiedi finanziamento
                        </a>
                      </p>
                      <span>Crea un piano per gli investimenti</span>
                      <i class="bx bxs-flag-alt bx-tada text-warning font-size-22 align-middle"></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
