import { Link } from "react-router-dom";

export const CardTableUtil = {
  getCellText: function (text, className) {
    return {
      type: "text",
      text,
      className,
    };
  },
  getCellLink: function (text, className, link, isHtml) {
    return {
      type: "link",
      text,
      className,
      link,
      isHtml,
    };
  },
  getCellBadge: function (text, className, badgeClassName) {
    return {
      type: "badge",
      text,
      className,
      badgeClassName,
    };
  },
  getCellActions: function (name, actions) {
    return {
      type: "actions",
      name: `actions-${name}`,
      actions,
    };
  },
  getAction: function (name, text, onClick) {
    return {
      name: `action-${name}`,
      text,
      onClick,
    };
  },
};

export const CardTable = ({ title, columnNames, values, buttons }) => {
  const renderColumnName = (column, index) => (
    <th key={`th-${index}`} className="align-middle">
      {column.value}
    </th>
  );

  const renderRow = (value, index) => (
    <tr key={`tr-${index}`}>
      {columnNames.map((item, index) => (
        <td key={`td-${index}`}>{renderCell(value[item.key])}</td>
      ))}
    </tr>
  );

  const renderCell = (value) => {
    if (!value) return;
    switch (value.type) {
      case "link":
        return renderCellLink(value);
      case "badge":
        return renderCellBadge(value);
      case "actions":
        return renderCellActions(value);
      default:
        return renderCellText(value);
    }
  };

  const renderCellLink = (value) => (
    <Link to={value.link} className={`${value.className}`}>
      {value.isHtml ? (
        <span dangerouslySetInnerHTML={{ __html: value.text }} />
      ) : (
        value.text
      )}
    </Link>
  );

  const renderCellBadge = (value) => (
    <span
      className={`badge badge-pill ${value.badgeClassName} font-size-12 ${value.className}`}
    >
      {value.text}
    </span>
  );

  const renderCellActions = (value) => {
    return (
      <div key={value.name} className="dropdown">
        <Link
          className="dropdown-toggle card-drop"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="mdi mdi-dots-horizontal font-size-18"></i>
        </Link>
        <div className="dropdown-menu dropdown-menu-end">
          {value.actions.map((x, i) => renderCellAction(x, i))}
        </div>
      </div>
    );
  };

  const renderCellAction = (action) => {
    return (
      <>
        <button
          key={`action-${action.name}`}
          type="button"
          className="dropdown-item"
          // data-bs-toggle="modal"
          // data-bs-target={`.confirm-modal-${action.name}`}
          onClick={action.onClick}
        >
          {action.text}
        </button>
        {/* <ConfirmModal
          id={`confirm-modal-${action.name}`}
          title={`Conferma`}
          text={`Attenzione, sei sicuro di voler proseguire?`}
          confirmText={`Conferma`}
          cancelText={`Annulla`}
          onConfirm={action.onClick}
        /> */}
      </>
    );
  };

  const renderCellText = (value) => (
    <span className={value.className}>{value.text}</span>
  );

  const renderButtons = () => {
    if (!buttons || buttons.length === 0) return null;

    return buttons.map((button) => (
      <button
        key={`button-${button.label}`}
        onClick={button.onClick}
        type="button"
        className="btn btn-sm btn-success btn-rounded waves-effect waves-light mb-2 me-2"
      >
        {button.label}
      </button>
    ));
  };

  return (
    <>
      <div className="card">
        <div className="card-body card-std-height-l">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h4 className="card-title mb-4 mt-1">{title}</h4>
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end">{renderButtons()}</div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table align-middle table-nowrap table-check">
              <thead>
                <tr>
                  {columnNames?.map((item, index) =>
                    renderColumnName(item, index)
                  )}
                </tr>
              </thead>
              <tbody>
                {values?.map((item, index) => renderRow(item, index))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
