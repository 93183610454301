import { _BaseUtil } from "./_BaseUtil";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";

export const OrdersUtil = {
  getModelTemplate: async function (businessId) {
    let today = new Date();

    let order = {
      code: await this.getNextAvailableCodeAsync(
        businessId,
        today.getFullYear()
      ),
      revisionNumber: 1,
      contactId: null,
      allowPublicPageEdit: true,
      subject: "",
      description: "",
      closingDays: 60,
      tags: "",
      currencyType: 1, //1. Fiat, 2. Crypto
      currency: "EUR",
      customerNotes: "",
      status: 1, //1. Draft, 2. Waiting, 3. Accepted, 4. Declined, 5. Reviewed
      revisedorderId: "", // To fill only for revise
    };

    order.date = today;
    order.expirationDate = today.addDays(5);
    order.closingDate = today.addDays(order.closingDays);

    let model = {
      order: order,
      rows: [],
      conditions: [],
      links: [],
    };

    return model;
  },

  getNextAvailableCodeAsync: async function (businessId, year) {
    const orders = await OrdersApi_Cache.get(businessId);

    if (!orders || !orders.length) {
      // If there are no existing codes, start with 1/2023
      return `1/${year}`;
    }

    let existingCodes = orders.map((x) => x.order.code).filter((x) => !!x);

    existingCodes = existingCodes
      .filter((code) => code.endsWith(`/${year}`)) // Filter by current year
      .map((code) => code.split("/")[0]); // Extract the number

    const maxNumber = Math.max(...existingCodes);

    // Increment the maximum number
    const nextNumber = maxNumber + 1;
    return `${nextNumber}/${year}`;
  },

  countByCustomerId: function (ordersModel, customerId) {
    return _BaseUtil.countBy(
      ordersModel.map((x) => x.order),
      "customerId",
      customerId
    );
  },

  countByServiceId: function (ordersModel, serviceId) {
    let count = 0;
    for (let index in ordersModel) {
      let rows = ordersModel[index].rows;
      let serviceRows = rows.filter((x) => x.serviceId === serviceId);
      if (serviceRows.length) count++;
    }
    return count;
  },

  getStatusBadge: function (status) {
    switch (status) {
      case 1:
        return "bg-info";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-success";
      default:
        break;
    }
    return "";
  },

  getStatusDescription: function (status) {
    switch (status) {
      case 1:
        return "Aperto";
      case 2:
        return "Annullato";
      case 3:
        return "Completato";
      default:
        break;
    }
    return "";
  },

  getInvoiceType: function (invoiceType) {
    switch (invoiceType) {
      case 1:
        return "No Fattura";
      case 2:
        return "Con fattura d'anticipo";
      case 3:
        return "Alla Consegna";
      default:
        break;
    }
    return "";
  },

  getAmountByCustomerId: function (
    orders,
    customerId,
    status,
    currency,
    culture
  ) {
    orders = orders.filter((x) => x.customerId === customerId);
    orders = orders.filter((x) => x.status === status);
    // Use the reduce function to calculate the sum
    const amount = orders.reduce((accumulator, currentService) => {
      return accumulator + currentService.totalAmountServicesGross;
    }, 0);
    return _BaseUtil.formatCurrency(currency, amount.toFixed(2), culture);
  },
};
