import { ContactsApi } from "../../ContactsApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "contacts";

export const ContactsApi_Cache = {
  apiName: "ContactsApi_Cache",

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    const newData = await ContactsApi.create(businessId, payload);

    if (newData) {
      $data.set([...$data.get(), newData]); //adding object to the existing array
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, newData);

    return newData;
  },

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || nocache) {
      cacheData = await ContactsApi.get(
        businessId,
        isExpired || nocache,
        noloader
      );
      $data.set(cacheData); //overriding the whole array

      const cacheDuration = currentDate.plus({
        seconds: process.env.REACT_APP_CACHE_DURATION,
      });
      $expiresOn.set(cacheDuration);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return (cacheData && cacheData.filter((x) => x)) || [];
  },

  find: async function (businessId, contactId) {
    const fnName = "find";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      contactId
    );

    const { $data, $expiresOn } = atomCache(businessId, KEY, []);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();
    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired) {
      cacheData = await ContactsApi.get(businessId);
      $data.set(cacheData); //overriding the whole array

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData.find((x) => x.rowKey === contactId) || {};
  },

  update: async function (businessId, contactId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      contactId,
      payload
    );

    const { $data } = atomCache(businessId, KEY, []);

    payload = await ContactsApi.update(businessId, contactId, payload);

    if (payload) {
      const cacheData = $data.get();
      const others = cacheData.filter((item) => item.rowKey !== contactId);
      $data.set([...others, payload]);
    }

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, payload);

    return payload;
  },
};
