import React from "react";

export const GoogleIntegrationCalendar = ({ businessId, isActive }) => {
  const handleConnectGoogleCalendar = () => {
    const clientId =
      "979469668996-d7mjg2pa555rpd5qofo3mbkp4ofhqn6h.apps.googleusercontent.com";
    const redirectUri = `${process.env.REACT_APP_REDIRECT_URL}/google-oauth-callback`;
    const scopes = "https://www.googleapis.com/auth/calendar";
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=${businessId}&access_type=offline&prompt=consent`;

    // Redirige l'utente alla pagina di autorizzazione di Google
    window.location.href = authUrl;
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Integrazione con Google Calendar</h5>
        {!!isActive ? (
          <p className="card-text">Google Calendar collegato!</p>
        ) : (
          <>
            <p className="card-text">
              Collega il tuo Google Calendar per sincronizzare gli eventi e
              gestire le attività direttamente dalla piattaforma.
            </p>
            <button
              className="btn btn-primary"
              onClick={handleConnectGoogleCalendar}
            >
              Collega Google Calendar
            </button>
          </>
        )}
      </div>
    </div>
  );
};
