import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/reactTags.css";

import { BusinessContext } from "../contexts/AppContexts";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { PreInvoicesApi_Cache } from "../api/decorators/cache/PreInvoicesApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";

import { PreInvoiceDetailsCard } from "../components/PreInvoiceDetailsCard";
import { CardTable, CardTableUtil } from "../components/CardTable";
import { PageHeader } from "../components/PageHeader";

export default function PreInvoiceDetails() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();
  const navigate = useNavigate();

  const [preInvoiceModel, setPreInvoiceModel] = useState(null);
  const [contact, setContact] = useState(null);
  const [orderModel, setOrderModel] = useState(null);
  const [estimateModel, setEstimateModel] = useState(null);
  const [estimateCondition, setEstimateCondition] = useState(null);

  const onLoadAsync = async () => {
    console.log("onLoadAsync");

    let preInvoicesModel = (await PreInvoicesApi_Cache.get(businessId)) || [];

    if (!preInvoicesModel) {
      console.log("No preInvoicesModel found");
      return;
    }

    let preInvoiceModel = preInvoicesModel.find(
      (x) => x.preInvoice.rowKey === id
    );

    if (!preInvoiceModel) {
      console.log("preInvoiceModel not found");
      return;
    }

    setPreInvoiceModel(preInvoiceModel);
  };

  useEffect(() => {
    onLoadAsync();
  }, []);

  useEffect(() => {
    onLoadAsync();
  }, [id]);

  const getContactAsync = async () => {
    console.log("getContactsAsync");

    if (!preInvoiceModel) return;

    let contacts = (await ContactsApi_Cache.get(businessId)) || [];

    let contact = contacts.find(
      (x) => x.rowKey === preInvoiceModel.preInvoice.customerId
    );

    if (!contact) {
      console.log("Contact not found");
      return;
    }

    setContact(contact);
  };

  const getOrderAsync = async () => {
    console.log("getOrderAsync");

    if (!preInvoiceModel) return;

    let ordersModel = (await OrdersApi_Cache.get(businessId)) || [];

    let orderModel = ordersModel.find(
      (x) => x.order.rowKey === preInvoiceModel.preInvoice.orderId
    );

    if (!orderModel) {
      console.log("Order not found");
      return;
    }

    setOrderModel(orderModel);
  };

  const getEstimateAsync = async () => {
    console.log("getEstimateAsync");

    if (!orderModel) return;

    let estimatesModel = (await EstimatesApi_Cache.get(businessId)) || [];

    let estimateModel = estimatesModel.find(
      (x) => x.estimate.rowKey === orderModel.estimateId
    );

    if (!estimateModel) {
      console.log("Estimate not found");
      return;
    }

    setEstimateModel(estimateModel);

    let estimateCondition = estimateModel.conditions.find(
      (x) => x.rowKey === estimateModel.estimate.estimateConditionId
    );

    setEstimateCondition(estimateCondition);
  };

  const [preInvoiceRowsCols, setPreInvoiceRowsCols] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo",
    },
  ]);
  const [preInvoiceRows, setPreInvoicesRows] = useState([]);
  const loadCardTablePreInvoiceRows = () => {
    if (!preInvoiceModel) return;
    let preInvoiceRows = preInvoiceModel.rows || [];
    preInvoiceRows = preInvoiceRows.filter((x) => x.serviceType === 1);

    let tblRows = preInvoiceRows.map((x) => {
      return {
        serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
        quantity: CardTableUtil.getCellText(`${x.quantity}`),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
        ),
      };
    });

    setPreInvoicesRows(tblRows);
  };

  const [plansCols, setPlansCol] = useState([
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo",
    },
  ]);
  const [plansRows, setPlansRows] = useState([]);
  const loadCardTablePlans = () => {
    if (!preInvoiceModel) return;
    let preInvoiceRows = preInvoiceModel.rows || [];
    let plans = preInvoiceRows.filter((x) => x.serviceType === 2);

    let tblRows = plans.map((x) => {
      return {
        serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
        quantity: CardTableUtil.getCellText(`${x.quantity}`),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
        ),
      };
    });

    setPlansRows(tblRows);
  };

  const [conditionsCols, setConditionsCols] = useState([
    {
      key: "name",
      value: "Nome",
    },
    {
      key: "default",
      value: "Default",
    },
  ]);
  const [conditionsRows, setConditionsRows] = useState([]);
  const loadCardTableConditions = () => {
    if (!preInvoiceModel) return;
    let preInvoiceConditions = preInvoiceModel.conditions || [];

    let tblRows = preInvoiceConditions.map((x) => {
      return {
        name: CardTableUtil.getCellText(`${x.name}`),
        default: CardTableUtil.getCellText(`${x.default ? "Si" : "No"}`),
      };
    });

    setConditionsRows(tblRows);
  };

  const [linksCols, setLinksCol] = useState([
    {
      key: "name",
      value: "Nome",
    },
    {
      key: "url",
      value: "Url",
    },
  ]);
  const [linksRows, setLinksRows] = useState([]);
  const loadCardTableLinks = () => {
    if (!preInvoiceModel) return;
    let preInvoiceLinks = preInvoiceModel.links || [];

    let tblRows = preInvoiceLinks.map((x) => {
      return {
        name: CardTableUtil.getCellText(`${x.name}`),
        quantity: CardTableUtil.getCellText(`${x.default ? "Si" : "No"}`),
      };
    });

    setLinksRows(tblRows);
  };

  useEffect(() => {
    getContactAsync();
    getOrderAsync();
    loadCardTablePreInvoiceRows();
    loadCardTablePlans();
    loadCardTableConditions();
    loadCardTableLinks();
  }, [preInvoiceModel]);

  useEffect(() => {
    getEstimateAsync();
  }, [orderModel]);

  return (
    <>
      {preInvoiceModel && (
        <>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <PageHeader
                    title="Dettaglio ProForma"
                    pageName={`Dettaglio ProForma`}
                    backPageName={`Elenco ProForma`}
                    backPageRoute={`/preInvoices`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4">
                  <PreInvoiceDetailsCard
                    preInvoice={preInvoiceModel.preInvoice}
                    contact={contact}
                    order={orderModel?.order}
                    estimateCondition={estimateCondition}
                  />
                </div>
                {preInvoiceRows?.length ? (
                  <div className="col-md-6 col-xxl-4">
                    <CardTable
                      key={`preInvoicedetails-card-table-preInvoicerows`}
                      title={`Righe`}
                      columnNames={preInvoiceRowsCols}
                      values={preInvoiceRows}
                    />
                  </div>
                ) : null}
                {plansRows?.length ? (
                  <div className="col-md-6 col-xxl-4">
                    <CardTable
                      key={`preInvoicedetails-card-table-plans`}
                      title={`Sottoscrizioni`}
                      columnNames={plansCols}
                      values={plansRows}
                    />
                  </div>
                ) : null}
                {conditionsRows?.length ? (
                  <div className="col-md-6 col-xxl-4">
                    <CardTable
                      key={`preInvoicedetails-card-table-conditions`}
                      title={`Condizioni`}
                      columnNames={conditionsCols}
                      values={conditionsRows}
                    />
                  </div>
                ) : null}
                {linksRows?.length ? (
                  <div className="col-md-6 col-xxl-4">
                    <CardTable
                      key={`preInvoicedetails-card-table-link`}
                      title={`Link utili`}
                      columnNames={linksCols}
                      values={linksRows}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
