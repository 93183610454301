import React, { useEffect, useState } from "react";

import { BusinessesApi_Cache } from "../api/decorators/cache/BusinessesApi_Cache";
import { UsersApi_Cache } from "../api/decorators/cache/UsersApi_Cache";
import { PecApi } from "../api/PecApi";

import { TabContent, TabPane, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

import RegisterStep1 from "./RegisterStep1";
import RegisterStep2 from "./RegisterStep2";
import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

const Register = () => {
  const [user, setUser] = useState(null);
  const [business, setBusiness] = useState(null);

  const [activeTab, setActiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const handleOnLoad = async () => {
    console.log("handleOnLoad");

    let user = await UsersApi_Cache.me();
    console.log("User", user);

    if (!user) throw "Utente non trovato";

    setUser(user);

    let businesses = await BusinessesApi_Cache.get();
    let business = businesses?.length ? businesses[0] : null;
    console.log("Business", business);
    setBusiness(business);

    //user check
    if (!user.firstName || !user.lastName) {
      toggleTab(1);
      return;
    }

    //business check
    if (!business?.vatNumber) {
      toggleTab(1);
      return;
    }

    if (business.status === 0) {
      atomClearCache();
      window.location.href = "/";
      return;
    }

    if (business.certifiedEmail) {
      await handleResentOtp(business.rowKey);
    }
    toggleTab(2);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  const toggleTab = (tab) => {
    console.log("toggleTab", tab);
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps];
      modifiedSteps.push(tab);
      setActiveTab(tab);
      setPassedSteps(modifiedSteps);
    }
  };

  async function updateUserAsync(obj) {
    console.log("updateUserAsync", obj);
    obj = await UsersApi_Cache.update(obj);
    setUser(obj);
  }

  async function handleCreateOrUpdateBusiness(obj) {
    console.log("handleCreateOrUpdateBusiness", obj);
    obj.eTag = undefined;
    obj = business?.rowKey
      ? await BusinessesApi_Cache.update(business.rowKey, obj)
      : await BusinessesApi_Cache.create(obj);
    await handleOnLoad();
  }

  async function validatePecAsync(otp) {
    console.log("validatePecAsync", otp);
    await PecApi.validate(business.rowKey, { otp });
    atomClearCache();
    handleOnLoad();
  }

  const handleResentOtp = async (businessId) => {
    console.log("handleResentOtp");
    await PecApi.send(businessId || business.rowKey);
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-10 col-xl-10">
            <div className="card overflow-hidden">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-9">
                    <div className="text-primary p-4">
                      <h1 className="text-primary">Attivazione</h1>
                      <p>Attiva le funzioni di HOLO per il tuo business.</p>
                    </div>
                  </div>
                  <div className="col-3 align-self-end">
                    <img
                      loading="lazy"
                      src="/assets/images/profile-img.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="card-body pt-0">
                <div>
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img
                        loading="lazy"
                        src="/assets/images/logo.svg"
                        alt="logo"
                        className="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </div>

                <div id="kyc-verify-wizard" className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({
                          current: activeTab === 1,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === 1,
                          })}
                          onClick={() => {
                            toggleTab(1);
                          }}
                          disabled={!passedSteps.includes(1)}
                        >
                          <span className="number">1.</span>
                          Dati dell'attività
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({
                          current: activeTab === 2,
                        })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === 2,
                          })}
                          onClick={() => {
                            toggleTab(2);
                          }}
                          disabled={!passedSteps.includes(2)}
                        >
                          <span className="number">2.</span>
                          Conferma dati
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>

                  <div className="content clearfix">
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      {user ? (
                        <TabPane tabId={1} id="personal-info">
                          <RegisterStep1
                            user={user}
                            business={business || {}}
                            updateUserAsync={updateUserAsync}
                            createBusinessAsync={handleCreateOrUpdateBusiness}
                          ></RegisterStep1>
                        </TabPane>
                      ) : (
                        ""
                      )}

                      {user && business?.vatNumber ? (
                        <TabPane tabId={2} id="confirm-email">
                          <RegisterStep2
                            user={user}
                            business={business}
                            back={() => {
                              toggleTab(1);
                            }}
                            onUpdateBusiness={handleCreateOrUpdateBusiness}
                            validatePecAsync={validatePecAsync}
                            resendOtpAsync={handleResentOtp}
                          ></RegisterStep2>
                        </TabPane>
                      ) : (
                        ""
                      )}
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <div>
                <p>
                  ©<script>document.write(new Date().getFullYear());</script>
                  Holo | Get Paid. Designed with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by HoloTeam
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
