// styles
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.min.css";

// Skote CSS
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/app.min.css";

import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/DatePicker.css"; // Import the CSS file you created

import "./assets/css/holo.css";

import "./styles/index.css";

// react
import React from "react";
import ReactDOM from "react-dom/client";
// msal
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./configs/authConfig";
import { MsalProvider } from "@azure/msal-react";

import App from "./App";

console.log("Redirect " + process.env.REACT_APP_REDIRECT_URL);

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    let activeAccount = msalInstance.getAllAccounts()[0];

    console.log("Found active account", activeAccount);

    msalInstance.setActiveAccount(activeAccount);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <MsalProvider instance={msalInstance}>
      <App></App>
    </MsalProvider>
  );
});
