import { api } from "../configs/axiosConfigs";

export const CompaniesApi = {
  apiName: "CompaniesApi",
  baseUrl: "/webapp/businesses",

  verify: async function (businessId, vatNumber) {
    const fnName = "verify";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      vatNumber
    );

    const response = await api.get(
      `${this.baseUrl}/${businessId}/companies?vatNumber=${vatNumber}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
