import React from "react";

export const ConfirmModal = ({
  id,
  title,
  text,
  confirmText,
  confirmClass,
  cancelText,
  onConfirm,
}) => {
  return (
    <>
      <div
        className={`modal fade ${id || "confirm-modal"}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`confirm-modal-label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-primary"
                id={`confirm-modal-label`}
              >
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="mb-1">{text}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn ${confirmClass || "btn-warning"}`}
                data-bs-dismiss="modal"
                onClick={onConfirm}
              >
                {confirmText}
              </button>
              <button
                type="button"
                className={`btn btn-secondary`}
                data-bs-dismiss="modal"
              >
                {cancelText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
