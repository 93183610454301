import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    localStorage.clear();

    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <Link
      role="button"
      className="dropdown-item text-danger"
      onClick={() => handleLogout("redirect")}
    >
      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
      <span key="t-logout">Logout</span>
    </Link>
    // <DropdownButton variant="secondary" className="ml-auto" drop="start" title="Sign Out">
    //     <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</Dropdown.Item>
    //     <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>
    // </DropdownButton>
  );
};
