import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuickActionsButton from "./Buttons/QuickActionsButton";

export const CardMainSummary = ({
  last_access,
  user_full_name,
  user_job_description,
  user_avatar,
  total_receivables,
  next_60_days_value,
  current_value,
  overdue_value,
  next_60_days_percent,
  current_percent,
  overdue_percent,
}) => {
  const { t } = useTranslation("dashboard");
  const culture_lang = "it-IT";
  const percent_number_format = new Intl.NumberFormat(culture_lang, {
    style: "percent",
    maximumFractionDigits: 0,
  });

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-6 col-xxl-3">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <img
                    src={user_avatar}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <p className="mb-1 text-capitalize-first-word">
                      {last_access}
                    </p>
                    <h5 className="mb-1">Ciao {user_full_name}!</h5>
                    <p className="mb-0">{user_job_description} </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 d-xxl-none align-self-center d-flex justify-content-between mt-4">
              <p className="text-muted text-truncate mb-2 text-capitalize-first-word">
                <Link>{t("total_receivables")}</Link>
                <h5 className="mb-0">{total_receivables}</h5>
              </p>
              <QuickActionsButton />
            </div>

            <div className="col-xxl-7 align-self-center">
              <div className="text-xxl-center mt-4 mt-xxl-0">
                <div className="row">
                  <div className="d-none d-xxl-block col-xxl-3">
                    <div>
                      <p className="text-muted text-truncate mb-2 text-capitalize-first-word">
                        <Link>{t("total_receivables")}</Link>
                      </p>
                      <h5 className="mb-0">{total_receivables}</h5>
                    </div>
                  </div>
                  <div className="col-4  col-xxl-3">
                    <div>
                      <p className="text-muted text-truncate mb-2 text-capitalize-first-word">
                        <Link>{t("next_60_days")}</Link>
                      </p>
                      <h5 className="mb-0">
                        {next_60_days_value}
                        <span className="badge badge-soft-primary font-size-12 mx-1 relative-top--2">
                          {percent_number_format.format(next_60_days_percent)}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="col-4  col-xxl-3">
                    <div>
                      <p className="text-muted text-truncate mb-2 text-capitalize-first-word">
                        <Link>{t("current")}</Link>
                      </p>
                      <h5 className="mb-0">
                        {current_value}
                        <span className="badge badge-soft-success font-size-12 mx-1 relative-top--2">
                          {percent_number_format.format(current_percent)}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="col-4  col-xxl-3">
                    <div>
                      <p className="text-muted text-truncate mb-2 text-capitalize-first-word">
                        <Link>{t("overdue")}</Link>
                      </p>
                      <h5 className="mb-0">
                        {overdue_value}
                        <span className="badge badge-soft-danger font-size-12 mx-1 relative-top--2">
                          {percent_number_format.format(overdue_percent)}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="d-none d-xxl-block col-xxl-3">
                    <p className="text-muted mb-2"></p>
                  </div>
                  <div className="col-12 col-xxl-9">
                    <div className="progress progress-xl">
                      <div
                        className="progress-bar bg-primary progress-bar-striped"
                        role="progressbar"
                        style={{ width: next_60_days_percent * 100 + "%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar bg-success progress-bar-striped"
                        role="progressbar"
                        style={{ width: current_percent * 100 + "%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar bg-danger progress-bar-striped"
                        role="progressbar"
                        style={{ width: overdue_percent * 100 + "%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-2 d-none d-xxl-block align-self-center">
              <QuickActionsButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
