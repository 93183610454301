const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 my-1 text-center text-sm-start">
            © {new Date().getFullYear()} Holo | Get Paid
          </div>
          <div className="col-sm-6 my-1 text-center text-sm-end">
            Designed with <i className="mdi mdi-heart text-danger"></i> by
            HoloTeam
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
