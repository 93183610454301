import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const PreInvoicesApi = {
    apiName: "PreInvoicesApi",
    baseUrl: "/webapp/businesses",

    get: async function(businessId, nocache, noloader) {
        const fnName = "get";

        console.log(
            `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
            businessId,
            nocache
        );

        let options = _BaseApi.getHeaders(nocache, noloader);

        const response = await api.get(
            `${this.baseUrl}/${businessId}/preInvoices`,
            options
        );

        console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

        return response.data;
    },
};