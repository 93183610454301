import { Col, Row } from "reactstrap";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { OrdersUtil } from "../utils/OrdersUtil";
import { TransactionsUtil } from "../utils/TransactionsUtil";

import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";
import { ConfirmModal } from "../components/ConfirmModal";

export const OrderDetailsCard = ({
  order,
  estimate,
  estimateCondition,
  onComplete,
}) => {
  return (
    !!order && (
      <>
        <div className="card card-std-height-l">
          <div className="card-body border-bottom">
            <div className="d-flex">
              <div className="flex-shrink-0 me-4">
                <img
                  src="/assets/images/services/wired-outline-1020-rules-book-guideline.gif"
                  alt="generic order"
                  className="avatar-sm"
                />
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-15">{order.subject}</h5>
                <DocumentDetailsCardItem
                  routeText={OrdersUtil.getStatusDescription(order.status)}
                  className={`badge ${OrdersUtil.getStatusBadge(
                    order.status
                  )} font-size-12`}
                />
              </div>
            </div>
            {!!order.notes && (
              <div className="mt-2">
                <h5 className="font-size-15">Note:</h5>
                <p className="text-muted">{order.notes}</p>
              </div>
            )}

            <div className="row task-dates mt-5">
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Cliente`}
                  icon={`bx bx-buildings`}
                  route={`/contact/${order.customerId}/profile`}
                  routeText={ContactsUtil.getFullCustomerName(
                    order.customerType,
                    order.customerFirstName,
                    order.customerLastName,
                    order.customerBusinessName
                  )}
                />
              </div>
              {!!estimate && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Preventivo`}
                    icon={`bx bx-buildings`}
                    route={`/estimate/${estimate.rowKey}/details`}
                    routeText={estimate.subject || "Preventivo"}
                  />
                </div>
              )}
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Data creazione`}
                  icon={`bx bx-calendar`}
                  route={null}
                  routeText={_BaseUtil.formatDate(order.createdOn, "it-IT")}
                />
              </div>
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Data consegna`}
                  icon={`bx bx-calendar-check`}
                  route={null}
                  routeText={_BaseUtil.formatDate(order.deliveryDate, "it-IT")}
                />
              </div>
              {!!order.closedOn && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Data chiusura`}
                    icon={`bx bx-calendar-check`}
                    route={null}
                    routeText={_BaseUtil.formatDate(order.closedOn, "it-IT")}
                  />
                </div>
              )}
              {!!estimateCondition && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Condizione selezionata`}
                    icon={`bx bx-receipt`}
                    route={null}
                    routeText={estimateCondition.name}
                  />
                </div>
              )}
              <div className="col-6 col-lg-4 p-2">
                <DocumentDetailsCardItem
                  title={`Importo totale`}
                  icon={`bx bx-money`}
                  route={null}
                  routeText={_BaseUtil.formatCurrency(
                    order.currency,
                    order.totalAmountGross,
                    "it-IT"
                  )}
                />
              </div>
              {!!order.totalVatAmount && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Importo iva`}
                    icon={`bx bx-money`}
                    route={null}
                    routeText={_BaseUtil.formatCurrency(
                      order.currency,
                      order.totalVatAmount,
                      "it-IT"
                    )}
                  />
                </div>
              )}
              {!!order.discountPercentage && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto %`}
                    icon={`bx bxs-discount`}
                    route={null}
                    routeText={`${order.discountPercentage}%`}
                  />
                </div>
              )}
              {!!order.discountAmountGross && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Sconto ${order.currency}`}
                    icon={`bx bxs-discount`}
                    route={null}
                    routeText={_BaseUtil.formatCurrency(
                      order.currency,
                      order.discountAmountGross,
                      "it-IT"
                    )}
                  />
                </div>
              )}
              {!!order.paymentProvider && (
                <div className="col-6 col-lg-4 p-2">
                  <DocumentDetailsCardItem
                    title={`Provider`}
                    icon={`bx bxs-bank`}
                    routeText={TransactionsUtil.getPaymentProviderDescription(
                      order.paymentProvider
                    )}
                    className={`badge ${TransactionsUtil.getPaymentProviderBadge(
                      order.paymentProvider
                    )} font-size-12`}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="card-body">
            <Row>
              <Col xs={6} className="my-1"></Col>
              <Col xs={6} className="my-1">
                {order.status === 1 && (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`.confirm-modal`}
                  >
                    <i className="bx bx-money label-icon"></i> Richiedi saldo
                  </button>
                )}
              </Col>
            </Row>
          </div>
        </div>

        <ConfirmModal
          title={`Conferma richiesta saldo`}
          text={`Attenzione, confermando la richiesta di saldo verrà avviata la procedura di completamento dell'ordine inviando la pro forma al cliente, mentre al suo pagamento verrà generata la fattura. Confermi di voler proseguire?`}
          confirmText={`Conferma`}
          cancelText={`Annulla`}
          onConfirm={onComplete}
        />
      </>
    )
  );
};
