import { useContext } from "react";

import { BusinessContext } from "../contexts/AppContexts";

import { OpenBankingApi_Cache } from "../api/decorators/cache/OpenBankingApi_Cache";

export const OpenBankingCTA = ({ showCta = true }) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const connectOpenBanking = async () => {
    console.log("connectOpenBanking");

    let returnUrl = `${window.location.origin}?reset=true`;

    let connectUrl = await OpenBankingApi_Cache.connectAsync(
      businessId,
      returnUrl
    );
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  return (
    <>
      <div className="card bg-primary bg-soft card-std-height-xs">
        <div>
          <div className="row">
            <div className="col-12">
              <div className="text-primary p-3">
                <h1 className="text-primary">
                  Tutti i tuoi conti in un unico posto!
                </h1>
                <p>
                  Con Holo tieni sotto'occhio i tuoi soldi, incassi e uscite, in
                  modo aggregato.
                </p>

                <p className="mt-4">
                  Holo è un sistema per incassare quindi il nostro open banking
                  è in sola consultazione. Puoi dormire sonni tranquilli,{" "}
                  <strong>
                    nessuna disposizione in uscita dai tuoi conti correnti
                  </strong>{" "}
                  può essere effettuata dai nostri sistemi.
                </p>

                <p className="mt-4">
                  L'autorizzazione alla lettura dei movimenti dura 90 (novanta)
                  giorni, trascorsi i quali ti sarà richiesto di rinnovare il
                  consenso al collegamento. Sono supportati tutti i principali
                  istituti bancari italiani ed esteri.
                </p>

                {showCta && (
                  <>
                    <p className="mt-4 text-center">
                      <button
                        onClick={connectOpenBanking}
                        className="btn btn-primary btn-lg mb-1"
                      >
                        <i className="bx bx-link align-middle"></i>
                        Collega open banking
                      </button>
                    </p>
                    <p className="mt-2">
                      Al primo collegamento, sarà necessario attendere un'ora
                      per la sincronizzazione di tutti i movemtni
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
