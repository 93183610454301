import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const BusinessesApi = {
  apiName: "BusinessesApi",
  baseUrl: "/webapp/businesses",

  get: async function (nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(`${this.baseUrl}`, options);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  create: async function (payload) {
    const fnName = "create";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const response = await api.post(`${this.baseUrl}`, payload);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },

  update: async function (businessId, payload) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    payload = await api.put(`${this.baseUrl}/${businessId}`, payload);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${payload}`);

    return payload && payload.data;
  },

  uploadContract: async function (businessId, filename, file) {
    const fnName = "update";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      filename,
      file
    );

    const config = {
      headers: {
        "Content-Type": file.type,
        "x-filename": filename,
      },
    };

    const result = await api.post(
      `${this.baseUrl}/${businessId}/files/upload/contract`,
      file,
      config
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response:`, result);

    return result && result.data;
  },

  fattureInCloudConnect: async function (businessId, payload) {
    const fnName = "fattureInCloudConnect";

    console.log(
      `🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`,
      businessId,
      payload
    );

    payload = await api.post(
      `${this.baseUrl}/${businessId}/fattureincloud/connect`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${payload}`);

    return payload && payload.data;
  },

  fattureInCloudDisconnect: async function (businessId) {
    const fnName = "fattureInCloudDisconnect";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    const result = await api.delete(
      `${this.baseUrl}/${businessId}/fattureincloud/disconnect`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${result}`);

    return result && result.data;
  },

  getFattureInCloudCompanies: async function (businessId) {
    const fnName = "getFattureInCloudCompanies";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/fattureincloud/companies`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  getRecap: async function (businessId, nocache, noloader) {
    const fnName = "getRecap";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(`${this.baseUrl}/${businessId}`, options);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
