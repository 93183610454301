import React from "react";
import { Link } from "react-router-dom";
import CardInfo from "../../Cards/CardInfo";
import { TransactionsUtil } from "../../../utils/TransactionsUtil";

const PaymentCardInfo = ({ payment, className }) => {
  const renderDocumentLink = (payment) => {
    if (payment.subscriptionId)
      return (
        <Link
          to={`/subscription/${payment.subscriptionId}/details`}
          className={`align-self-start ms-1 text-truncate`}
        >
          <i className="bx bx-file me-1 text-muted"></i>{" "}
          {payment.subscriptionName}
        </Link>
      );

    if (payment.orderId)
      return (
        <Link
          to={`/order/${payment.orderId}/details`}
          className={`align-self-start ms-1 text-truncate`}
        >
          <i className="bx bx-file me-1 text-muted"></i> {payment.orderSubject}
        </Link>
      );

    if (payment.estimateId)
      return (
        <Link
          to={`/estimate/${payment.estimateId}/details`}
          className={`align-self-start ms-1 text-truncate`}
        >
          <i className="bx bx-file me-1 text-muted"></i>{" "}
          {payment.estimateSubject}
        </Link>
      );

    return "";
  };

  return (
    <CardInfo className={className}>
      <Link
        to={`/contact/${payment.contactId}/profile`}
        className={`align-self-start text-truncate`}
      >
        <i className="bx bx-buildings me-1 text-muted"></i>{" "}
        {payment.contactName}
      </Link>

      {renderDocumentLink(payment)}

      <span
        className={`badge ${TransactionsUtil.getStatusBadge(
          payment.status
        )} font-size-12 mt-0 align-self-start ms-1 text-truncate`}
      >
        {TransactionsUtil.getStatusDescription(payment)}
      </span>
    </CardInfo>
  );
};

export default PaymentCardInfo;
