import { _BaseUtil } from "./_BaseUtil";

export const ContactsUtil = {
  getTemplate: function (type) {
    let obj = {
      businessName: "",
      firstName: "",
      lastName: "",
      type: type, //1. Private, 2. Business
      countryCode: "IT",
      vatNumber: "",
      email: "",
      pecEmail: "",
      eInvoiceProviderCode: "",
      address: "",
      city: "",
      province: "",
      zipCode: "",
      streetNumber: "",
      phone: "",
      governmentId: "",
      tags: "",
    };

    return obj;
  },

  getFullName: function (contact) {
    if (!contact) return "";
    switch (contact.type) {
      case 1:
        return `${contact.firstName} ${contact.lastName}`;
      case 2:
        return `${contact.businessName}`;
      default:
        break;
    }
    return "";
  },

  getFullCustomerName: function (type, firstName, lastName, businessName) {
    switch (type) {
      case 1:
        return `${firstName} ${lastName}`;
      case 2:
        return `${businessName}`;
      default:
        break;
    }
    return "";
  },

  getTypeDescription: function (type) {
    switch (type) {
      case 1:
        return "Privato";
      case 2:
        return "Azienda/Freelance";
      default:
        break;
    }
    return "";
  },

  getType: function (type) {
    switch (type) {
      case 1:
        return "PRIVATE";
      case 2:
        return "AZIENDA/FREELANCE";
      default:
        break;
    }
    return "";
  },

  getStatus: function (statusCode) {
    switch (statusCode) {
      case -1:
        return "INACTIVE";
      case 0:
        return "ACTIVE";
      default:
        break;
    }
    return "";
  },

  getFullAddress: function (contact) {
    if (!contact) return "";
    return _BaseUtil.getFullAddress(
      contact.address,
      contact.streetNumber,
      contact.zipCode,
      contact.city,
      contact.country
    );
  },
};
