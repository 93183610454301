import { UsersApi } from "../../UsersApi";
import { atomCache } from "./AtomPersistence";
import { DateTime } from "luxon";

const KEY = "user";

export const UsersApi_Cache = {
  apiName: "UsersApi_Cache",

  me: async function (noloader) {
    const fnName = "me";

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const { $data, $expiresOn } = atomCache("", KEY, null);

    let cacheData = $data && $data.get();

    let expirationDate = $expiresOn && $expiresOn.get();

    let currentDate = DateTime.utc();
    let isExpired =
      !expirationDate || DateTime.fromISO(expirationDate) < currentDate;

    if (isExpired || !cacheData) {
      cacheData = await UsersApi.me(noloader);
      $data.set(cacheData);

      const oneHourLater = currentDate.plus({ seconds: 3600 });
      $expiresOn.set(oneHourLater);
    }

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Response: `, cacheData);

    return cacheData;
  },

  update: async function (payload) {
    const fnName = "update";

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache("", KEY, null);

    payload = await UsersApi.update(payload);

    if (payload) $data.set(payload);

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Response`, payload);

    return payload;
  },

  uploadAvatar: async function (payload) {
    const fnName = "uploadAvatar";

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const { $data } = atomCache("", KEY, null);

    payload = await UsersApi.uploadAvatar(payload);

    if (payload) $data.set(payload);

    console.log(`🐝 ~ ${this.apiName} ~ ${fnName} ~ Response`);

    return payload;
  },
};
