import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { _BaseUtil } from "../utils/_BaseUtil";
import { EstimatesUtil } from "../utils/EstimatesUtil";

import { CardTable, CardTableUtil } from "../components/CardTable";

export const CardTableEstimates = ({ estimates }) => {
  const navigate = useNavigate();

  const [cardTableEstimatesColumns, setCardTableEstimatesColumns] = useState([
    {
      key: "code",
      value: "Codice",
    },
    {
      key: "subject",
      value: "Oggetto",
    },
    {
      key: "amount",
      value: "Importo",
    },
    {
      key: "status",
      value: "Stato",
    },
  ]);
  const [cardTableEstimatesValues, setCardTableEstimatesValues] = useState([]);

  const handleOnLoad = async () => {
    if (!estimates) return;

    let values = estimates
      .map((x) => {
        return {
          code: CardTableUtil.getCellLink(
            x.estimate.code,
            `text-primary fw-bold`,
            `/estimate/${x.estimate.rowKey}/details`
          ),
          subject: CardTableUtil.getCellLink(
            x.estimate.subject,
            `text-primary fw-bold`,
            `/estimate/${x.estimate.rowKey}/details`
          ),
          amount: CardTableUtil.getCellText(
            `${_BaseUtil.formatCurrency(
              x.estimate.currency,
              x.estimate.totalAmountServicesNet,
              "it-IT"
            )}`
          ),
          status: CardTableUtil.getCellBadge(
            `${EstimatesUtil.getStatusDescription(x.estimate.status)}`,
            null,
            `${EstimatesUtil.getStatusBadge(x.estimate.status)}`
          ),
        };
      })
      .filter((x) => x);

    setCardTableEstimatesValues(values);
  };

  useEffect(() => {
    handleOnLoad();
  }, [estimates]);

  const onClickNewEstimate = () => {
    navigate("/estimate");
  };

  return (
    <>
      <CardTable
        key={`ct-estimates`}
        title={`Preventivi`}
        columnNames={cardTableEstimatesColumns}
        values={cardTableEstimatesValues}
        buttons={[
          {
            label: `Nuovo preventivo`,
            onClick: onClickNewEstimate,
          },
        ]}
      />
    </>
  );
};
