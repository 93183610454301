import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ConfigurationsApi_Cache } from "../api/decorators/cache/ConfigurationsApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";

import SelectRadioButtonGroup from "../components/Buttons/SelectRadioButtonGroup";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ServicesUtil } from "../utils/ServicesUtil";
import { OrdersUtil } from "../utils/OrdersUtil";
import { Col, Row } from "reactstrap";

export default function Services() {
  const tagsColorClasses = ["primary", "success", "warning", "danger"];

  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const activeOptions = [
    {
      label: "Attivi",
      value: 0,
    },
    {
      label: "Disattivi",
      value: -1,
    },
  ];
  const [active, setActive] = useState(0);

  const [filter, setFilter] = useState("");
  const [tableRecords, setTableRecords] = useState([]);

  const loadTable = async (nocache) => {
    if (nocache) atomClearCache();

    let pServices = ServicesApi_Cache.get(businessId);
    let pOrders = OrdersApi_Cache.get(businessId);

    let promises = await Promise.all([pServices, pOrders]);

    let services = promises[0] || [];
    let orders = promises[1] || [];

    //cleaning the list
    services = services.filter((x) => x);

    //recupera servizi singoli e attivi
    services = services.filter((x) => x.type === 1 && x.status === active);

    services = _BaseUtil.filterList(services, filter);

    let result = services.map((x) => mapTableRecord(x, orders));

    result = result.sort((a, b) => a.name.localeCompare(b.name));

    setTableRecords(result);
  };

  function mapTableRecord(service, orders) {
    let obj = service;
    obj.id = service.rowKey;
    obj.ordersCount = OrdersUtil.countByServiceId(orders, service.rowKey);
    return obj;
  }

  useEffect(() => {
    loadTable();
  }, [filter]);

  useEffect(() => {
    loadTable();
  }, [active]);

  const [vatTypes, setVatTypes] = useState([]);

  const getVatTypes = async () => {
    const vatTypes = await ConfigurationsApi_Cache.getVatTypes(businessId);

    const sortedItems = [...vatTypes].sort((a, b) => {
      const intA = +a.id;
      const intB = +b.id;
      return intA - intB;
    });

    setVatTypes(sortedItems);
  };

  useEffect(() => {
    getVatTypes();
  }, []);

  const getVatPercentage = (vatTypeId) => {
    const value =
      vatTypes && vatTypes.find((x) => x.id === vatTypeId)?.percentage;
    return value ? `IVA: ${value}%` : `No IVA`;
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col md={12}>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Listino Servizi</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/services">Servizi</Link>
                    </li>
                    <li className="breadcrumb-item active">Listino Servizi</li>
                  </ol>
                </div>
              </div>
            </Col>

            <div className={`card d-sm-none`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <div className="row">
                    <div className="col-6 my-auto">
                      <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                    </div>
                    <div className="col-6 my-auto">
                      <div className="text-end">
                        <Link to="/service" className="btn btn-primary">
                          Nuovo Servizio
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 my-4">
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Filtra ..."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </div>
                        <div className="col-2">
                          <Link
                            onClick={() => loadTable(true)}
                            className="btn btn-light float-end"
                          >
                            <i className="mdi mdi-refresh"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <SelectRadioButtonGroup
                        key={`active-optgroup`}
                        defaultValue={active}
                        onValueChange={setActive}
                        options={activeOptions}
                        className={`float-end`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`card d-none d-sm-block`}>
              <div className="card-body border-bottom">
                <div className="d-flex align-items-center">
                  <h5 className="mb-0 card-title flex-grow-1">Cerca</h5>
                  <input
                    type="text"
                    className="form-control mx-4"
                    id="searchInput"
                    placeholder="Filtra ..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <div className="flex-shrink-0">
                    <Link to="/service" className="btn btn-primary">
                      Nuovo Servizio
                    </Link>
                    <Link
                      onClick={() => loadTable(true)}
                      className="btn btn-light mx-1"
                    >
                      <i className="mdi mdi-refresh"></i>
                    </Link>
                  </div>
                  <div className="flex-shrink-0 mx-1">
                    <SelectRadioButtonGroup
                      key={`active-optgroup`}
                      defaultValue={active}
                      onValueChange={setActive}
                      options={activeOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive tableReflow">
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}></th>
                      <th scope="col">Servizio</th>
                      <th scope="col" style={{ width: "200px" }}>
                        Prezzo
                      </th>
                      <th scope="col" style={{ width: "300px" }}>
                        Cod. Ateco
                      </th>
                      <th scope="col" style={{ width: "300px" }}>
                        Tag
                      </th>
                      <th scope="col" style={{ width: "250px" }}>
                        Vendite
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRecords.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none">
                              <Link
                                to={`/service/${item.rowKey}`}
                                className="text-dark"
                              >
                                <span className="text-primary font-size-22">
                                  <img
                                    src={
                                      item.imagePath ||
                                      "/assets/images/services/wired-outline-969-meeting-conference-room.gif"
                                    }
                                    alt={item.name || "Servizio"}
                                    className="mw-100"
                                  />
                                </span>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link
                                to={`/service/${item.rowKey}`}
                                className="text-dark"
                              >
                                {item.name}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0 text-wrap">
                              {item.description}
                            </p>
                          </td>
                          <td>
                            <h5 className="font-size-14 ">
                              {_BaseUtil.getCurrency(item.defaultCurrency)}{" "}
                              {_BaseUtil.formatDecimals(item.defaultAmount)}
                            </h5>
                            <p className="text-muted mb-0 text-wrap fst-italic">
                              {getVatPercentage(item.vatTypeId)}
                            </p>
                            <p className="text-muted mb-0 text-wrap">
                              UM:{" "}
                              {ServicesUtil.getUnitDescription(item.unitCode)}
                            </p>
                          </td>
                          <td>
                            <h5 className="font-size-14 ">{item.atecoCode}</h5>
                            <p className="text-muted mb-0 text-wrap fst-italic">
                              {_BaseUtil.getAteco(item.atecoCode)?.label}
                            </p>
                          </td>
                          <td>
                            {_BaseUtil.tagsToList(item.tags).map((tag, i) => (
                              <React.Fragment key={i}>
                                <span
                                  className={`fs-6 badge badge-soft-${
                                    tagsColorClasses[i]
                                  } ${i !== 0 ? "mt-1" : ""}`}
                                >
                                  {tag}
                                </span>
                                <br />
                              </React.Fragment>
                            ))}
                          </td>
                          <td>
                            <Row>
                              <Col md={4}>
                                <h5 className="font-size-14">
                                  Ordini: {item.ordersCount}
                                </h5>
                              </Col>
                              {/* <Col md={8}>
                                <div className="d-flex">
                                  <span className="badge badge-soft-success font-size-12">
                                    {" "}
                                    + 3{" "}
                                  </span>
                                  <span className="ms-2 text-truncate">
                                    {" "}
                                    questo quadrimestre
                                  </span>
                                </div>
                              </Col> */}
                            </Row>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
