import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { BusinessContext } from "../contexts/AppContexts";

import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";
import { InvoicesUtil } from "../utils/InvoicesUtil";

import { PaymentButton } from "./Buttons/PaymentButton";

import { ConfirmModal } from "../components/ConfirmModal";

export const InvoiceDetailsCard = ({ invoice, contact, condition }) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const navigate = useNavigate();

  const handleDelete = async () => {
    await InvoicesApi_Cache.delete(businessId, invoice.rowKey);
    navigate(`/invoices`);
  };

  const handleSendEInvoice = async () => {
    if (invoice.eInvoiceCreated) return;
    await InvoicesApi_Cache.createEInvoice(businessId, invoice.rowKey);
  };

  const handleCreateCreditNote = () => {
    navigate("/invoice", {
      state: { pInvoiceType: 3, pRelatedDocumentId: invoice.rowKey },
    });
  };

  return (
    <>
      <div className="card card-std-height-l">
        <div className="card-body binvoice-bottom">
          <div className="d-flex">
            <div className="flex-shrink-0 me-4">
              <img
                src="/assets/images/services/wired-outline-2446-invoice-euro.gif"
                alt="generic invoice"
                className="avatar-sm"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">{invoice.subject}</h5>
              <div className="d-flex">
                <DocumentDetailsCardItem
                  title={null}
                  icon={`bx bx-check-double`}
                  route={null}
                  routeText={InvoicesUtil.getTypeDescription(
                    invoice.invoiceType
                  )}
                  className={`badge ${InvoicesUtil.getTypeBadge(
                    invoice.invoiceType
                  )} font-size-12`}
                />
                <DocumentDetailsCardItem
                  title={null}
                  icon={`bx bx-check-double`}
                  route={null}
                  routeText={InvoicesUtil.getEInvoiceDescription(
                    invoice.eInvoiceCreated
                  )}
                  className={`badge ${InvoicesUtil.getEInvoiceBadge(
                    invoice.eInvoiceCreated
                  )} font-size-12 ms-1`}
                />
              </div>
            </div>
            {invoice.status === 0 && !invoice.eInvoiceCreated && (
              <div className="flex-shrink-0 ms-2">
                <Link
                  to={`/invoice/${invoice.rowKey}`}
                  className="btn btn-light btn-sm"
                >
                  <i className="bx bxs-pencil align-middle me-1"></i> Modifica
                </Link>
              </div>
            )}
          </div>
          {!!invoice.notes && (
            <div className="mt-2">
              <h5 className="font-size-15">Note:</h5>
              <p className="text-muted">{invoice.notes}</p>
            </div>
          )}

          <Row className="task-dates mt-5">
            {!!invoice.supplierName && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Fornitore`}
                  icon={`bx bx-buildings`}
                  routeText={invoice.supplierName}
                />
              </Col>
            )}
            {!!contact && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Cliente`}
                  icon={`bx bx-buildings`}
                  route={`/contact/${contact.rowKey}/profile`}
                  routeText={ContactsUtil.getFullName(contact)}
                />
              </Col>
            )}
            {!!invoice.relatedDocumentCode && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Documento collegato`}
                  icon={`bx bx-buildings`}
                  route={
                    invoice.relatedDocumentId
                      ? `/invoice/${invoice.relatedDocumentId}/details`
                      : null
                  }
                  routeText={invoice.relatedDocumentCode}
                />
              </Col>
            )}
            <Col xs={6} xxl={4} className="my-1">
              <DocumentDetailsCardItem
                title={`Data creazione`}
                icon={`bx bx-calendar`}
                route={null}
                routeText={_BaseUtil.formatDate(invoice.createdOn, "it-IT")}
              />
            </Col>
            {!!condition && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Modalità Pagamento`}
                  icon={`bx bx-money`}
                  routeText={condition.name}
                />
              </Col>
            )}
            {!!invoice.expirationDate && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Scadenza`}
                  icon={`bx bx-calendar`}
                  route={null}
                  routeText={_BaseUtil.formatDate(
                    invoice.expirationDate,
                    "it-IT"
                  )}
                />
              </Col>
            )}
            <Col xs={6} xxl={4} className="my-1">
              <DocumentDetailsCardItem
                title={`Importo`}
                icon={`bx bx-money`}
                route={null}
                routeText={_BaseUtil.formatCurrency(
                  invoice.currency,
                  invoice.totalAmountGross,
                  "it-IT"
                )}
              />
            </Col>
            {!!invoice.discountPercentage && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Sconto %`}
                  icon={`bx bxs-discount`}
                  route={null}
                  routeText={`${invoice.discountPercentage}%`}
                />
              </Col>
            )}
            {!!invoice.discountAmountGross && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Sconto ${invoice.currency}`}
                  icon={`bx bxs-discount`}
                  route={null}
                  routeText={_BaseUtil.formatCurrency(
                    invoice.currency,
                    invoice.discountAmountGross,
                    "it-IT"
                  )}
                />
              </Col>
            )}
            {!!invoice.stampDuty && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Bollo`}
                  icon={`bx bx-money`}
                  route={null}
                  routeText={_BaseUtil.formatCurrency(
                    "EUR",
                    invoice.stampDuty,
                    "it-IT"
                  )}
                  className={null}
                />
              </Col>
            )}
            {!!invoice.paymentTerms && (
              <Col xs={6} xxl={4} className="my-1">
                <DocumentDetailsCardItem
                  title={`Termini di pagamento`}
                  icon={`bx bx-money`}
                  route={null}
                  routeText={invoice.paymentTerms}
                  className={null}
                />
              </Col>
            )}
          </Row>
        </div>

        <div className="card-body border-top">
          <Row>
            <Col xs={6} className="my-1">
              <Link
                to={`/invoice/${invoice.rowKey}/preview`}
                className="btn btn-warning waves-effect btn-label waves-light w-100"
              >
                <i className="bx bx-file label-icon"></i> Visualizza
              </Link>
            </Col>
            {[1, 2].includes(invoice.invoiceType) &&
              !!invoice.aCubeEInvoiceUuid && (
                <>
                  <Col xs={6} className="my-1">
                    <button
                      onClick={handleCreateCreditNote}
                      className="btn btn-light waves-effect btn-label waves-light w-100"
                    >
                      <i className="bx bx-x label-icon"></i> Nota di credito
                    </button>
                  </Col>
                </>
              )}
            {!invoice.eInvoiceCreated && (
              <>
                <Col xs={6} className="my-1">
                  <button
                    type="button"
                    className="btn btn-success waves-effect btn-label waves-light w-100"
                    data-bs-toggle="modal"
                    data-bs-target={`.confirm-modal-sendeinvoice`}
                  >
                    <i className="bx bx-right-arrow-alt label-icon"></i> Invia
                    allo SDI
                  </button>
                </Col>
              </>
            )}
            {!invoice.eInvoiceCreated && !invoice.orderId && (
              <Col xs={6} className="my-1">
                <button
                  type="button"
                  className="btn btn-danger waves-effect btn-label waves-light w-100"
                  data-bs-toggle="modal"
                  data-bs-target={`.confirm-modal-delete`}
                >
                  <i className="bx bx-x label-icon"></i> Elimina
                </button>
              </Col>
            )}
            {!invoice.eInvoiceCreated &&
              !invoice.orderId &&
              invoice.invoiceType === 1 && (
                <Col xs={6} className="my-1">
                  <PaymentButton
                    businessId={businessId}
                    documentId={invoice.rowKey}
                    documentType={`invoice`}
                    sharable={true}
                  />
                </Col>
              )}
          </Row>
        </div>
      </div>

      <ConfirmModal
        id={`confirm-modal-delete`}
        title={`Conferma eliminazione!`}
        text={`Attenzione, la fattura verrà definitivamente eliminata, procedere?`}
        confirmText={`Elimina`}
        confirmClass={`btn-danger`}
        cancelText={`Annulla`}
        onConfirm={handleDelete}
      />

      <ConfirmModal
        id={`confirm-modal-sendeinvoice`}
        title={`Conferma invio elettronico!`}
        text={`Attenzione, il documento verrà inviato al sistema di interscambio (SDI) senza ulteriori possibilità di modifica, l'operazione non potrà essere annullata e in caso di errori si dovrà proseguire creando una nota di credito. L'operazione verrà eseguita in background, attendere qualche secondo e aggiornare la pagina per vedere gli stati aggiornati. Confermi di voler inviare il documento allo SDI? `}
        confirmText={`Confermo invio allo SDI`}
        confirmClass={`btn-success`}
        cancelText={`Annulla operazione`}
        onConfirm={handleSendEInvoice}
      />
    </>
  );
};
