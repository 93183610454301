import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { subMonths } from "date-fns";

import { BusinessContext } from "../contexts/AppContexts";

import { it } from "date-fns/locale"; // Import the Italian locale
import clsx from "clsx";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { ServicesApi_Cache } from "../api/decorators/cache/ServicesApi_Cache";
import { SuppliersApi_Cache } from "../api/decorators/cache/SuppliersApi_Cache";

import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  InputGroup,
  Button,
  Label,
  Container,
  FormFeedback,
} from "reactstrap";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { InvoicesUtil } from "../utils/InvoicesUtil";

import { ServicesCard } from "../components/ServicesCard";
import TotalAmountGross from "../components/Amounts/TotalAmountGross";

import { HoloTooltip } from "../components/HoloTooltip";
import { atomClearCache } from "../api/decorators/cache/AtomPersistence";
import { PaymentButton } from "../components/Buttons/PaymentButton";

export default function Invoice() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const me = {
    rowKey: businessId,
    type: 2, // 2. Azienda / Freelance
    governmentId: business.governmentId,
    businessName: business.businessName,
    businessTypeCode: business.businessTypeCode,
    businessTaxRegime: business.taxRegime,
    countryCode: business.countryCode,
    vatNumber: business.vatNumber,
    address: business.address,
    streetNumber: business.streetNumber,
    zipCode: business.zipCode,
    city: business.city,
    province: business.province,
    eInvoiceProviderCode: business.eInvoiceProviderCode,
    certifiedEmail: business.certifiedEmail,
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pMovement, pInvoiceType, pRelatedDocumentId } = location.state || {}; // Ensure there's a fallback if state is undefined

  const [model, setModel] = useState(null);
  const [relatedDocumentModel, setRelatedDocumentModel] = useState(null);
  const [isCreateMode, setIsCreateMode] = useState(null);
  const [isDraft, setIsDraft] = useState(null);

  const [contacts, setContacts] = useState([]);
  const [services, setServices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const [currentContact, setCurrentContact] = useState(null);
  const [currentSupplier, setCurrentSupplier] = useState(null);
  const [currentServices, setCurrentServices] = useState([]);

  const [documentType, setDocumentType] = useState(null);

  const baseValidationSchema = {
    contactId: Yup.string().required("Seleziona un contatto"),
    supplierId: Yup.string().required("Seleziona un fornitore"),
    subject: Yup.string().required("Oggetto obbligatorio"),
    documentDate: Yup.string().required("Data documento obbligatoria"),
    expirationDate: Yup.string().required("Data scadenza obbligatoria"),
    paymentTerms: Yup.string().required("Termini di pagamento obbligatori"),
  };

  const autoInvoiceValidationSchema = {
    ...baseValidationSchema,
    relatedDocumentCode: Yup.string().required(
      "Id fattura estera obbligatoria"
    ),
    relatedDocumentDate: Yup.string().required(
      "Data fattura estera obbligatoria"
    ),
    purchaseType: Yup.string().required("Tipologia di acquisto obbligatoria"),
  };

  const invoiceValidationSchema = {
    ...baseValidationSchema,
  };

  // Usa una variabile di stato per mantenere lo schema attuale
  const [currentValidationSchema, setCurrentValidationSchema] =
    useState(baseValidationSchema);

  let form = useFormik({
    enableReinitialize: true,
    initialValues: {
      contactId: null,
      supplierId: null,
      subject: null,
      relatedDocumentCode: null,
      relatedDocumentDate: null,
      documentDate: null,
      expirationDate: null,
      paymentTerms: null,
    },
    onSubmit: () => handleSubmit(),
    validationSchema: Yup.object(currentValidationSchema),
  });

  useEffect(() => {
    handleOnLoad();
  }, [businessId, id, pInvoiceType]);

  const fetchContacts = async () => {
    if (contacts.length) return;
    if (pInvoiceType === 4) {
      setContacts([me]);
      return;
    }
    setContacts(await ContactsApi_Cache.get(businessId));
  };
  const fetchServices = async () => {
    if (services.length) return;

    if (pInvoiceType === 4 && pMovement) {
      let expService = {
        rowKey: "00000000-0000-0000-0000-000000000000",
        code: "EXP-AUTOINVOICE",
        name: "Spesa",
        type: 1,
        description: pMovement.description,
        defaultAmount: Math.abs(pMovement.amount),
        defaultQuantity: 1,
        defaultCurrency: pMovement.currency,
        vatTypeId: "3", // 22%, took from server
      };
      setServices([expService]);
      return;
    }

    setServices(
      (await ServicesApi_Cache.get(businessId)).filter(
        (s) => s.type === 1 && s.status === 0
      )
    );
  };
  const fetchSuppliers = async () => {
    if (suppliers.length) return;
    if (pInvoiceType !== 4) {
      setSuppliers([me]);
      return;
    }
    setSuppliers(await SuppliersApi_Cache.get(businessId));
  };

  useEffect(() => {
    if (!model) return;
    if (!model.invoice) return;
    if (!model.rows) return;

    const { invoice, rows } = model;

    const isAutoInvoice = invoice.invoiceType === 4;

    let formValues = {
      contactId: invoice.customerId,
      subject: invoice.subject,
      relatedDocumentId: pRelatedDocumentId,
      relatedDocumentCode: invoice.relatedDocumentCode,
      relatedDocumentDate: invoice.relatedDocumentDate,
      documentDate: new Date(invoice.date),
      expirationDate: new Date(invoice.expirationDate),
      paymentTerms: invoice.paymentTerms,
    };

    if (isAutoInvoice) {
      formValues.supplierId = invoice.businessId;
      formValues.contactId = invoice.customerId || me.rowKey;
      formValues.purchaseType = "1";
      formValues.relatedDocumentDate = new Date(invoice.relatedDocumentDate);
      setCurrentValidationSchema(autoInvoiceValidationSchema);
      setCurrentContact(me);
    } else {
      formValues.supplierId = invoice.businessId || me.rowKey;
      formValues.contactId = invoice.customerId;
      setCurrentValidationSchema(invoiceValidationSchema);
      setCurrentSupplier(me);
    }

    form.setValues(formValues);

    setDocumentType(model.invoice.invoiceType);
    setCurrentServices(rows.filter((x) => x.serviceType === 1));

    const isCreateMode = !model.invoice.rowKey;
    const isDraft = isCreateMode || model.invoice.status === 0;

    setIsCreateMode(isCreateMode);
    setIsDraft(isDraft);
  }, [model]);

  const handleRefresh = () => {
    atomClearCache();
    handleOnLoad();
  };

  const handleOnLoad = () => {
    if (!businessId) return;
    fetchContacts();
    fetchServices();
    fetchSuppliers();
    initInvoiceModel();
  };

  const initInvoiceModel = async () => {
    if (!businessId) return;

    if (id) {
      let model = (await InvoicesApi_Cache.get(businessId)).find(
        (x) => x.invoice.rowKey === id
      );
      setModel(model);
      return;
    }

    let model = await InvoicesUtil.getModelTemplate(businessId, pInvoiceType);

    if (pRelatedDocumentId) {
      const relatedDocument = (await InvoicesApi_Cache.get(businessId)).find(
        (x) => x.invoice.rowKey === pRelatedDocumentId
      );
      setRelatedDocumentModel(relatedDocument);

      model.invoice.relatedDocumentId = relatedDocument.invoice.rowKey;
      model.invoice.relatedDocumentCode = relatedDocument.invoice.code;
      if (relatedDocument.invoice.sequenceLetter) {
        model.invoice.relatedDocumentCode +=
          "/" + relatedDocument.invoice.sequenceLetter;
      }
      model.invoice.relatedDocumentDate = relatedDocument.invoice.date;
      model.invoice.customerId = relatedDocument.invoice.customerId;
      model.invoice.subject = `Storno Fattura ${relatedDocument.invoice.code} ${
        relatedDocument.invoice.subject
      } del ${_BaseUtil.formatDate(relatedDocument.invoice.date, "it-IT")}`;
      model.rows = relatedDocument.rows;
    }

    if (pMovement) {
      if (model.invoice.invoiceType === 4) {
        for (let index in services) {
          let current = services[index];
          let expRow = {
            serviceId: current.rowKey,
            serviceCode: current.code,
            serviceName: current.name,
            serviceDescription: current.description,
            serviceType: current.type,
            amount: current.defaultAmount,
            currency: current.defaultCurrency,
            currencyType: 1,
            quantity: current.defaultQuantity,
            vatTypeId: current.vatTypeId,
          };
          model.rows = [...model.rows, expRow];
        }
      }

      const movement_madeon_formatted = _BaseUtil.formatDate(
        pMovement.madeOn,
        "it-IT"
      );

      model.invoice.subject = `Rif: ${pMovement.description} del ${movement_madeon_formatted} conto: ${pMovement.bankName}`;
      model.invoice.date = new Date(pMovement.madeOn);
      model.invoice.expirationDate = new Date(pMovement.madeOn);
      model.invoice.relatedDocumentDate = new Date(pMovement.madeOn);

      if (model.invoice.invoiceType === 4) {
        model.invoice.paymentTerms = `Pagamento eseguito in data ${movement_madeon_formatted}`;
      } else {
        model.invoice.paymentTerms = `Incasso ricevuto in data ${movement_madeon_formatted}`;
      }
    }

    setModel(model);
  };

  const handleSubmit = async () => {
    const errors = await form.validateForm();

    if (Object.keys(errors).length) {
      console.log("form is not valid", errors);
      return;
    }

    try {
      const {
        contactId,
        supplierId,
        subject,
        relatedDocumentId,
        relatedDocumentCode,
        closingDays,
        allowPublicPageEdit,
        notes,
        paymentTerms,
      } = form.values;

      const contact = contacts.find((x) => x.rowKey === contactId);
      const supplier = suppliers.find((x) => x.rowKey === supplierId);

      const documentDate = !!form.values.documentDate
        ? new Date(form.values.documentDate)
        : null;

      const expirationDate = !!form.values.expirationDate
        ? new Date(form.values.expirationDate)
        : null;

      const relatedDocumentDate = !!form.values.relatedDocumentDate
        ? new Date(form.values.relatedDocumentDate)
        : null;

      const payload = {
        invoice: {
          code: model.invoice.code,
          sequenceLetter: business.sequenceLetter,
          allowPublicPageEdit,
          subject,
          relatedDocumentId,
          relatedDocumentCode,
          relatedDocumentDate: !relatedDocumentDate
            ? null
            : new Date(relatedDocumentDate).toJSON(),
          date: !documentDate ? null : new Date(documentDate).toJSON(),
          expirationDate: !expirationDate
            ? null
            : new Date(expirationDate).toJSON(),
          closingDays,
          tags: "",
          paymentTerms,
          currencyType: model.invoice.currencyType,
          currency: model.invoice.currency,
          status: model.invoice.status,
          invoiceType: documentType,
          purchaseType: form.values.purchaseType,
          notes: notes,
          isManuallyCreated: true,
          //business
          businessId: supplier.rowKey,
          businessType: supplier.type,
          businessFirstName: supplier.firstName,
          businessLastName: supplier.lastName,
          businessGovernmentId: supplier.governmentId,
          businessBusinessName: supplier.businessName,
          businessBusinessTypeCode: supplier.businessTypeCode,
          businessTaxRegime: supplier.businessTaxRegime,
          businessCountryCode: supplier.countryCode,
          businessAddress: supplier.address,
          businessStreetNumber: supplier.streetNumber,
          businessZipCode: supplier.zipCode,
          businessCity: supplier.city,
          businessProvince: supplier.province,
          businessPhone: supplier.phone,
          businessEmail: supplier.email,
          businessVatNumber: supplier.vatNumber,
          businessEInvoiceProviderCode: supplier.eInvoiceProviderCode,
          businessCertifiedEmail: supplier.certifiedEmail,
          //customer
          customerId: contact.rowKey,
          customerType: contact.type,
          customerFirstName: contact.firstName,
          customerLastName: contact.lastName,
          customerGovernmentId: contact.governmentId,
          customerBusinessName: contact.businessName,
          customerBusinessTypeCode: contact.businessTypeCode,
          customerTaxRegime: contact.businessTaxRegime,
          customerCountryCode: contact.countryCode,
          customerAddress: contact.address,
          customerStreetNumber: contact.streetNumber,
          customerZipCode: contact.zipCode,
          customerCity: contact.city,
          customerProvince: contact.province,
          customerPhone: contact.phone,
          customerEmail: contact.email,
          customerVatNumber: contact.vatNumber,
          customerEInvoiceProviderCode: contact.eInvoiceProviderCode,
          customerCertifiedEmail: contact.certifiedEmail,
        },
        rows: [...currentServices].map((x) => ({
          ...x,
          eTag: undefined,
          invoiceId: model.invoice.rowKey,
        })),
      };

      let result = isCreateMode
        ? await InvoicesApi_Cache.create(businessId, payload)
        : await InvoicesApi_Cache.update(
            businessId,
            model.invoice.rowKey,
            payload
          );

      if (!result) return;

      const invoiceId = result.invoice.rowKey;

      const movementId = pMovement?.rowKey;

      if (invoiceId && movementId) {
        await InvoicesApi_Cache.associateMovements(businessId, invoiceId, [
          {
            movementId,
            amount: pMovement.amount,
            currency: pMovement.currency,
            currencyType: pMovement.currencyType,
          },
        ]);
      }

      navigate(`/invoice/${invoiceId}`, {
        state: { nocache: true },
      });
      return;
    } catch {
      toast.error("C'è stato un errore durante il salvataggio");
    }
  };

  const onAddService = function () {
    setCurrentServices([
      ...(currentServices ?? []),
      {
        serviceId: "",
        serviceName: "",
        quantity: 0,
        amount: 0,
        serviceType: 1,
      },
    ]);
  };

  return (
    !!model && (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {isCreateMode ? "Crea" : "Modifica"}{" "}
                  {InvoicesUtil.getTypeDescription(documentType)}
                  {documentType === 3 && relatedDocumentModel && (
                    <p className="text-muted fs-6">
                      Storno fattura: {relatedDocumentModel.invoice.code}
                    </p>
                  )}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      {isCreateMode ? (
                        <Link to="/invoices">Fatture</Link>
                      ) : (
                        <Link to={`/invoice/${model.invoice.rowKey}/details`}>
                          Dettagli
                        </Link>
                      )}
                    </li>
                    <li className="breadcrumb-item active">
                      {isCreateMode ? "Nuovo" : "Modifica"}
                    </li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {!!isCreateMode && model.invoice.invoiceType === 1 && (
            <Row>
              <Col>
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                  <h4 className="alert-heading">
                    Attenzione, utilizza sempre il flusso Holo!
                  </h4>
                  <p>
                    <div>
                      Il flusso Holo parte dall'accettazione di un preventivo
                      fino all'automatizzazione completa di incassi e fatture,
                      questo garantisce uniformità e pulizia nei tuoi movimenti
                      per il calcolo delle tasse.
                    </div>
                    <div>
                      Uscire dal flusso Holo creando direttamente le fatture può
                      metterti in seria difficoltà sia lato contrattuale sia con
                      i conteggi del tuo commercialista che potrebbe farti
                      pagare un extra se i dati non risultassero corretti.
                    </div>
                    <div>
                      Pertanto noi di HoloTeam ti consigliamo di creare
                      manualmente una fattura e fartela pagare solo in casi
                      estremi, se possibile ti consigliamo di seguire sempre il
                      flusso Holo inviando al tuo cliente il preventivo.
                    </div>
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Form>
                <div className="card">
                  <div className="card-body">
                    <Container fluid>
                      <fieldset disabled={!isDraft}>
                        <Row>
                          {/* invoice logo */}
                          <Col xs={12} xxl={4} className="mb-3">
                            <img
                              src="/assets/images/invoice.png"
                              alt="Holo Invoice Logo"
                              className="img-fluid img-doc"
                            ></img>
                            <div className="position-absolute top-0 start-10 translate-middle fs-2 m-2">
                              <i className="bx bxs-add-to-queue fs-18 text-primary"></i>
                            </div>
                          </Col>

                          <Col xs={12} xxl={4}>
                            <Row>
                              <Col
                                xs={12}
                                className={documentType === 4 ? "d-none" : ""}
                              >
                                <FormGroup className="px-0">
                                  <Label>Scegli un contatto</Label>
                                  <InputGroup>
                                    <Input
                                      invalid={
                                        !!form.submitCount &&
                                        !!form.errors.contactId
                                      }
                                      type="select"
                                      placeholder="Seleziona un contatto..."
                                      name="contactId"
                                      value={form.values.contactId || ""}
                                      onChange={(e) => {
                                        const optValue = e.target.value;
                                        form.setFieldValue(
                                          "contactId",
                                          optValue
                                        );
                                        setCurrentContact(
                                          contacts.find(
                                            (x) => x.rowKey === optValue
                                          )
                                        );
                                      }}
                                      disabled={[3].includes(documentType)}
                                    >
                                      {[
                                        <option value="" disabled>
                                          Scegli un contatto
                                        </option>,
                                        contacts.map((x) => {
                                          return (
                                            <option
                                              key={`contact-${x.rowKey}`}
                                              value={x.rowKey}
                                            >
                                              {!x.vatNumber
                                                ? `${x.lastName} ${x.firstName}`
                                                : x.businessName}
                                            </option>
                                          );
                                        }),
                                      ]}
                                    </Input>
                                    {isCreateMode && (
                                      <Button
                                        color="primary"
                                        onClick={() => navigate("/contact")}
                                      >
                                        Nuovo
                                      </Button>
                                    )}
                                    {!!currentContact && (
                                      <Button
                                        color="light"
                                        onClick={() =>
                                          navigate(
                                            `/contact/${currentContact.rowKey}`
                                          )
                                        }
                                      >
                                        Modifica
                                      </Button>
                                    )}
                                  </InputGroup>
                                  <FormFeedback
                                    className={clsx("d-block", {
                                      "d-none":
                                        !form.submitCount ||
                                        !form.errors.contactId,
                                    })}
                                  >
                                    {form.errors.contactId}
                                  </FormFeedback>
                                </FormGroup>
                                <ul
                                  className={clsx(
                                    "list-unstyled",
                                    "vstack",
                                    "mt-2",
                                    {
                                      "d-none": !currentContact?.address,
                                    }
                                  )}
                                >
                                  <li>
                                    <div className="d-flex">
                                      <i className="bx bx-map font-size-18 text-primary"></i>
                                      <span className="mx-1 text-muted fw-semibold">
                                        Sede legale:
                                      </span>
                                      <span className="text-muted mx-1">
                                        {ContactsUtil.getFullAddress(
                                          currentContact
                                        )}
                                      </span>
                                      <span>
                                        <a
                                          target="_blank"
                                          href={`https://www.google.it/maps/search/${ContactsUtil.getFullAddress(
                                            currentContact
                                          )}`}
                                          rel="noreferrer"
                                        >
                                          <i className="bx bx-link-external"></i>
                                        </a>
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      className={clsx("d-flex", "mt-2", {
                                        "d-none": !!currentContact?.firstName,
                                      })}
                                    >
                                      <i className="bx bx-user-circle font-size-18 text-primary"></i>
                                      <span className="mx-1 text-muted fw-semibold">
                                        Referente:
                                      </span>
                                      <span className="text-muted mx-1">
                                        {`${currentContact?.firstName} ${currentContact?.lastName}`}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                              <Col
                                xs={12}
                                className={documentType !== 4 ? "d-none" : ""}
                              >
                                <FormGroup className="px-0">
                                  <Label>Scegli un fornitore</Label>
                                  <InputGroup>
                                    <Input
                                      invalid={
                                        !!form.submitCount &&
                                        !!form.errors.supplierId
                                      }
                                      type="select"
                                      placeholder="Seleziona un fornitore..."
                                      name="supplierId"
                                      value={form.values.supplierId || ""}
                                      onChange={(e) => {
                                        const optValue = e.target.value;
                                        form.setFieldValue(
                                          "supplierId",
                                          optValue
                                        );
                                        setCurrentSupplier(
                                          suppliers.find(
                                            (x) => x.rowKey === optValue
                                          )
                                        );
                                      }}
                                    >
                                      {[
                                        <option value="" disabled>
                                          Scegli un fornitore
                                        </option>,
                                        suppliers.map((x) => {
                                          return (
                                            <option
                                              key={`supplier-${x.rowKey}`}
                                              value={x.rowKey}
                                            >
                                              {x.businessName}
                                            </option>
                                          );
                                        }),
                                      ]}
                                    </Input>
                                    {isCreateMode && (
                                      <Button
                                        color="primary"
                                        onClick={() => navigate("/supplier")}
                                      >
                                        Nuovo
                                      </Button>
                                    )}
                                    {!!currentSupplier && (
                                      <Button
                                        color="light"
                                        onClick={() =>
                                          navigate(
                                            `/supplier/${currentSupplier.rowKey}`
                                          )
                                        }
                                      >
                                        Modifica
                                      </Button>
                                    )}
                                  </InputGroup>
                                  <FormFeedback
                                    className={clsx("d-block", {
                                      "d-none":
                                        !form.submitCount ||
                                        !form.errors.supplierId,
                                    })}
                                  >
                                    {form.errors.supplierId}
                                  </FormFeedback>
                                </FormGroup>
                                <ul
                                  className={clsx(
                                    "list-unstyled",
                                    "vstack",
                                    "mt-2",
                                    {
                                      "d-none": !currentSupplier?.address,
                                    }
                                  )}
                                >
                                  <li>
                                    <div className="d-flex">
                                      <i className="bx bx-map font-size-18 text-primary"></i>
                                      <span className="mx-1 text-muted fw-semibold">
                                        Sede legale:
                                      </span>
                                      <span className="text-muted mx-1">
                                        {ContactsUtil.getFullAddress(
                                          currentSupplier
                                        )}
                                      </span>
                                      <span>
                                        <a
                                          target="_blank"
                                          href={`https://www.google.it/maps/search/${ContactsUtil.getFullAddress(
                                            currentSupplier
                                          )}`}
                                          rel="noreferrer"
                                        >
                                          <i className="bx bx-link-external"></i>
                                        </a>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </Col>

                          {/* subject and dates */}
                          <Col xs={12} xxl={4}>
                            <Row>
                              <Col xs={12}>
                                <FormGroup>
                                  <Label>Oggetto</Label>
                                  <Input
                                    type="text"
                                    name="subject"
                                    invalid={
                                      !!form.submitCount && form.errors.subject
                                    }
                                    placeholder="Inserisci oggetto"
                                    value={form.values.subject}
                                    onChange={form.handleChange}
                                  ></Input>
                                  <FormFeedback>
                                    {form.errors.subject}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <Label className="text-truncate d-inline-block">
                                  Data Documento
                                </Label>
                                <FormGroup>
                                  <DatePicker
                                    locale={it}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    placeholder="Data Documento"
                                    selected={form.values.documentDate}
                                    onChange={(value) =>
                                      form.setFieldValue("documentDate", value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={6}>
                                <Label className="text-truncate d-inline-block">
                                  Data Scadenza
                                </Label>
                                <FormGroup>
                                  <DatePicker
                                    locale={it}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    placeholder="Data Scadenza"
                                    selected={form.values.expirationDate}
                                    onChange={(value) =>
                                      form.setFieldValue(
                                        "expirationDate",
                                        value
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={12}>
                                <FormGroup>
                                  <Label>Termini di pagamento</Label>
                                  <Input
                                    type="text"
                                    name="paymentTerms"
                                    invalid={
                                      !!form.submitCount &&
                                      form.errors.paymentTerms
                                    }
                                    placeholder="Esempio: Bonifico immediato"
                                    value={form.values.paymentTerms}
                                    onChange={form.handleChange}
                                  ></Input>
                                  <FormFeedback>
                                    {form.errors.paymentTerms}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {documentType === 4 && (
                                <>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label>Id fattura estera</Label>
                                      <HoloTooltip
                                        id="tooltip-id-foreign-invoice"
                                        description={`Inserisci l'identificativo che trovi sulla fattura estera`}
                                        className="ms-1"
                                      />
                                      <Input
                                        type="text"
                                        name="relatedDocumentCode"
                                        invalid={
                                          !!form.submitCount &&
                                          form.errors.relatedDocumentCode
                                        }
                                        placeholder="Inserisci l'identificativo che trovi sulla fattura estera"
                                        value={form.values.relatedDocumentCode}
                                        onChange={form.handleChange}
                                      ></Input>
                                      <FormFeedback>
                                        {form.errors.relatedDocumentCode}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label>Tipologia di acquisto</Label>
                                      <Input
                                        type="select"
                                        name="purchaseType"
                                        invalid={
                                          !!form.submitCount &&
                                          form.errors.purchaseType
                                        }
                                        value={form.values.purchaseType}
                                        onChange={form.handleChange}
                                      >
                                        <option value="1">
                                          Acquisto servizi dall'estero
                                        </option>
                                        <option value="2">
                                          Acquisto beni dall'estero (ex art.17
                                          c. 2 DPR 633/72)
                                        </option>
                                      </Input>
                                      <FormFeedback>
                                        {form.errors.purchaseType}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={6}>
                                    <Label className="text-truncate d-inline-block">
                                      Data Fattura estera
                                    </Label>
                                    <FormGroup>
                                      <DatePicker
                                        locale={it}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={subMonths(new Date(), 1)} // sottrae un mese dalla data corrente
                                        placeholder="Inserisci la data presente sulla fattura estera"
                                        selected={
                                          form.values.relatedDocumentDate
                                        }
                                        onChange={(value) =>
                                          form.setFieldValue(
                                            "relatedDocumentDate",
                                            value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </fieldset>
                      <Row className="my-2 pt-4 border-top">
                        <Col xs={6} xxl={4}>
                          <p className="fw-medium mb-0">
                            {InvoicesUtil.getTypeDescription(documentType)}{" "}
                            N&ordm;
                          </p>
                          <h4>
                            <span>{model.invoice.code}</span>
                          </h4>
                        </Col>

                        {/* totals */}
                        <Col xs={6} xxl={4}>
                          <div className="d-flex flex-row">
                            <div className="px-2">
                              {[3].includes(documentType) ? (
                                <p className="fw-medium mb-0">
                                  Totale da stornare
                                </p>
                              ) : (
                                <p className="fw-medium mb-0">Totale Servizi</p>
                              )}
                              <h4 className="mb-0">
                                €{" "}
                                {_BaseUtil.formatDecimals(
                                  currentServices.reduce(
                                    (px, x) => px + x.quantity * x.amount,
                                    0
                                  )
                                )}
                              </h4>
                              <TotalAmountGross services={currentServices} />
                            </div>
                          </div>
                        </Col>

                        {!!currentServices?.filter((x) => !!x.serviceId)
                          ?.length && (
                          <>
                            <Col xs={12} xxl={4}>
                              <Row>
                                {!(
                                  !!isCreateMode ||
                                  model.invoice.invoiceType !== 1 ||
                                  !!model.invoice.eInvoiceCreated
                                ) && (
                                  <Col xs={12} md={6} className="mt-3">
                                    <PaymentButton
                                      businessId={businessId}
                                      documentId={model.invoice.rowKey}
                                      documentType={`invoice`}
                                      sharable={true}
                                      onClick={handleRefresh}
                                    />
                                  </Col>
                                )}

                                {!!model.invoice.rowKey && (
                                  <Col xs={12} md={6} className="mt-3">
                                    <Link
                                      to={`/invoice/${model.invoice.rowKey}/details`}
                                      className={clsx(
                                        "btn",
                                        "btn-info",
                                        "btn-label",
                                        "waves-effect",
                                        "waves-light",
                                        "w-100",
                                        { "d-none": !isDraft }
                                      )}
                                    >
                                      <i className="bx bx-arrow-back label-icon"></i>{" "}
                                      Vai ai dettagli
                                    </Link>
                                  </Col>
                                )}

                                <Col xs={12} md={6} className="mt-3">
                                  <Button
                                    type="submit"
                                    onClick={form.handleSubmit}
                                    className={clsx(
                                      "btn",
                                      "btn-success",
                                      "btn-label",
                                      "waves-effect",
                                      "waves-light",
                                      "w-100",
                                      { "d-none": !isDraft }
                                    )}
                                  >
                                    <i className="bx bx-save label-icon"></i>
                                    {isCreateMode ? "Salva" : "Aggiorna"}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Container>
                  </div>
                </div>
              </Form>
            </Col>
            <Col xxl={6}>
              <ServicesCard
                values={currentServices}
                onChangeValues={setCurrentServices}
                onAddService={onAddService}
                services={services}
                cardLabel="Servizi"
                btnLabel="Aggiungi un servizio"
                disabled={!isDraft}
                isPlan={false}
              ></ServicesCard>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}
