import { useEffect, useState } from "react";

import { _BaseUtil } from "../utils/_BaseUtil";
import { SubscriptionsUtil } from "../utils/SubscriptionsUtil";

import { CardTable, CardTableUtil } from "../components/CardTable";

export const CardTableSubscriptions = ({ title, subscriptions }) => {
  const cardTableSubscriptionsColumns = [
    {
      key: "serviceName",
      value: "Sottoscrizione",
    },
    {
      key: "startDate",
      value: "Data inizio",
    },
    {
      key: "endDate",
      value: "Data fine",
    },
    {
      key: "amount",
      value: "Importo",
    },
    {
      key: "billingInterval",
      value: "Fatturazione",
    },
    {
      key: "automaticRenew",
      value: "Rinnovo automatico",
    },
    {
      key: "status",
      value: "Stato",
    },
  ];

  const [cardTableSubscriptionsValues, setCardTableSubscriptionsValues] =
    useState([]);

  const handleOnLoad = async () => {
    if (!subscriptions?.length) return;

    let values = subscriptions
      .map((x) => {
        return {
          serviceName: CardTableUtil.getCellLink(
            x.serviceName,
            `text-primary fw-bold`,
            `/subscription/${x.rowKey}/details`
          ),
          startDate: CardTableUtil.getCellText(
            _BaseUtil.formatDate(x.startDate, "it-IT")
          ),
          endDate: CardTableUtil.getCellText(
            _BaseUtil.formatDate(x.endDate, "it-IT")
          ),
          amount: CardTableUtil.getCellText(
            _BaseUtil.formatCurrency(x.currency, x.totalAmountNet, "it-IT")
          ),
          billingInterval: CardTableUtil.getCellText(
            `Ogni ${x.billingInterval === 1 ? "" : x.billingInterval} ${
              x.billingInterval === 1 ? "mese" : "mesi"
            }`
          ),
          automaticRenew: CardTableUtil.getCellBadge(
            `${x.automaticRenew ? "Si" : "No"}`,
            null,
            `${x.automaticRenew ? "bg-info" : "bg-warning"}`
          ),
          status: CardTableUtil.getCellBadge(
            `${SubscriptionsUtil.getStatusDescription(x.status)}`,
            null,
            `${SubscriptionsUtil.getStatusBadge(x.status)}`
          ),
        };
      })
      .filter((x) => x);

    setCardTableSubscriptionsValues(values);
  };

  useEffect(() => {
    handleOnLoad();
  }, [subscriptions]);

  return (
    <>
      <CardTable
        key={`ct-subscriptions`}
        title={title}
        columnNames={cardTableSubscriptionsColumns}
        values={cardTableSubscriptionsValues}
      />
    </>
  );
};
