import React from "react";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { DocumentDetailsCardItem } from "./DocumentDetailsCardItem";
import { SubscriptionsUtil } from "../utils/SubscriptionsUtil";

export const SubscriptionDetailsCard = ({ subscription, contact, order }) => {
  return (
    <>
      <div className="card card-std-height-l">
        <div className="card-body border-bottom">
          <div className="d-flex">
            <div className="flex-shrink-0 me-4">
              <img
                src="/assets/images/services/wired-outline-28-calendar.gif"
                alt="generic subscription"
                className="avatar-sm"
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">
                {subscription.serviceName}
              </h5>
              <DocumentDetailsCardItem
                routeText={SubscriptionsUtil.getStatusDescription(
                  subscription.status
                )}
                className={`badge ${SubscriptionsUtil.getStatusBadge(
                  subscription.status
                )} font-size-12`}
              />
            </div>
          </div>

          <div className="row task-dates mt-5">
            {contact ? (
              <div className="col-6 col-xxl-4 p-2">
                <DocumentDetailsCardItem
                  title={`Cliente`}
                  icon={`bx bx-buildings`}
                  route={`/contact/${contact.rowKey}/profile`}
                  routeText={ContactsUtil.getFullName(contact)}
                />
              </div>
            ) : null}
            {!!order && (
              <div className="col-6 col-xxl-4 p-2">
                <DocumentDetailsCardItem
                  title={`Ordine`}
                  icon={`bx bx-buildings`}
                  route={`/order/${order.rowKey}/details`}
                  routeText={order.subject || "Ordine"}
                />
              </div>
            )}
            <div className="col-6 col-xxl-4 p-2">
              <DocumentDetailsCardItem
                title={`Inizio`}
                icon={`bx bx-calendar`}
                route={null}
                routeText={_BaseUtil.formatDate(
                  subscription.startDate,
                  "it-IT"
                )}
              />
            </div>
            <div className="col-6 col-xxl-4 p-2">
              <DocumentDetailsCardItem
                title={`Fine`}
                icon={`bx bx-calendar-check`}
                route={null}
                routeText={_BaseUtil.getLastMonthDate(
                  subscription.endDate,
                  "it-IT"
                )}
              />
            </div>
            <div className="col-6 col-xxl-4 p-2">
              <DocumentDetailsCardItem
                title={`Importo totale`}
                icon={`bx bx-money`}
                route={null}
                routeText={_BaseUtil.formatCurrency(
                  subscription.currency,
                  subscription.totalAmountGross,
                  "it-IT"
                )}
              />
            </div>
            {!!subscription.totalVatAmount && (
              <div className="col-6 col-xxl-4 p-2">
                <DocumentDetailsCardItem
                  title={`Importo iva`}
                  icon={`bx bx-money`}
                  route={null}
                  routeText={_BaseUtil.formatCurrency(
                    subscription.currency,
                    subscription.totalVatAmount,
                    "it-IT"
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
