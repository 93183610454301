import React from "react";
import { Link } from "react-router-dom";

import { _BaseUtil } from "../utils/_BaseUtil";
import { BankAccountsUtil } from "../utils/BankAccountsUtil";

export const FinancialAssistantAggregate = () => {
  return (
    <div class="card card-std-height-l">
      <div class="bg-primary bg-soft">
        <div class="row">
          <div class="col-8">
            <div class="text-primary p-3">
              <h2 class="text-primary">Assistente finanziario</h2>
              <p>
                Sulla base del tuo indice di redditività e la stima delle tasse
                da versare, HOLO ti suggerirà quanto accantonare ogni mese.
              </p>
            </div>
          </div>
          <div class="col-4 align-self-end">
            <img
              src="assets/images/profile-img-fiat.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <div class="row">
          <div class="table-responsive">
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th scope="col">Mese</th>
                  <th scope="col">Accantonamento</th>
                  <th scope="col">Conto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">
                    <a href="#">Giugno 23</a>
                  </td>
                  <td>
                    € 800,00
                    <p class="text-muted mb-0">
                      <span class="badge badge-soft-warning font-size-12">
                        {" "}
                        17%{" "}
                      </span>{" "}
                      dell'incassato
                    </p>
                  </td>
                  <td scope="row">
                    <a href="revolut.html">Revolut</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <a href="#">Luglio 23</a>
                  </td>
                  <td>
                    € 3.900,00
                    <p class="text-muted mb-0">
                      <span class="badge badge-soft-success font-size-12">
                        {" "}
                        35%{" "}
                      </span>{" "}
                      dell'incassato
                    </p>
                  </td>
                  <td scope="row">
                    <a href="revolut.html">Revolut</a>
                  </td>
                </tr>
                <tr>
                  <td scope="row">
                    <a href="wallet-detail.html">Agosto 2023</a>
                  </td>
                  <td>
                    € 500,00
                    <p class="text-muted mb-0">
                      <span class="badge badge-soft-danger font-size-12">
                        {" "}
                        5%{" "}
                      </span>{" "}
                      dell'incassato
                    </p>
                  </td>
                  <td scope="row">
                    <a href="revolut.html">Revolut</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="card-body pt-3 pb-1">
        <div class="text-center">
          <div class="row">
            <div class="col-sm-6">
              <div>
                <div class="font-size-24 text-primary">
                  <h4 class="card-title mb-1">Da accantonare questo mese:</h4>

                  <i class="bx bx-import"></i>
                </div>
                <p class="text-muted  mb-1">Da trasferire</p>
                <h5>€ 3.150,00</h5>
              </div>
            </div>
            <div class="col-sm-6">
              <div>
                <div class="font-size-24 text-primary">
                  <h4 class="card-title mb-1">Accantonato nell'anno:</h4>

                  <i class="bx bx-wallet-alt"></i>
                </div>

                <p class="text-muted  mb-1">Traseriti</p>
                <h5>€ 13.400,00</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
