import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const BusinessesRolesApi = {
  apiName: "BusinessesRolesApi",
  baseUrl: "/webapp/businesses/roles",

  get: async function (nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(`${this.baseUrl}`, options);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response.data;
  },
};
