import React, { useEffect, useState } from "react";
import { HoloTooltip } from "../HoloTooltip";

import BusinessesTableRow, {
  BusinessesTableRowHandler,
} from "./Rows/BusinessesTableRow";

import { _BaseUtil } from "../../utils/_BaseUtil";

const BusinessesTable = ({ businesses, filter, year, onImpersonate }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (!businesses.length) return;

    const fetchRows = async () => {
      let records = [];
      for (let index in businesses) {
        let mapped = await BusinessesTableRowHandler.map(
          businesses[index],
          year
        );
        records = [...records, mapped];
      }
      setRows(records);
    };

    fetchRows();
  }, [businesses, year]);

  useEffect(() => {
    if (!rows.length) return;
    setFilteredRows(_BaseUtil.filterList(rows, filter));
  }, [rows, filter]);

  return (
    <>
      <div className="table-responsive tableReflow">
        <table className="table project-list-table table-nowrap align-middle table-borderless">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Dettagli</th>
              {/* <th scope="col">Recapiti</th> */}
              <th scope="col">
                Fatturato {year}
                <HoloTooltip
                  id="tooltip-paid"
                  description={`Somma dei pagamenti incassati nel ${year}`}
                  className="ms-1"
                />
              </th>
              <th scope="col">
                Da fatturare
                <HoloTooltip
                  id="tooltip-topay"
                  description={`Somma dei pagamenti da incassare con scadenza nel ${year}`}
                  className="ms-1"
                />
              </th>
              <th scope="col">
                Residuo
                <HoloTooltip
                  id="tooltip-remaining"
                  description={`Valore rimanente per restare nel regime forfettario nel ${year}`}
                  className="ms-1"
                />
              </th>
              <th scope="col">Documenti {year}</th>
              <th scope="col">
                Spese categorizzate
                <HoloTooltip
                  id="tooltip-remaining"
                  description={`Spese da inserire nel quadro RS per l'anno ${year}`}
                  className="ms-1"
                />
              </th>
              <th scope="col">Stime fine anno - AI</th>
              <th scope="col">Azioni</th>
            </tr>
          </thead>

          <tbody>
            {filteredRows.map((item, i) => {
              return (
                <BusinessesTableRow
                  key={i}
                  row={item}
                  onImpersonate={onImpersonate}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BusinessesTable;
