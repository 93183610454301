import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../assets/css/reactTags.css";

import { BusinessesApi_Cache } from "../api/decorators/cache/BusinessesApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { MovementsApi_Cache } from "../api/decorators/cache/MovementsApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { ContactsUtil } from "../utils/ContactsUtil";
import { InvoicesUtil } from "../utils/InvoicesUtil";
import { OrdersUtil } from "../utils/OrdersUtil";

import { CardSimpleSummary } from "../components/CardSimpleSummary";
import { CardTableEstimates } from "../components/CardTableEstimates";
import { CardTableOrders } from "../components/CardTableOrders";
import { CardTableSubscriptions } from "../components/CardTableSubscriptions";
import { BusinessProfileDetails } from "../components/Businesses/BusinessProfileDetails";
import { _BaseUtil } from "../utils/_BaseUtil";

const BusinessProfile = () => {
  const { id } = useParams();

  const [business, setBusiness] = useState(null);
  const [estimatesModel, setEstimatesModel] = useState([]);
  const [ordersModel, setOrdersModel] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoicesModel, setInvoicesModel] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [movements, setMovements] = useState([]);

  const [totalExpired, setTotalExpired] = useState(null);
  const [subscriptionsToPayAmount, setSubscriptionsToPayAmount] =
    useState(null);
  const [totalPaid, setTotalPaid] = useState(null);

  useEffect(() => {
    const fetchBusiness = async () => {
      setBusiness(
        (await BusinessesApi_Cache.get()).find((x) => x.rowKey === id)
      );
    };
    fetchBusiness();
  }, [id]);

  useEffect(() => {
    if (!business) return;

    const businessId = business.rowKey;

    const fetchEstimates = async () => {
      setEstimatesModel(await EstimatesApi_Cache.get(businessId));
    };

    const fetchOrders = async () => {
      setOrdersModel(await OrdersApi_Cache.get(businessId));
    };

    const fetchSubscriptions = async () => {
      setSubscriptions(await SubscriptionsApi_Cache.get(businessId));
    };

    const fetchInvoices = async () => {
      setInvoicesModel(await InvoicesApi_Cache.get(businessId));
    };

    const fetchTransactions = async () => {
      setTransactions(await TransactionsApi_Cache.get(businessId));
    };

    const fetchMovements = async () => {
      setMovements(await MovementsApi_Cache.get(businessId));
    };

    fetchEstimates();
    fetchOrders();
    fetchSubscriptions();
    fetchInvoices();
    fetchTransactions();
    fetchMovements();
  }, [business]);

  useEffect(() => {
    if (!transactions.length) return;

    const today = new Date();

    const totalExpired = transactions
      .filter((x) => [1, 2].includes(x.status))
      .filter((x) => !!x.expirationDate && new Date(x.expirationDate) < today)
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);

    setTotalExpired(_BaseUtil.formatCurrency("EUR", totalExpired, "it-IT"));

    const totalPaid = transactions
      .filter((x) => x.status === 3)
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);

    setTotalPaid(_BaseUtil.formatCurrency("EUR", totalPaid, "it-IT"));

    const subscriptionsToPay = transactions
      .filter((x) => [1, 2].includes(x.status))
      .filter((x) => !!x.subscriptionId)
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);

    setSubscriptionsToPayAmount(
      _BaseUtil.formatCurrency("EUR", subscriptionsToPay, "it-IT")
    );
  }, [transactions]);

  return (
    !!business && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                    Profilo {ContactsUtil.getFullName(business)}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to={`/businesses`}>I tuoi Holo</Link>
                      </li>
                      <li className="breadcrumb-item active">Profilo</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4">
                <BusinessProfileDetails
                  business={business}
                  transactions={transactions}
                ></BusinessProfileDetails>
              </div>

              <div className="col-xxl-8">
                <div className="row">
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Totale scaduto`}
                      value={totalExpired}
                      icon={`bx bx-check-circle`}
                      titleClassName={`text-danger`}
                    />
                  </div>
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Sottoscrizioni da incassare`}
                      value={subscriptionsToPayAmount}
                      icon={`bx bx-hourglass`}
                    />
                  </div>
                  <div className="col-md-4">
                    <CardSimpleSummary
                      title={`Totale incassato`}
                      value={totalPaid}
                      icon={`bx bx-package`}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xxl-6">
                    <CardTableOrders
                      key={`card-table-contactprofile-orders`}
                      title={`Ordini`}
                      orders={ordersModel}
                    />
                  </div>
                  <div className="col-xxl-6">
                    <CardTableEstimates
                      key={`card-table-contactprofile-estimates`}
                      estimates={estimatesModel}
                    />
                  </div>
                  <div className="col">
                    <CardTableSubscriptions
                      key={`card-table-contactprofile-subscriptions`}
                      title={`Piani`}
                      subscriptions={subscriptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default BusinessProfile;
