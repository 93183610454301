import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserContext, BusinessContext } from "../../contexts/AppContexts";

import { EstimatesApi_Cache } from "../../api/decorators/cache/EstimatesApi_Cache";
import { OrdersApi_Cache } from "../../api/decorators/cache/OrdersApi_Cache";
import { TransactionsApi_Cache } from "../../api/decorators/cache/TransactionsApi_Cache";
import { MovementsApi_Cache } from "../../api/decorators/cache/MovementsApi_Cache";
import { OpenBankingApi_Cache } from "../../api/decorators/cache/OpenBankingApi_Cache";

import { WelcomeBox } from "../../components/WelcomeBox";
import { CardMainSummary } from "../../components/CardMainSummary";
import { CardSimple } from "../../components/CardSimple";
import { Movements } from "../../components/Movements";

import { TransactionsUtil } from "../../utils/TransactionsUtil";
import { UsersUtil } from "../../utils/UsersUtil";
import {
  CardRadialChart,
  CardRadialChartUtil,
} from "../../components/CardRadialChart";
import {
  CardDonutChart,
  CardDonutChartUtil,
} from "../../components/CardDonutChart";
import { _BaseUtil } from "../../utils/_BaseUtil";
import { TaxForecast } from "../TaxForecast";

const expensesMapping = {
  0: "Personale",
  1: "Altro professionale",
  2: "Tasse sul reddito",
  3: "Contributi previdenziali",
  4: "Carburanti, Lubrificanti",
  5: "Energia Elettrica",
  6: "Telefonia, Internet",
};

const DashboardActivationComplete = () => {
  const { t } = useTranslation("dashboard");
  const { user } = useContext(UserContext);
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [periodDays, setPeriodDays] = useState(null);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodPreviousStart, setPeriodPreviousStart] = useState(null);

  const [automaticIncomesQuarters, setAutomaticIncomesQuarters] = useState([]);
  const [balancesDonuts, setBalancesDonuts] = useState([]);
  const [salesDonuts, setSalesDonuts] = useState([]);
  const [expensesDonuts, setExpensesDonuts] = useState([]);

  const [estimates, setEstimates] = useState([]);
  const [movements, setMovements] = useState([]);
  const [obTransactions, setObTransactions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [WelcomeBoxCard, setWelcomeBox] = useState({
    title: "",
    message: "",
    list: [],
  });

  const [MainCard, setMainCard] = useState({
    last_access: "",
    user_full_name: UsersUtil.getFullName(user.firstName, user.lastName),
    user_job_description: "",
    user_avatar:
      user.avatarPath ||
      "/assets/images/avatars/wired-lineal-527-boy-young-men.gif",
    total_receivables: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    next_60_days_value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    current_value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    overdue_value: _BaseUtil.formatCurrency("EUR", 0, "it-IT"),
    next_60_days_percent: "0",
    current_percent: "0",
    overdue_percent: "0",
  });

  const [EstimatesCard, setEstimatesCard] = useState({
    title: "",
    sub_title: "",
    icon: "",
    main_value: "",
    description: "",
    secondary_value: "",
    badge_class: "",
    trend_icon: "",
    link_to: "",
  });

  const [OrdersCard, setOrdersCard] = useState({
    title: "",
    sub_title: "",
    icon: "",
    main_value: "",
    description: "",
    secondary_value: "",
    badge_class: "",
    trend_icon: "",
    link_to: "",
  });

  const [RevenueCard, setRevenueCard] = useState({
    title: "",
    sub_title: "",
    icon: "",
    main_value: "",
    description: "",
    secondary_value: "",
    badge_class: "",
    trend_icon: "",
    link_to: "",
  });

  const success_edge = 0.05;
  const danger_edge = -0.05;

  //const { culture } = useTranslation("culture");
  const culture_lang = "it-IT";
  const culture_currency = "EUR";
  const percent_number_format = new Intl.NumberFormat(culture_lang, {
    style: "percent",
    signDisplay: "exceptZero",
    maximumFractionDigits: 0,
  });
  const currency_number_format = new Intl.NumberFormat(culture_lang, {
    style: "currency",
    currency: culture_currency,
    signDisplay: "negative",
  });
  let date_time_full_options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date_time_full_format = Intl.DateTimeFormat(
    culture_lang,
    date_time_full_options
  );

  useEffect(() => {
    const fetchEstimates = async () => {
      const result = await EstimatesApi_Cache.get(businessId);
      setEstimates(result);
    };
    const fetchMovements = async () => {
      const result = await MovementsApi_Cache.get(businessId);
      setMovements(result);
    };
    const fetchOrders = async () => {
      const result = await OrdersApi_Cache.get(businessId);
      setOrders(result);
    };
    const fetchTransactions = async () => {
      const result = await TransactionsApi_Cache.get(businessId);
      setTransactions(result);
    };
    const fetchObTransactions = async () => {
      let obAccounts =
        (await OpenBankingApi_Cache.getAccounts(businessId, false, true)) || [];
      let promises = obAccounts.map((a) => {
        return OpenBankingApi_Cache.getTransactionsAsync(
          businessId,
          a.uuid,
          false,
          true
        );
      });
      promises = await Promise.all(promises);
      let results = promises
        .map((p) => {
          return p;
        })
        .flat(1);
      results = results.map((r, i) => {
        r.account = obAccounts.find((a) => a.uuid === r.account.uuid);
        return r;
      });
      setObTransactions(results);
    };
    fetchEstimates();
    fetchMovements();
    fetchOrders();
    fetchTransactions();
    fetchObTransactions();
  }, [businessId]);

  useEffect(() => {
    setPeriodDays(30);
  }, []);

  useEffect(() => {
    const today = new Date();
    let priorDate = new Date(new Date().setDate(today.getDate() - periodDays));
    let previousDate = new Date(
      new Date().setDate(today.getDate() - periodDays)
    );
    setPeriodStart(priorDate);
    setPeriodPreviousStart(previousDate);
  }, [periodDays]);

  useEffect(() => {
    const loadOrdersCard = async () => {
      const newOrders = orders.filter(
        (x) => new Date(x.order.createdOn) > periodStart
      );
      const oldOrders = orders.filter(
        (x) =>
          new Date(x.order.createdOn) < periodStart &&
          new Date(x.order.createdOn) > periodPreviousStart
      );

      const totalSumNew = newOrders
        .map((x) => x.order)
        .reduce(calculateTotalSum, {
          totalAmountServicesNet: 0,
          totalAmountPlansNet: 0,
        });

      const totalSumOld = oldOrders
        .map((x) => x.order)
        .reduce(calculateTotalSum, {
          totalAmountServicesNet: 0,
          totalAmountPlansNet: 0,
        });

      let totalAmountNew =
        totalSumNew.totalAmountServicesNet + totalSumNew.totalAmountPlansNet;

      let totalAmountOld =
        totalSumOld.totalAmountServicesNet + totalSumOld.totalAmountPlansNet;

      let orders_main_value = totalAmountNew;

      //nota: le percentuali le formattiamo lato frontend, quindi devono essere calcolate solo come rapporto senza la moltipicazione per 100
      let delta_value = !totalAmountOld
        ? newOrders.length
          ? 100
          : 0
        : (totalAmountNew - totalAmountOld) / totalAmountOld;
      delta_value = delta_value / 100;

      let badge_class_card = getBadgeClass(delta_value);

      const obj = {
        title: t("orders"),
        sub_title: t("last_30_days"),
        icon: "bx bx-copy-alt",
        main_value: currency_number_format.format(orders_main_value), //risultato
        description: t("paralell_period"),
        secondary_value: percent_number_format.format(delta_value),
        badge_class: badge_class_card,
        trend_icon:
          delta_value > 0 ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down",
        link_to: "orders",
      };

      setOrdersCard(obj);
    };
    const createDonutSales = () => {
      let pies = [];

      const months = _BaseUtil.getLastMonths(3);

      for (let index in months) {
        let month = months[index];
        const monthOrders = getOrdersByMonth(
          orders.filter((x) => [1, 3].includes(x.order.status)),
          month.value - 1
        );
        if (!monthOrders?.length) continue;

        // Step 1: Flatten the rows array
        const allRows = monthOrders.flatMap((orderModel) => orderModel.rows);

        // Step 2: Group by serviceName
        const groupedRows = allRows.reduce((grouped, row) => {
          const { serviceName, amount } = row;

          if (!grouped[serviceName]) {
            grouped[serviceName] = [];
          }

          grouped[serviceName].push(amount);

          return grouped;
        }, {});

        // Step 3: Calculate totalAmount for each group
        const finalGroup = Object.entries(groupedRows).map(
          ([serviceName, amounts]) => ({
            serviceName,
            totalAmount: amounts.reduce((sum, amount) => sum + amount, 0),
          })
        );

        const pieces = finalGroup.map((group) => {
          return CardDonutChartUtil.getPiece(
            group.serviceName,
            group.totalAmount
          );
        });

        const pie = CardDonutChartUtil.getPie(
          index,
          month.text,
          "EUR",
          "it-IT",
          pieces
        );

        pies.push(pie);
      }

      setSalesDonuts(pies);
    };

    loadOrdersCard();
    createDonutSales();
  }, [orders, periodStart, periodPreviousStart]);

  useEffect(() => {
    // if (!transactions.length) return;

    const loadRevenueCard = async () => {
      let paidTransactions = transactions.filter((x) => x.status === 3);

      let currPeriod = TransactionsUtil.getLastXDays(
        paidTransactions,
        "closedOn",
        30
      );

      let prevPeriod = TransactionsUtil.getLastXDays(
        paidTransactions,
        "closedOn",
        60
      );

      let firstListRowKeys = currPeriod.map((x) => x.rowKey);
      prevPeriod = prevPeriod.filter(
        (x) => !firstListRowKeys.includes(x.rowKey)
      );

      let revenue_main_value = TransactionsUtil.sumAmount(currPeriod);
      let lastPeriodSum = TransactionsUtil.sumAmount(prevPeriod);

      //nota: le percentuali le formattiamo lato frontend, quindi devono essere calcolate solo come rapporto senza la moltipicazione per 100
      let delta_value = !lastPeriodSum
        ? currPeriod.length
          ? 100
          : 0
        : (revenue_main_value - lastPeriodSum) / lastPeriodSum;
      delta_value = delta_value / 100;

      let badge_class_card = getBadgeClass(delta_value);

      const obj = {
        title: t("revenue"),
        sub_title: t("last_30_days"),
        icon: "bx bx-archive-in",
        main_value: currency_number_format.format(revenue_main_value), //risultato
        description: t("paralell_period"),
        secondary_value: percent_number_format.format(delta_value),
        badge_class: badge_class_card,
        trend_icon:
          delta_value > 0 ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down",
        link_to: "account",
      };

      setRevenueCard(obj);
    };

    const loadMainCard = async () => {
      //nota: le percentuali le formattiamo lato frontend, quindi devono essere calcolate solo come rapporto senza la moltipicazione per 100
      // let last_access_value = new Date("2021-08-21T13:45:00Z");

      let total_receivables = TransactionsUtil.getTotalReceivable(transactions);

      let total = 0;

      let transactions60Days =
        TransactionsUtil.getExpiringTransactionsInNextXDays(transactions, 60);
      let next_60_days_value =
        TransactionsUtil.getTotalReceivable(transactions60Days);

      total += next_60_days_value;

      let transactions15Days =
        TransactionsUtil.getExpiringTransactionsInNextXDays(transactions, 15);
      let current_value =
        TransactionsUtil.getTotalReceivable(transactions15Days);

      total += current_value;

      let expiredTransactions =
        TransactionsUtil.getExpiredTransactions(transactions);
      let overdue_value =
        TransactionsUtil.getTotalReceivable(expiredTransactions);

      total += overdue_value;

      const next_60_days_percent = !total ? 0 : next_60_days_value / total;
      const current_percent = !total ? 0 : current_value / total;
      const overdue_percent = !total ? 0 : overdue_value / total;

      const obj = {
        // last_access: t("last_access") + " " + date_time_full_format.format(last_access_value),
        user_full_name: UsersUtil.getFullName(user.firstName, user.lastName),
        // user_job_description: "Senior Developer",
        user_avatar:
          user.avatarPath ||
          "/assets/images/avatars/wired-lineal-527-boy-young-men.gif",
        total_receivables: currency_number_format.format(total_receivables),
        next_60_days_value: currency_number_format.format(next_60_days_value),
        current_value: currency_number_format.format(current_value),
        overdue_value: currency_number_format.format(overdue_value),
        next_60_days_percent,
        current_percent,
        overdue_percent,
      };

      setMainCard(obj);
    };
    const loadAutomaticIncomesCard = async () => {
      // Step 1: Sort transactions by 'madeOn' in descending order
      const sortedTransactions = transactions
        .filter((x) => x.subscriptionId)
        .filter((x) => x.expirationDate)
        // .filter((x) => new Date(x.expirationDate) <= currentQuarterEndDate)
        .sort(
          (a, b) => new Date(b.expirationDate) - new Date(a.expirationDate)
        );

      // Step 2: Group transactions by quarter
      const quarterlyData = sortedTransactions.reduce((acc, transaction) => {
        const expirationDate = new Date(transaction.expirationDate);
        const quarter = Math.floor((expirationDate.getMonth() + 3) / 3);
        const year = expirationDate.getFullYear();
        const key = `Q${quarter}-${year}`;

        if (!acc[key]) {
          acc[key] = { quarter, year, totalAmount: 0 };
        }

        acc[key].totalAmount += transaction.amountNet;

        return acc;
      }, {});

      // Step 3: Create an array of quarterly summaries
      const quarterlySummaries = Object.values(quarterlyData);

      const quarterlySummariesSorted = quarterlySummaries.sort((a, b) => {
        // Confronto per anno
        if (a.year !== b.year) {
          return a.year - b.year;
        }
        // Se gli anni sono uguali, confronta per trimestre
        return a.quarter - b.quarter;
      });

      const finalList = quarterlySummariesSorted.map((x) =>
        CardRadialChartUtil.getQuarter(
          `Q${x.quarter} - ${x.year % 100}`,
          `Q${x.quarter} - ${x.year % 100}`,
          x.totalAmount,
          "EUR",
          "it-IT"
        )
      );

      setAutomaticIncomesQuarters(finalList);
    };
    loadRevenueCard();
    loadMainCard();
    loadAutomaticIncomesCard();
  }, [transactions]);

  const getBadgeClass = (delta) => {
    if (delta > success_edge) return "success";
    if (delta < danger_edge) return "danger";
    return "warning";
  };

  const calculateTotalSum = (accumulator, estimate) => {
    accumulator.totalAmountServicesNet += estimate.totalAmountServicesNet;
    accumulator.totalAmountPlansNet += estimate.totalAmountPlansNet;
    return accumulator;
  };

  useEffect(() => {
    const loadEstimatesCard = async () => {
      let newEstimates = estimates.filter(
        (x) => new Date(x.estimate.createdOn) > periodStart
      );

      let oldEstimates = estimates.filter(
        (x) =>
          new Date(x.estimate.createdOn) < periodStart &&
          new Date(x.estimate.createdOn) > periodPreviousStart
      );

      let totalSumNew = newEstimates
        .map((x) => x.estimate)
        .reduce(calculateTotalSum, {
          totalAmountServicesNet: 0,
          totalAmountPlansNet: 0,
        });
      let totalSumOld = oldEstimates
        .map((x) => x.estimate)
        .reduce(calculateTotalSum, {
          totalAmountServicesNet: 0,
          totalAmountPlansNet: 0,
        });

      let totalAmountNew =
        totalSumNew.totalAmountServicesNet + totalSumNew.totalAmountPlansNet;

      let totalAmountOld =
        totalSumOld.totalAmountServicesNet + totalSumOld.totalAmountPlansNet;

      let estimates_main_value = totalAmountNew;

      //nota: le percentuali le formattiamo lato frontend, quindi devono essere calcolate solo come rapporto senza la moltipicazione per 100
      let delta_value = !totalAmountOld
        ? newEstimates.length
          ? 100
          : 0
        : (totalAmountNew - totalAmountOld) / totalAmountOld;
      delta_value = delta_value / 100;

      let badge_class_card = getBadgeClass(delta_value);

      const obj = {
        title: t("estimates"),
        sub_title: t("last_30_days"),
        icon: "bx bx-paper-plane",
        main_value: currency_number_format.format(estimates_main_value), //risultato
        description: t("paralell_period"),
        secondary_value: percent_number_format.format(delta_value),
        badge_class: badge_class_card,
        trend_icon:
          delta_value > 0 ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down",
        link_to: "estimates",
      };

      setEstimatesCard(obj);
    };
    loadEstimatesCard();
  }, [estimates, periodStart, periodPreviousStart]);

  useEffect(() => {
    const loadWelcomeBox = () => {
      const obj = {
        title: t("welcome_title"),
        message: t("welcome_subtitle"),
      };
      const orders_count = orders.filter(
        (x) => new Date(x.order.createdOn) > periodStart && x.order.status === 1
      ).length;
      const transactions_count = transactions.filter(
        (x) => new Date(x.closedOn) > periodStart && x.status === 3
      ).length;
      obj.list = [
        `${orders_count} ordini accettati`,
        `${transactions_count} pagamenti ricevuti`,
      ];
      setWelcomeBox(obj);
    };
    loadWelcomeBox();
  }, [orders, transactions, periodStart]);

  function getLastTransaction(accountId, obTransactions, month) {
    const monthlyTransactions = obTransactions.filter(
      (x) =>
        x.account.uuid === accountId && new Date(x.madeOn).getMonth() === month
    );
    return monthlyTransactions.sort(
      (a, b) => new Date(b.madeOn) - new Date(a.madeOn)
    )[0];
  }

  const getAccountBalancesByMonth = (obTransactions, month) => {
    if (isNaN(month)) return;
    let accountUuids = obTransactions.map(
      (transaction) => transaction.account.uuid
    );
    if (!accountUuids?.length) return;

    // distinct
    accountUuids = [...new Set(accountUuids)];

    const accounts = accountUuids
      .map((x) => {
        const lastTransaction = getLastTransaction(x, obTransactions, month);
        if (!lastTransaction) return null;
        return {
          accountId: lastTransaction?.account.uuid,
          accountName: lastTransaction?.account.providerName,
          balance: lastTransaction?.extra.closingBalance,
        };
      })
      .filter((x) => x);

    return accounts;
  };

  useEffect(() => {
    const createDonutBalances = () => {
      let pies = [];

      const months = _BaseUtil.getLastMonths(3);

      for (let index in months) {
        let month = months[index];
        let accountBalances = getAccountBalancesByMonth(
          obTransactions,
          month.value - 1
        );
        if (!accountBalances?.length) continue;

        const pieces = accountBalances.map((group) => {
          return CardDonutChartUtil.getPiece(group.accountName, group.balance);
        });

        const pie = CardDonutChartUtil.getPie(
          index,
          month.text,
          "EUR",
          "it-IT",
          pieces
        );
        pies.push(pie);
      }

      setBalancesDonuts(pies);
    };
    createDonutBalances();
  }, [obTransactions]);

  const getActiveOrders = (orders, month) => {
    return orders
      .filter((x) => x.order.status === 1)
      .filter((x) => new Date(x.order.createdOn).getMonth() === month);
  };

  const getOrdersByMonth = (orders, month) => {
    return orders.filter(
      (x) => new Date(x.order.createdOn).getMonth() === month
    );
  };

  useEffect(() => {
    const createDonutExpenses = () => {
      if (!movements.length) return;

      let pies = [];

      const months = _BaseUtil.getLastMonths(3);

      for (let index in months) {
        let month = months[index];

        const expenses = movements.filter(
          (x) =>
            x.amount < 0 &&
            !isNaN(x.expenseType) &&
            new Date(x.madeOn).getMonth() === month.value - 1
        );

        // Step 2: Group by expenseType
        const groupedRows = expenses.reduce((grouped, item) => {
          const { expenseType, amount } = item;
          if (!grouped[expenseType]) {
            grouped[expenseType] = [];
          }
          grouped[expenseType].push(Math.abs(amount));
          return grouped;
        }, {});

        // Step 3: Calculate totalAmount for each group
        const finalGroup = Object.entries(groupedRows).map(
          ([expenseType, amounts]) => ({
            expenseType: expensesMapping[expenseType],
            totalAmount: amounts.reduce((sum, amount) => sum + amount, 0),
          })
        );

        const pieces = finalGroup.map((group) => {
          return CardDonutChartUtil.getPiece(
            group.expenseType,
            group.totalAmount
          );
        });

        const pie = CardDonutChartUtil.getPie(
          index,
          month.text,
          "EUR",
          "it-IT",
          pieces
        );

        pies.push(pie);
      }

      setExpensesDonuts(pies);
    };
    createDonutExpenses();
  }, [movements]);

  return (
    <>
      <div className={`row`}>
        <div className="col-md-12">
          <CardMainSummary
            last_access={MainCard.last_access}
            user_full_name={MainCard.user_full_name}
            user_job_description={MainCard.user_job_description}
            user_avatar={MainCard.user_avatar}
            total_receivables={MainCard.total_receivables}
            next_60_days_value={MainCard.next_60_days_value}
            current_value={MainCard.current_value}
            overdue_value={MainCard.overdue_value}
            next_60_days_percent={MainCard.next_60_days_percent}
            current_percent={MainCard.current_percent}
            overdue_percent={MainCard.overdue_percent}
          ></CardMainSummary>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
          {/* <TaxForecast /> */}
          <WelcomeBox
            title={WelcomeBoxCard.title}
            message={WelcomeBoxCard.message}
            list={WelcomeBoxCard.list}
          ></WelcomeBox>
        </div>
        <div className="col-xl-8">
          <div className="row">
            <div className="col-sm-4">
              <CardSimple
                title={OrdersCard.title}
                sub_title={OrdersCard.sub_title}
                icon={OrdersCard.icon}
                main_value={OrdersCard.main_value}
                description={OrdersCard.description}
                secondary_value={OrdersCard.secondary_value}
                badge_className={OrdersCard.badge_class}
                trend_icon={OrdersCard.trend_icon}
                link_to={OrdersCard.link_to}
              ></CardSimple>
            </div>
            <div className="col-sm-4">
              <CardSimple
                title={RevenueCard.title}
                sub_title={RevenueCard.sub_title}
                icon={RevenueCard.icon}
                main_value={RevenueCard.main_value}
                description={RevenueCard.description}
                secondary_value={RevenueCard.secondary_value}
                badge_className={RevenueCard.badge_class}
                trend_icon={RevenueCard.trend_icon}
                link_to={RevenueCard.link_to}
              ></CardSimple>
            </div>

            <div className="col-sm-4">
              <CardSimple
                title={EstimatesCard.title}
                sub_title={EstimatesCard.sub_title}
                icon={EstimatesCard.icon}
                main_value={EstimatesCard.main_value}
                description={EstimatesCard.description}
                secondary_value={EstimatesCard.secondary_value}
                badge_className={EstimatesCard.badge_class}
                trend_icon={EstimatesCard.trend_icon}
                link_to={EstimatesCard.link_to}
              ></CardSimple>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {!!automaticIncomesQuarters.length && (
          <div className="col-xl-6">
            <CardRadialChart
              title={`Incassi Automatici`}
              subtitle={`Incassi dalle tue sottoscrizioni attive`}
              label={`Quadrimestri`}
              values={automaticIncomesQuarters}
            />
          </div>
        )}
        {!!balancesDonuts.length && (
          <div className="col-xl-6">
            <CardDonutChart
              title={`Saldo conti`}
              subtitle={`Saldo disponibile`}
              label={`Mese`}
              colorsHex={[`#34C38F`, `#F1B44C`, `#50A5F1`]}
              pies={balancesDonuts}
            />
          </div>
        )}
        {!!salesDonuts.length && (
          <div className="col-xl-6">
            <CardDonutChart
              title={`Composizione vendite`}
              subtitle={`Venduto totale`}
              label={`Mese`}
              colorsHex={[`#50A5F1`, `#34C38F`, `#F46A6A`, `#F1B44C`]}
              pies={salesDonuts}
            />
          </div>
        )}
        {!!expensesDonuts.length && (
          <div className="col-xl-6">
            <CardDonutChart
              title={`Composizione spese`}
              subtitle={`Spese totali`}
              label={`Mese`}
              colorsHex={[`#50A5F1`, `#34C38F`, `#F46A6A`, `#F1B44C`]}
              pies={expensesDonuts}
            />
          </div>
        )}
      </div>
      {!!business.isOpenBankingConnected && (
        <div className="row">
          <div className="col-lg-12">
            <Movements useSearch={false} top={10} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardActivationComplete;
