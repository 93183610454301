import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { Button, Input, Label, FormGroup } from "reactstrap";

import { BusinessContext } from "../contexts/AppContexts";

import { FattureInCloudApi_Cache } from "../api/decorators/cache/FattureInCloudApi_Cache";

export const FattureInCloudIntegration = () => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [fattureInCloud, setFattureInCloud] = useState({});
  const [companies, setCompanies] = useState([]);

  const [accessToken, setAccessToken] = useState(null);
  const [companyId, setCompanyId] = useState(0);
  const [documentType, setDocumentType] = useState(0);
  const [useSequence, setUseSequence] = useState(false);
  const [sequenceLetters, setSequenceLetters] = useState("H");

  const accessTokenRegex = /^a\/[A-Za-z0-9-_.]+$/;

  const documentTypeOptions = [
    { value: 0, label: "Pro Forma" },
    { value: 2, label: "Fattura Elettronica" },
    // {
    //   value: 3,
    //   label: "Fattura Elettronica Firmata e Inviata",
    // },
  ];

  const fetchCompanies = async () => {
    if (!fattureInCloud?.rowKey) return;
    const companiesList = await FattureInCloudApi_Cache.getCompanies(
      businessId,
      fattureInCloud.rowKey
    );
    setCompanies(
      companiesList.map((company) => ({
        value: company.id,
        label: company.name,
      }))
    );
  };

  const fetchFattureInCloudObj = async () => {
    const result = await FattureInCloudApi_Cache.get(businessId);
    if (result?.length) {
      setFattureInCloud(result[0]);
    }
  };

  useEffect(() => {
    if (!business) return;
    fetchFattureInCloudObj();
  }, []);

  useEffect(() => {
    if (!fattureInCloud?.externalKey) return;
    setCompanyId(fattureInCloud.companyId || 0);
    setDocumentType(fattureInCloud.documentType || 0);
    setUseSequence(fattureInCloud.useSequence !== false); // to set automatically undefined and null to true
    // setUseSequence(false);
    setSequenceLetters(fattureInCloud.sequenceLetters || "H");
    fetchCompanies();
  }, [fattureInCloud]);

  useEffect(() => {
    // Se c'è solo una company, selezionala automaticamente
    if (companies.length === 1) {
      setCompanyId(companies[0].value);
    }
  }, [companies]);

  useEffect(() => {
    // Se c'è solo una company, salva automaticamente
    if (
      companies?.length === 1 &&
      companyId &&
      fattureInCloud?.companyId !== companyId
    ) {
      onUpdate();
    }
  }, [companies, companyId]);

  const onSave = async () => {
    if (!accessToken) return;
    const result = await FattureInCloudApi_Cache.create(businessId, {
      accessToken,
    });
    if (result) setFattureInCloud(result);
  };

  const onUpdate = async () => {
    if (!fattureInCloud?.externalKey) return;
    const result = await FattureInCloudApi_Cache.update(
      businessId,
      fattureInCloud.rowKey,
      {
        companyId,
        documentType,
        useSequence,
        sequenceLetters,
      }
    );
    if (result) setFattureInCloud(result);
  };

  const onDelete = async () => {
    if (!fattureInCloud?.externalKey) return;
    await FattureInCloudApi_Cache.delete(businessId, fattureInCloud.rowKey);
    setFattureInCloud(null);
  };

  const handleNumeratoreChange = (e) => {
    const checked = e.target.checked;
    setUseSequence(checked);
  };

  return (
    <div className="card">
      <h5 className="card-header bg-transparent border-bottom text-uppercase">
        Fatture in Cloud
      </h5>
      <div className="card-body">
        <div className="row">
          {!fattureInCloud?.externalKey && (
            <>
              <div className="col-12 text-muted">
                Lo sai che se stai già utilizzando Fatture in Cloud puoi
                collegarlo ad Holo solo inserendo la chiave? Potrai continuare
                con gli attuali numeratori o inserirne uno completamente nuovo
                dedicato alle sole fatture generate da Holo!
              </div>
              <div className="col-12 mt-3">
                <Label htmlFor="fic-connect">AccessToken: </Label>
                <Input
                  id="fic-connect"
                  name="fic-connect"
                  type="text"
                  value={accessToken}
                  onChange={(e) => setAccessToken(e.target.value)}
                  className="form-control"
                />
                <small style={{ userSelect: "none" }} className="text-muted">
                  Formato di esempio:
                  a/eyJ0eXAiOiJKV1QiXXXXXXXXXXXXXXXXXXXX.eyJyZWYiOiJ5czB3QUJGeTVoa2FJOVRPR0J1XXXXXXXXXXXXXXXXXXXX.2b2NPrp4yieZdkrPwpkGI0ezHMnh-it5rXXXXXXXXXX
                </small>
              </div>
              <div className="col-12 mt-3 d-flex justify-content-end">
                <Button
                  className="btn btn-success mx-1"
                  onClick={() => onSave()}
                  disabled={!accessTokenRegex.test(accessToken)}
                >
                  Connetti
                </Button>
              </div>
            </>
          )}

          {!!fattureInCloud?.externalKey && (
            <>
              <div className="col-12 text-muted">
                Sei connesso con Fatture in Cloud!
              </div>
              <div className="col-12 mt-3">
                <Label>Importa documenti come:</Label>
                <Select
                  id="documentType"
                  name="documentType"
                  value={documentTypeOptions.find(
                    (x) => x.value === documentType
                  )}
                  onChange={(opt) => setDocumentType(opt.value)}
                  options={documentTypeOptions}
                />
              </div>
              <div className="col-12 mt-3">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={useSequence}
                      onChange={handleNumeratoreChange}
                    />{" "}
                    Usare numeratore fatture in cloud?
                  </Label>
                </FormGroup>
              </div>
              {!useSequence && (
                <div className="col-12 mt-3">
                  <Label htmlFor="letter">Sezionale fatture in cloud: </Label>
                  <Input
                    id="letter"
                    name="letter"
                    type="text"
                    value={sequenceLetters}
                    onChange={(e) => setSequenceLetters(e.target.value)}
                    className="form-control"
                  />
                </div>
              )}
              <div className="col-12 mt-3">
                <Label for="companySelect">Seleziona Company:</Label>
                <Select
                  id="companySelect"
                  options={companies}
                  value={companies.find(
                    (company) => company.value === companyId
                  )} // Trova l'oggetto opzione corrispondente
                  onChange={(option) => setCompanyId(option.value)}
                />
              </div>
              <div className="col-12 mt-3 d-flex justify-content-end">
                <Button className="btn btn-success mx-1" onClick={onUpdate}>
                  Aggiorna
                </Button>
                <Button
                  id="fic-disconnect"
                  name="fic-disconnect"
                  className="btn btn-danger mx-1"
                  onClick={onDelete}
                >
                  Disconnetti
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
