import { api } from "../configs/axiosConfigs";
import { _BaseApi } from "./_BaseApi";

export const FattureInCloudApi = {
  apiName: "FattureInCloudApi",
  baseUrl: "/webapp/businesses",

  get: async function (businessId, nocache, noloader) {
    const fnName = "get";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/fattureincloud`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  create: async function (businessId, payload) {
    const fnName = "create";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const response = await api.post(
      `${this.baseUrl}/${businessId}/fattureincloud`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },

  update: async function (businessId, id, payload) {
    const fnName = "update";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, payload);

    const response = await api.put(
      `${this.baseUrl}/${businessId}/fattureincloud/${id}`,
      payload
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);

    return response && response.data;
  },

  delete: async function (businessId, id) {
    const fnName = "delete";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId, id);

    const response = await api.delete(
      `${this.baseUrl}/${businessId}/fattureincloud/${id}`
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: ${response}`);

    return response && response.data;
  },

  getCompanies: async function (businessId, id, nocache, noloader) {
    const fnName = "getCompanies";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`);

    const options = _BaseApi.getHeaders(nocache, noloader);

    const response = await api.get(
      `${this.baseUrl}/${businessId}/fattureincloud/${id}/companies`,
      options
    );

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response && response.data;
  },
};
