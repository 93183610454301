import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import "../assets/css/reactTags.css";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";

import { BusinessContext } from "../contexts/AppContexts";

import { SuppliersApi_Cache } from "../api/decorators/cache/SuppliersApi_Cache";
import { CompaniesApi } from "../api/CompaniesApi";

import SelectCountry from "../components/Select/SelectCountry";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";

const suggestions = [
  {
    id: "Consulenza",
    text: "Consulenza",
  },
  {
    id: "Meeting",
    text: "Meeting",
  },
  {
    id: "Sviluppo",
    text: "Sviluppo",
  },
  {
    id: "Manutenzione",
    text: "Manutenzione",
  },
  {
    id: "Supporto",
    text: "Supporto",
  },
];

const KeyCodes = {
  comma: 188,
  enter: 13,
  pipe: 124,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.pipe];

const imageOptions = [
  { label: "Infinito", value: "/assets/images/companies/infinite.gif" },
  { label: "Fiore", value: "/assets/images/companies/flower.gif" },
  { label: "Cyber", value: "/assets/images/companies/cyber.gif" },
  { label: "Atomo 2", value: "/assets/images/companies/atom-2.gif" },
  { label: "Luce", value: "/assets/images/companies/light.gif" },
  { label: "Rotondo", value: "/assets/images/companies/round.gif" },
  { label: "Sfera", value: "/assets/images/companies/sphere.gif" },
  { label: "Cerchio", value: "/assets/images/companies/circle.gif" },
  { label: "Stella", value: "/assets/images/companies/star.gif" },
  { label: "Frecce", value: "/assets/images/companies/arrows.gif" },
  { label: "Infinito 2", value: "/assets/images/companies/infinite-2.gif" },
  { label: "Esagono", value: "/assets/images/companies/hexagon.gif" },
  { label: "Fulmine", value: "/assets/images/companies/flash.gif" },
  { label: "Fiore 2", value: "/assets/images/companies/flower-2.gif" },
  { label: "Scudo", value: "/assets/images/companies/shield.gif" },
  { label: "Frecce 2", value: "/assets/images/companies/arrows2.gif" },
  { label: "Atomo", value: "/assets/images/companies/atom.gif" },
];

export default function Supplier() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);

  const [supplier, setSupplier] = useState(null);

  const handleTagDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleTagAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleTagDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const isCreatedWithin = (date, seconds) => {
    return (new Date() - new Date(date)) / 1000 < seconds;
  };

  const [manualOn, setManualOn] = useState(false);
  const [verifiedVatData, setVerifiedVatData] = useState(null);

  let formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
      // firstName: Yup.string().required("Nome obbligatorio"),
      // lastName: Yup.string().required("Cognome obbligatorio"),
      // vatNumber: Yup.number().required("P. IVA obbligatoria"), //DA VALIDARE CON REGEX
    }),
    onSubmit: (values) => {
      console.log(`values`, values);
      handleSubmit();
    },
  });

  const mapTag = (tag) => {
    return {
      id: tag,
      text: tag,
    };
  };

  useEffect(() => {
    if (!id) {
      setSupplier(ContactsUtil.getTemplate(1));
    }
    const fetchSupplier = async () => {
      setSupplier(
        (await SuppliersApi_Cache.get(businessId)).find((x) => x.rowKey === id)
      );
    };
    fetchSupplier();
  }, [businessId, id]);

  useEffect(() => {
    if (!formik) return;
    if (!supplier) return;

    setManualOn(true);

    let currTags = supplier.tags || [];
    if (currTags.length) {
      let tagsList = _BaseUtil.tagsToList(currTags).map((x) => mapTag(x));
      setTags(tagsList);
    }

    formik.setValues(supplier);
  }, [supplier]);

  const removeAddressNumberIfExist = (address, number) => {
    // Converti il civico in stringa per evitare problemi di confronto
    const numberStr = number.toString();

    // Controlla se l'indirizzo termina con lo spazio seguito dal civico
    if (address.endsWith(" " + numberStr)) {
      // Rimuove il civico (e lo spazio prima di esso) dall'indirizzo
      return address.slice(0, address.length - numberStr.length - 1);
    }

    // Restituisci l'indirizzo originale se il civico non è presente
    return address;
  };

  const verifyVat = async () => {
    const vatNum = formik.values.vatNumber;
    const vatRes = await CompaniesApi.verify(businessId, vatNum);

    setManualOn(true);

    if (vatRes.piva) {
      //p.iva trovata!
      document.getElementById("alerttrovata").style.display = "block";
      document.getElementById("alertnontrovata").style.display = "none";

      setVerifiedVatData(vatRes);

      let obj = formik.values;

      let address = removeAddressNumberIfExist(vatRes.indirizzo, vatRes.civico);

      obj.businessName = vatRes.denominazione;
      obj.vatNumber = vatRes.piva;
      obj.certifiedEmail = vatRes.dettaglioObj?.certifiedEmail;
      obj.governmentId = vatRes.cf;
      obj.type = 2;
      obj.eInvoiceProviderCode =
        vatRes.codiceDestinatario === "0000000"
          ? null
          : vatRes.codiceDestinatario;
      obj.address = address;
      obj.city = vatRes.comune;
      obj.streetNumber = vatRes.civico;
      obj.zipCode = vatRes.cap;

      formik.setValues(obj);
    }

    if (!vatRes) {
      //p.iva NON trovata...
      document.getElementById("alerttrovata").style.display = "none";
      document.getElementById("alertnontrovata").style.display = "block";
      setManualOn(true);
      setVerifiedVatData(null);
    }
  };

  const changeManual = () => {
    setManualOn(!manualOn);
    document.getElementById("alerttrovata").style.display = "none";
    document.getElementById("alertnontrovata").style.display = "none";
  };

  const handleSubmit = async () => {
    try {
      const errors = await formik.validateForm();

      if (Object.keys(errors).length) {
        // Perform your desired action for invalid form
        console.log("Form is not invalid", errors);
        return;
      }

      let obj = formik.values;
      obj.eTag = undefined;
      obj.createdOn = undefined;

      // Perform your desired action for valid form
      console.log("Form is valid", obj);

      obj.tags = _BaseUtil.listToTags(tags.map((x) => x.text));

      if (!obj.rowKey) {
        obj = await SuppliersApi_Cache.create(businessId, obj);
        navigate("/supplier/" + obj.rowKey);
        return;
      }

      await SuppliersApi_Cache.update(businessId, obj.rowKey, obj);
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  // Custom component for rendering options with images
  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={value}
        alt={label}
        style={{ width: "30px", height: "30px", marginRight: "10px" }}
      />
      <span>{label}</span>
    </div>
  );

  return (
    <>
      <div className="page-content vh-100 overflow-auto">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4
                  className={`mb-sm-0 font-size-18 ${
                    formik.values.rowKey ? "d-none" : ""
                  }`}
                >
                  Crea nuovo fornitore
                </h4>
                <h4
                  className={`mb-sm-0 font-size-18 ${
                    formik.values.rowKey ? "" : "d-none"
                  }`}
                >
                  Aggiorna fornitore
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    {/* <li className="breadcrumb-item">
                      <Link to="/suppliers">Contatti</Link>
                    </li> */}
                    <li className="breadcrumb-item active">Nuovo</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${formik.values.rowKey ? "d-none" : ""}`}>
            <div className="card">
              <div className="card-body">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                >
                  <Row className="w-100">
                    <Col md={2}>
                      <img
                        src="/assets/images/page-contact.png"
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                      <div className="position-absolute top-0 start-10 translate-middle fs-2 m-2">
                        <i className="bx bxs-add-to-queue fs-18 text-primary"></i>
                      </div>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col xs={12}>
                          <label
                            htmlFor="taskname"
                            className="col-form-label fs-5"
                          >
                            Azienda / Libero Professionista
                          </label>
                          <br />
                          <label htmlFor="taskname" className="col-form-label">
                            Inserisci la Partita IVA e lancia la ricerca:
                          </label>
                          <FormGroup className="mb-3">
                            <InputGroup>
                              <Input
                                name="vatNumber"
                                className="inner form-control fs-5"
                                id="vatNumber"
                                placeholder="Partita IVA"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vatNumber || ""}
                                invalid={
                                  formik.touched.vatNumber &&
                                  formik.errors.vatNumber
                                }
                              />
                              <button
                                className="btn btn-primary fs-5"
                                type="button"
                                id="goHoloBtn"
                                onClick={verifyVat}
                                disabled={
                                  !_BaseUtil.validatePIva(
                                    formik.values.vatNumber
                                  )
                                }
                              >
                                Vai HOLO!
                              </button>
                              {formik.touched.vatNumber &&
                                formik.errors.vatNumber && (
                                  <FormFeedback type="invalid">
                                    {formik.errors.vatNumber}
                                  </FormFeedback>
                                )}
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col>
                          <div className="form-check form-check-right mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineFormCheck"
                              defaultChecked={manualOn}
                              onClick={changeManual}
                            />

                            <label
                              className="form-check-label fs-5"
                              htmlFor="inlineFormCheck"
                            >
                              Soggetto Privato / Partita IVA sconosciuta
                            </label>
                          </div>

                          <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                            id="alerttrovata"
                            style={{ display: "none" }}
                          >
                            <i className="bx bx-check-double me-2"></i>
                            Trovata! Controlla i dati e prosegui con
                            l'inserimento
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div
                            className="alert alert-warning alert-dismissible fade show"
                            role="alert"
                            id="alertnontrovata"
                            style={{ display: "none" }}
                          >
                            <i className="bx bx-error me-2"></i>
                            P.IVA non trovata, prosegui con l'inserimento
                            manuale
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className={manualOn ? "" : "d-none"}>
                      <Row>
                        <Col xs={12}>
                          {!manualOn && verifiedVatData && (
                            <div id="pivatrovata">
                              <ul className="list-unstyled vstack  mb-0">
                                {verifiedVatData.denominazione && (
                                  <li className="mt-2">
                                    <div className="d-flex">
                                      <i className="bx bx-buildings font-size-18 text-primary"></i>
                                      <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">
                                          Ragione Sociale:
                                        </h6>
                                        <span className="text-muted">
                                          {verifiedVatData.denominazione}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                                {verifiedVatData.indirizzo && (
                                  <li className="mt-2">
                                    <div className="d-flex">
                                      <i className="bx bx-map font-size-18 text-primary"></i>

                                      <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">
                                          Indirizzo:
                                        </h6>

                                        <span className="text-muted">
                                          {verifiedVatData.indirizzo},{" "}
                                          {verifiedVatData.comune},{" "}
                                          {verifiedVatData.provincia},{" "}
                                          {verifiedVatData.cap}
                                          <a
                                            href={`https://www.google.it/maps/search/${verifiedVatData.indirizzo},${verifiedVatData.comune},${verifiedVatData.provincia},${verifiedVatData.cap}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <i className="bx bx-link-external mx-2"></i>
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                                {verifiedVatData.piva && (
                                  <li className="mt-2">
                                    <div className="d-flex">
                                      <i className="bx bx-receipt font-size-18 text-primary"></i>
                                      <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">
                                          P. IVA e C.F. :
                                        </h6>
                                        <span className="text-muted">
                                          {verifiedVatData.piva}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                                {verifiedVatData.codice_destinatario && (
                                  <li className="mt-2">
                                    <div className="d-flex">
                                      <i className="bx bx-envelope font-size-18 text-primary"></i>
                                      <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">
                                          Codice Destinatario e PEC:
                                        </h6>
                                        <span className="text-muted">
                                          {verifiedVatData.codice_destinatario}{" "}
                                          {verifiedVatData.dettaglio &&
                                            verifiedVatData.dettaglio.pec && (
                                              <>
                                                - verifiedVatData.dettaglio.pec
                                              </>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}

                          <div
                            className={{ display: manualOn ? "" : "d-none" }}
                          >
                            <FormGroup>
                              <Label
                                check
                                htmlFor="type"
                                className="form-label mb-2 mt-2"
                              >
                                Scegli il tipo di soggetto:
                              </Label>
                              <Input
                                type="select"
                                name="type"
                                id="type"
                                value={formik.values.type || ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <option value="1">Privato</option>
                                <option value="2">
                                  Azienda / Libero professionista
                                </option>
                              </Input>
                              {formik.touched.type && formik.errors.type && (
                                <FormFeedback type="invalid">
                                  {formik.errors.type}
                                </FormFeedback>
                              )}
                            </FormGroup>

                            <FormGroup className="mt-3">
                              {formik.values.type === 1 && (
                                <Row>
                                  <Col md="6">
                                    <Label htmlFor="firstName">Nome</Label>
                                    <Input
                                      type="text"
                                      name="firstName"
                                      className="form-control"
                                      id="firstName"
                                      placeholder="Nome"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.firstName || ""}
                                      invalid={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                      }
                                    />
                                    {formik.touched.firstName &&
                                      formik.errors.firstName && (
                                        <FormFeedback type="invalid">
                                          {formik.errors.firstName}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col md="6">
                                    <Label htmlFor="lastName">Cognome</Label>
                                    <Input
                                      type="text"
                                      name="lastName"
                                      className="form-control"
                                      id="lastName"
                                      placeholder="Cognome"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.lastName || ""}
                                      invalid={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                      }
                                    />
                                    {formik.touched.lastName &&
                                      formik.errors.lastName && (
                                        <FormFeedback type="invalid">
                                          {formik.errors.lastName}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              )}

                              {formik.values.type === 2 && (
                                <Row>
                                  <Col md="12">
                                    <Label htmlFor="businessName">
                                      Inserisci la denominazione:
                                    </Label>
                                    <Input
                                      type="text"
                                      name="businessName"
                                      className="form-control"
                                      id="businessName"
                                      placeholder="Nome dell'Attività"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.businessName || ""}
                                    />
                                    {formik.touched.businessName &&
                                      formik.errors.businessName && (
                                        <FormFeedback type="invalid">
                                          {formik.errors.businessName}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              )}
                            </FormGroup>

                            <Row className="mt-3">
                              <Col className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-light waves-effect btn-label waves-light my-2 w-lg"
                                  style={{ marginRight: "8px" }}
                                >
                                  <i className="bx bx-rotate-left label-icon"></i>
                                  Annulla
                                </button>

                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect btn-label waves-light my-2 w-lg"
                                >
                                  <i className="bx bx-check-double label-icon"></i>
                                  Conferma
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>

          <Row
            id="datiestensivi"
            className={` ${(formik.values.rowKey || "") === "" && "d-none"}`}
          >
            <Col
              xs={12}
              className={
                isCreatedWithin(formik.values.createdOn, 5) ? "" : "d-none"
              }
            >
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
                <h4 className="alert-heading">Fornitore creato!</h4>
                <p>
                  Puoi procedere a creare il preventivo per il soggetto appena
                  inserito... ma se conosci altre informazioni sul fornitore,
                  aggiungile nel riquadro sotto: potranno essere molto utili in
                  seguito!
                </p>
              </div>
            </Col>

            <Col xs={12}>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Completa i dati</h4>

                  <div id="supplier-info">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="address">Indirizzo</Label>
                            <Input
                              type="text"
                              id="address"
                              name="address"
                              className="form-control"
                              placeholder="Indirizzo"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address || ""}
                              invalid={
                                formik.touched.address && formik.errors.address
                              }
                            />
                            {formik.touched.address &&
                              formik.errors.address && (
                                <FormFeedback type="invalid">
                                  {formik.errors.address}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="streetNumber">Civico</Label>
                            <Input
                              type="text"
                              name="streetNumber"
                              className="form-control"
                              id="streetNumber"
                              placeholder="Civico"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetNumber || ""}
                              invalid={
                                formik.touched.streetNumber &&
                                formik.errors.streetNumber
                              }
                            />
                            {formik.touched.streetNumber &&
                              formik.errors.streetNumber && (
                                <FormFeedback type="invalid">
                                  {formik.errors.streetNumber}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="zipCode">CAP</Label>
                            <Input
                              type="number"
                              name="zipCode"
                              className="form-control"
                              id="zipCode"
                              placeholder="CAP"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipCode || ""}
                              invalid={
                                formik.touched.zipCode && formik.errors.zipCode
                              }
                            />
                            {formik.touched.zipCode &&
                              formik.errors.zipCode && (
                                <FormFeedback type="invalid">
                                  {formik.errors.zipCode}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">Città</Label>
                            <Input
                              type="text"
                              name="city"
                              className="form-control"
                              id="city"
                              placeholder="Città"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city || ""}
                              invalid={
                                formik.touched.city && formik.errors.city
                              }
                            />
                            {formik.touched.city && formik.errors.city && (
                              <FormFeedback type="invalid">
                                {formik.errors.city}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <label htmlFor="countryCode" className="form-label">
                              Paese
                            </label>
                            <SelectCountry
                              id="countryCode"
                              name="countryCode"
                              value={formik.values.countryCode || ""}
                              onChange={(value) =>
                                formik.setFieldValue("countryCode", value)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("countryCode", true)
                              }
                              invalid={
                                formik.touched.countryCode &&
                                formik.errors.countryCode
                              }
                            />
                            {formik.touched.countryCode &&
                              formik.errors.countryCode && (
                                <div
                                  style={{ color: "red", marginTop: ".5rem" }}
                                >
                                  {formik.errors.countryCode}
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="businessName">Denominazione</Label>
                            <Input
                              type="text"
                              id="businessName"
                              name="businessName"
                              className="form-control"
                              placeholder="Ragione sociale"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.businessName || ""}
                              invalid={
                                formik.touched.businessName &&
                                formik.errors.businessName
                              }
                            />
                            {formik.touched.businessName &&
                              formik.errors.businessName && (
                                <FormFeedback type="invalid">
                                  {formik.errors.businessName}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="vatNumber">Partiva IVA</Label>
                            <Input
                              type="text"
                              name="vatNumber"
                              className="form-control"
                              id="vatNumber"
                              placeholder="P.IVA"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.vatNumber || ""}
                              invalid={
                                formik.touched.vatNumber &&
                                formik.errors.vatNumber
                              }
                            />
                            {formik.touched.vatNumber &&
                              formik.errors.vatNumber && (
                                <FormFeedback type="invalid">
                                  {formik.errors.vatNumber}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="eInvoiceProviderCode">
                              Codice Destinatario
                            </Label>
                            <Input
                              id="eInvoiceProviderCode"
                              name="eInvoiceProviderCode"
                              type="text"
                              className="form-control"
                              placeholder="Codice Destinatario"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.eInvoiceProviderCode || ""}
                              invalid={
                                formik.touched.eInvoiceProviderCode &&
                                formik.errors.eInvoiceProviderCode
                              }
                            />
                            {formik.touched.eInvoiceProviderCode &&
                              formik.errors.eInvoiceProviderCode && (
                                <FormFeedback type="invalid">
                                  {formik.errors.eInvoiceProviderCode}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col sm="6" md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="certifiedEmail">PEC</Label>
                            <Input
                              type="text"
                              name="certifiedEmail"
                              className="form-control"
                              id="certifiedEmail"
                              placeholder="PEC"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.certifiedEmail || ""}
                              invalid={
                                formik.touched.certifiedEmail &&
                                formik.errors.certifiedEmail
                              }
                            />
                            {formik.touched.certifiedEmail &&
                              formik.errors.certifiedEmail && (
                                <FormFeedback type="invalid">
                                  {formik.errors.certifiedEmail}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="firstName">Referente</Label>
                            <Input
                              type="text"
                              name="firstName"
                              className="form-control"
                              id="firstName"
                              placeholder="Nome"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName || ""}
                              invalid={
                                formik.touched.firstName &&
                                formik.errors.firstName
                              }
                            />
                            {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <FormFeedback type="invalid">
                                  {formik.errors.firstName}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="firstName"
                              className="form-label d-none d-sm-block"
                            >
                              &nbsp;
                            </Label>
                            <Input
                              type="text"
                              id="lastName"
                              name="lastName"
                              className="form-control"
                              placeholder="Cognome"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName || ""}
                              invalid={
                                formik.touched.lastName &&
                                formik.errors.lastName
                              }
                            />
                            {formik.touched.lastName &&
                              formik.errors.lastName && (
                                <FormFeedback type="invalid">
                                  {formik.errors.lastName}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="governmentId">Codice Fiscale</Label>
                            <Input
                              type="text"
                              id="governmentId"
                              name="governmentId"
                              className="form-control"
                              placeholder="Codice Fiscale"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.governmentId || ""}
                              invalid={
                                formik.touched.governmentId &&
                                formik.errors.governmentId
                              }
                            />
                            {formik.touched.governmentId &&
                              formik.errors.governmentId && (
                                <FormFeedback type="invalid">
                                  {formik.errors.governmentId}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              className="form-control"
                              id="email"
                              placeholder="fakeemail@fakeemail.com"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email || ""}
                              invalid={
                                formik.touched.email && formik.errors.email
                              }
                            />
                            {formik.touched.email && formik.errors.email && (
                              <FormFeedback type="invalid">
                                {formik.errors.email}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="phone">Telefono</Label>
                            <Input
                              type="tel"
                              name="phone"
                              className="form-control"
                              id="phone"
                              placeholder="+393330000000"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone || ""}
                              invalid={
                                formik.touched.phone && formik.errors.phone
                              }
                            />
                            {formik.touched.phone && formik.errors.phone && (
                              <FormFeedback type="invalid">
                                {formik.errors.phone}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="imageSelect">Scegli Avatar</Label>
                            <Select
                              options={imageOptions.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )}
                              formatOptionLabel={formatOptionLabel}
                              onChange={(selectedOption) => {
                                // Handle the selected image
                                formik.setFieldValue(
                                  "imagePath",
                                  selectedOption.value
                                );
                              }}
                              value={imageOptions.find(
                                (option) =>
                                  option.value === formik.values.imagePath
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="tags">Tags</Label>
                            <div>
                              <ReactTags
                                placeholder="Usare Invio o , per inserire un nuovo TAG"
                                classNames={{
                                  tags: "ReactTags__tags",
                                  tagInput: "",
                                  tagInputField: "form-control",
                                  selected: "mt-1",
                                  tag: "fs-6 badge badge-soft-primary me-1 mt-1",
                                  remove:
                                    "ReactTags__selected ReactTags__remove",
                                  suggestions: "ReactTags__suggestions",
                                  activeSuggestion:
                                    "ReactTags__activeSuggestion",
                                  editTagInput: "ReactTags__editTagInput",
                                  editTagInputField: "ReactTags__editTagInput",
                                  clearAll: "ReactTags__clearAll",
                                }}
                                tags={tags}
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleTagDelete}
                                handleAddition={handleTagAddition}
                                handleDrag={handleTagDrag}
                                handleTagClick={handleTagClick}
                                inputFieldPosition="top"
                                autocomplete
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="justify-content-end">
                        <Col className="d-flex justify-content-end">
                          <div className="text-end mt-3">
                            <Link
                              className={`btn btn-info waves-effect btn-label waves-light m-2`}
                              onClick={() => window.history.back()}
                            >
                              <i className="bx bx-arrow-back label-icon"></i>{" "}
                              Indietro
                            </Link>
                            <button
                              type="submit"
                              className={`btn btn-primary w-md waves-effect btn-label waves-light m-2`}
                            >
                              <i className="bx bx-user label-icon"></i>{" "}
                              {formik.values.rowKey ? "Aggiorna" : "Crea"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
