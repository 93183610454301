import Chart from "react-apexcharts";

import { _BaseUtil } from "../utils/_BaseUtil";
import { CompanyUtils } from "../utils/CompanyUtils";

export const HoloCompanyIndex = ({
  seniorityIndex,
  capitalIndex,
  dimensionIndex,
  marginIndex,
  growIndex,
  company,
}) => {
  const chartState = {
    series: [
      {
        name: "Indice Holo",
        data: [
          seniorityIndex,
          dimensionIndex,
          marginIndex,
          capitalIndex,
          growIndex,
        ],
      },
    ],
    options: {
      chart: {
        // height: 350,
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: "#e8e8e8",
            fill: {
              colors: ["#f8f8f8", "#fff"],
            },
          },
        },
      },
      colors: ["#556ee6"],
      legend: {
        show: false,
        height: 0,
      },
      markers: {
        size: 5,
        hover: {
          size: 10,
        },
      },
      yaxis: {
        max: 5,
        min: 0,
        tickAmount: 5,
        labels: {
          formatter: function (val, i) {
            return "";
          },
        },
      },
      xaxis: {
        categories: [
          "Anzianità",
          "Dimensione",
          "Marginalità",
          "Capitalizzazione",
          "Trend",
        ],
      },
      labels: {
        show: true,
        style: {
          colors: ["#a8a8a8"],
          fontSize: "11px",
          fontFamily: "Arial",
        },
      },
      dataLabels: {
        enabled: true,
        background: {
          enabled: true,
          borderRadius: 2,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var tt;
          if (!company?.dettaglioObj) return;

          const balanceYear = CompanyUtils.getLastBalanceYear(company);
          const last3balances = CompanyUtils.getLast3Balances(company);
          const balance = last3balances[0];

          const pTurnover = Number(balance.fatturato);
          const pMargins = Number(balance.utile);
          const pCapitalization = Number(balance.capitaleSociale);

          const marginsPercentage = ((pMargins / pTurnover) * 100).toFixed(2);
          const capitalizationPercentage = (
            (pCapitalization / pTurnover) *
            100
          ).toFixed(2);

          let marginCurr = last3balances[0].utile;
          let marginPrev = last3balances[1]?.utile || 1;
          let marginPrev2 = last3balances[2]?.utile || 1;

          let growPercCurr = (
            ((marginCurr - marginPrev) / marginPrev) *
            100
          ).toFixed(2);
          let growPercPrev = (
            ((marginPrev - marginPrev2) / marginPrev2) *
            100
          ).toFixed(2);

          switch (dataPointIndex) {
            case 0:
              // code block
              tt = `<div className="p-1"><strong>Anzianità: ${seniorityIndex}</strong><br>Anno di fondazione ${new Date(
                company.dataIscrizione
              ).getFullYear()}<br>${_BaseUtil.timeAgo(
                company.dataIscrizione
              )}</div>`;
              break;
            case 1:
              // code block
              tt = `<div className="p-1"><strong>Dimensione: ${dimensionIndex}</strong><br>Fatturato ${_BaseUtil.formatCurrency(
                "EUR",
                pTurnover,
                "it-IT"
              )} nel ${balanceYear}<br>${balance.dipendenti} dipendenti</div>`;
              break;
            case 2:
              // code block
              tt = `<div className="p-1"><strong>Marginalità: ${marginIndex}</strong><br>Utile ${_BaseUtil.formatCurrency(
                "EUR",
                pMargins,
                "it-IT"
              )} nel ${balanceYear}<br>Rappresenta il ${marginsPercentage}% del fatturato</div>`;
              break;
            case 3:
              // code block
              tt = `<div className="p-1"><strong>Capitalizzazione: ${capitalIndex}</strong><br>Capitale sociale ${_BaseUtil.formatCurrency(
                "EUR",
                pCapitalization,
                "it-IT"
              )}<br>Rappresenta il ${capitalizationPercentage}% del fatturato</div>`;
              break;
            case 4:
              // code block
              tt = `<div className="p-1"><strong>Trend: ${growIndex}</strong><br>Trend di crescita ${
                (growPercCurr > 0 ? "+" : "") + growPercCurr
              }% nel ${balanceYear - 1}<br>${growPercPrev}% nel ${
                balanceYear - 2
              }</div>`;
              break;
            default:
              tt = "Altro";
            // code block
          }
          return tt;
        },
      },
    },
  };

  return (
    <div className="card-body" style={{ position: "relative" }}>
      <div id="radarHolo" className="text-center">
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="radar"
          height={350}
        />
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "439px", height: "406px" }}></div>
          </div>
          <div className="contract-trigger"></div>
        </div>
      </div>
    </div>
  );
};
