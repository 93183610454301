export const TransactionsUtil = {
  sumAmount: function (transactions) {
    return transactions
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);
  },

  getTotalReceivable: function (transactions) {
    return this.sumAmount(transactions.filter((x) => x.status === 1));
  },

  getExpiringTransactionsInNextXDays: function (transactions, days) {
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + days);

    return transactions
      .filter((x) => x.status === 1)
      .filter(
        (x) =>
          new Date(x.expirationDate) > today &&
          new Date(x.expirationDate) <= maxDate
      );
  },

  isExpired: function (transaction) {
    if (!transaction.expirationDate) return false;
    const today = new Date();
    return new Date(transaction.expirationDate) < today;
  },

  getExpiredTransactions: function (transactions) {
    return transactions
      .filter((x) => x.status === 1)
      .filter((x) => this.isExpired(x));
  },

  getLastXDays: function (transactions, propertyName, days) {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - days);
    return transactions.filter((x) => new Date(x[propertyName]) >= minDate);
  },

  getPaymentProviderDescription: function (paymentProvider) {
    switch (paymentProvider) {
      case 1:
        return "Stripe";
      default:
        break;
    }
    return "PIS";
  },

  getPaymentProviderBadge: function (paymentProvider) {
    switch (paymentProvider) {
      case 1:
        return "bg-primary";
      default:
        break;
    }
    return "bg-info";
  },

  getPaymentMethodDescription: function (paymentMethod) {
    switch (paymentMethod) {
      case 1:
        return "Carta";
      case 2:
        return "Bonifico";
      default:
        break;
    }
    return "";
  },

  getStatusDescription: function (transaction) {
    switch (transaction.status) {
      case 1:
        return "Da Incassare";
      case 2:
        return "Attesa";
      case 3:
        return transaction.amountGross === 0 && transaction.invoiceType !== 1
          ? "Pre Autorizzato"
          : "Incassato"; //1. Fattura
      case 4:
        return "Fallito";
      case 5:
        return "Annullato";
      case 6:
        return "Aborted";
      default:
        break;
    }
    return "";
  },

  getStatusBadge: function (status) {
    switch (status) {
      case 1:
        return "bg-primary";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-success";
      case 4:
        return "bg-danger";
      case 5:
        return "bg-danger";
      case 6:
        return "bg-info";
      default:
        break;
    }
    return "";
  },

  getDailyBalances: function (obAccounts, obTransactions) {
    if (!obTransactions || !obTransactions.length) return [];

    let obAccountIds = obAccounts.map((x) => x.uuid);
    obTransactions = obTransactions.filter((x) =>
      obAccountIds.includes(x.account.uuid)
    );

    // Sort the transactions by date
    obTransactions.sort((a, b) => new Date(a.madeOn) - new Date(b.madeOn));

    // Dictionary to store daily balances for each account
    const dailyBalancesMap = new Map();

    // Initialize the balances with the first transaction's balance
    const initialBalances = {};
    obTransactions.forEach((transaction) => {
      const accountUuid = transaction.account.uuid;
      const balance = transaction.extra.accountBalanceSnapshot;
      initialBalances[accountUuid] = balance;
    });

    let currentBalances = { ...initialBalances };

    // Function to update daily balances
    const updateDailyBalances = (date, balances) => {
      const formattedDate = date.toISOString().split("T")[0];
      const totalBalance = Object.values(balances).reduce(
        (sum, balance) => sum + balance,
        0
      );
      dailyBalancesMap.set(formattedDate, {
        date: formattedDate,
        balances: { ...balances },
        totalBalance: totalBalance.toFixed(2),
      });
    };

    // Iterate through transactions
    obTransactions.forEach((transaction) => {
      const date = new Date(transaction.madeOn).setHours(0, 0, 0, 0);
      const accountUuid = transaction.account.uuid;
      const balance = transaction.extra.accountBalanceSnapshot;

      // Update daily balances
      updateDailyBalances(new Date(date), currentBalances);

      // Update current balances
      currentBalances = { ...currentBalances, [accountUuid]: balance };
    });

    // If there are missing dates, add them with the last known balance
    const allDates = [...dailyBalancesMap.keys()].map((formattedDate) =>
      new Date(formattedDate).setHours(0, 0, 0, 0)
    );
    const lastDate = new Date(); // allDates.length > 0 ? Math.max(...allDates) : 0;
    const missingDates = Array.from(
      { length: (lastDate - Math.min(...allDates)) / (24 * 60 * 60 * 1000) },
      (_, index) => new Date(lastDate - (index + 1) * 24 * 60 * 60 * 1000)
    ).sort((a, b) => a - b); // Sort missing dates in ascending order

    missingDates.forEach((date) => {
      const formattedDate = date.toISOString().split("T")[0];
      if (!dailyBalancesMap.has(formattedDate)) {
        const previousDate = new Date(date);
        previousDate.setDate(previousDate.getDate() - 1);
        const previousDateValue = dailyBalancesMap.get(
          previousDate.toISOString().split("T")[0]
        );
        if (previousDateValue) {
          dailyBalancesMap.set(formattedDate, {
            date: formattedDate,
            balances: { ...previousDateValue.balances },
            totalBalance: previousDateValue.totalBalance,
          });
        }
      }
    });

    // Set the state with the calculated daily balances
    const sortedDailyBalances = [...dailyBalancesMap.values()].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    return sortedDailyBalances;
  },
};
