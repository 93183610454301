import React, { useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { Button } from "reactstrap"; // Assicurati di avere reactstrap installato per usare Button
import "../../assets/css/reactTags.css";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const HoloTags = ({ initialTags, suggestions, readOnly, onChange }) => {
  const tagsColorClasses = ["primary", "success", "warning", "danger"];

  const [tags, setTags] = useState(
    initialTags.map((tag, index) => ({ id: index.toString(), text: tag }))
  );
  const [editable, setEditable] = useState(false);
  const [editTags, setEditTags] = useState([...tags]); // Stato temporaneo per la modifica

  const handleDelete = (i) => {
    setEditTags(editTags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setEditTags([...editTags, tag]);
  };

  const handleEdit = () => {
    if (!readOnly) {
      setEditable(true);
    }
  };

  const handleSave = () => {
    setTags(editTags);
    if (onChange) {
      onChange(editTags.map((tag) => tag.text)); // Solleva l'evento onChange con i nuovi tag
    }
    setEditable(false);
  };

  const handleCancel = () => {
    setEditTags([...tags]); // Reset alla versione precedente
    setEditable(false);
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="flex-grow-1 me-2">
        {editable ? (
          <ReactTags
            placeholder="Usare Invio o , per inserire un nuovo TAG"
            tags={editTags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            delimiters={delimiters}
            suggestions={suggestions}
            inputFieldPosition="top"
            autocomplete
            readOnly={readOnly}
            classNames={{
              tags: "ReactTags__tags",
              tagInput: "",
              tagInputField: "form-control",
              selected: "mt-1",
              tag: "fs-6 badge badge-soft-primary me-1 mt-1",
              remove: "ReactTags__selected ReactTags__remove",
              suggestions: "ReactTags__suggestions",
              activeSuggestion: "ReactTags__activeSuggestion",
              editTagInput: "ReactTags__editTagInput",
              editTagInputField: "ReactTags__editTagInput",
              clearAll: "ReactTags__clearAll",
            }}
          />
        ) : (
          tags.map((tag, i) => (
            <span
              key={tag.id}
              className={`fs-6 me-1 badge badge-soft-${tagsColorClasses[i]} ${
                !!i && "mt-1"
              }`}
            >
              {tag.text}
            </span>
          ))
        )}
      </div>

      <div
        className="d-flex flex-column justify-content-start"
        style={{ width: "50px" }}
      >
        {!readOnly && (
          <div className="buttons-container">
            {editable ? (
              <>
                <div className="my-2">
                  <Button
                    color="success"
                    size="sm"
                    onClick={handleSave}
                    className="me-2"
                  >
                    <i className="fas fa-check"></i>
                  </Button>
                  <Button color="danger" size="sm" onClick={handleCancel}>
                    <i className="fas fa-times"></i>
                  </Button>
                </div>
              </>
            ) : (
              <Button color="light" size="sm" onClick={handleEdit}>
                <i className="fas fa-pencil-alt"></i>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HoloTags;
