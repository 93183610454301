import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { atomClearCache } from "../../api/decorators/cache/AtomPersistence";

const RouteChangeHandler = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const refreshContextData = async () => {
      // Verifica se lo stato di navigazione include `nocache`
      const nocache = location.state?.nocache || false;

      // Chiama la funzione di refresh con il parametro appropriato
      if (nocache) atomClearCache();
    };
    refreshContextData();
  }, [location]);

  return <>{children}</>;
};

export default RouteChangeHandler;
