import { _BaseUtil } from "./_BaseUtil";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";

import "../extensions/dateExtensions"; // Import the dateExtensions.js file

export const EstimatesUtil = {
  getModelTemplate: async function (businessId) {
    let today = new Date();

    let estimate = {
      code: await this.getNextAvailableCodeAsync(
        businessId,
        today.getFullYear()
      ),
      revisionNumber: 1,
      contactId: null,
      allowPublicPageEdit: false,
      requestContractAcceptance: true,
      subject: "",
      description: "",
      closingDays: 60,
      tags: "",
      currencyType: 1, //1. Fiat, 2. Crypto
      currency: "EUR",
      customerNotes: "",
      status: 1, //1. Draft, 2. Waiting, 3. Accepted, 4. Declined, 5. Reviewed
      revisedEstimateId: "", // To fill only for revise
    };

    estimate.date = today;
    estimate.expirationDate = today.addDays(5);
    estimate.closingDate = today.addDays(estimate.closingDays);

    let model = {
      estimate: estimate,
      rows: [],
      conditions: [],
      links: [],
    };

    return model;
  },

  countByCustomerId: function (estimatesModel, customerId) {
    return _BaseUtil.countBy(
      estimatesModel.map((x) => x.estimate),
      "customerId",
      customerId
    );
  },

  getNextAvailableCodeAsync: async function (businessId, year) {
    const estimates = await EstimatesApi_Cache.get(businessId);

    if (!estimates || !estimates.length) {
      // If there are no existing codes, start with 1/2023
      return `1/${year}`;
    }

    let existingCodes = estimates
      .map((x) => x.estimate.code)
      .filter((x) => !!x);

    existingCodes = existingCodes
      .filter((code) => code.endsWith(`/${year}`)) // Filter by current year
      .map((code) => code.split("/")[0]); // Extract the number

    existingCodes = existingCodes.filter((x) => !isNaN(x));

    const maxNumber = Math.max(...existingCodes);

    // Increment the maximum number
    const nextNumber = (isNaN(maxNumber) ? 0 : maxNumber) + 1;
    return `${nextNumber}/${year}`;
  },

  getRowByService: function (service) {
    if (!service) return {};
    return {
      serviceId: service.rowKey,
      description: service.description,
      quantity: service.defaultQuantity || 1,
      vatType: service.vatType || 0,
      amount: service.defaultAmount,
    };
  },

  getInvoiceType: function (invoiceType) {
    switch (invoiceType) {
      case 0:
        return "No Fattura";
      case 1:
        return "Alla Consegna";
      case 2:
        return "Con fattura d'anticipo";
      case 3:
        return "Nota di credito";
      default:
        break;
    }
    return "";
  },

  getStatusDescription: function (status) {
    switch (status) {
      case 1:
        return "Bozza";
      case 2:
        return "Attesa pagamento";
      case 3:
        return "Confermato";
      case 4:
        return "Rifiutato";
      case 5:
        return "Revisionato";
      case 6:
        return "Annullato";
      default:
        break;
    }
    return "";
  },

  getStatusBadge: function (status) {
    switch (status) {
      case 1:
        return "bg-primary";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-success";
      case 4:
        return "bg-danger";
      case 5:
        return "bg-info";
      case 6:
        return "bg-info";
      default:
        break;
    }
    return "";
  },

  getClosingLabel: function (status) {
    switch (status) {
      case 3:
        return "Data conferma";
      case 4:
        return "Data rifiuto";
      case 5:
        return "Data revisione";
      case 6:
        return "Data annullamento";
      default:
        break;
    }
    return "";
  },

  getServicesTotalAmount: function (services, type) {
    console.log("getServicesTotalAmount", services);
    if (!services || !services.length) return 0;

    let amount = services
      .filter((x) => x.serviceType === type)
      .reduce(
        (acc, row) =>
          Number(acc) +
          Number(row.amount * row.quantity * (row.billingIntervalsCount || 1)),
        0
      );

    return _BaseUtil.formatCurrency("EUR", amount, "it-IT");
  },

  mapToEstimateCondition: function (estimateId, condition) {
    if (!condition) return {};

    let estimateCondition = { ...condition };
    estimateCondition.estimateId = estimateId;

    console.log("EstimateCondition", estimateCondition);

    return estimateCondition;
  },

  getNextMonthFirstDayDate: function () {
    const date = new Date();

    date.setMonth(date.getMonth() + 1);
    date.setDate(1);

    return date;
  },

  getDefaultCondition: function (estimateConditions) {
    let defaultConditions = (estimateConditions || []).filter(
      (x) => x.isDefault
    );
    return defaultConditions.length ? defaultConditions[0] : null;
  },
};
