import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { getI18nextConfig } from "./configs/i18nextConfig";

import { atomClearCache } from "./api/decorators/cache/AtomPersistence.js";

import jwtDecode from "jwt-decode";

import { HttpLoader } from "./components/HttpLoader";
import { loginRequest } from "./configs/authConfig";
import { api } from "./configs/axiosConfigs";

import HoloApp from "./HoloApp";

import { BusinessesApi_Cache } from "./api/decorators/cache/BusinessesApi_Cache";
import { BusinessesRolesApi_Cache } from "./api/decorators/cache/BusinessesRolesApi_Cache";
import { UsersApi_Cache } from "./api/decorators/cache/UsersApi_Cache";

import { UserContext } from "./contexts/AppContexts.js";

import { clarity } from "react-microsoft-clarity";
import SplashScreen from "./components/Splash/SplashScreen.jsx";
clarity.init(process.env.REACT_APP_CLARITY_ID);
clarity.consent();

const isPageRefreshed = () => {
  const entries = performance.getEntriesByType("navigation");
  return entries && entries[0]?.type === "reload";
};

const App = () => {
  const [user, setUser] = useState(null);
  const [businesses, setBusinesses] = useState(null);

  const { instance, inProgress } = useMsal();

  const [requestCounter, setRequestCounter] = useState(0);

  // http interceptors
  useEffect(() => {
    const resetCache = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const resetParam = queryParams.get("reset");
      const nocache = resetParam === "true" || isPageRefreshed();
      if (resetParam) {
        const urlWithoutParam = window.location.pathname + window.location.hash;
        window.history.replaceState(null, "", urlWithoutParam);
      }
      if (nocache) atomClearCache();
    };

    // cleaning the cache
    resetCache();

    // authentication
    api.interceptors.request.use(authenticationInterceptor);

    // loader
    api.interceptors.request.use(loaderRequestInterceptor);
    api.interceptors.response.use(
      loaderResponseInterceptor,
      loaderResponseInterceptor
    );
  }, []);

  const authenticationInterceptor = async function (requestConfig) {
    let idToken = await fetchToken();
    requestConfig.headers.Authorization = `Bearer ${idToken}`;
    return requestConfig;
  };

  const loaderRequestInterceptor = function (requestConfig) {
    if (requestConfig.headers["h-background"]) return requestConfig;
    setRequestCounter((val) => val + 1);
    console.log(`RequestCounter: ${requestCounter}`);
    return requestConfig;
  };

  const loaderResponseInterceptor = function (axios) {
    setRequestCounter((val) => (val - 1 < 0 ? 0 : val - 1));
    console.log(`RequestCounter: ${requestCounter}`);

    switch (axios.response?.status) {
      case 400:
        console.warn("Attenzione si è verificato un warn: ", axios.response);
        toast.warn(axios.response.data);
        break;
      case 500:
        console.error("Attenzione si è verificato un errore: ", axios.response);
        // toast.error("Attenzione si è verificato un errore.");
        break;
      default:
        break;
    }
    return axios;
  };

  // gestione autenticazione e startup
  useEffect(() => {
    const handleOnLoad = async () => {
      console.log(`MSAL progress status: ${inProgress}`);
      if (inProgress !== InteractionStatus.None) return;
      await fetchToken();
      await fetchUser();
      await fetchData();
    };
    handleOnLoad();
  }, [inProgress]);

  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decodedToken.exp < currentTime;
    } catch (error) {
      // Token is invalid or cannot be decoded
      return true;
    }
  };

  const redirectToLogin = function () {
    console.log("Redirect to login...");
    try {
      instance.loginRedirect(loginRequest);
    } catch (err) {
      console.log("Redirect to login error", err);
      throw err;
    }
  };

  const fetchToken = async function () {
    let idToken = localStorage.getItem("H-idToken");

    if (idToken && !isTokenExpired(idToken)) {
      console.log("idtoken from storage");
      return idToken;
    }

    let activeAccount = instance.getActiveAccount();

    if (!activeAccount) {
      console.log("Active account not found");
      redirectToLogin();
      return;
    }

    console.log("Active account found", activeAccount);
    console.log("Acquiring silent token");

    //MS BUG - cache is not working --> https://learn.microsoft.com/en-us/answers/questions/1296474/how-to-solve-cachemanager-getidtoken-no-token-foun
    let response = null;
    let loginNeeded = false;
    try {
      response = await instance.acquireTokenSilent({
        account: activeAccount,
        scopes: ["openid", "profile"],
      });

      console.log("acquireTokenSilent response: ", response);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        loginNeeded = true;
      }
    }

    if (loginNeeded) {
      console.log("acquiring token popup");
      response = await instance.acquireTokenRedirect({
        account: activeAccount,
        scopes: ["openid", "profile"],
      });
      console.log("acquireTokenPopup response: ", response);
    }

    idToken = response.idToken;

    localStorage.setItem("H-idToken", idToken);

    return idToken;
  };

  const fetchUser = async () => {
    const user = await UsersApi_Cache.me(true);
    setUser(user);
  };

  const fetchData = async () => {
    // caching results
    const tBusinesses = await BusinessesApi_Cache.get(false, true);
    await BusinessesRolesApi_Cache.get(false, true);

    setBusinesses(tBusinesses);
  };

  useEffect(() => {
    if (!user) return;
    const setupClarity = () => {
      if (!clarity) return;
      // Check if Clarity has been initialized before calling its methods
      if (clarity.hasStarted()) {
        clarity.identify("USER_ID", {
          userEmail: user.rowKey || "Unknown",
        });
      }
    };
    setupClarity();
    // i18next.init(getI18nextConfig(user.locale));
    i18next.init(getI18nextConfig("it"));
  }, [user]);

  return (
    <>
      {!!user && !!businesses ? (
        <>
          <HttpLoader visible={requestCounter > 0} />
          <I18nextProvider i18n={i18next}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <UserContext.Provider value={{ user, setUser }}>
              <HoloApp />
            </UserContext.Provider>
          </I18nextProvider>
        </>
      ) : (
        <SplashScreen />
      )}
    </>
  );
};

export default App;
