import { StripeApi } from "../../StripeApi";

export const StripeApi_Cache = {
  apiName: "StripeApi_Cache",

  connectAsync: async function (businessId) {
    const fnName = "connectAsync";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    let response = await StripeApi.connectAsync(businessId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },

  accountSessionsStart: async function (businessId) {
    const fnName = "accountSessionsStart";

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Request`, businessId);

    let response = await StripeApi.accountSessionsStart(businessId);

    console.log(`🚀 ~ ${this.apiName} ~ ${fnName} ~ Response: `, response);

    return response;
  },
};
