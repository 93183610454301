import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const FattureInCloudCTA = () => {
  const navigate = useNavigate();
  const [showCTA, setShowCTA] = useState(true); // Stato per mostrare/nascondere la CTA

  const connect = async () => {
    console.log("Connecting to Fatture in Cloud");
    navigate("/settings");
  };

  const hideCTA = () => {
    setShowCTA(false); // Nasconde la CTA
  };

  if (!showCTA) {
    return null; // Non renderizzare nulla se showCTA è false
  }

  return (
    <>
      <div className="card bg-info bg-soft card-std-height-xs">
        <div className="text-info p-3">
          <h1 className="text-info">
            Stai utilizzando Fatture in Cloud? Collegalo!
          </h1>
          <p>
            Se utilizzi già Fatture in Cloud, collegando il tuo account potrai
            proseguire con i tuoi numeratori esistenti, facilitando il lavoro
            del tuo commercialista.
          </p>

          <p className="mt-4">
            In caso contrario, puoi sempre nascondere questa notifica e lasciar
            gestire tutto ad Holo! Holo aiuta i liberi professionisti che odiano
            la burocrazia ad automatizzare preventivi, incassi e fatture!
          </p>

          <p className="mt-4">
            Segui Holo e scordati una volta per tutte delle fatture!
          </p>

          <div className="mt-4 text-center">
            <button onClick={connect} className="btn btn-info btn-lg mb-1 me-2">
              <i className="bx bx-link align-middle"></i> Collega Fatture in
              Cloud
            </button>
            <button onClick={hideCTA} className="btn btn-secondary btn-lg mb-1">
              Nascondi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
