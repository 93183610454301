import { _BaseUtil } from "../../../utils/_BaseUtil";

export const ExpensesCellHandler = {
  get: function (movements) {
    let fuel = Math.abs(
      movements
        .filter((x) => x.expenseType === 4)
        .map((x) => x.amount)
        .reduce((total, num) => Number(total) + Number(num), 0)
        .toFixed(2)
    );
    let eletricity = Math.abs(
      movements
        .filter((x) => x.expenseType === 5)
        .map((x) => x.amount)
        .reduce((total, num) => Number(total) + Number(num), 0)
        .toFixed(2)
    );
    let phone = Math.abs(
      movements
        .filter((x) => x.expenseType === 6)
        .map((x) => x.amount)
        .reduce((total, num) => Number(total) + Number(num), 0)
        .toFixed(2)
    );

    return {
      total: fuel + eletricity + phone,
      fuel,
      eletricity,
      phone,
    };
  },
};

const ExpensesCell = ({ cell }) => {
  return (
    <>
      <h5 className="font-size-14 ">
        Totale: {_BaseUtil.formatCurrency("EUR", cell.total, "it-IT")}
      </h5>
      <div className="text-truncate">
        Carburanti: {_BaseUtil.formatCurrency("EUR", cell.fuel, "it-IT")}
      </div>
      <div className="text-truncate">
        Energia elettr.:{" "}
        {_BaseUtil.formatCurrency("EUR", cell.eletricity, "it-IT")}
      </div>
      <div className="text-truncate">
        Telefonia: {_BaseUtil.formatCurrency("EUR", cell.phone, "it-IT")}
      </div>
    </>
  );
};

export default ExpensesCell;
