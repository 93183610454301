import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../assets/css/reactTags.css";

import { BusinessContext } from "../contexts/AppContexts";

import { atomClearCache } from "../api/decorators/cache/AtomPersistence";

import { ContactsApi_Cache } from "../api/decorators/cache/ContactsApi_Cache";
import { EstimatesApi_Cache } from "../api/decorators/cache/EstimatesApi_Cache";
import { InvoicesApi_Cache } from "../api/decorators/cache/InvoicesApi_Cache";
import { OrdersApi_Cache } from "../api/decorators/cache/OrdersApi_Cache";
import { SubscriptionsApi_Cache } from "../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../api/decorators/cache/TransactionsApi_Cache";

import { _BaseUtil } from "../utils/_BaseUtil";

import { OrderDetailsCard } from "../components/OrderDetailsCard";
import { CardTable, CardTableUtil } from "../components/CardTable";
import { PageHeader } from "../components/PageHeader";
import PaymentsCardTable from "../components/CardTables/PaymentsCardTable";

export default function OrderDetails() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const { id } = useParams();

  const [orderModel, setOrderModel] = useState(null);
  const [contact, setContact] = useState(null);
  const [estimateModel, setEstimateModel] = useState(null);
  const [estimateCondition, setEstimateCondition] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleOnLoad = async (nocache) => {
    if (nocache) atomClearCache();
    await fetchOrder();
  };

  useEffect(() => {
    if (!id) return;

    handleOnLoad();
  }, [businessId, id]);

  const fetchOrder = async () => {
    setOrderModel(
      (await OrdersApi_Cache.get(businessId)).find((x) => x.order.rowKey === id)
    );
  };

  useEffect(() => {
    if (!orderModel) return;
    const orderId = orderModel.order.rowKey;
    const contactId = orderModel.order.contactId;
    const estimateId = orderModel.order.estimateId;

    const fetchContact = async () => {
      setContact(
        (await ContactsApi_Cache.get(businessId)).find(
          (x) => x.rowKey === contactId
        )
      );
    };

    const fetchEstimate = async () => {
      setEstimateModel(
        (await EstimatesApi_Cache.get(businessId)).find(
          (x) => x.estimate.rowKey === estimateId
        )
      );
    };

    const fetchTransactions = async () => {
      setTransactions(
        (await TransactionsApi_Cache.get(businessId)).filter(
          (x) => x.orderId === orderId
        )
      );
    };

    const fetchInvoices = async () => {
      setInvoices(
        (await InvoicesApi_Cache.get(businessId)).filter(
          (x) => x.orderId === orderId
        )
      );
    };

    const fetchSubscriptions = async () => {
      setSubscriptions(
        (await SubscriptionsApi_Cache.get(businessId)).filter(
          (x) => x.orderId === orderId
        )
      );
    };

    fetchContact();
    fetchEstimate();
    fetchTransactions();
    fetchInvoices();
    fetchSubscriptions();
  }, [businessId, orderModel]);

  useEffect(() => {
    if (!orderModel) return;
    if (!estimateModel) return;

    setEstimateCondition(
      estimateModel.conditions.find(
        (x) => x.rowKey === orderModel.order.estimateConditionId
      )
    );
  }, [orderModel, estimateModel]);

  const servicesCols = [
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "quantity",
      value: "Quantità",
    },
    {
      key: "amount",
      value: "Importo",
    },
  ];
  const [servicesRows, setServicesRows] = useState([]);
  const loadCardTableServices = () => {
    if (!orderModel) return;
    let orderRows = orderModel.rows || [];
    let services = orderRows
      .filter((x) => x.serviceType === 1)
      .sort((a, b) => {
        return a.lineNumber - b.lineNumber;
      });

    let tblRows = services.map((x) => {
      return {
        serviceName: CardTableUtil.getCellText(`${x.serviceName}`),
        quantity: CardTableUtil.getCellText(`${x.quantity}`),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
        ),
      };
    });

    setServicesRows(tblRows);
  };

  const subscriptionsCols = [
    {
      key: "serviceName",
      value: "Nome",
    },
    {
      key: "amount",
      value: "Importo Totale",
    },
  ];

  const [subscriptionsRows, setSubscriptionsRows] = useState([]);
  const loadCardTableSubscriptions = () => {
    if (!orderModel) return;
    if (!subscriptions.length) return;

    let orderRows = orderModel.rows || [];
    let plans = orderRows
      .filter((x) => x.serviceType === 2)
      .sort((a, b) => {
        return a.lineNumber - b.lineNumber;
      });

    let tblRows = plans.map((x) => {
      let subscription = subscriptions.find((s) => s.serviceId === x.serviceId);

      return {
        serviceName: CardTableUtil.getCellLink(
          x.serviceName,
          `text-primary fw-bold`,
          `/subscription/${subscription.rowKey}/details`
        ),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(
            x.currency,
            x.amount * x.billingIntervalsCount,
            "it-IT"
          )}`
        ),
      };
    });

    setSubscriptionsRows(tblRows);
  };

  const conditionsCols = [
    {
      key: "name",
      value: "Nome",
    },
    {
      key: "default",
      value: "Default",
    },
  ];
  const [conditionsRows, setConditionsRows] = useState([]);
  const loadCardTableConditions = () => {
    if (!orderModel) return;
    let orderConditions = orderModel.conditions || [];

    let tblRows = orderConditions.map((x) => {
      return {
        name: CardTableUtil.getCellText(`${x.name}`),
        default: CardTableUtil.getCellText(`${x.default ? "Si" : "No"}`),
      };
    });

    setConditionsRows(tblRows);
  };

  const linksCols = [
    {
      key: "name",
      value: "Nome",
    },
    {
      key: "url",
      value: "Url",
    },
  ];
  const [linksRows, setLinksRows] = useState([]);
  const loadCardTableLinks = () => {
    if (!orderModel) return;
    let orderLinks = orderModel.links || [];

    let tblRows = orderLinks.map((x) => {
      return {
        name: CardTableUtil.getCellText(`${x.name}`),
        quantity: CardTableUtil.getCellText(`${x.default ? "Si" : "No"}`),
      };
    });

    setLinksRows(tblRows);
  };

  const invoicesCols = [
    {
      key: "code",
      value: "Codice",
    },
    {
      key: "amount",
      value: "Importo",
    },
    {
      key: "status",
      value: "Stato",
    },
  ];
  const [invoicesRows, setInvoicesRows] = useState([]);
  const loadCardTableInvoices = () => {
    if (!orderModel?.order) return;
    if (!invoices?.length) return;

    let tblValues = invoices.filter(
      (x) => x.orderId === orderModel.order.rowKey
    );

    let tblRows = tblValues.map((x) => {
      return {
        code: CardTableUtil.getCellLink(
          x.code,
          `text-primary fw-bold`,
          `/invoice/${x.rowKey}/details`
        ),
        amount: CardTableUtil.getCellText(
          `${_BaseUtil.formatCurrency(x.currency, x.amount, "it-IT")}`
        ),
        status: CardTableUtil.getCellText(`${x.status}`),
      };
    });

    setInvoicesRows(tblRows);
  };

  useEffect(() => {
    loadCardTableServices();
    loadCardTableConditions();
    loadCardTableLinks();
  }, [orderModel]);

  useEffect(() => {
    loadCardTableSubscriptions();
  }, [orderModel, subscriptions]);

  useEffect(() => {
    loadCardTableInvoices();
  }, [orderModel, invoices]);

  const handleComplete = async () => {
    let url = await OrdersApi_Cache.complete(
      businessId,
      orderModel.order.rowKey
    );

    if (url) {
      console.log("url", url);
      try {
        await navigator.share({
          title: "Holo - Completamento ordine",
          text: `Questo è il link per procedere al pagamento. <br /><br />Clicca qui per vedere i dettagli: ${url}`,
          url: url,
        });
      } catch {
        await navigator.clipboard.writeText(url);
        toast.info("Link di pagamento copiato negli appunti");
      }
    }

    await handleOnLoad(true);
  };

  return (
    !!orderModel &&
    !!estimateModel && (
      <>
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <PageHeader
                  title={`Dettaglio ordine: ${orderModel.order.code}`}
                  pageName={`Dettaglio ordine`}
                  backPageName={`Ordini`}
                  backPageRoute={`/orders`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4">
                <OrderDetailsCard
                  order={orderModel.order}
                  contact={contact}
                  estimate={estimateModel.estimate}
                  estimateCondition={estimateCondition}
                  onComplete={handleComplete}
                />
              </div>

              <div className="col-xxl-8">
                <PaymentsCardTable
                  title="Pagamenti"
                  transactions={transactions}
                />
              </div>

              {!!invoicesRows.length && (
                <div className="col-md-6 col-xxl-4">
                  <CardTable
                    key={`orderdetails-card-table-invoices`}
                    title={`Fatture`}
                    columnNames={invoicesCols}
                    values={invoicesRows}
                  />
                </div>
              )}

              {!!servicesRows.length && (
                <div className="col-md-6 col-xxl-4">
                  <CardTable
                    key={`orderdetails-card-table-services`}
                    title={`Servizi`}
                    columnNames={servicesCols}
                    values={servicesRows}
                  />
                </div>
              )}

              {!!subscriptionsRows.length && (
                <div className="col-md-6 col-xxl-4">
                  <CardTable
                    key={`orderdetails-card-table-subscriptions`}
                    title={`Sottoscrizioni`}
                    columnNames={subscriptionsCols}
                    values={subscriptionsRows}
                  />
                </div>
              )}

              {!!conditionsRows.length && (
                <div className="col-md-6 col-xxl-4">
                  <CardTable
                    key={`orderdetails-card-table-conditions`}
                    title={`Condizioni`}
                    columnNames={conditionsCols}
                    values={conditionsRows}
                  />
                </div>
              )}

              {!!linksRows.length && (
                <div className="col-md-6 col-xxl-4">
                  <CardTable
                    key={`orderdetails-card-table-link`}
                    title={`Link utili`}
                    columnNames={linksCols}
                    values={linksRows}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
}
