import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { BusinessContext } from "../contexts/AppContexts";

import { BusinessesApi_Cache } from "../api/decorators/cache/BusinessesApi_Cache";

import { CardBusinessSettings } from "../components/CardBusinessSettings";
import { AccountSettings } from "../components/AccountSettings";
import { GoogleIntegrationCalendar } from "../components/GoogleIntegrationCalendar";
import { FattureInCloudIntegration } from "../components/FattureInCloudIntegration";
import { SettingsContractEditor } from "../components/SettingsContractEditor";

export default function Settings() {
  const { business, setBusiness } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const onChangeContract = async (file) => {
    if (!file) {
      console.warn("No file selected.");
      return;
    }

    // Verifica la dimensione del file
    const maxSizeBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeBytes) {
      toast.warn("La dimensione del file supera il limite consentito di 5MB.");
      return;
    }

    // Verifica l'estensione del file
    const allowedExtensions = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //.docx
      "application/pdf",
    ];
    if (!allowedExtensions.includes(file.type)) {
      toast.warn(
        "Tipo di file non supportato. Sono consentite le estensioni: .docx e .pdf"
      );
      return;
    }

    try {
      // Call your API function to upload the logo here
      const result = await BusinessesApi_Cache.uploadContract(
        businessId,
        file.name,
        file
      );
      console.log("Logo uploaded successfully.");

      if (result) {
        setBusiness(result);
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Impostazioni</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link>Impostazioni</Link>
                    </li>
                    <li className="breadcrumb-item active">Impostazioni</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <CardBusinessSettings />
            </div>
            <div className="col-lg-6">
              <AccountSettings onChangeContract={onChangeContract} />
            </div>
            <div className="col-lg-6">
              <FattureInCloudIntegration />
            </div>
            <div className="col-12">
              <GoogleIntegrationCalendar
                businessId={businessId}
                isActive={business.googleAccessToken}
              />
            </div>
            <div className="col-12">
              <SettingsContractEditor />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
