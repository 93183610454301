import React, { useState, useEffect, useRef } from "react";
import "../assets/css/ChatBotDify.css";
import logo from "../assets/images/logo-64.png"; // Import del logo

import { marked } from "marked";

const ChatBotDify = ({
  id,
  title,
  description,
  auth,
  apiversion,
  userId,
  businessId,
}) => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [conversationId, setConversationId] = useState("");

  const chatHistoryRef = useRef(chatHistory);
  const chatContainerRef = useRef(null);

  const apiKey = "app-k8VcQoAj86o6gH6g3vFflTON"; // Sostituisci con la tua API key

  const loadingMessages = [
    "Sto scrivendo...",
    "Dammi un secondo...",
    "Elaboro i dati...",
    "Controllo i miei appunti...",
    "Un attimo, per favore...",
  ];

  const getRandomLoadingMessage = () => {
    return loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
  };

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    const newMessage = { sender: "user", text: userInput };
    chatHistoryRef.current = [...chatHistoryRef.current, newMessage];
    setChatHistory(chatHistoryRef.current);
    setUserInput("");
    setLoadingMessage(getRandomLoadingMessage());
    setLoading(true);

    try {
      const response = await fetch("https://api.dify.ai/v1/chat-messages", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputs: {
            auth,
            apiversion,
            businessid: businessId,
            regimestartup: "no startup 15%",
            codiceateco: "62",
            coefficenteredditivita: 76,
            fatturato: 50000,
          },
          query: userInput,
          response_mode: "streaming",
          conversation_id: conversationId,
          user: userId,
          files: [
            {
              type: "image",
              transfer_method: "remote_url",
              url: "https://cloud.dify.ai/logo/logo-site.png",
            },
          ],
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let botMessage = { sender: "bot", text: "" };
      chatHistoryRef.current = [...chatHistoryRef.current, botMessage];
      setChatHistory([...chatHistoryRef.current]);

      let partialText = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        partialText += decoder.decode(value, { stream: true });

        const lines = partialText.split("\n\n");
        partialText = lines.pop(); // keep last incomplete line for next chunk

        for (const line of lines) {
          if (line.startsWith("data:")) {
            const data = JSON.parse(line.substring(5).trim());
            if (data.event === "message") {
              botMessage.text += data.answer;
              chatHistoryRef.current = [...chatHistoryRef.current];
              setChatHistory(chatHistoryRef.current);
            }
          }
        }
      }

      setConversationId(botMessage.conversation_id);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = {
        sender: "bot",
        text: "Errore nella connessione al server.",
      };
      chatHistoryRef.current = [...chatHistoryRef.current, errorMessage];
      setChatHistory([...chatHistoryRef.current]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    setChatHistory(chatHistoryRef.current);
  }, [chatHistory]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const renderMessage = (message) => {
    const htmlContent = marked.parse(message.text);
    return (
      <div
        className={message.sender === "user" ? "user-message" : "bot-message"}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
    );
  };

  return (
    <div id={id} className="chatbot-dify">
      <div className="chat-icon" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <i className="bx bx-x" /> : <i className="bx bx-bot" />}
      </div>
      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <img
              src={logo}
              alt="Logo"
              className={`logo-left ${loading ? "logo-spin" : ""}`}
            />
            <h2>{title}</h2>
            <img
              src={logo}
              alt="Logo"
              className={`logo-right ${loading ? "logo-spin" : ""}`}
            />
          </div>
          <div className="chat-history" ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div key={index}>{renderMessage(message)}</div>
            ))}
            {loading && <div className="loading-message">{loadingMessage}</div>}
          </div>
          <div className="input-container">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Scrivi un messaggio..."
            />
            <button onClick={handleSendMessage} disabled={!userInput.trim()}>
              Invia
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBotDify;
